import type { RequestErrorType } from "src/types";

export class RequestError extends Error {
    private data;

    private status;

    constructor(property: RequestErrorType) {
        super(`request-error - ${property.status}`);
        this.name = "Request Error";
        this.data = property.data;
        this.status = property.status;
    }
}
