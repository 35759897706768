import styled from "styled-components";

export const CookieBannerLayout = styled.div`
    position: fixed;
    top: 0;
    left: 0;
    width: 100%;
    padding: 3.2rem 1.6rem;
    color: ${({ theme }) => theme.color.white};
    background-color: ${({ theme }) => theme.color.neutral400};
    gap: 1rem;
    box-shadow: rgba(0, 0, 0, 0.24) 0px 3px 8px;
`;
