import { debounce } from "debounce";
import { useFormikContext } from "formik";
import type { FC } from "react";
import { useMemo, useState } from "react";

import { useLazyGetCurrentPriceForProductPackageQuery, useLazyGetProductPackageByNameQuery } from "src/api";
import { Grid, NumberInput, NumberInputProps, Select, SelectProps, Text } from "src/components";
import { createCurrencyInputProps, InputField, percentageInputProps } from "src/form";
import { NotificationTypes, useNotifications } from "src/notifications";
import { Color } from "src/theme";
import { useTranslation } from "src/translations";
import { isEmpty, isNotNullish, useOnUpdate } from "src/utils";
import { PriceBox } from "../../components";
import type { CreateAndUpdateSpecialPrice, SimpleProductPackage } from "./types";

const DEBOUNCE_SEARCH_VALUE = 500;
const productPackageField = "productPackageId";
const productPriceField = "productPrice";
const productPackageMarginField = "productPackageMargin";
const productPriceInclMarginField = "productPriceInclMargin";
const specialPriceInclMarginField = "specialPriceInclMargin";

type ProductPackagePriceFieldProps = {
    selectedProductPackage?: SimpleProductPackage;
    isEditMode?: boolean;
};

export const ProductPackagePriceFields: FC<ProductPackagePriceFieldProps> = ({ selectedProductPackage, isEditMode }) => {
    const { t } = useTranslation();
    const notifications = useNotifications();
    const { values, setFieldError, setFieldTouched, setFieldValue } = useFormikContext<CreateAndUpdateSpecialPrice>();
    const initialProductPackage = selectedProductPackage ? [selectedProductPackage] : [];
    const [getCurrentProductPrice] = useLazyGetCurrentPriceForProductPackageQuery();
    const [getProductPackagesByName, { isLoading }] = useLazyGetProductPackageByNameQuery();
    const [productPackages, setProductPackages] = useState<Array<SimpleProductPackage>>(initialProductPackage);

    const onProductPackageSearchByNameDebounce = useMemo(
        () =>
            debounce(async (name: string) => {
                if (name && name.trim() !== "") {
                    getProductPackagesByName(name)
                        .unwrap()
                        .then((data) => {
                            if (data && data.length !== 0) {
                                setFieldError(productPackageField, undefined);
                                setProductPackages(data);
                            } else {
                                setFieldTouched(productPackageField, true, false);
                                setFieldError(productPackageField, "common.form.product_not_found");
                            }
                        })
                        .catch(() => {
                            setFieldError(productPackageField, "common.form.search_error");
                        });
                }
            }, DEBOUNCE_SEARCH_VALUE),
        [getProductPackagesByName, setFieldError, setFieldTouched],
    );

    const fetchCurrentPrice = async (productPackageId: string) => {
        return getCurrentProductPrice(Number(productPackageId)).unwrap();
    };

    useOnUpdate(() => {
        if (isNotNullish(values?.productPackageId)) {
            const currentValue = productPackages.find(
                (productPackage) => productPackage.id.toString() === values.productPackageId.toString(),
            );
            if (isNotNullish(currentValue?.margin)) {
                const currentMargin = currentValue?.margin || 0;
                fetchCurrentPrice(values.productPackageId).then((currentProductPrice) => {
                    if (!isEmpty(currentProductPrice)) {
                        const marginValue = (currentProductPrice.price / 100) * currentMargin;
                        const productPriceInclMargin = Number((currentProductPrice.price + marginValue).toFixed(2));
                        setFieldValue(productPriceField, currentProductPrice.price);
                        setFieldValue(productPackageMarginField, currentMargin);
                        setFieldValue(productPriceInclMarginField, productPriceInclMargin);
                        if (!isEditMode) {
                            setFieldValue(specialPriceInclMarginField, productPriceInclMargin);
                        }
                    } else {
                        setFieldValue(productPackageField, null, false);
                        notifications.showNotification({
                            title: t("common.form.errorTitle"),
                            message: t("specialPrices.noCurrentPrice"),
                            type: NotificationTypes.error,
                        });
                    }
                });
            }
        } else {
            setFieldValue(productPriceField, 0);
            setFieldValue(productPackageMarginField, 0);
            setFieldValue(productPriceInclMarginField, 0);
        }

        return () => onProductPackageSearchByNameDebounce.clear();
        // run only when value has been changed. Ignore change for productPackages.
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [setFieldValue, values.productPackageId]);

    const productPackageOptions = useMemo(
        () =>
            productPackages.map((option) => ({
                label: option.name,
                value: option.id,
            })),
        [productPackages],
    );

    return (
        <>
            <InputField<SelectProps["value"], SelectProps>
                input={Select}
                name={productPackageField}
                required
                inputProps={{
                    label: t("specialPrices.modal.productPackage.label"),
                    placeholder: t("specialPrices.modal.productPackage.placeholder"),
                    nothingFound: t("specialPrices.modal.productPackage.nothingFound"),
                    onSearchChange: (query: string) => onProductPackageSearchByNameDebounce(query),
                    options: productPackageOptions,
                    loading: isLoading,
                    disabled: isEditMode,
                }}
            />
            <PriceBox>
                <Text color={Color.primary600} size="1.6rem" weight={700}>
                    {isEditMode ? t("specialPrices.modal.initialPriceTitle") : t("specialPrices.modal.currentPriceTitle")}
                </Text>
                <Grid gridTemplateColumns="1fr 1fr" columnGap="4rem" rowGap="0.5rem">
                    <InputField<NumberInputProps["value"], NumberInputProps>
                        name={productPriceField}
                        input={NumberInput}
                        inputProps={{
                            label: isEditMode
                                ? t("specialPrices.modal.currentPrice.labelInitial")
                                : t("specialPrices.modal.currentPrice.label"),
                            description: isEditMode
                                ? t("specialPrices.modal.currentPrice.descriptionInitial")
                                : t("specialPrices.modal.currentPrice.description"),
                            ...createCurrencyInputProps(t),
                            disabled: true,
                        }}
                    />
                    <InputField<NumberInputProps["value"], NumberInputProps>
                        name={productPackageMarginField}
                        input={NumberInput}
                        inputProps={{
                            label: isEditMode
                                ? t("specialPrices.modal.currentMargin.labelInitial")
                                : t("specialPrices.modal.currentMargin.label"),
                            description: t("specialPrices.modal.currentMargin.description"),
                            ...percentageInputProps,
                            disabled: true,
                        }}
                    />
                    <InputField<NumberInputProps["value"], NumberInputProps>
                        name={productPriceInclMarginField}
                        input={NumberInput}
                        inputProps={{
                            label: isEditMode
                                ? t("specialPrices.modal.finalPrice.labelInitial")
                                : t("specialPrices.modal.finalPrice.label"),
                            description: isEditMode
                                ? t("specialPrices.modal.finalPrice.descriptionInitial")
                                : t("specialPrices.modal.finalPrice.description"),
                            ...createCurrencyInputProps(t),
                            disabled: true,
                        }}
                    />
                </Grid>
            </PriceBox>
        </>
    );
};
