import { MS_EXCEL_MIME_TYPE, MS_POWERPOINT_MIME_TYPE, MS_WORD_MIME_TYPE, PDF_MIME_TYPE } from "@mantine/dropzone";

export const HUNDRED_MB_IN_BYTES = 100000000;

const ADDITIONAL_MIME_TYPES = [
    "application/octet-stream",
    "application/zip",
    "application/rtf",
    // .eml mime type - https://www.w3.org/Protocols/rfc1341/7_3_Message.html
    "message/rfc822",
    "text/*",
    "image/*",
];

export const ATTACHMENT_ACCEPT_TYPES = [
    ...PDF_MIME_TYPE,
    ...MS_WORD_MIME_TYPE,
    ...MS_EXCEL_MIME_TYPE,
    ...MS_POWERPOINT_MIME_TYPE,
    ...ADDITIONAL_MIME_TYPES,
];
