import { CustomerTypeEnum, InvoicingFrequencyEnum, PaymentDueEnum } from "src/types";
import { createIsEnum } from "src/utils";
import { ApproveStartPointEnum } from "../approveCustomer";

export type SupplyLimitsSettingsType = {
    id: number;
    acn: number;
    hasCustomizedSupplyLimits: boolean;
};

// type guards
export const isPaymentDueEnum = createIsEnum(PaymentDueEnum);
export const isInvoicingFrequencyEnum = createIsEnum(InvoicingFrequencyEnum);
export const isApproveStartPointEnum = createIsEnum(ApproveStartPointEnum);
export const isCustomerTypeEnum = createIsEnum(CustomerTypeEnum);
