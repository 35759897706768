import type { FC } from "react";

import { Flex, LabelValuePair, TabularText, Text } from "src/components";
import { Color } from "src/theme";
import { useTranslation } from "src/translations";
import type { CustomerDto } from "src/types";
import { CustomerStatusEnum } from "src/types";
import { getCustomerDetailTextColor } from "./utils";

type PayerInfoProps = {
    payerDetail?: CustomerDto;
};

export const PayerInfo: FC<PayerInfoProps> = ({ payerDetail }) => {
    const { t } = useTranslation();
    const textColor = getCustomerDetailTextColor(payerDetail?.status === CustomerStatusEnum.Inactive);

    return (
        <Flex flexDirection="column" gap="1rem">
            <Text color={Color.primary600} size="1.8rem">
                {t("payerDetail.customerInfo.title")}
            </Text>
            <TabularText maxWidth="30rem" rowGap="0.2rem">
                <LabelValuePair
                    label={
                        <Text color={textColor} size="1.4rem">
                            {t("payerDetail.customerInfo.vat")}
                        </Text>
                    }
                    value={
                        <Text color={textColor} size="1.4rem">
                            {payerDetail?.vatNumber}
                        </Text>
                    }
                />
                <LabelValuePair
                    label={
                        <Text color={textColor} size="1.4rem">
                            {t("payerDetail.customerInfo.tin")}
                        </Text>
                    }
                    value={
                        <Text color={textColor} size="1.4rem">
                            {payerDetail?.tin}
                        </Text>
                    }
                />
                <LabelValuePair
                    label={
                        <Text color={textColor} size="1.4rem">
                            {t("payerDetail.customerInfo.acn")}
                        </Text>
                    }
                    value={
                        <Text color={textColor} size="1.4rem">
                            {payerDetail?.acn}
                        </Text>
                    }
                />
            </TabularText>
        </Flex>
    );
};
