import { NAME } from "./constants";
import { reducer } from "./reducer";

export { AppLayout } from "./AppLayout";
export { PageLayout } from "./PageLayout";

export default {
    NAME,
    reducer,
};
