import type { FC } from "react";
import { useEffect } from "react";

import { useGetClientConfigQuery } from "src/api";
import { Grid, Loader } from "src/components";

// @ts-ignore
export const ClientConfigProvider: FC = ({ children }) => {
    const { isLoading, isError } = useGetClientConfigQuery();

    useEffect(() => {
        if (isError) {
            throw new Error("Error with receiving client config.");
        }
    }, [isError]);

    return isLoading ? (
        <Grid justifyContent="center" alignItems="center" gridTemplateRows="100vh">
            <Loader size={40} />
        </Grid>
    ) : (
        children
    );
};
