import { Menu as MenuMantine } from "@mantine/core";
import styled from "styled-components";

export const MenuItem = styled(MenuMantine.Item).withConfig({
    shouldForwardProp: (prop) => !["danger"].includes(prop as string),
})<{ danger?: boolean }>`
    &.mantine-Menu-item {
        font-family: ${({ theme }) => theme.fontFamily};
        padding: 0.8rem 1.6rem;
        color: ${({ theme, danger }) => (danger ? theme.color.primary600 : theme.color.supportNavy500)};
        &:disabled {
            color: ${({ theme }) => theme.color.supportNavy200};
        }

        &:hover {
            background-color: ${({ theme }) => theme.color.neutral100};
        }
    }
`;
