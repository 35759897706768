import { useDebouncedValue, useHotkeys } from "@mantine/hooks";
import * as equal from "fast-deep-equal/es6/react";
import { ReactNode, useCallback, useEffect, useMemo, useState } from "react";
import { useSearchParams } from "react-router-dom";
import { Cell, Filters, Row, useExpanded, useFilters, usePagination, useRowSelect, useTable } from "react-table";

import {
    Button,
    Grid,
    LoadingOverlay,
    Pagination,
    Table,
    TableBorder,
    TableLayout,
    TableScrollDecoration,
    TableSelectionTop,
    TableWithPaginationLayout,
    TBody,
    TCell,
    Text,
    THead,
    TRow,
} from "src/components";
import { createSearchParamsAsString } from "src/routes";
import { Color } from "src/theme";
import { useTranslation } from "src/translations";
import type { TableRecordType } from "src/types";
import { cleanObject, useAppDispatch, useAppParamSelector, useOnUpdate } from "src/utils";
import { saveFilters } from "../actions";
import { EmptyRow } from "../components";
import { ACTION_COLUMN_ID } from "../constants";
import { getResetSelectionTrigger } from "../selectors";
import type { ExpandRowType, TableContainerProps } from "../types";
import { createFiltersSearchParam } from "../utils";
import {
    addSpecialColumns,
    collectCellProps,
    convertFilterParamsToFilters,
    convertFiltersToFilterParams,
    tableStateReducer,
    useTableDecoration,
} from "./utils";

const createCell = <RecordType extends TableRecordType>(
    row: Row<RecordType>,
    page: Array<Row<RecordType>>,
    cell: Cell<RecordType>,
    actions: TableContainerProps<RecordType>["actions"],
) => {
    return cell.column.id === ACTION_COLUMN_ID && actions ? (
        <TCell {...cell.getCellProps(collectCellProps)}>{actions(row, page)}</TCell>
    ) : (
        <TCell {...cell.getCellProps(collectCellProps)}>{cell.render("Cell")}</TCell>
    );
};

const createCellsForExpandRow = <RecordType extends TableRecordType>(
    row: Row<RecordType>,
    page: Array<Row<RecordType>>,
    expandedRow: (row: Row<RecordType>) => ExpandRowType,
    actions: TableContainerProps<RecordType>["actions"],
) => {
    const cells: ReactNode[] = [];
    for (let i = 0; i < row.cells.length; i += 1) {
        const expandRow = expandedRow(row);
        if (row.cells[i].column.id === expandRow.accessor) {
            cells.push(
                <TCell {...row.cells[i].getCellProps(collectCellProps)} colSpan={expandRow.colspan}>
                    {expandRow.component}
                </TCell>,
            );
            i += expandRow.colspan - 1;
        } else {
            cells.push(createCell(row, page, row.cells[i], actions));
        }
    }
    return cells;
};

const createRow = <RecordType extends TableRecordType>(
    row: Row<RecordType>,
    page: Array<Row<RecordType>>,
    actions: TableContainerProps<RecordType>["actions"],
    expandedRow: TableContainerProps<RecordType>["expandedRow"],
    overrideRowProps: TableContainerProps<RecordType>["overrideRowProps"],
) => {
    const overrideProps = overrideRowProps ? overrideRowProps(row) : {};
    if (!!expandedRow && row.depth === 0) {
        return (
            <TRow {...row.getRowProps()} $backgroundColor={Color.neutral50} $hoveredColor={Color.supportNavy50} {...overrideProps}>
                {createCellsForExpandRow(row, page, expandedRow, actions)}
            </TRow>
        );
    }
    return (
        <TRow {...row.getRowProps()} $backgroundColor={Color.white} $hoveredColor={Color.accent300} {...overrideProps}>
            {row.cells.map((cell) => createCell(row, page, cell, actions))}
        </TRow>
    );
};

export const TableContainer = <RecordType extends TableRecordType>({
    tableId,
    tableName,
    createColumns,
    data,
    defaultTableConfig,
    fetchWithNewParams,
    pageCount: controlledPageCount,
    actions,
    expandedRow,
    overrideRowProps,
    isLoading,
    initialFilterValuesFromUrl,
    isSelectable,
    totalElements,
    actionsForSelection,
    emptyRow,
    canSelectAll,
    setPersistedPageSize,
    maxWidth,
}: TableContainerProps<RecordType>): JSX.Element => {
    const dispatch = useAppDispatch();
    const [expandState, setExpandState] = useState<Record<number, boolean>>({});
    const resetSelectionTrigger = useAppParamSelector(getResetSelectionTrigger, tableId);
    const [isAllRowsSelected, setIsAllRowsSelected] = useState<boolean>(false);
    const [areFiltersDirty, setAreFiltersDirty] = useState(false);
    const [searchParams, setSearchParams] = useSearchParams();
    const { t } = useTranslation();
    const columns = useMemo(() => createColumns(t, tableName), [t, createColumns, tableName]);
    const dataWithExpand = useMemo(
        () => (expandedRow ? data.map((d, index) => ({ ...d, expanded: expandState[index] || false })) : data),
        [data, expandState, expandedRow],
    );

    const getRowId = useCallback(
        (row: RecordType, _, parent: Row<RecordType> | undefined): string => (parent ? [parent.id, row.id].join(".") : row.id.toString()),
        [],
    );

    const allColumns = useMemo(
        () => addSpecialColumns(columns, setExpandState, setIsAllRowsSelected, !!expandedRow, isSelectable, isAllRowsSelected),
        [columns, expandedRow, isSelectable, isAllRowsSelected],
    );

    const filterSearchParam = useMemo(() => createFiltersSearchParam(tableName), [tableName]);

    // We don't need to create initial state for every render,
    // all our values are achievable in first render
    const tableInitialState = useMemo(
        () => ({
            pageSize: defaultTableConfig.pageSize,
            pageIndex: defaultTableConfig.pageIndex,
            filters: convertFilterParamsToFilters<RecordType>(initialFilterValuesFromUrl),
        }),
        // eslint-disable-next-line react-hooks/exhaustive-deps
        [],
    );

    const { tableRef, wrapperRef, isLeftDecorationVisible, isRightDecorationVisible } = useTableDecoration();

    const {
        getTableProps,
        getTableBodyProps,
        headerGroups,
        prepareRow,
        page, // Instead of using 'rows', we'll use page,
        // which has only the rows for the active page
        setAllFilters,
        pageOptions,
        gotoPage,
        setPageSize,
        state: { pageIndex, pageSize, filters, selectedRowIds },
        toggleAllRowsSelected,
    } = useTable(
        {
            columns: allColumns,
            data: dataWithExpand,
            manualPagination: true,
            autoResetExpanded: false,
            autoResetSelectedRows: false,
            manualFilters: true,
            initialState: tableInitialState,
            pageCount: controlledPageCount,
            getRowId,
            // toggleAllRowsSelected is not working properly with paged data,
            // so we have to manage deselect all action ourselves
            stateReducer: tableStateReducer,
        },
        useExpanded,
        useFilters,
        usePagination,
        useRowSelect,
    );

    const [debouncedFilters] = useDebouncedValue<Filters<RecordType>>(filters, 500);

    const resetFiltersToInitialValue = () => {
        setAllFilters(convertFilterParamsToFilters<RecordType>(defaultTableConfig.initialFilters));
    };

    const selectAllPages = () => {
        setIsAllRowsSelected(true);
    };

    const deselectAllPages = () => {
        setIsAllRowsSelected(false);
        toggleAllRowsSelected(false);
    };

    const changePageSize = (value: number) => {
        setPersistedPageSize(value);
        setPageSize(value);
    };

    useHotkeys([["mod+Backspace", resetFiltersToInitialValue]]);

    // Save filters on unmount
    useEffect(
        () => () => {
            dispatch(saveFilters({ tableName, filters: convertFiltersToFilterParams(debouncedFilters) }));
        },
        [dispatch, tableName, debouncedFilters],
    );

    // Fetch new records witch new search params
    useEffect(() => {
        const filterParams = convertFiltersToFilterParams(debouncedFilters);
        fetchWithNewParams({ pageIndex, pageSize, filters: filterParams });
    }, [fetchWithNewParams, pageIndex, pageSize, debouncedFilters]);

    // Close expanded rows when page is changed
    useOnUpdate(() => {
        setExpandState({});
    }, [pageIndex, pageSize]);

    // Reset selection when resetSelectionTrigger is updated
    useOnUpdate(() => {
        deselectAllPages();
    }, [resetSelectionTrigger]);

    // Expand rows when filter is active, check if pagination is set correctly after data refetch
    useEffect(() => {
        const filterParams = convertFiltersToFilterParams(debouncedFilters);
        if (debouncedFilters.length !== 0 && !equal(filterParams, defaultTableConfig.initialFilters)) {
            const expandedRows = data.reduce((all, _, index) => ({ ...all, [index]: true }), {});
            setExpandState(expandedRows);
        }

        if (!!controlledPageCount && pageIndex + 1 > controlledPageCount) {
            gotoPage(controlledPageCount - 1);
        }

        // eslint-disable-next-line
    }, [data]);

    // Close expanded rows when you clear filters and set dirty, you cannot bind this action to data change,
    // because you want to keep rows expanded when you create new entity in table and refetch data
    useEffect(() => {
        const filterParams = convertFiltersToFilterParams(debouncedFilters);
        const isDirty = !equal(filterParams, defaultTableConfig.initialFilters);

        if (debouncedFilters.length === 0 || !isDirty) {
            setExpandState({});
        }

        const currentParams = Object.fromEntries([...searchParams]);
        const cleanParams = cleanObject({ ...currentParams, [filterSearchParam]: createSearchParamsAsString(filterParams) });
        setSearchParams(cleanParams, { replace: true });

        setAreFiltersDirty(isDirty);
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [debouncedFilters, filterSearchParam]);

    const memoizedTableBody = useMemo(
        () =>
            page.length > 0 ? (
                page.map((row) => {
                    prepareRow(row);
                    return createRow(row, page, actions, expandedRow, overrideRowProps);
                })
            ) : (
                <EmptyRow t={t} customEmptyRow={emptyRow} colSpan={allColumns.length} dirtyFilters={areFiltersDirty} />
            ),
        // eslint-disable-next-line react-hooks/exhaustive-deps
        [actions, expandedRow, overrideRowProps, page, prepareRow, selectedRowIds, isAllRowsSelected, allColumns, areFiltersDirty],
    );

    // TODO :: this is working only because actionsForSelection getting new reference on every render, actually you need to know filter state when isAllRowsSelected is active
    // we don't need to rerender on filter change,
    // we need to know filter state only when isAllRowsSelected is changed
    const actionsForSelectComponent = useMemo(
        () => (actionsForSelection ? actionsForSelection(isAllRowsSelected, selectedRowIds, filters) : null),
        // eslint-disable-next-line react-hooks/exhaustive-deps
        [actionsForSelection, isAllRowsSelected, selectedRowIds],
    );

    const getSelectedRowsCount = () => {
        if (isAllRowsSelected) {
            return totalElements;
        }
        // If expand is active count only child rows, each child row has id in format xx.yy,
        // so if there is no dot it isn’t child row
        return expandedRow ? Object.keys(selectedRowIds).filter((id) => id.includes(".")).length : Object.keys(selectedRowIds).length;
    };

    return (
        <TableLayout maxWidth={maxWidth}>
            {isSelectable && (
                <TableSelectionTop>
                    <Grid alignItems="center" gridAutoFlow="column" gap="3rem">
                        <Text size="1.4rem">
                            {isAllRowsSelected && expandedRow
                                ? t("common.selection.totalExpand")
                                : t("common.selection.total", {
                                      total: getSelectedRowsCount(),
                                  })}
                        </Text>
                        {!isAllRowsSelected && canSelectAll && (
                            <Button type="button" onClick={selectAllPages} variant="subtle">
                                {expandedRow
                                    ? t("common.selection.selectAllWithExpand")
                                    : t("common.selection.selectAll", { total: totalElements })}
                            </Button>
                        )}
                        {(Object.keys(selectedRowIds).length > 0 || isAllRowsSelected) && (
                            <Button type="button" onClick={deselectAllPages} variant="subtle">
                                {t("common.selection.deselectAll")}
                            </Button>
                        )}
                    </Grid>
                    {actionsForSelectComponent}
                </TableSelectionTop>
            )}
            <TableWithPaginationLayout>
                <TableScrollDecoration
                    isLeftDecorationVisible={isLeftDecorationVisible}
                    isRightDecorationVisible={isRightDecorationVisible}
                >
                    <TableBorder ref={wrapperRef}>
                        <LoadingOverlay loading={isLoading} />
                        <Table {...getTableProps()} ref={tableRef}>
                            <THead<RecordType>
                                headerGroups={headerGroups}
                                resetFilters={resetFiltersToInitialValue}
                                areFiltersDirty={areFiltersDirty}
                                t={t}
                            />
                            <TBody {...getTableBodyProps()}>{memoizedTableBody}</TBody>
                        </Table>
                    </TableBorder>
                </TableScrollDecoration>
                <Pagination
                    setPageSize={changePageSize}
                    activePage={pageIndex + 1}
                    setPage={(activePage) => gotoPage(activePage - 1)}
                    total={pageOptions.length}
                    pageSize={pageSize}
                />
            </TableWithPaginationLayout>
        </TableLayout>
    );
};
