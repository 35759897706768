import type { ClientConfigurationProperties, CreateEmergencyOrderDto, ProductPackagePublicFormDto, ShipToPublicFormDto } from "src/types";
import { publicApi } from "../../baseQuery";
import { requestMethod, url } from "../../constants";
import { tags } from "../../tags";

const api = publicApi.injectEndpoints({
    endpoints: (builder) => ({
        getClientConfig: builder.query<ClientConfigurationProperties, void>({
            query: () => ({
                url: `${url.CLIENT_CONFIG}`,
            }),
            providesTags: [tags.clientConfig],
        }),
        getShipToByAcnForEmergencyOrder: builder.query<ShipToPublicFormDto, { acn: string }>({
            query: ({ acn }) => ({
                url: `${url.SHIP_TO_BY_ACN_FOR_EO}/${acn}`,
            }),
            providesTags: [tags.shipToEmergencyOrder],
        }),
        getProductPackageBySuklForEmergencyOrder: builder.query<ProductPackagePublicFormDto, { sukl: string }>({
            query: ({ sukl }) => ({
                url: `${url.PRODUCT_PACKAGE_BY_SUKL_FOR_EO}`,
                params: { sukl },
            }),
            providesTags: [tags.productPackageEmergencyOrder],
        }),
        getProductPackageByNameForEmergencyOrder: builder.query<Array<ProductPackagePublicFormDto>, { name: string }>({
            query: ({ name }) => ({
                url: `${url.PRODUCT_PACKAGE_BY_NAME_FOR_EO}`,
                params: { nameSubstring: name, limit: 10 },
            }),
            providesTags: [tags.productPackageEmergencyOrder],
        }),
        createEmergencyOrder: builder.mutation<void, CreateEmergencyOrderDto>({
            query: (values) => ({
                url: url.PUBLIC_EMERGENCY_ORDER,
                method: requestMethod.POST,
                body: values,
            }),
            invalidatesTags: [tags.productPackageEmergencyOrder, tags.shipToEmergencyOrder],
        }),
    }),
});

export const {
    useGetClientConfigQuery,
    useLazyGetShipToByAcnForEmergencyOrderQuery,
    useLazyGetProductPackageByNameForEmergencyOrderQuery,
    useLazyGetProductPackageBySuklForEmergencyOrderQuery,
    useCreateEmergencyOrderMutation,
} = api;
