import type { FC, ReactNode } from "react";
import styled from "styled-components";

type TooltipContentProps = Readonly<{
    icon?: ReactNode;
    header?: string;
}>;

export const TooltipContent: FC<TooltipContentProps> = ({ header, children, icon }) => (
    <Layout>
        {icon && icon}
        <Content>
            {header && <Header>{header}</Header>}
            <Text>{children}</Text>
        </Content>
    </Layout>
);

const Layout = styled.div`
    display: grid;
    gap: 1.5rem;
    grid-auto-flow: column;
    justify-content: start;
    align-items: start;
`;

const Content = styled.div`
    display: grid;
    grid-auto-flow: row;
`;

const Header = styled.div`
    font-size: 1.4rem;
`;

const Text = styled.div`
    font-size: 1.4rem;
`;
