import { Link } from "react-router-dom";
import styled, { css } from "styled-components";

import { ActionIcon, Icon, NumberInput } from "src/components";
import { createSearchParamsAsString, routes } from "src/routes";
import { UNLIMITED_TIER_NUMBER } from "src/supplyLimitSelect";
import { createFiltersSearchParam, TableNames } from "src/table";
import { Color, IconSizes, maxWidthBreakpoint } from "src/theme";
import { Trans, useTranslation } from "src/translations";
import { isEmpty } from "src/utils";
import { MAX_TIER_LIMIT, MIN_TIER_LIMIT } from "../constants";
import { controlsClassName, tierClassName } from "./constants";

type TierProps = Readonly<{
    tierId: number;
    tierNumber?: number;
    dailyLimit?: number | null;
    isDefault?: boolean;
    changeDailyLimit: (value: number) => void;
    monthlyLimit?: number | null;
    changeMonthlyLimit: (value: number) => void;
    onSave: () => void;
    focused?: boolean;
    setFocused: () => void;
    isAnyTierFocused: boolean;
    inputId: string;
    setDefaultTier: () => void;
    error?: string;
    onReset: () => void;
    productPackageId: number;
    pharmaciesCount: number;
}>;

export const Tier = ({
    tierId,
    tierNumber,
    dailyLimit,
    changeDailyLimit,
    monthlyLimit,
    changeMonthlyLimit,
    onSave,
    isDefault,
    focused,
    setFocused,
    isAnyTierFocused,
    inputId,
    setDefaultTier,
    error,
    onReset,
    productPackageId,
    pharmaciesCount,
}: TierProps): JSX.Element => {
    const isSaveDisabled = isEmpty(dailyLimit) || isEmpty(monthlyLimit);
    const { t } = useTranslation();

    const onPressEnter = (e: KeyboardEvent) => {
        if (e.key === "Enter") {
            onSave();
        }
    };

    const isUnlimited = tierNumber === UNLIMITED_TIER_NUMBER;

    return (
        <TierWrapper $controlsAreVisible={focused} $dull={isAnyTierFocused && !focused} $hasError={!!error}>
            <StyledTier className={tierClassName} $unlimited={isUnlimited} $hasError={!!error}>
                {!isUnlimited && <StyledTierNumber $active={focused}>{tierNumber}</StyledTierNumber>}
                <LimitInput
                    disabled={!focused}
                    type="number"
                    id={inputId}
                    value={dailyLimit}
                    hideControls
                    onChange={(value: number) => changeDailyLimit(value)}
                    onKeyPress={onPressEnter}
                    min={MIN_TIER_LIMIT}
                    max={MAX_TIER_LIMIT}
                />
                <LimitInput
                    disabled={!focused}
                    type="number"
                    value={monthlyLimit}
                    hideControls
                    onChange={(value: number) => changeMonthlyLimit(value)}
                    onKeyPress={onPressEnter}
                    min={MIN_TIER_LIMIT}
                    max={MAX_TIER_LIMIT}
                />
            </StyledTier>
            <Controls className={controlsClassName} $isDefault={isDefault}>
                {!focused && !isUnlimited && (
                    <ActionIcon
                        tooltip={!isDefault ? t("supplyLimits.tiers.default") : undefined}
                        onClick={setDefaultTier}
                        color={Color.neutral100}
                        disabled={isDefault}
                    >
                        <Icon.Star size={IconSizes.l} color={Color.primary600} filled={isDefault} />
                    </ActionIcon>
                )}
                <OtherControls className={controlsClassName}>
                    {focused ? (
                        <>
                            <ActionIcon onClick={onSave} color={Color.neutral100} disabled={isSaveDisabled}>
                                <Icon.Save
                                    size={IconSizes.m}
                                    color={isSaveDisabled ? Color.supportGraphite200 : Color.primary600}
                                    filled={!isSaveDisabled}
                                />
                            </ActionIcon>
                            <ActionIcon tooltip={t("supplyLimits.tiers.resetChanges")} onClick={onReset} color={Color.neutral100}>
                                <Icon.Close size={IconSizes.m} color={Color.primary600} />
                            </ActionIcon>
                        </>
                    ) : (
                        <ActionIcon onClick={setFocused} color={Color.neutral100}>
                            <Icon.Edit size={IconSizes.m} color={Color.primary600} filled />
                        </ActionIcon>
                    )}
                </OtherControls>
            </Controls>
            <AppliesTo>
                {pharmaciesCount !== undefined ? (
                    <>
                        <Trans
                            i18nKey={pharmaciesCount > 0 ? "supplyLimits.tiers.pharmaciesCount" : "supplyLimits.tiers.pharmacyCount"}
                            values={{ value: pharmaciesCount }}
                            components={{
                                paragraph: <p />,
                            }}
                        />
                        <Link
                            to={`${routes.supplyLimitsBulkEdit.fillPathParams({
                                productPackageId: productPackageId.toString(),
                            })}?${createSearchParamsAsString({
                                [createFiltersSearchParam(TableNames.supplyLimitBulkEdit)]: { supplyLimitDto: [tierId] },
                            })}`}
                        >
                            {t("supplyLimits.tiers.seeAll")}
                        </Link>
                    </>
                ) : null}
            </AppliesTo>
            {error && <Error $unlimited={isUnlimited}>{error}</Error>}
        </TierWrapper>
    );
};

const Error = styled.div<{ $unlimited: boolean }>`
    color: ${({ theme }) => theme.color.error500};
    font-size: 1.4rem;
    grid-column: ${({ $unlimited }) => (!$unlimited ? "1 / -1" : "2 / -1")};
`;

const TierWrapper = styled.div<{ $controlsAreVisible?: boolean; $dull: boolean; $hasError: boolean }>`
    display: grid;
    grid-template-columns: repeat(5, 1fr);
    grid-gap: 0.4rem;
    width: 100%;
    box-sizing: border-box;
    align-items: center;
    transition: opacity 100ms ease;
    opacity: ${({ $dull }) => (!$dull ? 1 : 0.5)};

    .${controlsClassName} {
        opacity: ${({ $controlsAreVisible, $dull }) => ($controlsAreVisible && !$dull ? 1 : 0)};
        pointer-events: ${({ $controlsAreVisible, $dull }) => ($controlsAreVisible && !$dull ? "unset" : "none")};
    }

    ${({ $controlsAreVisible }) =>
        $controlsAreVisible &&
        css`
            .${tierClassName} {
                border-color: ${({ theme }) => theme.color.accent400};
            }
        `}
    &:hover {
        .${controlsClassName} {
            opacity: ${({ $dull }) => (!$dull ? 1 : 0)};
            pointer-events: ${({ $dull }) => (!$dull ? "unset" : "none")};
        }

        .${tierClassName} {
            ${({ $hasError }) =>
                !$hasError &&
                css`
                    border-color: ${({ theme }) => theme.color.accent400};
                `}
        }
    }

    ${maxWidthBreakpoint.mobile(css`
        grid-template-columns: repeat(3, 1fr);
        row-gap: 1rem;

        .${controlsClassName} {
            opacity: 1;
            pointer-events: unset;
        }
    `)}
`;

const StyledTier = styled.div<{ $unlimited: boolean; $hasError: boolean }>`
    display: grid;
    grid-template-columns: ${({ $unlimited }) => (!$unlimited ? "repeat(3, 33.3%)" : "repeat(2, 50%)")};
    grid-column: ${({ $unlimited }) => (!$unlimited ? "1 / 4" : "2 / 4")};
    border-radius: ${({ theme }) => theme.radius.default};
    border: 1px solid ${({ theme, $hasError }) => ($hasError ? theme.color.error500 : theme.color.neutral300)};
    color: ${({ theme }) => theme.color.supportNavy500};
    box-sizing: border-box;
    overflow: hidden;
    align-items: center;
    transition: border 100ms ease;
`;

const Controls = styled.div<{ $isDefault?: boolean }>`
    margin: 0 1rem;
    display: grid;
    grid-gap: 1rem;
    grid-auto-flow: column;
    justify-content: left;
    transition: opacity 100ms ease;
    ${({ $isDefault }) =>
        $isDefault &&
        css`
            opacity: 1 !important;
        `}
`;

const OtherControls = styled.div`
    display: grid;
    grid-auto-flow: column;
    justify-content: left;
    grid-gap: 1rem;
    transition: opacity 100ms ease;
`;

const AppliesTo = styled.div`
    font-size: 1.6rem;

    p {
        margin: 0;
        color: ${({ theme }) => theme.color.supportNavy500};
    }

    a {
        color: ${({ theme }) => theme.color.supportNavy500};

        &:hover {
            text-decoration: none;
        }
    }

    ${maxWidthBreakpoint.mobile(css`
        grid-column: 2 / -1;
    `)}
`;

const StyledTierNumber = styled.div<{ $active?: boolean }>`
    padding: 1.6rem;
    text-align: center;
    background-color: ${({ theme, $active }) => ($active ? theme.color.accent200 : theme.color.accent100)};
`;

const LimitInput = styled(NumberInput)`
    &.mantine-InputWrapper-root {
        padding: 0;
    }

    & input {
        text-align: center;
        padding: 1.6rem;
        height: 5rem;
        border: none;
        border-radius: 0;
        color: ${({ theme }) => theme.color.supportNavy500};
        background-color: ${({ theme }) => theme.color.white};

        &:disabled {
            background-color: ${({ theme }) => theme.color.white};
            color: ${({ theme }) => theme.color.supportNavy500};
            opacity: 1;
        }

        &:focus {
            background-color: ${({ theme }) => theme.color.neutral100};
        }
    }

    &:active,
    &:focus {
        border: none;
        outline: none;
    }
`;
