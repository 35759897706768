import type { TFunction } from "react-i18next";
import type { Cell } from "react-table";

import { useGetProductBrandsWithSupplyLimitsQuery } from "src/api";
import { createSelectColumnFilter, HighlightCell, NumberColumnFilter, TextColumnFilter, Tooltip } from "src/components";
import { UNLIMITED_TIER_NUMBER } from "src/supplyLimitSelect";
import { createTableModule, TableNames } from "src/table";
import { Color } from "src/theme";
import type { ExpandColumnsType, ExpandTableProductWithSupplyLimitsType, SupplyLimitWithPackageAndMarginDto } from "src/types";
import { MarginSourceEnum } from "src/types";
import { enumToLocalizedOptions, getTranslatedEnumValue, percentageFormatter } from "src/utils";
import { SupplyLimitAdjustmentCell } from "../../../components";

const createColumns = (t: TFunction): ExpandColumnsType<ExpandTableProductWithSupplyLimitsType> => {
    return [
        {
            width: 350,
            minWidth: 120,
            accessor: "productPackageName" as const,
            Header: t("shipToDetail.supplyLimit.table.name") as string,
            Filter: TextColumnFilter,
        },
        {
            minWidth: 100,
            collapse: true,
            accessor: "tierNumber" as const,
            Header: t("shipToDetail.supplyLimit.table.tierNumber") as string,
            Filter: NumberColumnFilter,
            filter: "exact",
            Cell: ({ row }: Cell<SupplyLimitWithPackageAndMarginDto>) => (
                <HighlightCell highlight={!row.original.isDefault}>
                    {row.original.tierNumber === UNLIMITED_TIER_NUMBER ? t("common.unlimited") : row.original.tierNumber}
                </HighlightCell>
            ),
        },
        {
            minWidth: 100,
            Header: t("shipToDetail.supplyLimit.table.dailyLimit") as string,
            accessor: "dailyLimit" as const,
            Filter: NumberColumnFilter,
            filter: "exact",
            align: "end",
            Cell: ({ value, row }: Cell<SupplyLimitWithPackageAndMarginDto>) => (
                <SupplyLimitAdjustmentCell t={t} tierLimit={value} adjustment={row.original.dailyVolumeAdjustment} />
            ),
        },
        {
            minWidth: 100,
            Header: t("shipToDetail.supplyLimit.table.monthlyLimit") as string,
            accessor: "monthlyLimit" as const,
            Filter: NumberColumnFilter,
            filter: "exact",
            align: "end",
            Cell: ({ value, row }: Cell<SupplyLimitWithPackageAndMarginDto>) => (
                <SupplyLimitAdjustmentCell t={t} tierLimit={value} adjustment={row.original.monthlyVolumeAdjustment} />
            ),
        },
        {
            minWidth: 100,
            Header: t("shipToDetail.supplyLimit.table.margin") as string,
            accessor: "margin" as const,
            Filter: createSelectColumnFilter(enumToLocalizedOptions("marginSource", MarginSourceEnum)),
            Cell: ({ row }: Cell<SupplyLimitWithPackageAndMarginDto>) => (
                <Tooltip
                    withinPortal={false}
                    color={Color.supportGraphite500}
                    backgroundColor={Color.accent50}
                    withArrow
                    label={getTranslatedEnumValue("marginSource", row.original.marginSource)}
                >
                    <HighlightCell highlight={row.original.marginSource !== MarginSourceEnum.ProductPackage}>
                        {percentageFormatter(row.original.margin)}
                    </HighlightCell>
                </Tooltip>
            ),
            filter: "exact",
            align: "end",
        },
    ];
};

export const tableModule = createTableModule<ExpandTableProductWithSupplyLimitsType>(
    TableNames.ShipToSupplyLimits,
    useGetProductBrandsWithSupplyLimitsQuery,
    createColumns,
);
