import { RichTextEditor as MtRichTextEditor } from "@mantine/rte";
import type { FC } from "react";
import styled, { css } from "styled-components";

import { createInputStyles } from "../Form/inputStyles";

const RTE_ID = "rte";

export type RichTextEditorProps = {
    value: string;
    onChange: (value: string) => void;
    minHeight?: string;
};

export const RichTextEditor: FC<RichTextEditorProps> = ({ onChange, value, minHeight }) => (
    <StyledRichTextEditor
        id={RTE_ID}
        onChange={onChange}
        value={value}
        minHeight={minHeight}
        controls={[
            ["bold", "italic", "underline", "strike", "clean"],
            ["h1", "h2", "h3"],
            ["unorderedList", "orderedList"],
            ["link"],
            ["alignLeft", "alignCenter", "alignRight"],
        ]}
    />
);

const StyledRichTextEditor = styled(MtRichTextEditor).withConfig({
    shouldForwardProp: (prop) => !["minHeight"].includes(prop as string),
})<Pick<RichTextEditorProps, "minHeight">>`
    & .ql-editor * {
        font-family: ${({ theme }) => theme.fontFamily};
    }

    &.mantine-RichTextEditor-root {
        border-color: ${({ theme }) => theme.color.neutral300};

        .ql-tooltip {
            z-index: ${({ theme }) => theme.zIndex.modal};
        }

        & .ql-action:before {
            background-color: ${({ theme }) => theme.color.primary600};
        }

        & .ql-remove:before {
            color: ${({ theme }) => theme.color.error500};
        }

        ${({ minHeight }) =>
            minHeight &&
            css`
                & .ql-editor {
                    min-height: ${minHeight};
                }
            `}
        & .ql-editing input {
            ${createInputStyles}
        }
    }

    .mantine-RichTextEditor-toolbar {
        border-color: ${({ theme }) => theme.color.neutral300};
    }
`;
