import type { FC } from "react";
import styled from "styled-components";

import { createCssProperty } from "../createCssProperty";
import type {
    AlignContent,
    AlignItems,
    ColumnGap,
    Gap,
    GridAutoColumns,
    GridAutoFlow,
    GridAutoRows,
    GridTemplateAreas,
    GridTemplateColumns,
    GridTemplateRows,
    JustifyContent,
    JustifyItems,
    RowGap,
} from "../types";

type GridProps = GridTemplateRows &
    GridTemplateColumns &
    GridTemplateAreas &
    GridAutoColumns &
    GridAutoRows &
    GridAutoFlow &
    JustifyItems &
    AlignItems &
    JustifyContent &
    AlignContent &
    Gap &
    RowGap &
    ColumnGap;

/**
 * Component for creating simple grid layouts.
 * Should be used for simple layouts where no other styling of component (for example responsivity) is needed.
 * See https://css-tricks.com/snippets/css/complete-guide-grid/ for details.
 * @see GridElement
 */
export const Grid: FC<GridProps> = styled.div<GridProps>`
    display: grid;

    ${({ gridTemplateColumns }) => createCssProperty("grid-template-columns", gridTemplateColumns)}
    ${({ gridTemplateRows }) => createCssProperty("grid-template-rows", gridTemplateRows)}
    ${({ gridTemplateAreas }) => createCssProperty("grid-template-areas", gridTemplateAreas)}
    ${({ gridAutoColumns }) => createCssProperty("grid-auto-columns", gridAutoColumns)}
    ${({ gridAutoRows }) => createCssProperty("grid-auto-rows", gridAutoRows)}
    ${({ gridAutoFlow }) => createCssProperty("grid-auto-flow", gridAutoFlow)}
    ${({ justifyItems }) => createCssProperty("justify-items", justifyItems)};
    ${({ alignItems }) => createCssProperty("align-items", alignItems)};
    ${({ justifyContent }) => createCssProperty("justify-content", justifyContent)};
    ${({ alignContent }) => createCssProperty("align-content", alignContent)};
    ${({ gap }) => createCssProperty("gap", gap)};
    ${({ columnGap }) => createCssProperty("column-gap", columnGap)};
    ${({ rowGap }) => createCssProperty("row-gap", rowGap)};
`;
