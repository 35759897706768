import { Dropzone as DropzoneMantine, DropzoneProps as DropzoneMantineProps } from "@mantine/dropzone";
import styled, { css } from "styled-components";

import { maxWidthBreakpoint } from "src/theme";

type DropzoneProps = { minHeight?: string } & DropzoneMantineProps;

export const Dropzone = styled(DropzoneMantine).withConfig({
    shouldForwardProp: (prop) => !["minHeight"].includes(prop as string),
})<DropzoneProps>`
    &.mantine-Dropzone-root {
        min-height: ${({ minHeight }) => minHeight || "unset"};
        background-color: ${({ theme }) => theme.color.neutral100};
        border-color: ${({ theme }) => theme.color.neutral300};
        border-width: 1px;
        padding: 2.5rem;
        display: grid;
        justify-content: center;
        align-items: center;

        &:hover {
            background-color: ${({ theme }) => theme.color.neutral200};
        }

        &[data-accept] {
            background-color: ${({ theme }) => theme.color.accent100};
            border-color: ${({ theme }) => theme.color.accent400};
        }

        &[data-reject] {
            background-color: ${({ theme }) => theme.color.error100};
            border-color: ${({ theme }) => theme.color.error400};
        }

        ${maxWidthBreakpoint.tablet(css`
            padding: 1rem;
        `)}
    }
`;
