import type { FC } from "react";
import { useState } from "react";

import { Modal } from "src/components";
import { useModal } from "src/modal";
import { useTranslation } from "src/translations";
import { PhoenixInterfaceOperationFilterDtoInterfaceOperationTypeEnum } from "src/types";
import { exportInterfaceTableModule, overrideInterfaceTableRowProps } from "../containers";
import { EXPORT_INTERFACE_ERROR_MODAL_ID, EXPORT_INTERFACE_ERROR_MODAL_WIDTH } from "./constants";
import { ExportInterfacesError } from "./ExportInterfaceError";
import { createActionColumn } from "./utils";

export const ExportInterfaces: FC = () => {
    const [isModalOpened, openModal, closeModal] = useModal(EXPORT_INTERFACE_ERROR_MODAL_ID);
    const [interfaceOperationId, setInterfaceOperationId] = useState<number | undefined>(undefined);
    const { t } = useTranslation();

    const onModalOpen = (operationId: number) => {
        setInterfaceOperationId(operationId);
        openModal();
    };

    return (
        <>
            <Modal
                title={t("importExport.exportInterfaceError.title")}
                maxWidth={EXPORT_INTERFACE_ERROR_MODAL_WIDTH}
                closeOnClickOutside
                opened={isModalOpened}
                onClose={closeModal}
            >
                <ExportInterfacesError interfaceOperationId={interfaceOperationId} />
            </Modal>
            <exportInterfaceTableModule.Container
                actions={createActionColumn(t, onModalOpen)}
                overrideRowProps={overrideInterfaceTableRowProps}
                additionalQueryParams={{ interfaceOperation: PhoenixInterfaceOperationFilterDtoInterfaceOperationTypeEnum.Outgoing }}
            />
        </>
    );
};
