import { Autocomplete as AutocompleteMantine, AutocompleteProps as AutocompletePropsMantine } from "@mantine/core";
import type { FC } from "react";
import { forwardRef } from "react";
import styled from "styled-components";

import { createInputStyles, createInputWrapperStyles, errorMessageStyles, InputStylesProps, labelStyles } from "../inputStyles";
import { removeUnusedPropsFromStyledInput } from "../utils";
import type { AutocompleteOptions } from "./types";

export type AutocompleteProps = AutocompletePropsMantine & {
    data: Array<AutocompleteOptions>;
} & InputStylesProps;

export const Autocomplete: FC<AutocompleteProps> = ({ value, onChange, required, disabled, ...props }) => (
    <StyledAutocomplete
        {...props}
        value={value || ""}
        onChange={(newValue: string) => onChange?.(newValue || "")}
        required={required}
        disabled={disabled}
        // We use this component with BE filtering
        filter={() => true}
        itemComponent={AutocompleteItem}
        withinPortal={false}
        transition="scale-y"
        transitionDuration={150}
    />
);

const AutocompleteItem = forwardRef<HTMLDivElement, { value: string }>(({ value, ...props }, ref) => (
    <div ref={ref} {...props}>
        <span>{value}</span>
    </div>
));

const StyledAutocomplete = styled(AutocompleteMantine).withConfig(removeUnusedPropsFromStyledInput)`
    ${createInputWrapperStyles}
    & label {
        ${labelStyles}
    }

    & input {
        ${createInputStyles}
    }

    & .mantine-Autocomplete-error {
        ${errorMessageStyles}
    }

    & .mantine-Autocomplete-dropdown {
        padding: 0;
        font-weight: 400;
    }

    & .mantine-Autocomplete-hovered {
        color: ${({ theme }) => theme.color.neutral50};
        background-color: ${({ theme }) => theme.color.primary500};
    }

    & .mantine-Autocomplete-selected {
        color: ${({ theme }) => theme.color.neutral50};
        background-color: ${({ theme }) => theme.color.primary700};
    }
`;
