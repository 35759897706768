import type { FC } from "react";

type SvgIconProps = Readonly<{
    icon: string;
    height?: number;
    width?: number;
    alt?: string;
    className?: string;
}>;

export const SvgIcon: FC<SvgIconProps> = ({ icon, height, width, alt, className }) => (
    <img className={className} src={icon} height={height} width={width} alt={alt} />
);
