import { useGetCustomerCountQuery } from "src/api";
import { Icon, Loader, LoadingOverlay, Text } from "src/components";
import { createSearchParamsAsString, CustomersTab, routes, TAB_PARAM } from "src/routes";
import { Color } from "src/theme";
import { Trans, useTranslation } from "src/translations";
import { ActionRequestEnum } from "src/types";
import { getCustomerCountByActionRequest } from "src/utils";
import { CustomersWidgetLayout, WidgetRowLink } from "../components";
import { DashboardCollapsibleWidgetsEnum } from "../enums";
import { DashboardCard } from "./DashboardCard";

export const CustomersWidget = () => {
    const { t } = useTranslation();
    const { data: customerCount, isLoading, isFetching } = useGetCustomerCountQuery();

    const hasSomeAction = customerCount ? customerCount.some(({ count }) => count > 0) : false;

    const getStatusBarIcon = () => {
        if (!isLoading && !isFetching) {
            if (hasSomeAction) {
                return <Icon.PeopleError color={Color.accent600} filled />;
            }
            return null;
        }
        return <Loader size={20} />;
    };

    return (
        <DashboardCard
            title={t("dashboard.customers.title")}
            collapsibleWidgetId={DashboardCollapsibleWidgetsEnum.CustomersWidget}
            statusBar={getStatusBarIcon()}
        >
            <LoadingOverlay loading={isLoading || isFetching} />
            <CustomersWidgetLayout>
                <WidgetRowLink to={`${routes.customers.path}?${createSearchParamsAsString({ [TAB_PARAM]: CustomersTab.new })}`}>
                    <Trans
                        i18nKey="dashboard.customers.widget.new"
                        values={{ count: getCustomerCountByActionRequest(ActionRequestEnum.Approve, customerCount) }}
                        components={{
                            highlight: <Text color={Color.accent600} />,
                        }}
                    />
                </WidgetRowLink>
                <WidgetRowLink to={`${routes.customers.path}?${createSearchParamsAsString({ [TAB_PARAM]: CustomersTab.updated })}`}>
                    <Trans
                        i18nKey="dashboard.customers.widget.update"
                        values={{ count: getCustomerCountByActionRequest(ActionRequestEnum.Update, customerCount) }}
                        components={{
                            highlight: <Text color={Color.accent600} />,
                        }}
                    />
                </WidgetRowLink>
                <WidgetRowLink to={`${routes.customers.path}?${createSearchParamsAsString({ [TAB_PARAM]: CustomersTab.blocked })}`}>
                    <Trans
                        i18nKey="dashboard.customers.widget.block"
                        values={{ count: getCustomerCountByActionRequest(ActionRequestEnum.Block, customerCount) }}
                        components={{
                            highlight: <Text color={Color.accent600} />,
                        }}
                    />
                </WidgetRowLink>
                <WidgetRowLink to={`${routes.customers.path}?${createSearchParamsAsString({ [TAB_PARAM]: CustomersTab.unblocked })}`}>
                    <Trans
                        i18nKey="dashboard.customers.widget.unblock"
                        values={{ count: getCustomerCountByActionRequest(ActionRequestEnum.Unblock, customerCount) }}
                        components={{
                            highlight: <Text color={Color.accent600} />,
                        }}
                    />
                </WidgetRowLink>
                <WidgetRowLink
                    $withoutDecoration
                    to={`${routes.customers.path}?${createSearchParamsAsString({ [TAB_PARAM]: CustomersTab.deactivated })}`}
                >
                    <Trans
                        i18nKey="dashboard.customers.widget.deactivate"
                        values={{ count: getCustomerCountByActionRequest(ActionRequestEnum.Deactivate, customerCount) }}
                        components={{
                            highlight: <Text color={Color.accent600} />,
                        }}
                    />
                </WidgetRowLink>
            </CustomersWidgetLayout>
        </DashboardCard>
    );
};
