import type { FC } from "react";

import { useCreateProductBrandMutation, useUpdateProductBrandMutation } from "src/api";
import {
    Button,
    Checkbox,
    CheckboxProps,
    Confirm,
    InputGroupRowLayout,
    Modal,
    ModalButtonLayout,
    SectionFormLayout,
    TextInput,
    TextInputProps,
} from "src/components";
import { composeValidators, Form, InputField, max255Length } from "src/form";
import { useModal } from "src/modal";
import { useTranslation } from "src/translations";
import type { ProductBrandDto } from "src/types";
import { PRODUCT_BRAND_MODAL_ID } from "../constants";
import type { CreateAndUpdateProductBrand } from "../types";

type ProductBrandFormProps = Readonly<{
    productBrandForEdit?: ProductBrandDto;
}>;

export const ProductBrandForm: FC<ProductBrandFormProps> = ({ productBrandForEdit }) => {
    const [isModalOpened, , closeModal] = useModal(PRODUCT_BRAND_MODAL_ID);
    const [createProductBrand] = useCreateProductBrandMutation();
    const [updateProductBrand] = useUpdateProductBrandMutation();
    const { t } = useTranslation();
    const updateMode = !!productBrandForEdit;

    const initialValues = {
        id: updateMode ? productBrandForEdit.id : undefined,
        name: updateMode ? productBrandForEdit.name : "",
        active: updateMode ? productBrandForEdit.active : true,
        archived: updateMode ? productBrandForEdit.archived : false,
    };

    return (
        <Modal
            title={productBrandForEdit ? t("products.updateBrandTitle") : t("products.createBrandTitle")}
            opened={isModalOpened}
            onClose={closeModal}
            maxWidth="300px"
        >
            {(setIsTransparent) => (
                <Form<CreateAndUpdateProductBrand>
                    initialValues={initialValues}
                    onSubmit={updateMode ? updateProductBrand : createProductBrand}
                    onSuccess={closeModal}
                    loadingMessage={updateMode ? t("products.brandForm.updatingMessage") : t("products.brandForm.savingMessage")}
                    successMessage={
                        updateMode ? t("products.brandForm.updatingMessageSuccess") : t("products.brandForm.savingMessageSuccess")
                    }
                >
                    {({ dirty, submitForm, values }) => (
                        <>
                            <SectionFormLayout>
                                <InputField<TextInputProps["value"], TextInputProps>
                                    name="name"
                                    input={TextInput}
                                    validate={composeValidators(max255Length)}
                                    autofocus
                                    required
                                    inputProps={{
                                        label: t("products.brandForm.name.label"),
                                        placeholder: t("products.brandForm.name.placeholder"),
                                    }}
                                />
                                <InputGroupRowLayout>
                                    <InputField<CheckboxProps["value"], CheckboxProps>
                                        name="active"
                                        input={Checkbox}
                                        type="checkbox"
                                        inputProps={{
                                            label: t("products.brandForm.active.label"),
                                            description: updateMode ? undefined : t("products.brandForm.active.description"),
                                        }}
                                    />
                                    <InputField<CheckboxProps["value"], CheckboxProps>
                                        name="archived"
                                        input={Checkbox}
                                        inputProps={{
                                            label: t("products.brandForm.archived.label"),
                                        }}
                                    />
                                </InputGroupRowLayout>
                            </SectionFormLayout>
                            <ModalButtonLayout>
                                <Confirm
                                    title={t("products.brandForm.confirm.title")}
                                    content={t("products.brandForm.confirm.content")}
                                    setIsTransparentForModal={setIsTransparent}
                                    onConfirm={submitForm}
                                    conditionalConfirmation={() =>
                                        (values.archived && productBrandForEdit?.packages?.some((pack) => pack.active)) || false
                                    }
                                >
                                    {(openConfirm) => (
                                        <Button
                                            type="button"
                                            onClick={() => {
                                                openConfirm();
                                            }}
                                            disabled={!dirty}
                                        >
                                            {updateMode ? t("common.update") : t("common.create")}
                                        </Button>
                                    )}
                                </Confirm>
                                <Button type="button" onClick={closeModal} variant="outline">
                                    {t("common.cancel")}
                                </Button>
                            </ModalButtonLayout>
                        </>
                    )}
                </Form>
            )}
        </Modal>
    );
};
