import styled from "styled-components";

import { CopyTiersLayoutAreas } from "./CopyTiersLayout";

export const ApproveButtonLayout = styled.div`
    display: grid;
    grid-area: ${CopyTiersLayoutAreas.button};
    grid-auto-flow: column;
    column-gap: 0.5rem;
    justify-content: end;
`;
