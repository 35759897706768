import { ReactElement, useContext } from "react";

import { TabContext } from "./tabContext";

type TabPanelProps = {
    value: string;
    children: ReactElement;
};

export const TabPanel = ({ value, children }: TabPanelProps): ReactElement | null => {
    const activeTab = useContext(TabContext);

    return activeTab === value ? children : null;
};
