import { combineReducers, createReducer, Reducer } from "@reduxjs/toolkit";

import { setAccessToken } from "./actions";
import type { AuthState } from "./types";

const initialState: AuthState = {
    accessToken: null,
} as const;

const accessToken: Reducer<AuthState["accessToken"]> = createReducer(initialState.accessToken, (builder) => {
    builder.addCase(setAccessToken, (_, action) => action.payload);
});

export const reducer: Reducer<AuthState> = combineReducers({ accessToken });
