import { forwardRef, ReactNode } from "react";
import styled, { css } from "styled-components";

import { Color, IconSizes, maxWidthBreakpoint } from "src/theme";
import { ActionIcon, Icon } from "../../Icons";

type LeftSectionProps = Readonly<{
    bottomSection: JSX.Element;
    isStretched: boolean;
    children: ReactNode;
    isOpen: boolean;
    closeMobileMenu: () => void;
}>;

// eslint-disable-next-line react/prop-types
export const LeftSection = forwardRef<HTMLDivElement, LeftSectionProps>(
    ({ children, isStretched, bottomSection, isOpen, closeMobileMenu }, ref) => (
        <Wrapper ref={ref}>
            <Section isStretched={isStretched} isOpen={isOpen}>
                <CloseMenu>
                    <ActionIcon onClick={closeMobileMenu} color={Color.primary500}>
                        <Icon.Close size={IconSizes.xl} color={Color.white} />
                    </ActionIcon>
                </CloseMenu>
                <Top>{children}</Top>
                <Bottom>{bottomSection}</Bottom>
            </Section>
        </Wrapper>
    ),
);

const CloseMenu = styled.div`
    position: absolute;
    top: 3rem;
    left: 2rem;
    display: none;
    z-index: ${({ theme }) => theme.zIndex.leftSection};

    ${maxWidthBreakpoint.tablet(css`
        display: block;
    `)}
`;

const Wrapper = styled.div`
    height: 100%;
    width: 100%;
`;

const Section = styled.div<{ isStretched: LeftSectionProps["isStretched"]; isOpen: LeftSectionProps["isOpen"] }>`
    position: sticky;
    z-index: ${({ theme }) => theme.zIndex.leftSection};
    left: 0;
    width: ${({ isStretched }) => (isStretched ? "27rem" : "7rem")};
    transition: width 200ms ease, transform 400ms ease-out;
    overflow: hidden;
    top: 0;
    display: grid;
    height: 100vh;
    grid-auto-columns: 100%;
    grid-template-rows: 1fr auto;
    background-color: ${({ theme }) => theme.color.primary600};
    box-shadow: 0 4px 10px rgba(0, 0, 0, 0.25);

    ${({ isOpen }) =>
        maxWidthBreakpoint.tablet(css`
            position: fixed;
            left: 0;
            transform: translateX(${isOpen ? "0%" : "-100%"});
            overflow-y: ${isOpen ? "auto" : "unset"};
        `)}
`;

const Bottom = styled.div`
    display: grid;
    height: 100%;
    grid-auto-columns: 100%;
    width: 27rem;
    padding: 1.5rem 2rem;
    grid-auto-flow: row;
    align-items: end;
    box-sizing: border-box;
`;

const Top = styled.div`
    display: grid;
    height: 100%;
    grid-auto-flow: row;
    align-items: start;
    box-sizing: border-box;
    grid-template-columns: 100%;
`;
