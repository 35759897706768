import styled, { css } from "styled-components";

import { maxWidthBreakpoint } from "src/theme";

export const InterfaceWidgetWrapper = styled.div<{ $gap?: string }>`
    display: grid;
    grid-auto-flow: row;
    gap: ${({ $gap }) => $gap || "1rem"};

    ${maxWidthBreakpoint.mobile(css`
        gap: 1.5rem;
    `)}
`;
