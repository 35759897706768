import styled, { css } from "styled-components";

import { maxWidthBreakpoint } from "src/theme";

export const TableSelectionTop = styled.div`
    display: grid;
    grid-auto-flow: column;
    gap: 3rem;
    align-items: center;
    justify-content: space-between;
    width: 100%;
    box-sizing: border-box;
    padding-left: 1.6rem;

    ${maxWidthBreakpoint.desktopSmall(css`
        gap: 2rem;
    `)}

    ${maxWidthBreakpoint.mobile(css`
        grid-auto-flow: row;
        grid-template-columns: minmax(0, 1fr);
        padding: 0;
        gap: 1rem;
    `)}
`;
