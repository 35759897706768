// imports to setup polyfills
import "core-js/stable";
import "regenerator-runtime/runtime";

import { MantineProvider } from "@mantine/core";
import { NotificationsProvider } from "@mantine/notifications";
import { StrictMode } from "react";
import { render } from "react-dom";
import { Helmet, HelmetProvider } from "react-helmet-async";
import { Provider } from "react-redux";
import { BrowserRouter, Route, Routes } from "react-router-dom";
import { ThemeProvider } from "styled-components";

import { AppRouter } from "src/app";
import favIcon from "src/assets/icons/favicon-32x32.png";
import { AuthProvider } from "src/auth";
import { ClientConfigProvider } from "src/clientConfig";
import { ErrorBoundary } from "src/errorHandling";
import { AppLayout } from "src/layout";
import { ConfirmProvider } from "src/modal";
import { RecaptchaProvider } from "src/recaptcha";
import { routes } from "src/routes";
import { store } from "src/setup";
import { SpotlightProvider } from "src/spotlight";
import { GlobalStyle, theme, ZIndex } from "src/theme";
import { initTranslations } from "src/translations";
import { isDevelopment, printAppVersion } from "src/utils";
import { publicEmergencyOrders } from "./app/publicEmergencyOrders";

initTranslations();

if (isDevelopment()) {
    printAppVersion();
}

render(
    <StrictMode>
        <ErrorBoundary>
            <ThemeProvider theme={theme}>
                <HelmetProvider>
                    <GlobalStyle />
                    <MantineProvider withNormalizeCSS>
                        <Provider store={store}>
                            <ClientConfigProvider>
                                <BrowserRouter>
                                    <Helmet>
                                        <link rel="icon" href={favIcon} />
                                    </Helmet>
                                    {/* @ts-ignore */}
                                    <NotificationsProvider zIndex={ZIndex.notifications} position="top-center">
                                        <ConfirmProvider>
                                            <Routes>
                                                <Route
                                                    path={routes.emergencyOrders.path}
                                                    element={
                                                        <RecaptchaProvider>
                                                            <publicEmergencyOrders.Container />
                                                        </RecaptchaProvider>
                                                    }
                                                />
                                                <Route
                                                    path="*"
                                                    element={
                                                        <AuthProvider>
                                                            <SpotlightProvider>
                                                                <AppLayout>
                                                                    <AppRouter />
                                                                </AppLayout>
                                                            </SpotlightProvider>
                                                        </AuthProvider>
                                                    }
                                                />
                                            </Routes>
                                        </ConfirmProvider>
                                    </NotificationsProvider>
                                </BrowserRouter>
                            </ClientConfigProvider>
                        </Provider>
                    </MantineProvider>
                </HelmetProvider>
            </ThemeProvider>
        </ErrorBoundary>
    </StrictMode>,
    document.getElementById("app"),
);
