import styled from "styled-components";

export enum CopyTiersLayoutAreas {
    previous = "previous",
    new = "new",
    arrow = "arrow",
    button = "button",
}

export const CopyTiersLayout = styled.div`
    display: grid;
    grid-template-columns: 1fr 1fr 1fr;
    grid-template-rows: repeat(2, auto);
    grid-template-areas:
        "${CopyTiersLayoutAreas.previous} ${CopyTiersLayoutAreas.arrow} ${CopyTiersLayoutAreas.new}"
        ". . ${CopyTiersLayoutAreas.button}";
    row-gap: 5rem;
`;
