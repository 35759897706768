import styled, { css } from "styled-components";

import { maxWidthBreakpoint } from "src/theme";

export const CustomersWidgetLayout = styled.div`
    display: grid;
    grid-auto-flow: row;
    grid-template-columns: 1fr 1fr;
    column-gap: 1rem;

    ${maxWidthBreakpoint.tablet(css`
        grid-template-columns: 1fr;
    `)}
`;
