import type { FC } from "react";
import { useState } from "react";

import { Drawer } from "src/components";
import { useModal } from "src/modal";
import { routes } from "src/routes";
import { useTranslation } from "src/translations";
import type { SupplyLimitWithPackageAndMarginDto } from "src/types";
import { SHIP_TO_SUPPLY_LIMIT_DRAWER_ID } from "./constants";
import { ShipToSupplyLimitForm } from "./ShipToSupplyLimitForm";
import { tableModule } from "./table";
import { createActionColumn, createExpandRow } from "./utils";

export const ShipToSupplyLimit: FC = () => {
    const { t } = useTranslation();
    const { customerId } = routes.customerDetail.useParams();
    const [isDrawerOpened, openDrawer, closeDrawer] = useModal(SHIP_TO_SUPPLY_LIMIT_DRAWER_ID);
    const [selectedProductPackage, setSelectedProductPackage] = useState<SupplyLimitWithPackageAndMarginDto | undefined>(undefined);

    const onOpenDrawer = (value: SupplyLimitWithPackageAndMarginDto) => {
        openDrawer();
        setSelectedProductPackage(value);
    };

    return (
        <>
            <tableModule.Container
                additionalQueryParams={{ customerId }}
                expandedRow={createExpandRow}
                actions={createActionColumn(t, onOpenDrawer)}
            />
            <Drawer
                title={t("shipToDetail.supplyLimit.title", { name: selectedProductPackage?.productPackageName })}
                open={isDrawerOpened}
                onClose={closeDrawer}
            >
                <ShipToSupplyLimitForm closeDrawer={closeDrawer} productPackageId={selectedProductPackage?.productPackageId} />
            </Drawer>
        </>
    );
};
