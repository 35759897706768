import { format } from "date-fns";
import type { ReactNode } from "react";
import NumberFormat from "react-number-format";

import { CZK_CURRENCY, DATE_FORMAT, DEFAULT_CURRENCY_DECIMAL_SCALE, DEFAULT_PERCENTAGE_DECIMAL_SCALE } from "./constants";
import { isEmpty } from "./fn";

type NumberFormatterType = (value?: string | number, decimalScale?: number) => ReactNode;

export const currencyFormatter: NumberFormatterType = (value, decimalScale = DEFAULT_CURRENCY_DECIMAL_SCALE) =>
    !isEmpty(value) ? (
        <NumberFormat
            value={value}
            displayType="text"
            thousandSeparator=" "
            decimalSeparator=","
            decimalScale={decimalScale}
            suffix={` ${CZK_CURRENCY}`}
            fixedDecimalScale
        />
    ) : null;

export const percentageFormatter: NumberFormatterType = (value, decimalScale = DEFAULT_PERCENTAGE_DECIMAL_SCALE) =>
    !isEmpty(value) ? (
        <NumberFormat value={value} displayType="text" suffix=" %" decimalSeparator="," decimalScale={decimalScale} fixedDecimalScale />
    ) : null;

export const dateTimeFormatter = (dateTime: string, customFormat?: string): ReactNode => {
    if (dateTime) {
        return format(new Date(dateTime), customFormat ?? DATE_FORMAT);
    }

    return null;
};

export const zipFormatter = (zip: string): ReactNode => {
    if (zip) {
        return zip.replace(/^(.{3})(.*)$/, "$1 $2");
    }

    return null;
};

export const bytesFormatter = (bytes: number) => {
    if (!+bytes) return "0 Bytes";
    const decimals = 2;
    const k = 1024;
    const dm = decimals < 0 ? 0 : decimals;

    const sizes = ["Bytes", "KB", "MB", "GB", "TB", "PB", "EB", "ZB", "YB"];

    const i = Math.floor(Math.log(bytes) / Math.log(k));

    return `${parseFloat((bytes / k ** i).toFixed(dm))} ${sizes[i]}`;
};
