import { combineReducers } from "redux";

import { api, publicApi } from "src/api";
import auth from "src/auth";
import layout from "src/layout";
import modal from "src/modal";
import table from "src/table";

/**
 * Gets root reducer of application.
 *
 * Module reducers are added manually. Hardcoded names are used, otherwise
 * code suggestion for state does not work well.
 *
 * Any unhandled error from reducers will be processed by top level error handling and ErrorPage will be displayed.
 */
export const getRootReducer = () =>
    combineReducers({
        modalsModule: modal.reducer,
        [api.reducerPath]: api.reducer,
        [publicApi.reducerPath]: publicApi.reducer,
        tableModule: table.reducer,
        authModule: auth.reducer,
        layoutModule: layout.reducer,
    });
