import type { FileWithPath } from "@mantine/dropzone";
import type { FC } from "react";

import {
    url,
    useDeleteAttachmentMutation,
    useDownloadFileMutation,
    useGetSpecialPriceAttachmentsQuery,
    useUploadSpecialPriceAttachmentMutation,
} from "src/api";
import { Dropzone, Grid, Icon, LoadingOverlay, Modal, Text } from "src/components";
import { GlobalFormError } from "src/form";
import { NotificationTypes, useNotifications } from "src/notifications";
import { Color, IconSizes } from "src/theme";
import { useTranslation } from "src/translations";
import { bytesFormatter } from "src/utils";
import { SpecialPriceAttachmentRow, SpecialPriceAttachmentWrapper } from "../../components";
import { ATTACHMENT_ACCEPT_TYPES, HUNDRED_MB_IN_BYTES } from "../constants";
import { SPECIAL_PRICES_ATTACHMENT_MODAL_WIDTH } from "./constants";
import { createActions } from "./utils";

type SpecialPriceAttachmentProps = {
    isOpened: boolean;
    onClose: () => void;
    specialPriceId?: number;
    customerId: string;
};

export const SpecialPriceAttachment: FC<SpecialPriceAttachmentProps> = ({ customerId, specialPriceId, isOpened, onClose }) => {
    const { t } = useTranslation();
    const notifications = useNotifications();
    const { data: specialPriceAttachments, isLoading } = useGetSpecialPriceAttachmentsQuery(
        { customerId, specialPriceId: specialPriceId!! },
        { skip: !specialPriceId },
    );
    const [uploadFile] = useUploadSpecialPriceAttachmentMutation();
    const [downloadFile] = useDownloadFileMutation();
    const [deleteFile] = useDeleteAttachmentMutation();

    const uploadAttachment = async (files: Array<File>) => {
        const formData = new FormData();
        files.forEach((file) => {
            if (file.size > HUNDRED_MB_IN_BYTES) {
                throw new GlobalFormError(t("customers.attachments.maxFileSize100MB"));
            }
            formData.append("files", file);
        });
        return uploadFile({ formData, customerId, specialPriceId: specialPriceId!! }).unwrap();
    };

    const downloadAttachment = async (fileId: number, filename: string, fileMimeType: string, open: boolean) => {
        const notificationId = notifications.showNotification({
            title: t("common.form.loadingTitle"),
            message: t(`specialPrices.attachments.download.loading`),
            loading: true,
        });
        try {
            await downloadFile({ url: `${url.ATTACHMENT}/${fileId}`, filename, fileMimeType, open }).unwrap();
            notifications.updateNotification({
                id: notificationId,
                loading: false,
                title: t("common.form.successTitle"),
                message: t(`specialPrices.attachments.download.success`),
                type: NotificationTypes.success,
            });
        } catch (e) {
            notifications.updateNotificationToGlobalError({
                id: notificationId,
                error: e,
                fallbackMessage: t(`specialPrices.attachments.download.error`),
            });
        }
    };

    const deleteAttachment = async (fileId: number) => {
        const notificationId = notifications.showNotification({
            title: t("common.form.loadingTitle"),
            message: t(`specialPrices.attachments.delete.loading`),
            loading: true,
        });
        try {
            await deleteFile(fileId).unwrap();
            notifications.updateNotification({
                id: notificationId,
                loading: false,
                title: t("common.form.successTitle"),
                message: t(`specialPrices.attachments.delete.success`),
                type: NotificationTypes.success,
            });
        } catch (e) {
            notifications.updateNotificationToGlobalError({
                id: notificationId,
                error: e,
                fallbackMessage: t(`specialPrices.attachments.delete.success`),
            });
        }
    };

    const onDrop = (files: FileWithPath[]) => {
        const notificationId = notifications.showNotification({
            title: t("common.form.loadingTitle"),
            message: t("specialPrices.attachments.upload.loading"),
            loading: true,
        });
        if (files) {
            uploadAttachment(files)
                .then(() => {
                    notifications.updateNotification({
                        id: notificationId,
                        loading: false,
                        title: t("common.form.successTitle"),
                        message: t(`specialPrices.attachments.upload.success`),
                        type: NotificationTypes.success,
                    });
                })
                .catch((e) => {
                    notifications.updateNotificationToGlobalError({
                        id: notificationId,
                        error: e,
                        fallbackMessage: t(`specialPrices.attachments.upload.error`),
                    });
                });
        }
    };

    return (
        <Modal
            title={t("specialPrices.attachments.title")}
            opened={isOpened}
            onClose={onClose}
            maxWidth={SPECIAL_PRICES_ATTACHMENT_MODAL_WIDTH}
        >
            {(setIsTransparent) => (
                <>
                    <LoadingOverlay loading={isLoading} />

                    <Grid gridAutoFlow="row" gap="1rem">
                        <Dropzone minHeight="8rem" accept={ATTACHMENT_ACCEPT_TYPES} onDrop={onDrop} autoFocus multiple>
                            <Dropzone.Accept>
                                <Grid gridAutoFlow="column" gap="3rem" justifyContent="center" alignItems="center">
                                    <Icon.Checkmark size={IconSizes.xl} color={Color.supportNavy500} filled />
                                    <Text color={Color.supportNavy500} size="1.6rem">
                                        {t("customers.attachments.dropzone.accept.info")}
                                    </Text>
                                </Grid>
                            </Dropzone.Accept>
                            <Dropzone.Reject>
                                <Grid gridAutoFlow="column" gap="3rem" justifyContent="center" alignItems="center">
                                    <Icon.Close size={IconSizes.xl} color={Color.supportNavy500} />
                                    <Text color={Color.supportNavy500} size="1.6rem">
                                        {t("customers.attachments.dropzone.reject.info")}
                                    </Text>
                                </Grid>
                            </Dropzone.Reject>
                            <Dropzone.Idle>
                                <Grid gridAutoFlow="column" gap="3rem" justifyContent="center" alignItems="center">
                                    <Icon.Upload size={IconSizes.xl} color={Color.supportNavy500} filled />
                                    <Grid gridAutoFlow="row" gap="0.5rem">
                                        <Text color={Color.supportNavy500} size="1.6rem">
                                            {t("customers.attachments.dropzone.idle.info")}
                                        </Text>
                                        <Text color={Color.neutral400} size="1.4rem">
                                            {t("customers.attachments.dropzone.idle.info2")}
                                        </Text>
                                    </Grid>
                                </Grid>
                            </Dropzone.Idle>
                        </Dropzone>
                        <SpecialPriceAttachmentWrapper>
                            {specialPriceAttachments?.map((attachment) => (
                                <SpecialPriceAttachmentRow key={attachment.fileName}>
                                    <Text size="1.4rem">{attachment.fileName}</Text>
                                    <Text size="1.4rem">{bytesFormatter(attachment.fileSize)}</Text>
                                    <Text size="1.4rem">{attachment.contentType}</Text>
                                    {createActions(t, attachment, downloadAttachment, deleteAttachment, setIsTransparent)}
                                </SpecialPriceAttachmentRow>
                            ))}
                        </SpecialPriceAttachmentWrapper>
                    </Grid>
                </>
            )}
        </Modal>
    );
};
