import type { FC } from "react";

import { useBulkRefuseEmergencyOrdersMutation } from "src/api";
import { Button, Modal, ModalButtonLayout, Textarea, TextareaProps } from "src/components";
import { Form, InputField } from "src/form";
import { useModal } from "src/modal";
import { useTranslation } from "src/translations";
import type { RefuseEmergencyOrderDto } from "src/types";
import { useAppDispatch } from "src/utils";
import { SHIP_TO_EMERGENCY_ORDERS_MODAL_WIDTH, SHIP_TO_REFUSE_EMERGENCY_ORDERS_MODAL } from "./constants";
import { tableModule } from "./table";

type RefuseFormProps = {
    selectedIds: number[];
};

export const RefuseForm: FC<RefuseFormProps> = ({ selectedIds }) => {
    const { t } = useTranslation();
    const dispatch = useAppDispatch();
    const [isModalOpened, openModal, closeModal] = useModal(SHIP_TO_REFUSE_EMERGENCY_ORDERS_MODAL);
    const [bulkRefuse] = useBulkRefuseEmergencyOrdersMutation();

    const onSuccess = () => {
        dispatch(tableModule.resetTableSelectionAction);
        closeModal();
    };

    return (
        <>
            <Button disabled={selectedIds.length === 0} variant="outline" type="button" onClick={openModal}>
                {t("shipToDetail.emergencyOrders.refuseSelected")}
            </Button>
            <Modal
                title={t("shipToDetail.emergencyOrders.refuseModal.title")}
                opened={isModalOpened}
                onClose={onSuccess}
                maxWidth={SHIP_TO_EMERGENCY_ORDERS_MODAL_WIDTH}
            >
                <Form<RefuseEmergencyOrderDto>
                    initialValues={{ emergencyOrderProductPackageIds: selectedIds, refusalReason: "" }}
                    onSubmit={bulkRefuse}
                    onSuccess={closeModal}
                    loadingMessage={t("shipToDetail.emergencyOrders.loadingRefuseBulkMessage")}
                    successMessage={t("shipToDetail.emergencyOrders.successRefuseBulkMessage")}
                >
                    {({ dirty }) => (
                        <>
                            <InputField<TextareaProps["value"], TextareaProps>
                                name="refusalReason"
                                input={Textarea}
                                inputProps={{
                                    label: t("shipToDetail.emergencyOrders.modal.refusalReason.label"),
                                }}
                                required
                            />
                            <ModalButtonLayout>
                                <Button type="submit" disabled={!dirty}>
                                    {t("shipToDetail.emergencyOrders.refuseSelected")}
                                </Button>
                                <Button type="button" onClick={closeModal} variant="outline">
                                    {t("common.cancel")}
                                </Button>
                            </ModalButtonLayout>
                        </>
                    )}
                </Form>
            </Modal>
        </>
    );
};
