import type { FC } from "react";

import { useGetInterfaceAcknowledgementErrorQuery } from "src/api";
import { Grid, LoadingOverlay, Text } from "src/components";
import { useTranslation } from "src/translations";

type ExportInterfacesErrorProps = {
    interfaceOperationId?: number;
};

export const ExportInterfacesError: FC<ExportInterfacesErrorProps> = ({ interfaceOperationId }) => {
    const { data, isLoading } = useGetInterfaceAcknowledgementErrorQuery(interfaceOperationId!!, { skip: !interfaceOperationId });
    const { t } = useTranslation();

    return (
        <>
            <LoadingOverlay loading={isLoading} />
            {data && data.length > 0 ? (
                <Grid gridTemplateColumns="max-content max-content 1fr" gap="2rem">
                    <Text weight={700}>{t("importExport.exportInterfaceError.positionNumber")}</Text>
                    <Text weight={700}>{t("importExport.exportInterfaceError.errorCode")}</Text>
                    <Text weight={700}>{t("importExport.exportInterfaceError.errorMessage")}</Text>
                    {data?.map((error) => (
                        <>
                            <Text>{error.positionNumber}</Text>
                            <Text>{error.errorCode}</Text>
                            <Text>{error.errorMessage}</Text>
                        </>
                    ))}
                </Grid>
            ) : (
                <Text>{t("common.noData")}</Text>
            )}
        </>
    );
};
