import styled, { css } from "styled-components";

import { maxWidthBreakpoint } from "src/theme";

export const ProductPackageDependentFieldLayout = styled.div`
    display: grid;
    grid-template-columns: 1fr min-content 1fr;
    gap: 3rem;

    ${maxWidthBreakpoint.tablet(css`
        grid-template-columns: 1fr;
        gap: 1rem;
    `)};
`;
