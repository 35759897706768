import type { FC, ReactNode } from "react";
import styled from "styled-components";

type CurrentPriceCellProps = {
    price: ReactNode;
    date: ReactNode;
    disabled?: boolean;
};

export const CurrentPriceCell: FC<CurrentPriceCellProps> = ({ disabled, price, date }) => (
    <StyledCurrentPriceCell>
        <div>{price}</div>
        <Date $disabled={disabled}>{date}</Date>
    </StyledCurrentPriceCell>
);

const StyledCurrentPriceCell = styled.div`
    display: grid;
    grid-auto-flow: row;
    justify-items: end;
`;

const Date = styled.div<{ $disabled?: boolean }>`
    color: ${({ theme, $disabled }) => ($disabled ? theme.color.supportGraphite300 : theme.color.supportNavy300)};
    font-size: 1.1rem;
`;
