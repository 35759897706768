export const APPROVE_CUSTOMER_MODAL_ID = "APPROVE_CUSTOMER_MODAL";

export enum DefaultSupplyLimitEnum {
    Default = "DEFAULT",
    Unlimited = "UNLIMITED",
}

export enum ApproveStartPointEnum {
    List = "LIST",
    Detail = "DETAIL",
}
