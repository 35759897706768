import { FC, ReactNode, useCallback, useMemo, useRef, useState } from "react";

import { Button, Modal, ModalButtonLayout, StyledConfirmContent } from "src/components";
import { ZIndex } from "src/theme";
import { Trans, useTranslation } from "src/translations";
import { ConfirmContext } from "./ConfirmContext";
import { GLOBAL_CONFIRM_MODAL_ID } from "./constants";
import type { ConfirmContextType, ConfirmParamsType } from "./types";
import { useModal } from "./useModal";

type ConfirmProviderProps = {
    children: ReactNode;
};

export const ConfirmProvider: FC<ConfirmProviderProps> = ({ children }) => {
    const [isConfirmOpen, openConfirm, closeConfirm] = useModal(GLOBAL_CONFIRM_MODAL_ID);
    const [params, setParams] = useState<ConfirmParamsType | null>();
    const { t } = useTranslation();
    const resolver = useRef<Function>();

    const handleShow = useCallback(
        (confirmParams: ConfirmParamsType): Promise<boolean> => {
            setParams(confirmParams);
            confirmParams.setIsTransparentForModal?.(true);
            openConfirm();
            // Return Promise<boolean> which can be resolved inside this component,
            // because we saved resolve function into ref
            return new Promise((resolve) => {
                resolver.current = resolve;
            });
        },
        [openConfirm],
    );

    const modalContext: ConfirmContextType = useMemo(
        () => ({
            showConfirm: handleShow,
        }),
        [handleShow],
    );

    const handleConfirm = () => {
        if (resolver.current) {
            resolver.current(true);
        }
        params?.setIsTransparentForModal?.(false);
        closeConfirm();
    };

    const handleClose = () => {
        if (resolver.current) {
            resolver.current(false);
        }
        params?.setIsTransparentForModal?.(false);
        closeConfirm();
    };

    return (
        <ConfirmContext.Provider value={modalContext}>
            {children}
            <Modal
                opened={isConfirmOpen}
                onClose={handleClose}
                title={params?.title}
                maxWidth={params?.maxWidth}
                zIndex={ZIndex.confirmation}
                overlayOpacity={params?.setIsTransparentForModal === undefined ? 0.75 : 0}
            >
                <>
                    {(params?.content || params?.contentTransKey) && (
                        <StyledConfirmContent>
                            {params.content || <Trans i18nKey={params.contentTransKey} values={params.transValues} />}
                        </StyledConfirmContent>
                    )}
                    <ModalButtonLayout>
                        <Button type="button" onClick={handleConfirm}>
                            {t(params?.confirmButtonTransKey || "common.confirm")}
                        </Button>
                        <Button type="button" onClick={handleClose} variant="outline">
                            {t("common.cancel")}
                        </Button>
                    </ModalButtonLayout>
                </>
            </Modal>
        </ConfirmContext.Provider>
    );
};
