import styled from "styled-components";

export const SuccessBlock = styled.div`
    display: grid;
    grid-auto-flow: row;
    gap: 1rem;
    padding: 0.8rem 1.6rem;
    background-color: ${({ theme }) => theme.color.accent50};
    border-radius: ${({ theme }) => theme.radius.default};
`;
