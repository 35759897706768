import type { UseFiltersColumnProps } from "react-table";

import { useGetCustomerBranchesQuery } from "src/api";
import { MultiSelect, TABLE_FILTER_HEIGHT } from "src/components";
import type { TableRecordType } from "src/types";
import { isString } from "src/utils";

type BranchColumnFilterProps<RecordType extends TableRecordType> = Readonly<{
    column: UseFiltersColumnProps<RecordType>;
}>;

export const BranchColumnFilter = <RecordType extends TableRecordType>({
    column: { filterValue, setFilter },
}: BranchColumnFilterProps<RecordType>) => {
    const { data: branches, isLoading } = useGetCustomerBranchesQuery();

    const options = branches?.map(({ id, name, code }) => {
        return {
            value: id,
            label: code.toString(),
            description: `${code} - ${name}`,
        };
    });

    return (
        <MultiSelect
            options={isLoading || !options ? [] : options}
            withoutBorder
            withoutErrorPlaceholder
            height={TABLE_FILTER_HEIGHT}
            fontSize="1.2rem"
            value={isString(filterValue) ? [filterValue] : filterValue}
            disableOverflow
            withinPortal
            clearable
            maxDropdownHeight={300}
            searchInputWidth="60px"
            onChange={(value) => {
                setFilter(value && value.length > 0 ? value : undefined); // Set undefined to remove the filter entirely
            }}
        />
    );
};
