import { FluentIconsProps, iconFilledClassName, iconRegularClassName } from "@fluentui/react-icons";
import type { FC, ReactNode } from "react";
import styled, { css } from "styled-components";

import type { IconProps } from "src/types";
import { Tooltip } from "../Tooltip";
import { DELAY_FOR_ICON_TOOLTIP } from "./constants";

export { iconFilledClassName, iconRegularClassName } from "@fluentui/react-icons";

const withIconComponent =
    (component: ReactNode | ReactNode[]): FC<IconProps> =>
    // eslint-disable-next-line react/prop-types
    ({ color, filled, hoverColor, size, tooltip, tooltipBackgroundColor, tooltipDelay, tooltipArrow, tooltipColor }) => {
        const icon = tooltip ? (
            <Tooltip
                withArrow={!!tooltipArrow}
                withinPortal={false}
                label={tooltip}
                openDelay={tooltipDelay === undefined ? DELAY_FOR_ICON_TOOLTIP : tooltipDelay}
                backgroundColor={tooltipBackgroundColor}
                color={tooltipColor}
            >
                <span>{component}</span>
            </Tooltip>
        ) : (
            component
        );
        return (
            <StyledIcon size={size} color={color} hoverColor={hoverColor} filled={filled}>
                {icon}
            </StyledIcon>
        );
    };

const StyledIcon = styled.span<Omit<IconProps, "title">>`
    display: grid;

    ${({ theme, size }) =>
        size
            ? css`
                  font-size: ${size};
              `
            : css`
                  font-size: ${theme.iconSizes.l};
              `}
    svg.${iconFilledClassName} {
        display: none;
    }

    svg {
        color: ${({ color }) => color || "currentColor"};
        transition: color 200ms ease;
    }

    &:hover {
        ${({ hoverColor }) =>
            hoverColor &&
            css`
                svg {
                    color: ${hoverColor};
                }
            `};
    }

    ${({ filled }) =>
        filled &&
        css`
            svg.${iconFilledClassName} {
                display: inline;
            }

            svg.${iconRegularClassName} {
                display: none;
            }
        `};
`;

// We cannot use bundle icon function from @fluentui/react-icons because it will
// add primaryFill prop to svg which is not recognize by browser and
// it will show error in console
export const createIcon = (RegularIcon: FC<FluentIconsProps>, title: string, FilledIcon?: FC<FluentIconsProps>): FC<IconProps> => {
    const components = FilledIcon ? (
        [
            <FilledIcon className={iconFilledClassName} key="filled-icon" title={title} />,
            <RegularIcon className={iconRegularClassName} key="regular-icon" title={title} />,
        ]
    ) : (
        <RegularIcon className={iconRegularClassName} title={title} />
    );
    return withIconComponent(components);
};
