import type { TFunction } from "i18next";

import { Button, Confirm } from "src/components";

export const createActionsForSelection =
    (t: TFunction, blockPayersMutation: (value: Array<number>) => void) => (_: boolean, selectedRowIds: Record<number, boolean>) => {
        const onClick = () => {
            blockPayersMutation(Object.entries(selectedRowIds).map(([key]) => Number(key)));
        };
        return (
            <Confirm
                title={t("customers.deactivate.confirm.title")}
                content={t("customers.deactivate.confirm.content")}
                onConfirm={onClick}
            >
                {(open) => (
                    <Button disabled={Object.keys(selectedRowIds).length === 0} type="button" onClick={open}>
                        {t("customers.deactivate.deactivateSelected")}
                    </Button>
                )}
            </Confirm>
        );
    };
