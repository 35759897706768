import type { FC } from "react";

import { useGetCustomerDetailQuery } from "src/api";
import { routes } from "src/routes";
import { useTranslation } from "src/translations";
import { EmptyShipToCard, ShipToCard } from "../components";
import type { SupplyLimitsSettingsType } from "../types";

type NewShipToProps = {
    supplyLimitsSettings?: SupplyLimitsSettingsType;
};

export const NewShipTo: FC<NewShipToProps> = ({ supplyLimitsSettings }) => {
    const { t } = useTranslation();
    const cardTitle = t("copyTiers.newShipTo");

    const { newShipToId } = routes.copyTiers.useParams();
    const { data: newShipTo, isLoading, isError } = useGetCustomerDetailQuery(newShipToId);

    if (isError) {
        return <EmptyShipToCard isNewShipTo title={cardTitle} />;
    }

    return (
        <ShipToCard isNewShipTo shipTo={newShipTo} isLoading={isLoading} title={cardTitle} supplyLimitsSettings={supplyLimitsSettings} />
    );
};
