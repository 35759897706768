import { useCallback } from "react";

import { useAppDispatch, useAppParamSelector } from "src/utils";
import { closeMobileMenu, openMobileMenu } from "./actions";
import { isMobileMenuOpen } from "./selectors";

type UseMobileMenuReturnType = [boolean, () => void, () => void];

export const useMobileMenu = (): UseMobileMenuReturnType => {
    const dispatch = useAppDispatch();

    const open = useCallback(() => dispatch(openMobileMenu()), [dispatch]);
    const close = useCallback(() => dispatch(closeMobileMenu()), [dispatch]);
    const isOpen = useAppParamSelector(isMobileMenuOpen);

    return [isOpen, open, close];
};
