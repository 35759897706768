import styled, { css } from "styled-components";

import { maxWidthBreakpoint } from "src/theme";

export const AppLayout = styled.div`
    display: grid;
    font-family: ${({ theme }) => theme.fontFamily};
    height: 100%;
    grid-template-columns: 70px auto;
    min-height: 100vh;
    font-weight: 400;
    background-color: ${({ theme }) => theme.color.neutral50};

    ${maxWidthBreakpoint.tablet(css`
        grid-template-columns: 0px auto;
    `)}
`;
