import { combineReducers, createReducer, Reducer } from "@reduxjs/toolkit";

import { closeMobileMenu, openMobileMenu } from "./actions";
import type { LayoutState } from "./types";

const initialState: LayoutState = {
    isMobileMenuOpen: false,
} as const;

const isMobileMenuOpen: Reducer<LayoutState["isMobileMenuOpen"]> = createReducer(initialState.isMobileMenuOpen, (builder) => {
    builder.addCase(openMobileMenu, () => true);
    builder.addCase(closeMobileMenu, () => false);
});

export const reducer: Reducer<LayoutState> = combineReducers({ isMobileMenuOpen });
