import type { FC } from "react";

import { Tooltip } from "src/components";
import { Color } from "src/theme";
import { stringTrim } from "src/utils";

type LongTextCellProps = { text: string; charLimit: number };

export const LongTextCell: FC<LongTextCellProps> = ({ text, charLimit }) => {
    if (text) {
        if (text.length > charLimit) {
            return (
                <Tooltip width={300} withArrow multiline label={text} color={Color.supportGraphite500} backgroundColor={Color.accent50}>
                    <div>{`${stringTrim(text.slice(0, charLimit))}…`}</div>
                </Tooltip>
            );
        }
        return <span>{text}</span>;
    }

    return null;
};
