import { msalConfig } from "./config";
import msalInstance from "./msalInstance";

export const acquireAccessToken = async () => {
    const activeAccount = msalInstance.getActiveAccount(); // This will only return a non-null value if you have logic somewhere else that calls the setActiveAccount API
    const accounts = msalInstance.getAllAccounts();

    if (!activeAccount && accounts.length === 0) {
        // User is not signed in.
        return null;
    }
    const request = {
        scopes: [`${msalConfig.auth.clientId}/.default`],
        account: activeAccount || accounts[0],
    };

    try {
        const authResult = await msalInstance.acquireTokenSilent(request);
        return authResult.accessToken;
    } catch (e) {
        // This error will occur if the user has not granted consent.
        // After success, you will be redirected to home page.
        await msalInstance.acquireTokenRedirect(request);
        return null;
    }
};
