import { Modal as ModalMantine, ModalProps as ModalPropsMantine } from "@mantine/core";
import type { FC, ReactNode } from "react";
import { useState } from "react";
import styled, { css } from "styled-components";

import { maxWidthBreakpoint, ZIndex } from "src/theme";
import { MODAL_OVERLAY_DEFAULT_OPACITY } from "./constants";

export type ModalProps = Pick<ModalPropsMantine, "opened" | "onClose" | "title" | "overlayOpacity" | "closeOnClickOutside"> & {
    maxWidth?: string;
    children: ReactNode | ((show: (isTransparent: boolean) => void) => JSX.Element);
    zIndex?: ZIndex;
};

export const Modal: FC<ModalProps> = ({
    children,
    opened,
    onClose,
    title,
    maxWidth,
    overlayOpacity = MODAL_OVERLAY_DEFAULT_OPACITY,
    closeOnClickOutside = false,
    zIndex = ZIndex.modal,
}) => {
    const [isTransparent, setIsTransparent] = useState(false);
    return (
        <StyledModal
            isTransparent={isTransparent}
            title={title}
            maxWidth={maxWidth}
            opened={opened}
            closeOnClickOutside={closeOnClickOutside}
            onClose={onClose}
            zIndex={zIndex}
            overlayOpacity={overlayOpacity}
        >
            <ModalLayout>{typeof children === "function" ? children(setIsTransparent) : children}</ModalLayout>
        </StyledModal>
    );
};

const StyledModal = styled(ModalMantine).withConfig({
    shouldForwardProp: (prop) => !["maxWidth", "isTransparent"].includes(prop as string),
})<{
    isTransparent: boolean;
    maxWidth: ModalProps["maxWidth"];
}>`
    & .mantine-Modal-modal {
        ${({ isTransparent }) =>
            isTransparent &&
            css`
                opacity: 0 !important;
            `}
        padding: 3rem;
        ${({ maxWidth }) =>
            maxWidth &&
            css`
                width: 100%;
                max-width: ${maxWidth};
            `}
        ${maxWidthBreakpoint.mobile(css`
            padding: 2rem;
        `)}
    }

    & .mantine-Modal-header {
        margin-bottom: 2rem;

        svg {
            color: ${({ theme }) => theme.color.primary600};
        }
    }

    & .mantine-Modal-title {
        color: ${({ theme }) => theme.color.primary600};
        margin: 0;
        font-weight: 700;
        font-size: 1.8rem;
        white-space: pre-line;
    }

    & .mantine-Modal-close:focus {
        outline: none;
    }
`;

const ModalLayout = styled.div`
    display: grid;
    grid-auto-flow: row;
    gap: 3rem;
`;
