import { ActionIcon as ActionIconMantine, ActionIconProps } from "@mantine/core";
import { forwardRef } from "react";
import styled from "styled-components";

import { Color } from "src/theme";
import { Tooltip } from "../Tooltip";
import { DELAY_FOR_ICON_TOOLTIP } from "./constants";

type StyledActionIconProps = Omit<ActionIconProps, "color"> & {
    color?: Color;
    className?: string;
    tooltipWithinPortal?: boolean;
};

export const ActionIcon = forwardRef<
    HTMLButtonElement,
    Omit<StyledActionIconProps, "component"> & { tooltip?: string; onClick?: () => void }
>(({ tooltip, children, tooltipWithinPortal = true, ...props }, ref) =>
    tooltip ? (
        <Tooltip
            backgroundColor={props.color ?? Color.neutral200}
            color={Color.supportGraphite500}
            withinPortal={tooltipWithinPortal}
            openDelay={DELAY_FOR_ICON_TOOLTIP}
            label={tooltip}
        >
            <StyledActionIcon ref={ref} {...props}>
                {children}
            </StyledActionIcon>
        </Tooltip>
    ) : (
        <StyledActionIcon ref={ref} {...props}>
            {children}
        </StyledActionIcon>
    ),
);

const StyledActionIcon = styled(ActionIconMantine).withConfig({
    shouldForwardProp: (prop) => !["color"].includes(prop as string),
})<StyledActionIconProps>`
    &:disabled {
        background-color: transparent;
        border-color: transparent;
    }

    &:hover:enabled {
        background-color: ${({ color }) => color || "transparent"};
    }

    &:focus {
        outline: 2px solid ${({ theme }) => theme.color.neutral100};
    }
`;
