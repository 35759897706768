import { FieldValidator, useField } from "formik";
import type { FC } from "react";

import { useTranslation } from "src/translations";
import { noop } from "src/utils";
import { DO_NOT_TRANSLATE_PREFIX } from "./constants";
import { composeValidators, required as requiredValidator, Validator } from "./validation";

type InputFieldProps<ValueType, InputProps> = {
    name: string;
    input: FC<InputProps>;
    inputProps?: Partial<InputProps>;
    validate?: FieldValidator;
    id?: string;
    required?: boolean;
    type?: string;
    autofocus?: boolean;
    onInputChange?: (value: ValueType) => void;
};

export const InputField = <ValueType, InputProps>({
    name,
    input: Input,
    inputProps,
    id,
    required,
    validate = noop,
    type,
    autofocus,
    onInputChange,
}: InputFieldProps<ValueType, InputProps>): JSX.Element => {
    const { t } = useTranslation();

    const enhancedValidation = required ? composeValidators(requiredValidator, validate as Validator) : validate;
    const [field, meta, helpers] = useField<ValueType>({ name, id, type, required, validate: enhancedValidation });
    const otherProps = inputProps || {};
    const props = { ...field, setError: helpers.setError, setTouched: helpers.setTouched, ...otherProps };
    let translatedError: string | undefined;
    if (meta.touched && meta.error) {
        translatedError = meta.error?.startsWith(DO_NOT_TRANSLATE_PREFIX)
            ? meta.error.slice(DO_NOT_TRANSLATE_PREFIX.length)
            : t(meta.error);
    }

    // eslint-disable-next-line
    const onChange = (value: any) => {
        if (value?.currentTarget || value?.target) {
            field.onChange(value);
        } else {
            helpers.setValue(value ?? null);
        }
        onInputChange?.(value);
    };

    return (
        // @ts-ignore
        <Input data-autofocus={autofocus} required={required} {...props} id={id} error={translatedError} onChange={onChange} />
    );
};
