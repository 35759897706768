import { Checkbox as CheckboxMantine, CheckboxProps as CheckboxPropsMantine } from "@mantine/core";
import type { FC } from "react";
import { forwardRef } from "react";
import styled, { css } from "styled-components";

import type { Color } from "src/theme";
import { Tooltip } from "../../Tooltip";
import { labelStyles } from "../inputStyles";
import { removeUnusedPropsFromStyledInput } from "../utils";

export type CheckboxProps = CheckboxPropsMantine &
    Readonly<{
        description?: string;
        className?: string;
        tooltip?: string;
        tooltipColor?: Color;
        tooltipBackgroundColor?: Color;
    }>;

export const Checkbox: FC<CheckboxProps> = forwardRef(
    ({ label, description, value, tooltip, tooltipColor, tooltipBackgroundColor, ...props }, ref) => {
        const checkbox = tooltip ? (
            <Tooltip
                withinPortal={false}
                label={tooltip}
                backgroundColor={tooltipBackgroundColor}
                withArrow
                color={tooltipColor}
                position="left"
                openDelay={500}
            >
                <StyledCheckbox ref={ref} label={label || description} checked={value} {...props} />
            </Tooltip>
        ) : (
            <StyledCheckbox ref={ref} label={label || description} checked={value} {...props} />
        );
        return (
            <CheckboxWrapper>
                {description && <Label>{description}</Label>}
                {checkbox}
            </CheckboxWrapper>
        );
    },
);

const CheckboxWrapper = styled.div`
    display: flex;
    flex-direction: column;
    gap: 0.4rem;
`;

const StyledCheckbox = styled(CheckboxMantine).withConfig(removeUnusedPropsFromStyledInput)<CheckboxPropsMantine>`
    align-items: start;
    position: relative;

    & .mantine-Checkbox-labelWrapper {
        line-height: 1.6rem;
    }

    & .mantine-Checkbox-label {
        ${labelStyles};
        margin: 0;
        line-height: 1.8rem;
    }

    & .mantine-Checkbox-inner,
    & .mantine-Checkbox-input {
        width: 1.6rem;
        height: 1.6rem;

        border-radius: 0.2rem;

        cursor: pointer;

        &:disabled {
            cursor: not-allowed;
        }

        ${({ error, theme }) =>
            error &&
            css`
                border-color: ${theme.color.error500};
            `}
    }

    & .mantine-Checkbox-input:checked {
        background-color: ${({ theme }) => theme.color.primary600};
        border-color: ${({ theme }) => theme.color.primary600};
    }

    & .mantine-Checkbox-icon {
        width: 0.8rem;
    }
`;

const Label = styled.label`
    ${labelStyles}
`;
