export enum ZIndex {
    loading = 50,
    mobileBreadcrumbs = 75,
    leftSection = 100,
    modal = 500,
    confirmation = 550,
    tooltip = 600,
    calendar = 800,
    notifications = 1000,
}
