import type { TFunction } from "i18next";
import type { FC, ReactNode } from "react";

import { TCell, Text, TRow } from "src/components";
import { Color } from "src/theme";

type EmptyRowProps = {
    t: TFunction;
    colSpan: number;
    customEmptyRow?: (dirtyFilters: boolean) => ReactNode;
    dirtyFilters: boolean;
};

export const EmptyRow: FC<EmptyRowProps> = ({ colSpan, customEmptyRow, t, dirtyFilters }) => (
    <TRow key="empty_row" $backgroundColor={Color.neutral50}>
        <TCell key="empty_cell" colSpan={colSpan} $align="center">
            {customEmptyRow ? (
                customEmptyRow(dirtyFilters)
            ) : (
                <Text color={Color.neutral400}>{dirtyFilters ? t("common.noDataWithFilters") : t("common.noData")}</Text>
            )}
        </TCell>
    </TRow>
);
