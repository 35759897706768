import type { Configuration, PopupRequest } from "@azure/msal-browser";

const getADRedirectUrl = () => {
    const developmentUrl = "http://localhost:3000";
    return window.location.hostname === "localhost" ? developmentUrl : `${window.location.protocol}//${window.location.host}`;
};

// Config object to be passed to Msal on creation
// CONFIGURATION FOR AZ AD
export const msalConfig: Configuration = {
    auth: {
        clientId: "92701ff1-3db7-4b5b-9aae-29e3eaddf922",
        authority: "https://login.microsoftonline.com/af8e89a3-d9ac-422f-ad06-cc4eb4214314",
        redirectUri: getADRedirectUrl(),
        postLogoutRedirectUri: getADRedirectUrl(),
    },
};

// CONFIGURATION FOR MOROSYSTEMS AD
// export const msalConfig: Configuration = {
//     auth: {
//         clientId: "a084b1be-6933-4884-93d5-a69a754ebd82",
//         authority: "https://login.microsoftonline.com/1f312650-8b01-4311-b692-debe9187a686",
//         redirectUri: getADRedirectUrl(),
//         postLogoutRedirectUri: getADRedirectUrl(),
//     },
// };

// Add here scopes for id token to be used at MS Identity Platform endpoints.
export const loginRequest: PopupRequest = {
    scopes: ["openid", "profile"],
};
