import { IMAGE_MIME_TYPE, PDF_MIME_TYPE } from "@mantine/dropzone";
import type { TFunction } from "i18next";
import type { ReactNode } from "react";
import type { Row } from "react-table";

import { ActionIcon, Icon, TableAction } from "src/components";
import { ConfirmContextConsumer } from "src/modal";
import { ICON_TABLE_SIZE } from "src/table";
import { Color } from "src/theme";
import type { AttachmentDto } from "src/types";

const createDeleteAttachmentButton = (t: TFunction, onConfirm: () => void) => (
    <ConfirmContextConsumer>
        {({ showConfirm }) => {
            const confirm = async () => {
                const confirmResult = await showConfirm({
                    title: t("customers.attachments.delete.title"),
                    content: t("customers.attachments.delete.content"),
                });
                if (confirmResult) {
                    onConfirm();
                }
            };
            return (
                <ActionIcon onClick={confirm} color={Color.error100} tooltip={t("customers.attachments.deleteTooltip")}>
                    <Icon.Delete size={ICON_TABLE_SIZE} color={Color.error500} />
                </ActionIcon>
            );
        }}
    </ConfirmContextConsumer>
);

export const createActionColumn =
    (
        t: TFunction,
        downloadAttachment: (fileId: number, filename: string, fileMimeType: string, open: boolean) => void,
        deleteAttachment: (fileId: number) => void,
    ) =>
    ({ original }: Row<AttachmentDto>): ReactNode => {
        const canOpen = ([...IMAGE_MIME_TYPE, ...PDF_MIME_TYPE] as string[]).includes(original.contentType);
        return (
            <TableAction>
                {canOpen && (
                    <ActionIcon
                        onClick={() => downloadAttachment(original.id, original.fileName, original.contentType, true)}
                        color={Color.accent100}
                        tooltip={t("customers.attachments.openTooltip")}
                    >
                        <Icon.Open size={ICON_TABLE_SIZE} color={Color.supportNavy500} />
                    </ActionIcon>
                )}
                <ActionIcon
                    onClick={() => downloadAttachment(original.id, original.fileName, original.contentType, false)}
                    color={Color.accent100}
                    tooltip={t("customers.attachments.downloadTooltip")}
                >
                    <Icon.Download size={ICON_TABLE_SIZE} color={Color.supportNavy500} />
                </ActionIcon>
                {createDeleteAttachmentButton(t, () => deleteAttachment(original.id))}
            </TableAction>
        );
    };
