import styled from "styled-components";

export const TableWithPaginationLayout = styled.div`
    display: grid;
    grid-auto-flow: row;
    grid-template-rows: minmax(0, 1fr);
    gap: 2rem;
    justify-items: center;
    width: 100%;
`;
