import { TextInput as TextInputMantine, TextInputProps } from "@mantine/core";
import styled from "styled-components";

import {
    createInputStyles,
    createInputWrapperStyles,
    errorMessageStyles,
    InputStylesProps,
    invalidInputStyles,
    labelStyles,
} from "../inputStyles";
import { removeUnusedPropsFromStyledInput } from "../utils";

export type { TextInputProps } from "@mantine/core";

export const TextInput = styled(TextInputMantine).withConfig(removeUnusedPropsFromStyledInput)<TextInputProps & InputStylesProps>`
    ${createInputWrapperStyles}
    & input {
        ${createInputStyles}
    }

    & label {
        ${labelStyles}
    }

    & .mantine-TextInput-invalid {
        ${invalidInputStyles}
    }

    & .mantine-TextInput-error {
        ${errorMessageStyles}
    }

    & .mantine-TextInput-rightSection {
        width: auto;
        padding-right: 0.1rem;
    }
`;
