import type { FC } from "react";
import { useNavigate, useSearchParams } from "react-router-dom";

import { useGetPayerShipTosQuery } from "src/api";
import { Flex, TabPanel, Tabs } from "src/components";
import { createSearchParamsAsString, PayerDetailTab, routes, ShipToDetailTab, TAB_PARAM } from "src/routes";
import { useTranslation } from "src/translations";
import type { CustomerDto } from "src/types";
import { createIsEnum } from "src/utils";
import { Attachments, DataModificationLog, Notes, SegmentedInfo } from "../../containers";
import { DistributionMargins } from "./distributionMargins";
import { PayerSpecialPrices } from "./payerSpecialPrices";
import { ShipTos } from "./shipTos";

type PayerDetailProps = {
    customerDetail: CustomerDto;
};

const isPayerDetailTabEnum = createIsEnum(PayerDetailTab);

export const PayerDetail: FC<PayerDetailProps> = ({ customerDetail }) => {
    const { t } = useTranslation();
    const navigate = useNavigate();
    const { customerId } = routes.customerDetail.useParams();
    const [searchParams] = useSearchParams();

    const { data: payerShipTos } = useGetPayerShipTosQuery({ pageSize: 10, pageIndex: 0, filters: { payerId: customerId } });

    const activeTabFromSearchParam = searchParams.get(TAB_PARAM);

    const activeTab =
        activeTabFromSearchParam && isPayerDetailTabEnum(activeTabFromSearchParam)
            ? activeTabFromSearchParam
            : PayerDetailTab.shipToPartners;

    return (
        <Flex flexDirection="column" gap="3rem">
            <SegmentedInfo isPayer customerDetail={customerDetail} />
            <Tabs<PayerDetailTab>
                activeTab={activeTab}
                onTabChange={(tabValue: PayerDetailTab) =>
                    navigate(
                        `${routes.customerDetail.fillPathParams({ customerId })}?${createSearchParamsAsString({
                            [TAB_PARAM]: tabValue,
                        })}`,
                    )
                }
                tabs={[
                    {
                        tabValue: PayerDetailTab.shipToPartners,
                        label: t("payerDetail.tabs.shipToPartners", { count: payerShipTos?.totalElements || 0 }),
                    },
                    {
                        tabValue: PayerDetailTab.packageDistributionMargins,
                        label: t("payerDetail.tabs.packageDistributionMargins"),
                    },
                    {
                        tabValue: PayerDetailTab.specialPrices,
                        label: t("payerDetail.tabs.specialPrices"),
                    },
                    {
                        tabValue: PayerDetailTab.dataModificationLog,
                        label: t("payerDetail.tabs.dataModificationLog"),
                    },
                    {
                        tabValue: PayerDetailTab.notes,
                        label: t("payerDetail.tabs.notes"),
                    },
                    {
                        tabValue: PayerDetailTab.attachments,
                        label: t("payerDetail.tabs.attachments"),
                    },
                ]}
            >
                <TabPanel value={PayerDetailTab.shipToPartners}>
                    <ShipTos />
                </TabPanel>
                <TabPanel value={PayerDetailTab.packageDistributionMargins}>
                    <DistributionMargins />
                </TabPanel>
                <TabPanel value={PayerDetailTab.specialPrices}>
                    <PayerSpecialPrices />
                </TabPanel>
                <TabPanel value={PayerDetailTab.dataModificationLog}>
                    <DataModificationLog isPayer />
                </TabPanel>
                <TabPanel value={PayerDetailTab.notes}>
                    <Notes />
                </TabPanel>
                <TabPanel value={ShipToDetailTab.attachments}>
                    <Attachments customerId={customerId} />
                </TabPanel>
            </Tabs>
        </Flex>
    );
};
