import { addDays } from "date-fns";
import type { FC } from "react";

import { useCreateProductPriceMutation, useUpdateProductPriceMutation } from "src/api";
import {
    Button,
    DateInput,
    DateInputProps,
    Icon,
    Modal,
    ModalButtonLayout,
    NumberInput,
    NumberInputProps,
    Text,
    WarningBox,
} from "src/components";
import { createCurrencyInputProps, Form, InputField } from "src/form";
import { useModal } from "src/modal";
import { Color, IconSizes } from "src/theme";
import { Trans, useTranslation } from "src/translations";
import type { CreateAndUpdateProductPrice, ProductPackageWithPricesDto, ProductPriceDto } from "src/types";
import { isDateBeforeToday, isDateOlderThenYesterday, isDateUnlimited } from "src/utils";
import { PRODUCT_PRICE_MODAL_ID } from "../constants";

type ProductPriceFormProps = Readonly<{
    productPackageWithPrices?: ProductPackageWithPricesDto;
    productPriceForEdit?: ProductPriceDto;
}>;

export const ProductPriceForm: FC<ProductPriceFormProps> = ({ productPackageWithPrices, productPriceForEdit }) => {
    const [isModalOpened, , closeModal] = useModal(PRODUCT_PRICE_MODAL_ID);
    const [createProductBrand] = useCreateProductPriceMutation();
    const [updateProductBrand] = useUpdateProductPriceMutation();
    const { t } = useTranslation();
    const hasAnyPrice = productPackageWithPrices?.prices.length !== 0;

    if (isModalOpened && !productPackageWithPrices) {
        throw new Error("Product package with prices has to be selected for displaying product price form.");
    }

    const updateMode = !!productPriceForEdit;
    const productPackagePricesLength = productPackageWithPrices?.prices.length || 0;
    const newestProductPrice = productPackageWithPrices?.prices[0];
    // prices are sorted from newest to oldest, so last item in array is the oldest price
    const idNewestPrice =
        productPackageWithPrices?.prices && productPackagePricesLength > 0 ? newestProductPrice?.id === productPriceForEdit?.id : true;
    const isOldestPrice =
        productPackageWithPrices?.prices && productPackagePricesLength > 0
            ? productPackageWithPrices?.prices[productPackagePricesLength - 1].id === productPriceForEdit?.id
            : true;
    const isCurrentPrice = productPriceForEdit?.id === productPackageWithPrices?.currentPrice?.id;
    const nextValidFromDate =
        newestProductPrice?.validTo && !isDateUnlimited(new Date(newestProductPrice.validTo))
            ? addDays(new Date(newestProductPrice.validTo), 1)
            : undefined;

    const initialValues = {
        id: updateMode ? productPriceForEdit?.id : undefined,
        productPackageId: productPackageWithPrices?.id,
        price: updateMode ? productPriceForEdit?.price : undefined,
        validFrom: updateMode ? new Date(productPriceForEdit?.validFrom) : nextValidFromDate,
        validTo: updateMode ? new Date(productPriceForEdit?.validTo) : undefined,
    };

    return (
        <Modal
            title={
                <Trans
                    i18nKey={updateMode ? "prices.updatePriceTitle" : "prices.createPriceTitle"}
                    values={{ name: productPackageWithPrices?.name }}
                    components={{
                        productName: <Text uppercase />,
                    }}
                />
            }
            maxWidth="450px"
            opened={isModalOpened}
            onClose={closeModal}
        >
            <Form<CreateAndUpdateProductPrice>
                initialValues={initialValues}
                onSubmit={updateMode ? updateProductBrand : createProductBrand}
                onSuccess={closeModal}
                loadingMessage={updateMode ? t("prices.form.updatingMessage") : t("prices.form.savingMessage")}
                successMessage={updateMode ? t("prices.form.updatingMessageSuccess") : t("prices.form.savingMessageSuccess")}
            >
                {({ dirty, values }) => (
                    <>
                        <InputField<NumberInputProps["value"], NumberInputProps>
                            name="price"
                            type="number"
                            input={NumberInput}
                            required
                            inputProps={{
                                label: t("prices.form.price.label"),
                                placeholder: t("prices.form.price.placeholder"),
                                disabled: updateMode && isCurrentPrice,
                                maxWidth: "160px",
                                ...createCurrencyInputProps(t),
                            }}
                        />
                        <InputField<DateInputProps["value"], DateInputProps>
                            name="validFrom"
                            input={DateInput}
                            required
                            inputProps={{
                                label: t("prices.form.validFrom.label"),
                                placeholder: t("common.datePlaceholder"),
                                maxWidth: "195px",
                                disabled: updateMode && (isCurrentPrice || !isOldestPrice),
                                excludeDate: hasAnyPrice ? undefined : isDateBeforeToday,
                            }}
                        />
                        <InputField<DateInputProps["value"], DateInputProps>
                            name="validTo"
                            input={DateInput}
                            inputProps={{
                                label: t("prices.form.validTo.label"),
                                placeholder: t("prices.form.validTo.placeholder"),
                                maxWidth: "195px",
                                clearable: true,
                                disabled: updateMode && !isCurrentPrice && !idNewestPrice,
                                excludeDate: hasAnyPrice ? isDateOlderThenYesterday : isDateBeforeToday,
                            }}
                        />
                        {values.validTo && isDateBeforeToday(values.validTo) && (
                            <WarningBox>
                                <Icon.Warning size={IconSizes.m} color={Color.warning700} />
                                <Text color={Color.warning700} size="1.4rem">
                                    {t("prices.form.validTo.pastWarning")}
                                </Text>
                            </WarningBox>
                        )}
                        <ModalButtonLayout>
                            <Button type="submit" disabled={!dirty}>
                                {updateMode ? t("common.update") : t("common.create")}
                            </Button>
                            <Button type="button" onClick={closeModal} variant="outline">
                                {t("common.cancel")}
                            </Button>
                        </ModalButtonLayout>
                    </>
                )}
            </Form>
        </Modal>
    );
};
