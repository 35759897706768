import { format } from "date-fns";
import type { FC } from "react";
import styled from "styled-components";

const { VERSION, LAST_COMMIT_DATETIME, BUILD_TIME } = APP_INFO;

type AppVersionProps = Readonly<{
    visible: boolean;
}>;

const APP_VERSION_DATE_TIME_FORMAT = "dd/MM/yyyy HH:mm:ss";

/**
 * Displays information about application, like version and name from package.json and commit info.
 * Localization is not currently supported.
 */
export const AppVersion: FC<AppVersionProps> = ({ visible }) => {
    const commitTime = format(new Date(LAST_COMMIT_DATETIME), APP_VERSION_DATE_TIME_FORMAT);
    const buildTime = format(new Date(BUILD_TIME), APP_VERSION_DATE_TIME_FORMAT);
    return (
        <AppVersionComponent visible={visible}>
            <p>Version: {VERSION}</p>
            <p>Commit time: {commitTime}</p>
            <p>Build time: {buildTime}</p>
        </AppVersionComponent>
    );
};

const AppVersionComponent = styled.div<{ visible: AppVersionProps["visible"] }>`
    color: ${({ theme }) => theme.color.supportGraphite100};
    box-sizing: border-box;
    transition: opacity 200ms ease ${({ visible }) => (visible ? "200ms" : 0)};
    opacity: ${({ visible }) => (visible ? 100 : 0)};

    p {
        margin: 0;
        font-size: 1rem;
    }
`;
