import type { FC } from "react";
import styled from "styled-components";

import { Color, IconSizes } from "src/theme";
import { Icon } from "../../Icons";

type FileInputValueProps = {
    file: File;
};

export const FileInputValue: FC<FileInputValueProps> = ({ file }) => (
    <StyledFileInputValue>
        <Icon.Document color={Color.supportNavy300} size={IconSizes.xs} filled />
        <FileName>{file.name}</FileName>
    </StyledFileInputValue>
);

const StyledFileInputValue = styled.div`
    display: grid;
    grid-template-columns: min-content min-content;
    align-items: center;
    gap: 0.5rem;
    background-color: ${({ theme }) => theme.color.neutral200};
    font-size: 1.2rem;
    padding: 0.2rem 1rem;
    border-radius: ${({ theme }) => theme.radius.default};
`;

const FileName = styled.span`
    white-space: nowrap;
    text-overflow: ellipsis;
    overflow: hidden;
    max-width: 20rem;
    display: inline-block;
`;
