import type { TFunction } from "react-i18next";
import type { Cell } from "react-table";

import { useGetPayersDistributionMarginQuery } from "src/api";
import { createSelectColumnFilter, HighlightCell, TextColumnFilter, Tooltip } from "src/components";
import { createTableModule, TableNames } from "src/table";
import { Color } from "src/theme";
import type { ExpandColumnsType, ExpandTableDistributionMarginsType, SupplyLimitWithPackageAndMarginDto } from "src/types";
import { MarginSourceEnum } from "src/types";
import { enumToLocalizedOptions, getTranslatedEnumValue, percentageFormatter } from "src/utils";

const createColumns = (t: TFunction): ExpandColumnsType<ExpandTableDistributionMarginsType> => {
    return [
        {
            minWidth: 150,
            accessor: "productPackageName" as const,
            Header: t("payerDetail.distributionMargins.table.name") as string,
            Filter: TextColumnFilter,
        },
        {
            minWidth: 120,
            Header: t("payerDetail.distributionMargins.table.margin") as string,
            accessor: "margin" as const,
            Filter: createSelectColumnFilter(
                enumToLocalizedOptions("marginSource", MarginSourceEnum, [
                    MarginSourceEnum.InheritedFromPayer,
                    MarginSourceEnum.SpecialPriceInheritedFromPayer,
                ]),
            ),
            Cell: ({ row }: Cell<SupplyLimitWithPackageAndMarginDto>) => (
                <Tooltip
                    withinPortal={false}
                    backgroundColor={Color.accent50}
                    color={Color.supportGraphite500}
                    withArrow
                    label={getTranslatedEnumValue("marginSource", row.original.marginSource)}
                >
                    <HighlightCell highlight={row.original.marginSource !== MarginSourceEnum.ProductPackage}>
                        {percentageFormatter(row.original.margin)}
                    </HighlightCell>
                </Tooltip>
            ),
            filter: "exact",
            align: "end",
        },
    ];
};

export const tableModule = createTableModule<ExpandTableDistributionMarginsType>(
    TableNames.payerDistributionMargin,
    useGetPayersDistributionMarginQuery,
    createColumns,
);
