import type { FC } from "react";
import { useEffect } from "react";

import { useGetCustomerDetailQuery } from "src/api";
import { LoadingOverlay, Modal } from "src/components";
import { useModal } from "src/modal";
import { useTranslation } from "src/translations";
import { CustomerCategoryEnum } from "src/types";
import { TERMS_OF_TRADE_MODAL_WIDTH } from "../constants";
import { ApprovePayerForm } from "./ApprovePayerForm";
import { ApproveShipToForm } from "./ApproveShipToForm";
import { APPROVE_CUSTOMER_MODAL_ID, ApproveStartPointEnum } from "./constants";

type ApproveModalProps = {
    customerId: number | undefined;
    onClose: () => void;
    startPoint: ApproveStartPointEnum;
};

export const ApproveModal: FC<ApproveModalProps> = ({ customerId, onClose, startPoint }) => {
    const { data: customerDetail, isLoading } = useGetCustomerDetailQuery(customerId?.toString() || "", { skip: !customerId });
    const [isModalOpened, openModal, closeModal] = useModal(APPROVE_CUSTOMER_MODAL_ID);
    const { t } = useTranslation();

    useEffect(() => {
        if (customerId !== undefined && customerDetail) {
            openModal();
        }
        return () => {
            // we have to close modal, because if you use redirect in widget and return, modal is still opened
            closeModal();
        };
    }, [closeModal, customerDetail, customerId, openModal]);

    const onCloseModal = () => {
        closeModal();
        onClose();
    };

    return (
        <>
            <LoadingOverlay loading={isLoading} />
            <Modal
                title={t("customers.approveForm.title")}
                opened={isModalOpened}
                onClose={onCloseModal}
                maxWidth={TERMS_OF_TRADE_MODAL_WIDTH}
            >
                {customerDetail &&
                    (customerDetail.category === CustomerCategoryEnum.Payer ? (
                        <ApprovePayerForm payer={customerDetail} onClose={onCloseModal} />
                    ) : (
                        <ApproveShipToForm shipTo={customerDetail} onClose={onCloseModal} startPoint={startPoint} />
                    ))}
            </Modal>
        </>
    );
};
