import type { FC } from "react";

import { useGetPayerShipTosQuery } from "src/api";
import { LabelValuePair, TabularText, Tooltip } from "src/components";
import { routes } from "src/routes";
import { Color } from "src/theme";
import { useTranslation } from "src/translations";
import type { SimpleShipToDto } from "src/types";

type ShipTosCellProps = {
    shipTos: Array<SimpleShipToDto>;
};

export const ShipTosCell: FC<ShipTosCellProps> = ({ shipTos }) => {
    const { customerId } = routes.customerDetail.useParams();
    const { t } = useTranslation();

    const { data: payerShipTos, isLoading } = useGetPayerShipTosQuery({ pageSize: 10, pageIndex: 0, filters: { payerId: customerId } });

    const appliedToCount = shipTos.length;

    return appliedToCount > 0 ? (
        <Tooltip
            withinPortal={false}
            openDelay={500}
            backgroundColor={Color.accent50}
            color={Color.supportGraphite500}
            label={
                <TabularText columnGap="0.5rem">
                    {shipTos.map((shipTo) => (
                        <LabelValuePair key={shipTo.id} label={shipTo.acn} value={shipTo.name} />
                    ))}
                </TabularText>
            }
        >
            <div>{!isLoading && payerShipTos?.totalElements === appliedToCount ? t("common.all") : appliedToCount}</div>
        </Tooltip>
    ) : (
        <div>0</div>
    );
};
