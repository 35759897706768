import { isToday } from "date-fns";
import { useMemo } from "react";
import { Link } from "react-router-dom";

import { useGetLastInterfaceByOperationTypeQuery } from "src/api";
import failIcon from "src/assets/icons/interfaces_export_fail.svg";
import failSmIcon from "src/assets/icons/interfaces_export_fail_sm.svg";
import okIcon from "src/assets/icons/interfaces_export_ok.svg";
import processingIcon from "src/assets/icons/interfaces_export_processing.svg";
import processingSmIcon from "src/assets/icons/interfaces_export_processing_sm.svg";
import okSmIcon from "src/assets/icons/interfaces_ok_sm.svg";
import timeIcon from "src/assets/icons/time.svg";
import { useClientConfig } from "src/clientConfig";
import { Button, Grid, Icon, Loader, LoadingOverlay, SvgIcon, Text } from "src/components";
import { createSearchParamsAsString, ImportExportTab, routes, TAB_PARAM } from "src/routes";
import { Color } from "src/theme";
import { Trans, useTranslation } from "src/translations";
import { PhoenixInterfaceOperationDtoStatusEnum, PhoenixInterfaceOperationFilterDtoInterfaceOperationTypeEnum } from "src/types";
import { DATE_TIME_FORMAT, dateTimeFormatter, getTranslatedEnumValue, isNotNullish } from "src/utils";
import { ErrorBlock, InterfacesIcon, InterfaceWidgetIconWithInfo, InterfaceWidgetWrapper, SuccessBlock } from "../components";
import { DashboardCollapsibleWidgetsEnum } from "../enums";
import { DashboardCard } from "./DashboardCard";
import { WidgetWarning } from "./WidgetWarning";

export const OutgoingInterfacesWidget = () => {
    const { t } = useTranslation();

    const {
        data: lastOutgoingInterface,
        isLoading,
        isFetching,
    } = useGetLastInterfaceByOperationTypeQuery(PhoenixInterfaceOperationFilterDtoInterfaceOperationTypeEnum.Outgoing);
    const config = useClientConfig();

    const hasError = lastOutgoingInterface?.lastInterface?.status === PhoenixInterfaceOperationDtoStatusEnum.Error;
    const lastOutgoingTimestamp = lastOutgoingInterface?.lastInterface?.operationTimestamp;
    const lastFailedOutgoingTimestamp = lastOutgoingInterface?.lastFailedInterface?.operationTimestamp;
    const lastProcessingOutgoingTimestamp = lastOutgoingInterface?.lastInProgressInterface?.operationTimestamp;

    const errorToday = useMemo(() => {
        if (isNotNullish(lastFailedOutgoingTimestamp)) {
            const errorDate = new Date(lastFailedOutgoingTimestamp);
            return isToday(errorDate);
        }
        return false;
    }, [lastFailedOutgoingTimestamp]);

    const getStatusBarIcon = () => {
        if (!isLoading && !isFetching) {
            if (hasError || errorToday) {
                return <SvgIcon icon={failSmIcon} />;
            }
            if (lastProcessingOutgoingTimestamp) {
                return <SvgIcon icon={processingSmIcon} />;
            }
            if (lastFailedOutgoingTimestamp) {
                return <Icon.Warning color={Color.warning500} filled />;
            }
            return <SvgIcon icon={okSmIcon} />;
        }
        return <Loader size={20} />;
    };

    return (
        <DashboardCard
            title={t("dashboard.outgoingInterfaces.title")}
            collapsibleWidgetId={DashboardCollapsibleWidgetsEnum.OutgoingInterfacesWidget}
            statusBar={getStatusBarIcon()}
        >
            <InterfaceWidgetWrapper $gap={hasError ? "0" : "1rem"}>
                <LoadingOverlay loading={isLoading || isFetching} />
                {hasError ? (
                    <ErrorBlock>
                        <Text color={Color.error500} size="1.8rem">
                            {t("dashboard.outgoingInterfaces.widget.fail")}
                        </Text>
                        <Grid gridAutoFlow="column" gap="3rem">
                            <Trans
                                i18nKey="dashboard.outgoingInterfaces.widget.timestamp"
                                values={{
                                    date: lastOutgoingTimestamp
                                        ? dateTimeFormatter(lastOutgoingTimestamp, DATE_TIME_FORMAT)
                                        : t("common.never"),
                                }}
                                components={{
                                    text: <Text color={Color.supportNavy500} size="1.4rem" />,
                                    breakLine: <br />,
                                }}
                            />
                            <Trans
                                i18nKey="dashboard.outgoingInterfaces.widget.interface"
                                values={{
                                    interface: getTranslatedEnumValue(
                                        "PhoenixInterfaceOperationDtoInterfaceType",
                                        lastOutgoingInterface?.lastInterface?.interfaceType,
                                    ),
                                }}
                                components={{
                                    text: <Text color={Color.supportNavy500} size="1.4rem" />,
                                    breakLine: <br />,
                                }}
                            />
                            <Trans
                                i18nKey="dashboard.outgoingInterfaces.widget.seqId"
                                values={{ seqId: lastOutgoingInterface?.lastInterface?.sequenceNumber || "-" }}
                                components={{
                                    text: <Text color={Color.supportNavy500} size="1.4rem" />,
                                    breakLine: <br />,
                                }}
                            />
                        </Grid>
                        <Button
                            component={Link}
                            to={`${routes.importExport.path}?${createSearchParamsAsString({
                                [TAB_PARAM]: ImportExportTab.exportInterfaces,
                            })}`}
                        >
                            {t("dashboard.outgoingInterfaces.widget.detail")}
                        </Button>
                    </ErrorBlock>
                ) : (
                    <>
                        <InterfaceWidgetIconWithInfo>
                            <InterfacesIcon $withPadding>
                                {lastProcessingOutgoingTimestamp ? (
                                    <SvgIcon icon={processingIcon} />
                                ) : errorToday ? (
                                    <SvgIcon icon={failIcon} />
                                ) : (
                                    <SvgIcon icon={okIcon} />
                                )}
                            </InterfacesIcon>
                            <Grid gridAutoFlow="row" gap="0.5rem">
                                <Grid gridTemplateColumns="auto min-content" gap="1rem">
                                    <Trans
                                        i18nKey="dashboard.outgoingInterfaces.widget.lastExport"
                                        values={{
                                            date: lastOutgoingTimestamp
                                                ? dateTimeFormatter(lastOutgoingTimestamp, DATE_TIME_FORMAT)
                                                : t("common.never"),
                                        }}
                                        components={{
                                            text: <Text color={Color.supportNavy300} size="1.4rem" />,
                                            breakLine: <br />,
                                        }}
                                    />
                                    <Button
                                        component={Link}
                                        variant="subtle"
                                        to={`${routes.importExport.path}?${createSearchParamsAsString({
                                            [TAB_PARAM]: ImportExportTab.exportInterfaces,
                                        })}`}
                                    >
                                        {t("dashboard.outgoingInterfaces.widget.exportDetail")}
                                    </Button>
                                </Grid>
                                {lastFailedOutgoingTimestamp && (
                                    <Grid gridTemplateColumns="1fr min-content">
                                        <Text color={Color.error500} size="1.4rem">
                                            {t("dashboard.incomingInterfaces.widget.lastFail", {
                                                date: dateTimeFormatter(lastFailedOutgoingTimestamp, DATE_TIME_FORMAT),
                                            })}
                                        </Text>
                                    </Grid>
                                )}
                            </Grid>
                        </InterfaceWidgetIconWithInfo>
                        {errorToday ? (
                            <WidgetWarning text={t("dashboard.outgoingInterfaces.errorToday")} />
                        ) : (
                            <SuccessBlock>
                                <Grid gap="1rem" gridTemplateColumns="min-content 1fr" alignItems="center">
                                    <SvgIcon icon={timeIcon} />
                                    <Trans
                                        i18nKey="dashboard.outgoingInterfaces.widget.nextScheduled"
                                        values={{ minutes: config?.interfaceUploadInterval }}
                                        components={{
                                            text: <Text color={Color.supportNavy300} size="1.4rem" />,
                                        }}
                                    />
                                </Grid>
                            </SuccessBlock>
                        )}
                    </>
                )}
            </InterfaceWidgetWrapper>
        </DashboardCard>
    );
};
