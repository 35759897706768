import type { FC } from "react";
import styled from "styled-components";

import type { Color } from "src/theme";

type PackagePlusIconProps = { color: Color };

// Special icon for package icon
// We are using google icon for this one, instead of fluidIcon
export const PackagePlusIcon: FC<PackagePlusIconProps> = ({ color }) => (
    <StyledPackagePlusIcon $color={color} width="16" height="15" viewBox="0 0 16 15">
        <path
            fill="currentColor"
            d="M8.66663 9.4C8.86663 9.53333 9.13329 9.53333 9.33329 9.4L12.6666 7.46666V7.66666C13.1333 7.66666 13.6 7.73333 14 7.93333V6.73333L14.6666 6.33333C15 6.13333 15.0666 5.73333 14.9333 5.4L13.9333 3.73333C13.8666 3.59999 13.8 3.46666 13.6666 3.39999L8.39996 0.466662C8.26663 0.399995 8.13329 0.333328 7.99996 0.333328C7.86663 0.333328 7.73329 0.399995 7.59996 0.466662L2.39996 3.39999C2.26663 3.46666 2.13329 3.53333 2.06663 3.66666L1.06663 5.4C0.866626 5.73333 0.999959 6.13333 1.33329 6.33333C1.53329 6.46666 1.79996 6.46666 1.99996 6.33333V10C1.99996 10.2667 2.13329 10.4667 2.33329 10.6L7.59996 13.5333C7.73329 13.6 7.86663 13.6667 7.99996 13.6667C8.13329 13.6667 8.26663 13.6 8.39996 13.5333L8.99996 13.2C8.79996 12.8 8.73329 12.3333 8.66663 11.8667V9.4ZM7.33329 11.8667L3.33329 9.59999V5.13333L7.33329 7.4V11.8667ZM13.4 5.46666L9.19996 7.86666L8.79996 7.2L13 4.79999L13.4 5.46666ZM7.99996 6.2V1.79999L12 3.99999L7.99996 6.2ZM13.3333 9V11H15.3333V12.3333H13.3333V14.3333H12V12.3333H9.99996V11H12V9H13.3333Z"
        />
    </StyledPackagePlusIcon>
);

const StyledPackagePlusIcon = styled.svg<{ $color: Color }>`
    color: ${({ $color }) => $color};
`;
