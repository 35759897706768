import { Link } from "react-router-dom";
import styled from "styled-components";

import type { Color } from "src/theme";

export const TableLink = styled(Link)<{ color?: Color; weight?: number }>`
    color: ${({ theme, color }) => color || theme.color.supportGraphite500};
    font-weight: ${({ weight }) => weight || 400};

    &:hover {
        text-decoration: none;
    }
`;
