import type { FC } from "react";

import { Text, Tooltip } from "src/components";
import { Color } from "src/theme";
import { useTranslation } from "src/translations";
import { Separator, TextSeparator } from "./TextSeparator";

type LimitTextProps = { currentLimit: number; newLimit: number; tierLimit: number };

export const LimitText: FC<LimitTextProps> = ({ currentLimit, newLimit, tierLimit }) => {
    const { t } = useTranslation();

    return (
        <TextSeparator>
            <Tooltip withArrow label={t("supplyLimits.tiers.newLimitTooltip")} color={Color.white} backgroundColor={Color.accent500}>
                <Text weight={700} color={Color.accent500}>
                    {newLimit}
                </Text>
            </Tooltip>
            <Separator />
            <Tooltip
                withArrow
                label={t("supplyLimits.tiers.currentLimitTooltip")}
                color={Color.white}
                backgroundColor={Color.supportNavy500}
            >
                <Text color={Color.supportNavy500}>{currentLimit}</Text>
            </Tooltip>
            <Separator />
            <Tooltip withArrow label={t("supplyLimits.tiers.tierLimitTooltip")} color={Color.white} backgroundColor={Color.supportNavy500}>
                <Text color={Color.supportNavy500}>{tierLimit}</Text>
            </Tooltip>
        </TextSeparator>
    );
};
