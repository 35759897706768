import type { TFunction } from "i18next";
import type { FC } from "react";

import { HighlightCell, Tooltip } from "src/components";
import { Color } from "src/theme";

type SupplyLimitAdjustmentCellProps = {
    t: TFunction;
    tierLimit: number;
    adjustment?: number;
};

export const SupplyLimitAdjustmentCell: FC<SupplyLimitAdjustmentCellProps> = ({ t, tierLimit, adjustment }) => {
    const currentLimit = (adjustment ?? 0) + tierLimit;
    return currentLimit > tierLimit ? (
        <Tooltip
            withinPortal={false}
            color={Color.supportGraphite500}
            backgroundColor={Color.accent100}
            withArrow
            label={t("shipToDetail.supplyLimit.table.withAdjustment", { value: tierLimit })}
        >
            <HighlightCell highlight>{currentLimit}</HighlightCell>
        </Tooltip>
    ) : (
        <span>{currentLimit}</span>
    );
};
