import type { FC } from "react";
import styled from "styled-components";

import { isArray } from "src/utils";
import { FileInputValue } from "./FileInputValue";

type ValueComponentProps = {
    value: File | Array<File>;
};

export const FileInputComponent: FC<ValueComponentProps> = ({ value }) => {
    if (isArray(value)) {
        return (
            <FileValues>
                {value.map((file) => (
                    <FileInputValue file={file} key={file.name} />
                ))}
            </FileValues>
        );
    }

    return (
        <FileValues>
            <FileInputValue file={value} />
        </FileValues>
    );
};

const FileValues = styled.div`
    display: grid;
    justify-content: start;
    gap: 1rem;
`;
