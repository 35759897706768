import type { FC } from "react";
import { useNavigate } from "react-router-dom";

import { useApproveSupplyLimitSuggestionMutation, useRefuseSupplyLimitSuggestionMutation } from "src/api";
import { useMutationNotification } from "src/notifications";
import { useTranslation } from "src/translations";
import { DashboardCard } from "../DashboardCard";
import { tableModule } from "./table";
import { createActionColumn } from "./utils";

export const SupplyLimitSuggestionsWidget: FC = () => {
    const { t } = useTranslation();
    const navigate = useNavigate();
    const [approveSuggestions, approveResult] = useApproveSupplyLimitSuggestionMutation();
    const [refuseSuggestions, refuseResult] = useRefuseSupplyLimitSuggestionMutation();
    useMutationNotification(
        approveResult,
        "dashboard.supplyLimitSuggestions.approveSuccess",
        "dashboard.supplyLimitSuggestions.approveError",
    );
    useMutationNotification(refuseResult, "dashboard.supplyLimitSuggestions.refuseSuccess", "dashboard.supplyLimitSuggestions.refuseError");

    return (
        <DashboardCard>
            <tableModule.Container actions={createActionColumn(t, navigate, approveSuggestions, refuseSuggestions)} />
        </DashboardCard>
    );
};
