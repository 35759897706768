import type { ReactNode } from "react";
import type { TFunction } from "react-i18next";
import type { Row } from "react-table";

import { TableAction, TableActionButton } from "src/components";
import type { PhoenixInterfaceOperationDto } from "src/types";
import { PhoenixInterfaceOperationDtoStatusEnum } from "src/types";

export const createActionColumn =
    (t: TFunction, openModal: (interfaceOperationId: number) => void) =>
    ({ original }: Row<PhoenixInterfaceOperationDto>): ReactNode =>
        original.status === PhoenixInterfaceOperationDtoStatusEnum.Error ? (
            <TableAction>
                <TableActionButton type="button" onClick={() => openModal(original.id)}>
                    {t("importExport.exportInterfaceError.detail")}
                </TableActionButton>
            </TableAction>
        ) : null;
