import styled from "styled-components";

export const SupplyLimitOptionsLayout = styled.div`
    display: grid;
    grid-auto-flow: row;
    grid-gap: 1.6rem;
    background-color: transparent;
    width: 100%;
    // Place for "isDefault" mark
    padding-right: 3.5rem;
`;
