import { type FileInputProps, FileInput as FileInputMantine } from "@mantine/core";
import styled from "styled-components";

import {
    createInputStyles,
    createInputWrapperStyles,
    errorMessageStyles,
    InputStylesProps,
    invalidInputStyles,
    labelStyles,
} from "../inputStyles";
import { removeUnusedPropsFromStyledInput } from "../utils";
import { FileInputComponent } from "./FileInputComponent";

export type { FileInputProps } from "@mantine/core";

export const FileInput = styled(FileInputMantine)
    .withConfig(removeUnusedPropsFromStyledInput)
    .attrs({ valueComponent: FileInputComponent })<FileInputProps & InputStylesProps>`
    ${createInputWrapperStyles}
    & button {
        line-height: unset !important;
        ${createInputStyles}
    }

    & .mantine-FileInput-placeholder {
        font-size: 1.4rem;
        color: ${({ theme }) => theme.color.supportNavy200};
    }

    & label {
        ${labelStyles}
    }

    & .mantine-FileInput-invalid {
        ${invalidInputStyles}
    }

    & .mantine-FileInput-error {
        ${errorMessageStyles}
    }
`;
