import type { FetchBaseQueryError } from "@reduxjs/toolkit/query";

import { api } from "../../baseQuery";
import type { DownloadFileArguments } from "./types";

export { FileMimeType } from "./types";

const commonApi = api.injectEndpoints({
    endpoints: (builder) => ({
        downloadFile: builder.mutation<null, DownloadFileArguments>({
            queryFn: async ({ url, filename, fileMimeType, open }, _1, _2, baseQuery) => {
                const result = await baseQuery({
                    url,
                    responseHandler: (response) => {
                        if (response.ok) {
                            return response.blob();
                        }
                        return Promise.reject();
                    },
                });

                if (result.error) return { error: result.error as FetchBaseQueryError };
                const response = result.data as Blob;

                const a = document.createElement("a");
                a.style.setProperty("display", "none");
                document.body.appendChild(a);

                const blob = new Blob([response], { type: fileMimeType });
                const hrefUrl = URL.createObjectURL(blob);
                a.href = hrefUrl;
                if (open) {
                    a.target = "_blank";
                } else {
                    a.download = filename;
                }

                a.click();
                URL.revokeObjectURL(hrefUrl);
                a.remove();

                // We don't want to cache anything
                return { data: null };
            },
        }),
    }),
});

export const { useDownloadFileMutation } = commonApi;
