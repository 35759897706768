import type { FC } from "react";
import { useEffect } from "react";

import { createGlobalErrorMessage, useGetAggregationsQuery, useLazyGetCountForAggregationQuery } from "src/api";
import { Grid, Icon, LoadingOverlay, Text } from "src/components";
import { NotificationTypes, useNotifications } from "src/notifications";
import { Color, IconSizes } from "src/theme";
import { useTranslation } from "src/translations";
import type { GenerateAggregation, RequestErrorType } from "src/types";
import { dateTimeFormatter, formatDateForBE, isNotNullish } from "src/utils";
import { AggregationToolInfoBox } from "./components";

type InfoBoxProps = GenerateAggregation;

export const InfoBox: FC<InfoBoxProps> = ({ from, to }) => {
    const { t } = useTranslation();
    const notifications = useNotifications();
    const [fetchAggregationCount, { data: aggregationCount, isLoading: isCountLoading }] = useLazyGetCountForAggregationQuery();
    const { data: lastAggregation, isLoading: isLastAggregationLoading } = useGetAggregationsQuery({
        pageSize: 1,
        pageIndex: 0,
        filters: {},
    });

    const lastAggregationFrom = lastAggregation?.content && lastAggregation.content[0]?.dateFrom;
    const lastAggregationTo = lastAggregation?.content && lastAggregation.content[0]?.dateTo;
    const lastAggregationCreated = lastAggregation?.content && lastAggregation.content[0]?.created;
    const isInfoLoading = isLastAggregationLoading || isCountLoading;

    const getAggregationCount = async (fromDate: Date, toDate: Date) => {
        try {
            await fetchAggregationCount({ from: formatDateForBE(fromDate), to: formatDateForBE(toDate) }).unwrap();
        } catch (e) {
            const error = e as RequestErrorType;
            const errorMessage = createGlobalErrorMessage(error, t("importExport.aggregatorTool.exportSapFile.countError"));
            notifications.showNotification({
                title: t("common.form.errorTitle"),
                message: errorMessage,
                type: NotificationTypes.error,
            });
        }
    };

    useEffect(() => {
        if (isNotNullish(from) && isNotNullish(to)) {
            getAggregationCount(from, to);
        }
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [from, to]);

    return (
        <AggregationToolInfoBox>
            <LoadingOverlay loading={isInfoLoading} />
            <Icon.Info size={IconSizes.l} color={Color.supportNavy500} />
            <Grid justifyContent="start" gap="1rem" gridTemplateColumns="1fr 1fr">
                <Text size="1.4rem">{t("importExport.aggregatorTool.noOfProductPackages")}</Text>
                <Text size="1.4rem" color={Color.primary600}>
                    {aggregationCount ?? "-"}
                </Text>
                <Text size="1.4rem">{t("importExport.aggregatorTool.lastOrderGenerated")}</Text>
                <Text size="1.4rem">{(lastAggregationCreated && dateTimeFormatter(lastAggregationCreated)) ?? "-"}</Text>
                <Text size="1.4rem">{t("importExport.aggregatorTool.lastGeneratedDuration")}</Text>
                <Text size="1.4rem">{`${(lastAggregationFrom && dateTimeFormatter(lastAggregationFrom)) ?? ""} - ${
                    (lastAggregationTo && dateTimeFormatter(lastAggregationTo)) ?? ""
                }`}</Text>
            </Grid>
        </AggregationToolInfoBox>
    );
};
