import { Link as RouterLink } from "react-router-dom";
import styled from "styled-components";

type TextLinkProps = {
    children: string | undefined;
    size?: string;
};

export const TextLink = styled(RouterLink)<Pick<TextLinkProps, "size">>`
    display: inline-grid;
    align-items: center;
    color: ${({ theme }) => theme.color.primary500};
    font-size: ${({ size }) => size || "inherit"};

    &:hover {
        color: ${({ theme }) => theme.color.primary600};
    }
`;
