import type { FC } from "react";
import styled, { css } from "styled-components";

import { Icon } from "src/components";
import { Color, IconSizes } from "src/theme";

type SupplyLimitOptionProps = {
    onClick: (e: unknown) => void;
    tierLabel?: string;
    monthlyLimit: number;
    dailyLimit: number;
    unlimited: boolean;
    selected: boolean;
    isDefault: boolean;
};

export const SupplyLimitOption: FC<SupplyLimitOptionProps> = ({
    tierLabel = null,
    monthlyLimit,
    dailyLimit,
    onClick,
    unlimited,
    selected,
    isDefault,
}) => (
    <StyledTierOptionWrapper>
        <StyledTierOption type="button" onClick={onClick} $selected={selected} $unlimited={unlimited}>
            <TierCell>{tierLabel}</TierCell>
            <TierCell>{dailyLimit}</TierCell>
            <TierCell>{monthlyLimit}</TierCell>
        </StyledTierOption>
        {isDefault && (
            <IconWrapper>
                <Icon.Star size={IconSizes.l} color={Color.primary600} filled />
            </IconWrapper>
        )}
    </StyledTierOptionWrapper>
);

const TierCell = styled.div`
    padding-block: 1.6rem;
    display: grid;
    justify-content: center;
    font-size: 1.6rem;
    color: ${({ theme }) => theme.color.supportNavy500};
`;

const StyledTierOptionWrapper = styled.div`
    position: relative;
`;

const IconWrapper = styled.div`
    position: absolute;
    right: -3.5rem;
    top: 50%;
    transform: translateY(-50%);
`;

const StyledTierOption = styled.button<{ $selected: boolean; $unlimited: boolean }>`
    display: grid;
    cursor: pointer;
    width: 100%;
    padding: 0;
    grid-template-columns: 1fr 1fr 1fr;
    background-color: transparent;
    border: 0.5px solid ${({ theme }) => theme.color.neutral300};
    border-radius: ${({ theme }) => theme.radius.default};
    overflow: hidden;

    &:hover {
        border: 0.5px solid ${({ theme }) => theme.color.neutral400};
    }

    ${({ $unlimited, theme }) => css`
        & > div:first-child {
            background-color: ${$unlimited ? theme.color.neutral50 : theme.color.accent100};
        }
    `};

    ${({ $unlimited, theme, $selected }) =>
        $selected &&
        css`
            & > div:first-child {
                background-color: ${$unlimited ? theme.color.neutral200 : theme.color.accent200};
            }
        `};
`;
