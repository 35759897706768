import type { FC } from "react";
import { useState } from "react";

import { useTranslation } from "src/translations";
import { ApproveModal, ApproveStartPointEnum } from "../../../approveCustomer";
import { defaultCustomersTableConfig } from "../../constants";
import { tableModule } from "./table";
import { createActionColumn, overrideRowProps } from "./utils";

export const New: FC = () => {
    const [selectedCustomerId, setSelectedCustomerId] = useState<number | undefined>(undefined);
    const { t } = useTranslation();
    return (
        <>
            <ApproveModal
                startPoint={ApproveStartPointEnum.List}
                customerId={selectedCustomerId}
                onClose={() => setSelectedCustomerId(undefined)}
            />
            <tableModule.Container
                overrideRowProps={overrideRowProps}
                actions={createActionColumn(t, setSelectedCustomerId)}
                defaultTableConfig={defaultCustomersTableConfig}
            />
        </>
    );
};
