import type { FC } from "react";

import payerIcon from "src/assets/icons/payer.svg";
import shipToIcon from "src/assets/icons/ship-to.svg";
import { BorderBox, Flex, LabelValuePair, SvgIcon, TabularText, Text } from "src/components";
import { Color } from "src/theme";
import { useTranslation } from "src/translations";
import type { CustomerDto } from "src/types";
import { formatStringDate, zipFormatter } from "src/utils";
import { Card } from "../components";

type CustomerProps = {
    isPayer: boolean;
    customerDetail?: CustomerDto;
};

export const Customer: FC<CustomerProps> = ({ isPayer, customerDetail }) => {
    const { t } = useTranslation();

    return (
        <Card>
            <Flex justifyContent="space-between" alignItems="flex-start">
                <Flex flexDirection="column" gap="1rem" flexGrow={1}>
                    <Text color={Color.primary600} size="1.8rem">
                        {customerDetail?.name}
                    </Text>
                    <Flex flexDirection="column" gap="0.5rem">
                        <Text color={Color.supportNavy500} size="1.4rem">
                            {customerDetail?.street}
                        </Text>
                        <Text color={Color.supportNavy500} size="1.4rem">
                            {customerDetail ? `${zipFormatter(customerDetail.zip)} ${customerDetail.city}` : null}
                        </Text>
                    </Flex>
                </Flex>
                <BorderBox padding="1rem">
                    <SvgIcon icon={isPayer ? payerIcon : shipToIcon} />
                </BorderBox>
            </Flex>
            <TabularText rowGap="0.2rem">
                <LabelValuePair
                    label={
                        <Text color={Color.supportNavy500} size="1.4rem">
                            {t("payerDetail.customer.acn")}
                        </Text>
                    }
                    value={
                        <Text color={Color.supportNavy500} size="1.4rem">
                            {customerDetail?.acn}
                        </Text>
                    }
                />
                {!isPayer && (
                    <>
                        <LabelValuePair
                            label={
                                <Text color={Color.supportNavy500} size="1.4rem">
                                    {t("payerDetail.customer.phone")}
                                </Text>
                            }
                            value={
                                <Text color={Color.supportNavy500} size="1.4rem">
                                    {customerDetail?.phone}
                                </Text>
                            }
                        />
                        <LabelValuePair
                            label={
                                <Text color={Color.supportNavy500} size="1.4rem">
                                    {t("payerDetail.customer.email")}
                                </Text>
                            }
                            value={
                                <Text color={Color.supportNavy500} size="1.4rem">
                                    {customerDetail?.email}
                                </Text>
                            }
                        />
                    </>
                )}
                <LabelValuePair
                    label={
                        <Text color={Color.supportNavy500} size="1.4rem">
                            {t("payerDetail.customer.tradingStart")}
                        </Text>
                    }
                    value={
                        <Text color={Color.supportNavy500} size="1.4rem">
                            {formatStringDate(customerDetail?.tradingStartDate)}
                        </Text>
                    }
                />
                <LabelValuePair
                    label={
                        <Text color={Color.supportNavy500} size="1.4rem">
                            {t("payerDetail.customerInfo.contactPerson")}
                        </Text>
                    }
                    value={
                        <Text color={Color.supportNavy500} size="1.4rem">
                            {customerDetail?.leadingPersonName}
                        </Text>
                    }
                />
            </TabularText>
        </Card>
    );
};
