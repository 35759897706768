import styled from "styled-components";

type InterfacesIconProps = { $withPadding?: boolean };

export const InterfacesIcon = styled.div<InterfacesIconProps>`
    display: grid;
    grid-auto-flow: row;
    justify-content: center;
    justify-items: center;
    align-content: center;
    justify-self: center;
    gap: 1rem;
    padding: ${({ $withPadding }) => ($withPadding ? "1rem" : "0")};
    border-radius: ${({ theme }) => theme.radius.default};
`;
