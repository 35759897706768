import type { FC } from "react";

import { routes } from "src/routes";
import { payerDataModificationLogTableModule, shipToDataModificationLogTableModule } from "./dataModificationLogTable";

type DataModificationLogProps = {
    isPayer?: boolean;
};

export const DataModificationLog: FC<DataModificationLogProps> = ({ isPayer }) => {
    const { customerId } = routes.customerDetail.useParams();

    const tableModule = isPayer ? payerDataModificationLogTableModule : shipToDataModificationLogTableModule;

    return customerId ? <tableModule.Container additionalQueryParams={{ customerId }} /> : null;
};
