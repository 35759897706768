import type { FC } from "react";
import { useMemo } from "react";

import { useCreateSpecialPriceMutation, useGetCustomerDetailQuery, useGetSpecialPriceQuery, useUpdateSpecialPriceMutation } from "src/api";
import {
    Button,
    DateInput,
    DateInputProps,
    Grid,
    LoadingOverlay,
    ModalButtonLayout,
    MultiSelect,
    MultiSelectProps,
    Text,
    Textarea,
    TextareaProps,
    TextInput,
    TextInputProps,
} from "src/components";
import { Form, InputField, isNonEmptyArray } from "src/form";
import { Color } from "src/theme";
import { useTranslation } from "src/translations";
import { CreateSpecialPriceDto, CustomerCategoryEnum, CustomerStatusEnum, UpdateSpecialPriceDto } from "src/types";
import { formatDateForBE, isDateTodayOrBefore, isNotNullish } from "src/utils";
import { isSpecialPriceToday } from "../utils";
import { ProductPackagePriceFields } from "./ProductPackagePriceFields";
import { ProductPackageSpecialPriceFields } from "./ProductPackageSpecialPriceFields";
import type { CreateAndUpdateSpecialPrice } from "./types";

type SpecialPricesFormProps = {
    closeModal: () => void;
    specialPriceId?: number;
    customerId: string;
};

export const SpecialPriceForm: FC<SpecialPricesFormProps> = ({ closeModal, customerId, specialPriceId }) => {
    const { t } = useTranslation();

    const { data: customer, isLoading: isLoadingCustomer } = useGetCustomerDetailQuery(customerId);
    const [createSpecialPrice] = useCreateSpecialPriceMutation();
    const [updateSpecialPrice] = useUpdateSpecialPriceMutation();
    const isPayer = customer?.category === CustomerCategoryEnum.Payer;
    const isEditMode = !!specialPriceId;
    const { data: specialPrice, isLoading: isLoadingSpecialPrice } = useGetSpecialPriceQuery(specialPriceId!!, { skip: !specialPriceId });

    const shipToOptions = useMemo(() => {
        if (isNotNullish(customer) && customer.shipToList) {
            return customer.shipToList
                .filter((shipTo) => shipTo.status !== CustomerStatusEnum.New)
                .map((shipTo) => ({
                    label: shipTo.acn.toString(),
                    value: shipTo.id,
                }));
        }
        return [];
    }, [customer]);

    const selectedProductPackage = specialPrice
        ? { id: specialPrice.productPackageId, name: specialPrice.productPackageName, margin: specialPrice.productPackageMargin }
        : undefined;

    const initialProductPriceInclMargin = useMemo(() => {
        if (isEditMode && specialPrice) {
            const marginValue = (specialPrice.productPrice / 100) * specialPrice.productPackageMargin;
            return Number((specialPrice.productPrice + marginValue).toFixed(2));
        }
        return 0;
    }, [isEditMode, specialPrice]);

    const initialValues = {
        id: isEditMode ? Number(specialPrice?.id) : undefined,
        customerId: Number(customerId),
        productPackageId: isEditMode ? specialPrice?.productPackageId.toString() : undefined,
        specialPriceInclMargin: isEditMode ? specialPrice?.specialPriceInclMargin : 0,
        specialPriceMargin: isEditMode ? specialPrice?.specialPriceMargin : 0,
        validFrom: isEditMode && specialPrice?.validFrom ? new Date(specialPrice.validFrom) : undefined,
        validTo: isEditMode && specialPrice?.validTo ? new Date(specialPrice.validTo) : undefined,
        contractId: isEditMode ? specialPrice?.contractId : "",
        contractFrom: isEditMode && specialPrice?.contractFrom ? new Date(specialPrice.contractFrom) : undefined,
        contractTo: isEditMode && specialPrice?.contractTo ? new Date(specialPrice.contractTo) : undefined,
        applyToShipTos: isEditMode && specialPrice?.applyToShipTos ? specialPrice.applyToShipTos.map(({ id }) => id.toString()) : [],
        azNote: isEditMode ? specialPrice?.azNote : "",
        productPrice: isEditMode ? specialPrice?.productPrice : 0,
        productPackageMargin: isEditMode ? specialPrice?.productPackageMargin : 0,
        productPriceInclMargin: initialProductPriceInclMargin,
    };

    const onSubmit = async (values: CreateAndUpdateSpecialPrice) => {
        const commonFields = {
            specialPriceInclMargin: values.specialPriceInclMargin,
            validFrom: formatDateForBE(values.validFrom),
            validTo: formatDateForBE(values.validTo),
            contractId: values.contractId,
            contractFrom: values.contractFrom ? formatDateForBE(values.contractFrom) : undefined,
            contractTo: values.contractTo ? formatDateForBE(values.contractTo) : undefined,
            applyToShipTos: isPayer ? values.applyToShipTos.map((shipToId) => Number(shipToId)) : undefined,
            azNote: values.azNote,
        };
        if (specialPriceId) {
            // update
            const updateValues: UpdateSpecialPriceDto = {
                id: specialPriceId,
                ...commonFields,
            };

            return updateSpecialPrice(updateValues).unwrap();
        }
        // create
        const createValues: CreateSpecialPriceDto = {
            customerId: Number(customerId),
            productPackageId: Number(values.productPackageId),
            ...commonFields,
        };

        return createSpecialPrice(createValues).unwrap();
    };

    const isSpecialPriceTodayInEditMode = isEditMode && specialPrice && isSpecialPriceToday(specialPrice);

    return (
        <>
            <LoadingOverlay loading={isLoadingCustomer || isLoadingSpecialPrice} />
            {!isLoadingCustomer && !isLoadingSpecialPrice && (
                <Form<CreateAndUpdateSpecialPrice>
                    initialValues={initialValues}
                    onSubmit={onSubmit}
                    unwrapOnSubmit={false}
                    onSuccess={closeModal}
                    loadingMessage={t("specialPrices.modal.loadingMessage")}
                    successMessage={t("specialPrices.modal.successMessage")}
                >
                    {({ setFieldValue }) => (
                        <>
                            <ProductPackagePriceFields selectedProductPackage={selectedProductPackage} isEditMode={isEditMode} />
                            <Grid gridTemplateColumns="1fr 1fr" columnGap="4rem" rowGap="0.5rem">
                                <ProductPackageSpecialPriceFields isEditMode={isEditMode} specialPrice={specialPrice} />
                                <InputField<DateInputProps["value"], DateInputProps>
                                    name="validFrom"
                                    input={DateInput}
                                    required
                                    inputProps={{
                                        label: t("specialPrices.modal.from.label"),
                                        placeholder: t("common.datePlaceholder"),
                                        disabled: isSpecialPriceTodayInEditMode,
                                        excludeDate: isDateTodayOrBefore,
                                    }}
                                />
                                <InputField<DateInputProps["value"], DateInputProps>
                                    name="validTo"
                                    input={DateInput}
                                    required
                                    inputProps={{
                                        label: t("specialPrices.modal.to.label"),
                                        placeholder: t("common.datePlaceholder"),
                                        excludeDate: isDateTodayOrBefore,
                                    }}
                                />
                            </Grid>
                            {isPayer && (
                                <Grid gridTemplateColumns="1fr max-content" gap="1.6rem" alignItems="center">
                                    <InputField<MultiSelectProps["value"], MultiSelectProps>
                                        name="applyToShipTos"
                                        input={MultiSelect}
                                        required
                                        validate={isPayer ? isNonEmptyArray : undefined}
                                        inputProps={{
                                            label: t("specialPrices.modal.applyTo.label"),
                                            placeholder: t("specialPrices.modal.applyTo.placeholder"),
                                            options: shipToOptions,
                                            disableOverflow: true,
                                            height: "3rem",
                                            fontSize: "1.4rem",
                                            searchInputWidth: "140px",
                                            usedInForm: true,
                                            clearable: true,
                                            disabled: isSpecialPriceTodayInEditMode,
                                        }}
                                    />
                                    <Button
                                        type="button"
                                        disabled={isSpecialPriceTodayInEditMode}
                                        onClick={() =>
                                            setFieldValue(
                                                "applyToShipTos",
                                                shipToOptions.map(({ value }) => value.toString()),
                                            )
                                        }
                                        variant="subtle"
                                    >
                                        {t("common.selectAll")}
                                    </Button>
                                </Grid>
                            )}
                            <Grid rowGap="0.5rem">
                                <Text color={Color.primary600} size="1.6rem" weight={700}>
                                    {t("specialPrices.modal.contractTitle")}
                                </Text>
                                <InputField<TextInputProps["value"], TextInputProps>
                                    name="contractId"
                                    input={TextInput}
                                    required
                                    inputProps={{
                                        label: t("specialPrices.modal.contractId.label"),
                                        placeholder: t("specialPrices.modal.contractId.placeholder"),
                                        maxWidth: "256px",
                                    }}
                                />
                            </Grid>
                            <Grid gridTemplateColumns="1fr 1fr" columnGap="4rem" rowGap="0.5rem">
                                <InputField<DateInputProps["value"], DateInputProps>
                                    name="contractFrom"
                                    input={DateInput}
                                    inputProps={{
                                        label: t("specialPrices.modal.contractStarts.label"),
                                        placeholder: t("common.datePlaceholder"),
                                    }}
                                />
                                <InputField<DateInputProps["value"], DateInputProps>
                                    name="contractTo"
                                    input={DateInput}
                                    inputProps={{
                                        label: t("specialPrices.modal.contractEnds.label"),
                                        placeholder: t("common.datePlaceholder"),
                                    }}
                                />
                            </Grid>
                            <InputField<TextareaProps["value"], TextareaProps>
                                name="azNote"
                                input={Textarea}
                                inputProps={{
                                    label: t("specialPrices.modal.note.label"),
                                }}
                            />
                            <ModalButtonLayout>
                                <Button type="submit">{isEditMode ? t("common.update") : t("common.create")}</Button>
                                <Button type="button" onClick={closeModal} variant="outline">
                                    {t("common.cancel")}
                                </Button>
                            </ModalButtonLayout>
                        </>
                    )}
                </Form>
            )}
        </>
    );
};
