import type { TFunction } from "react-i18next";
import type { Cell } from "react-table";

import { useGetAggregationsQuery } from "src/api";
import { createTableModule, TableNames } from "src/table";
import type { AggregationDto, ColumnsType } from "src/types";
import { dateTimeFormatter } from "src/utils";

const createColumns = (t: TFunction): ColumnsType<AggregationDto> => [
    {
        minWidth: 120,
        Header: t("importExport.aggregatorTool.table.sapInterfaceId") as string,
        accessor: "sapInterfaceId" as const,
        disableFilters: true,
    },
    {
        minWidth: 120,
        Header: t("importExport.aggregatorTool.table.salesOrganisation") as string,
        accessor: "salesOrganisation" as const,
        disableFilters: true,
    },
    {
        minWidth: 120,
        Header: t("importExport.aggregatorTool.table.created") as string,
        accessor: "created" as const,
        disableFilters: true,
        Cell: ({ value }: Cell<AggregationDto>) => dateTimeFormatter(value),
    },
    {
        minWidth: 120,
        Header: t("importExport.aggregatorTool.table.from") as string,
        accessor: "dateFrom" as const,
        disableFilters: true,
        Cell: ({ value }: Cell<AggregationDto>) => dateTimeFormatter(value),
    },
    {
        minWidth: 120,
        Header: t("importExport.aggregatorTool.table.to") as string,
        accessor: "dateTo" as const,
        disableFilters: true,
        Cell: ({ value }: Cell<AggregationDto>) => dateTimeFormatter(value),
    },
];

export const tableModule = createTableModule<AggregationDto>(TableNames.aggregatorTool, useGetAggregationsQuery, createColumns);
