import styled, { css } from "styled-components";

export const FormSection = styled.div<{ alternate?: boolean; padding?: string }>`
    display: grid;
    grid-auto-flow: row;
    gap: 1.5rem;
    padding: ${({ padding }) => padding || "0.8rem 2.4rem"};
    ${({ alternate }) =>
        alternate &&
        css`
            background-color: ${({ theme }) => theme.color.neutral100};
            border: 1px solid ${({ theme }) => theme.color.neutral200};
            border-radius: ${({ theme }) => theme.radius.default};
        `};
`;
