import { useDebouncedValue } from "@mantine/hooks";
import { SpotlightProvider as SpotlightProviderMantine } from "@mantine/spotlight";
import type { FC } from "react";
import { useState } from "react";
import { useNavigate } from "react-router-dom";

import { useGetSpotlightSearchResultsQuery } from "src/api";
import { Icon } from "src/components";
import { Color, IconSizes } from "src/theme";
import { useTranslation } from "src/translations";
import { ACTIONS_LIMIT, SPOTLIGHT_SHORTCUT } from "./constants";
import { transformAndLimitSearchResultsToActions } from "./utils";

export const SpotlightProvider: FC = ({ children }) => {
    const navigate = useNavigate();
    const { t } = useTranslation();
    const [searchQuery, setSearchQuery] = useState<string>("");
    const [debouncedSearchQuery] = useDebouncedValue<string | undefined>(searchQuery, 300);
    const { data: results } = useGetSpotlightSearchResultsQuery(debouncedSearchQuery || "", {
        skip: debouncedSearchQuery === "",
    });

    return (
        <SpotlightProviderMantine
            actions={searchQuery !== "" ? transformAndLimitSearchResultsToActions(t, results || [], navigate) : []}
            searchIcon={<Icon.Search color={Color.neutral500} size={IconSizes.l} />}
            searchPlaceholder={t("common.spotlight.placeholder")}
            shortcut={SPOTLIGHT_SHORTCUT}
            onQueryChange={(query) => setSearchQuery(query)}
            nothingFoundMessage={t("common.spotlight.nothingFound")}
            limit={ACTIONS_LIMIT}
            // Filter is done by BE
            filter={(_, actions) => actions}
            styles={{
                action: { paddingBlock: "0.5rem", fontSize: "1.4rem" },
                actionBody: { color: Color.supportNavy500 },
                searchInput: { color: Color.supportNavy500 },
                actionsGroup: { color: Color.supportNavy400, fontSize: "1.1rem" },
                inner: { marginInline: "1rem" },
            }}
        >
            {children}
        </SpotlightProviderMantine>
    );
};
