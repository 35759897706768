import styled from "styled-components";

import { useTranslation } from "src/translations";

export const ConfirmSetDefaultContent = () => {
    const { t } = useTranslation();
    return (
        <>
            <Title>{t("supplyLimits.tiers.setDefaultConfirm.content1")}</Title>
            <Content>
                <li>{t("supplyLimits.tiers.setDefaultConfirm.content2")}</li>
                <li>{t("supplyLimits.tiers.setDefaultConfirm.content3")}</li>
            </Content>
        </>
    );
};

const Content = styled.ul`
    margin: 0;
    padding: 0 0 0 1.5rem;

    li:not(:last-child) {
        margin-bottom: 1.5rem;
    }
`;

const Title = styled.p`
    margin: 0 0 1.5rem 0;
`;
