import {
    AddFilled,
    AddRegular,
    AddSquareFilled,
    AddSquareRegular,
    ArchiveFilled,
    ArchiveRegular,
    ArrowDownloadFilled,
    ArrowDownloadRegular,
    ArrowRepeatAllFilled,
    ArrowRepeatAllRegular,
    ArrowRightRegular,
    ArrowUploadFilled,
    ArrowUploadRegular,
    AttachFilled,
    AttachRegular,
    BoxFilled,
    BoxRegular,
    CalendarLtrRegular,
    CheckmarkFilled,
    CheckmarkRegular,
    ChevronDoubleDownRegular,
    ChevronDownRegular,
    ChevronLeftRegular,
    ChevronRightRegular,
    ChevronUpRegular,
    CommentFilled,
    CommentRegular,
    CookiesRegular,
    DeleteRegular,
    DismissFilled,
    DismissRegular,
    DocumentBulletListFilled,
    DocumentBulletListRegular,
    DocumentSearchRegular,
    EditFilled,
    EditRegular,
    HomeFilled,
    HomeRegular,
    InfoRegular,
    MoneyRegular,
    NewFilled,
    NewRegular,
    OpenFilled,
    OpenRegular,
    PeopleCommunityFilled,
    PeopleCommunityRegular,
    PeopleErrorFilled,
    PeopleErrorRegular,
    PersonProhibitedRegular,
    PersonRegular,
    PinOffRegular,
    PinRegular,
    QuestionCircleRegular,
    SaveFilled,
    SaveRegular,
    SearchRegular,
    StarFilled,
    StarRegular,
    VehicleTruckProfileFilled,
    VehicleTruckProfileRegular,
    WarningFilled,
    WarningRegular,
} from "@fluentui/react-icons";

import { createIcon } from "./Icon";
import { InterfaceIconFilled, InterfaceIconRegular } from "./InterfaceIcon";

// title is aria-label
const ChevronDoubleDown = createIcon(ChevronDoubleDownRegular, "Show All");
const ChevronDown = createIcon(ChevronDownRegular, "Down");
const ChevronUp = createIcon(ChevronUpRegular, "Up");
const ChevronLeft = createIcon(ChevronLeftRegular, "Left");
const ChevronRight = createIcon(ChevronRightRegular, "Right");
const People = createIcon(PeopleCommunityRegular, "Group", PeopleCommunityFilled);
const Home = createIcon(HomeRegular, "Home", HomeFilled);
const VehicleTruck = createIcon(VehicleTruckProfileRegular, "Vehicle", VehicleTruckProfileFilled);
const Box = createIcon(BoxRegular, "Box", BoxFilled);
const QuestionCircle = createIcon(QuestionCircleRegular, "Question");
const Add = createIcon(AddRegular, "Add", AddFilled);
const Edit = createIcon(EditRegular, "Edit", EditFilled);
const Close = createIcon(DismissRegular, "Dismiss", DismissFilled);
const Search = createIcon(SearchRegular, "Search");
const Checkmark = createIcon(CheckmarkRegular, "Checkmark", CheckmarkFilled);
const Person = createIcon(PersonRegular, "Person");
const Archive = createIcon(ArchiveRegular, "Archive", ArchiveFilled);
const Calendar = createIcon(CalendarLtrRegular, "Calendar");
const AddSquare = createIcon(AddSquareRegular, "AddSquare", AddSquareFilled);
const Delete = createIcon(DeleteRegular, "Delete");
const Star = createIcon(StarRegular, "Star", StarFilled);
const Save = createIcon(SaveRegular, "Save", SaveFilled);
const ArrowRight = createIcon(ArrowRightRegular, "Arrow right");
const Pin = createIcon(PinRegular, "Pin");
const PinOff = createIcon(PinOffRegular, "Pin off");
const DocumentSearch = createIcon(DocumentSearchRegular, "Document search");
const PersonProhibited = createIcon(PersonProhibitedRegular, "Person prohibited");
const Document = createIcon(DocumentBulletListRegular, "Document", DocumentBulletListFilled);
const Aggregation = createIcon(InterfaceIconRegular, "Interfaces", InterfaceIconFilled);
const Info = createIcon(InfoRegular, "Info");
const Cookies = createIcon(CookiesRegular, "Cookies");
const Warning = createIcon(WarningRegular, "Warning", WarningFilled);
const Upload = createIcon(ArrowUploadRegular, "Upload", ArrowUploadFilled);
const Download = createIcon(ArrowDownloadRegular, "Download", ArrowDownloadFilled);
const Open = createIcon(OpenRegular, "Open", OpenFilled);
const Money = createIcon(MoneyRegular, "Money");
const Comment = createIcon(CommentRegular, "Comment", CommentFilled);
const New = createIcon(NewRegular, "New", NewFilled);
const ArrowRepeatAll = createIcon(ArrowRepeatAllRegular, "ArrowRepeatAll", ArrowRepeatAllFilled);
const Attach = createIcon(AttachRegular, "Attach", AttachFilled);
const PeopleError = createIcon(PeopleErrorRegular, "PeopleError", PeopleErrorFilled);

export const Icon = {
    ChevronDoubleDown,
    ChevronDown,
    ChevronUp,
    Box,
    ChevronLeft,
    ChevronRight,
    People,
    VehicleTruck,
    Home,
    QuestionCircle,
    Add,
    Edit,
    Close,
    Search,
    Checkmark,
    Person,
    Archive,
    Calendar,
    AddSquare,
    Delete,
    Star,
    Save,
    ArrowRight,
    Pin,
    PinOff,
    DocumentSearch,
    PersonProhibited,
    Document,
    Aggregation,
    Info,
    Cookies,
    Warning,
    Upload,
    Download,
    Open,
    Money,
    Comment,
    New,
    ArrowRepeatAll,
    Attach,
    PeopleError,
};

export { ActionIcon } from "./ActionIcon";
export { ArchiveIcon } from "./ArchiveIcon";
export { DELAY_FOR_ICON_TOOLTIP } from "./constants";
export { iconFilledClassName, iconRegularClassName } from "./Icon";
export { PackagePlusIcon } from "./PackagePlusIcon";
export { SvgIcon } from "./SvgIcon";
