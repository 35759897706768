import type { FC } from "react";
import { GoogleReCaptchaProvider } from "react-google-recaptcha-v3";

import { useClientConfig } from "src/clientConfig";
import { CZECH_LANGUAGE } from "./constants";

export const RecaptchaProvider: FC = ({ children }) => {
    const config = useClientConfig();
    return (
        <GoogleReCaptchaProvider useEnterprise reCaptchaKey={config?.googleRecaptchaKey} language={CZECH_LANGUAGE}>
            {children}
        </GoogleReCaptchaProvider>
    );
};
