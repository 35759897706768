import type { FC } from "react";
import styled, { css } from "styled-components";

import refreshIcon from "src/assets/icons/refresh.svg";
import { Button, Grid, Icon, SvgIcon, Text } from "src/components";
import { Color, IconSizes, maxWidthBreakpoint } from "src/theme";
import { useTranslation } from "src/translations";
import { DashboardCollapsibleWidgetsEnum } from "../enums";
import { useCollapsibleWidget } from "../useCollapsibleWidget";

type CurrentDateProps = {
    onRefresh: () => void;
    date: string | null;
};

export const CurrentDate: FC<CurrentDateProps> = ({ date, onRefresh }) => {
    const { t } = useTranslation();
    // We can use state for just one widget, because others behave the same way on desktop
    const { isVisible: isWidgetVisible, toggle } = useCollapsibleWidget(DashboardCollapsibleWidgetsEnum.CustomersWidget);

    return date ? (
        <StyledCurrentDate>
            <Text size="1.1rem" color={Color.supportNavy500}>
                {t("dashboard.currentDate.lastUpdate", { date })}
            </Text>
            <Grid justifyContent="center" alignItems="center" gridAutoFlow="column" gap="2rem">
                <StyledToggleButton
                    variant="subtle"
                    leftIcon={isWidgetVisible ? <Icon.ChevronUp size={IconSizes.m} /> : <Icon.ChevronDown size={IconSizes.m} />}
                    onClick={toggle}
                    fontSize="1.1rem"
                >
                    {isWidgetVisible ? t("dashboard.currentDate.collapse") : t("dashboard.currentDate.open")}
                </StyledToggleButton>
                <StyledRefreshButton variant="subtle" leftIcon={<SvgIcon icon={refreshIcon} />} onClick={onRefresh} fontSize="1.1rem">
                    {t("dashboard.currentDate.refresh")}
                </StyledRefreshButton>
            </Grid>
        </StyledCurrentDate>
    ) : null;
};

const StyledCurrentDate = styled.div`
    display: grid;
    justify-items: end;
    align-items: center;
    height: 100%;

    ${maxWidthBreakpoint.mobile(css`
        justify-items: unset;
        grid-auto-flow: column;
    `)};
`;

const StyledRefreshButton = styled(Button)`
    &.mantine-Button-root {
        font-weight: 700;
        height: auto;
        padding: 0;
        color: ${({ theme }) => theme.color.supportNavy500};

        &:hover,
        &:active {
            color: ${({ theme }) => theme.color.supportNavy600};
        }
    }
`;

const StyledToggleButton = styled(StyledRefreshButton)`
    &.mantine-Button-root {
        ${maxWidthBreakpoint.tablet(css`
            display: none;
        `)};

        svg {
            color: ${({ theme }) => theme.color.supportNavy500} !important;
        }
    }
`;
