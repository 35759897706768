import { useCallback } from "react";

import {
    createGlobalErrorMessage,
    useActivateCustomersMutation,
    useBlockCustomersMutation,
    useDeactivateCustomersMutation,
    useUnblockCustomersMutation,
} from "src/api";
import { NotificationTypes, useNotifications } from "src/notifications";
import { useTranslation } from "src/translations";
import type { CustomerActionRequestDto, RequestErrorType } from "src/types";

export const useBlockCustomers = (onSuccess?: () => void) => {
    const { t } = useTranslation();
    const [blockCustomers] = useBlockCustomersMutation();
    const notifications = useNotifications();

    return useCallback(
        (value: CustomerActionRequestDto) => {
            try {
                blockCustomers(value).unwrap();
                onSuccess?.();
                notifications.showNotification({
                    title: t("common.form.successTitle"),
                    message: t("customers.block.blockSuccess"),
                    loading: false,
                    type: NotificationTypes.success,
                });
            } catch (e) {
                const error = e as RequestErrorType;
                const errorMessage = createGlobalErrorMessage(error, t("customers.block.blockError"));
                notifications.showNotification({
                    title: t("common.form.errorTitle"),
                    message: errorMessage,
                    loading: false,
                    type: NotificationTypes.error,
                });
            }
        },
        [blockCustomers, notifications, onSuccess, t],
    );
};

export const useUnblockCustomers = (onSuccess?: () => void) => {
    const { t } = useTranslation();
    const [unblockCustomers] = useUnblockCustomersMutation();
    const notifications = useNotifications();

    return useCallback(
        (value: CustomerActionRequestDto) => {
            try {
                unblockCustomers(value).unwrap();
                onSuccess?.();
                notifications.showNotification({
                    title: t("common.form.successTitle"),
                    message: t("customers.unblock.unblockSuccess"),
                    loading: false,
                    type: NotificationTypes.success,
                });
            } catch (e) {
                const error = e as RequestErrorType;
                const errorMessage = createGlobalErrorMessage(error, t("customers.unblock.unblockError"));
                notifications.showNotification({
                    title: t("common.form.errorTitle"),
                    message: errorMessage,
                    loading: false,
                    type: NotificationTypes.error,
                });
            }
        },
        [unblockCustomers, onSuccess, notifications, t],
    );
};

export const useDeactivateCustomers = (onSuccess?: () => void) => {
    const { t } = useTranslation();
    const [deactivateCustomers] = useDeactivateCustomersMutation();
    const notifications = useNotifications();

    return useCallback(
        (value: Array<number>) => {
            try {
                deactivateCustomers(value).unwrap();
                onSuccess?.();
                notifications.showNotification({
                    title: t("common.form.successTitle"),
                    message: t("customers.deactivate.deactivateSuccess"),
                    loading: false,
                    type: NotificationTypes.success,
                });
            } catch (e) {
                const error = e as RequestErrorType;
                const errorMessage = createGlobalErrorMessage(error, t("customers.deactivate.deactivateError"));
                notifications.showNotification({
                    title: t("common.form.errorTitle"),
                    message: errorMessage,
                    loading: false,
                    type: NotificationTypes.error,
                });
            }
        },
        [deactivateCustomers, notifications, onSuccess, t],
    );
};

export const useActivateCustomers = (onSuccess?: () => void) => {
    const { t } = useTranslation();
    const [activateCustomers] = useActivateCustomersMutation();
    const notifications = useNotifications();

    return useCallback(
        (value: Array<number>) => {
            try {
                activateCustomers(value).unwrap();
                onSuccess?.();
                notifications.showNotification({
                    title: t("common.form.successTitle"),
                    message: t("customers.activate.activateSuccess"),
                    loading: false,
                    type: NotificationTypes.success,
                });
            } catch (e) {
                const error = e as RequestErrorType;
                const errorMessage = createGlobalErrorMessage(error, t("customers.activate.activateError"));
                notifications.showNotification({
                    title: t("common.form.errorTitle"),
                    message: errorMessage,
                    loading: false,
                    type: NotificationTypes.error,
                });
            }
        },
        [activateCustomers, notifications, onSuccess, t],
    );
};
