import type { Row } from "react-table";

import { Color } from "src/theme";
import { CustomerStatusEnum, ShipToListDto } from "src/types";

export const overrideRowProps = (row: Row<ShipToListDto>): Record<string, unknown> => {
    if (row.original.status === CustomerStatusEnum.Blocked) {
        return { $backgroundColor: Color.neutral300 };
    }
    if (row.original.status === CustomerStatusEnum.Inactive) {
        return { $backgroundColor: Color.supportGraphite50 };
    }
    if (row.original.status === CustomerStatusEnum.New) {
        return { $backgroundColor: Color.accent100 };
    }
    return { $backgroundColor: Color.white };
};
