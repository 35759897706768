import type { SelectItemProps } from "@mantine/core";
import { FC, forwardRef } from "react";
import styled, { css } from "styled-components";

import { Checkbox } from "../Checkbox";

export const MultiSelectItem: FC = forwardRef<HTMLDivElement, SelectItemProps & { description?: string }>(
    ({ label, description, selected, ...props }, ref) => {
        return (
            <ItemWrapper $checked={selected} ref={ref} {...props}>
                <MultiSelectItemCheckbox checked={selected || false} onChange={props.onMouseDown} />
                <LabelWrapper $checked={selected} title={description || label}>
                    {description || label}
                </LabelWrapper>
            </ItemWrapper>
        );
    },
);

export const MultiSelectItemCheckbox = styled(Checkbox)`
    margin-top: 0.45rem;

    & .mantine-Checkbox-input {
        background-color: transparent;
        border: 1px solid ${({ theme }) => theme.color.supportGraphite400};
        width: 1.4rem;
        height: 1.4rem;
        transition: none;
    }

    & .mantine-Checkbox-icon {
        width: 0.7rem;
        margin: 0.5rem 0.35rem;
    }
`;

const LabelWrapper = styled.span<{ $checked: boolean }>`
    width: 100%;
    text-overflow: ellipsis;
    overflow: hidden;
    font-weight: ${({ $checked }) => ($checked ? 700 : 400)};
`;

const ItemWrapper = styled.div<{ $checked: boolean }>`
    display: grid;
    grid-template-columns: max-content 1fr;
    align-items: start;
    column-gap: 1rem;

    &:hover {
        & .mantine-Checkbox-input {
            ${({ theme, $checked }) =>
                !$checked &&
                css`
                    border-color: ${theme.color.supportGraphite400};
                `}
        }
    }
`;
