import { nanoid } from "@reduxjs/toolkit";
import type { FC } from "react";

import { useCreateEmergencyOrderMutation } from "src/api";
import { Button, Checkbox, CheckboxProps, Icon, Text, TextInput, TextInputProps } from "src/components";
import { composeValidators, email, Form, GlobalFormError, InputField, phone } from "src/form";
import { useGoogleReCaptcha } from "src/recaptcha";
import { Color } from "src/theme";
import { useTranslation } from "src/translations";
import type { CreateEmergencyOrderDto } from "src/types";
import { isNotNullish, removeSpaces, stringTrim } from "src/utils";
import { ButtonLayout, FormSection, InfoSection } from "./components";
import { PRODUCT_PACKAGES_ARRAY_FIELD } from "./constants";
import { CustomerDependentFields, EmergencyOrdersFormProductPackages } from "./containers";
import type { PublicEmergencyOrderForm } from "./types";

const initialValues = {
    customerNumber: "",
    customerName: "",
    customerId: null,
    contactName: "",
    contactPhoneNumber: "+420",
    contactEmail: "@",
    emergencyOrderProductPackages: [
        {
            id: nanoid(),
            productPackageId: null,
            name: "",
            sukl: "",
            requestedAmount: 1,
            orderReason: "",
        },
    ],
    consent: false,
    recaptcha: null,
};

type EmergencyOrdersFormProps = {
    onSuccess: () => void;
};

export const EmergencyOrdersForm: FC<EmergencyOrdersFormProps> = ({ onSuccess }) => {
    const { t } = useTranslation();
    const { executeRecaptcha } = useGoogleReCaptcha();
    const [createEmergencyOrder] = useCreateEmergencyOrderMutation();

    const onSubmit = async (values: PublicEmergencyOrderForm) => {
        if (!values.consent) {
            throw new GlobalFormError(t("publicEmergencyOrders.needToCheckConsent"));
        }
        if (executeRecaptcha) {
            const token = await executeRecaptcha("submitEmergencyForm");
            if (isNotNullish(values.customerId) && isNotNullish(token)) {
                const createValues: CreateEmergencyOrderDto = {
                    customerId: values.customerId,
                    contactName: stringTrim(values.contactName),
                    contactEmail: stringTrim(values.contactEmail),
                    contactPhoneNumber: removeSpaces(values.contactPhoneNumber),
                    recaptcha: token,
                    emergencyOrderProductPackages: values.emergencyOrderProductPackages.map((productPackage) => {
                        if (isNotNullish(productPackage.productPackageId)) {
                            return {
                                productPackageId: productPackage.productPackageId,
                                requestedAmount: productPackage.requestedAmount,
                                orderReason: productPackage.orderReason,
                            };
                        }
                        throw new GlobalFormError(t("common.form.errorGenericMessage"));
                    }),
                };
                return createEmergencyOrder(createValues).unwrap();
            }
            throw new GlobalFormError(t("common.form.errorGenericMessage"));
        }
        throw new GlobalFormError(t("common.form.recaptchaError"));
    };

    return (
        <Form<PublicEmergencyOrderForm>
            initialValues={initialValues}
            onSubmit={onSubmit}
            unwrapOnSubmit={false}
            onSuccess={onSuccess}
            loadingMessage={t("publicEmergencyOrders.loadingMessage")}
            successMessage={t("publicEmergencyOrders.successMessage")}
        >
            {({ dirty, values }) => (
                <>
                    <FormSection>
                        <Text size="1.6rem" color={Color.primary600} weight={700}>
                            {t("publicEmergencyOrders.contactTitle")}
                        </Text>
                        <Text size="1.4rem" color={Color.supportNavy500}>
                            {t("publicEmergencyOrders.info")}
                        </Text>
                        <Text size="1.4rem" color={Color.supportNavy500}>
                            {t("publicEmergencyOrders.required")}
                        </Text>
                    </FormSection>
                    <FormSection alternate>
                        <CustomerDependentFields />
                    </FormSection>
                    <FormSection>
                        <InputField<TextInputProps["value"], TextInputProps>
                            name="contactName"
                            input={TextInput}
                            inputProps={{
                                maxWidth: "450px",
                                label: t("publicEmergencyOrders.form.contactPerson.label"),
                                placeholder: t("publicEmergencyOrders.form.contactPerson.placeholder"),
                            }}
                            required
                        />
                        <InputField<TextInputProps["value"], TextInputProps>
                            name="contactPhoneNumber"
                            type="tel"
                            input={TextInput}
                            validate={composeValidators(phone)}
                            inputProps={{
                                maxWidth: "450px",
                                label: t("publicEmergencyOrders.form.phoneNumber.label"),
                                placeholder: t("publicEmergencyOrders.form.phoneNumber.placeholder"),
                            }}
                            required
                        />
                        <InputField<TextInputProps["value"], TextInputProps>
                            name="contactEmail"
                            type="email"
                            input={TextInput}
                            validate={composeValidators(email)}
                            inputProps={{
                                maxWidth: "450px",
                                label: t("publicEmergencyOrders.form.email.label"),
                            }}
                            required
                        />
                    </FormSection>
                    <FormSection alternate padding="1.6rem 2.4rem">
                        <EmergencyOrdersFormProductPackages productPackages={values[PRODUCT_PACKAGES_ARRAY_FIELD]} />
                    </FormSection>

                    <InfoSection>
                        <Icon.Info color={Color.primary600} />
                        <Text size="1.4rem" color={Color.supportNavy500}>
                            {t("publicEmergencyOrders.info1")}
                        </Text>
                    </InfoSection>
                    <InfoSection>
                        <Icon.Info color={Color.primary600} />
                        <Text size="1.4rem" color={Color.supportNavy500}>
                            {t("publicEmergencyOrders.info2")}
                        </Text>
                    </InfoSection>
                    <FormSection>
                        <InputField<CheckboxProps["value"], CheckboxProps>
                            name="consent"
                            input={Checkbox}
                            type="checkbox"
                            inputProps={{
                                label: t("publicEmergencyOrders.form.consent.label"),
                            }}
                        />
                    </FormSection>
                    <ButtonLayout>
                        <Button type="submit" disabled={!dirty}>
                            {t("publicEmergencyOrders.form.submit")}
                        </Button>
                    </ButtonLayout>
                </>
            )}
        </Form>
    );
};
