import type { TFunction } from "react-i18next";
import type { Cell } from "react-table";

import { useGetProductBrandsQuery } from "src/api";
import {
    ArchiveIcon,
    createBooleanSelectOption,
    createSelectColumnFilter,
    CurrentPriceCell,
    Icon,
    NumberColumnFilter,
    TextColumnFilter,
} from "src/components";
import { createTableModule, ICON_TABLE_SIZE, TableNames } from "src/table";
import { Color } from "src/theme";
import type { ExpandColumnsType, ExpandTableProductType } from "src/types";
import { currencyFormatter, dateTimeFormatter, percentageFormatter } from "src/utils";

const createColumns = (t: TFunction): ExpandColumnsType<ExpandTableProductType> => [
    {
        width: 350,
        minWidth: 150,
        accessor: "name" as const,
        Header: t("products.table.name") as string,
        Filter: TextColumnFilter,
        noWrap: true,
    },
    {
        minWidth: 120,
        Header: t("products.table.sapCode") as string,
        accessor: "sapCode" as const,
        Filter: TextColumnFilter,
    },
    {
        minWidth: 100,
        Header: t("products.table.agentCode") as string,
        accessor: "agentCode" as const,
        Filter: TextColumnFilter,
    },
    {
        minWidth: 100,
        Header: t("products.table.suklCode") as string,
        accessor: "suklCode" as const,
        Filter: TextColumnFilter,
    },
    {
        Header: t("products.table.margin") as string,
        accessor: "margin" as const,
        align: "flex-end",
        minWidth: 70,
        collapse: true,
        noWrap: true,
        Cell: ({ value }: Cell<ExpandTableProductType>) => percentageFormatter(value),
        Filter: NumberColumnFilter,
        filter: "exact",
    },
    {
        Header: t("products.table.active") as string,
        accessor: "active" as const,
        align: "flex-end",
        collapse: true,
        minWidth: 100,
        Cell: ({ value, row }: Cell<ExpandTableProductType>) => {
            if (row.depth === 0) {
                return value === false ? (
                    <Icon.Close
                        tooltipBackgroundColor={Color.error500}
                        tooltip={t("common.inactive")}
                        size={ICON_TABLE_SIZE}
                        color={Color.error500}
                    />
                ) : null;
            }
            return value ? (
                <Icon.Checkmark
                    tooltipBackgroundColor={Color.success500}
                    tooltip={t("common.active")}
                    size={ICON_TABLE_SIZE}
                    color={Color.success500}
                />
            ) : (
                <Icon.Close
                    tooltipBackgroundColor={Color.error500}
                    tooltip={t("common.inactive")}
                    size={ICON_TABLE_SIZE}
                    color={Color.error500}
                />
            );
        },
        Filter: createSelectColumnFilter(createBooleanSelectOption(t("common.active"), t("common.inactive"))),
    },
    {
        Header: t("products.table.archived") as string,
        accessor: "archived" as const,
        align: "flex-end",
        collapse: true,
        minWidth: 120,
        Cell: ({ value }: Cell<ExpandTableProductType>) =>
            value ? <ArchiveIcon tooltip={t("common.archived")} color={Color.neutral400} /> : null,
        Filter: createSelectColumnFilter(createBooleanSelectOption(t("common.archived"), t("common.unarchived"))),
    },
    {
        Header: t("products.table.price") as string,
        accessor: "currentPrice" as const,
        align: "flex-end",
        collapse: true,
        noWrap: true,
        minWidth: 100,
        Cell: ({ value, row }: Cell<ExpandTableProductType>) =>
            value ? (
                <CurrentPriceCell
                    disabled={!row.original.active}
                    date={`${t("products.table.validToPrice")} ${dateTimeFormatter(value?.validTo)}`}
                    price={currencyFormatter(value?.price)}
                />
            ) : null,
        disableFilters: true,
    },
];

export const tableModule = createTableModule<ExpandTableProductType>(TableNames.productList, useGetProductBrandsQuery, createColumns);
