import type { FC, ReactNode } from "react";

import { DashboardCardLayout, DashboardCardLayoutProps } from "../components";
import type { DashboardCollapsibleWidgetsEnum } from "../enums";
import { useCollapsibleWidget } from "../useCollapsibleWidget";

type DashboardCardProps = {
    collapsibleWidgetId?: DashboardCollapsibleWidgetsEnum;
    statusBar?: ReactNode;
} & Omit<DashboardCardLayoutProps, "onTitleClick">;

export const DashboardCard: FC<DashboardCardProps> = ({ children, collapsibleWidgetId, ...props }) => {
    const { isVisible, toggle } = useCollapsibleWidget(collapsibleWidgetId);

    return (
        <DashboardCardLayout isVisible={isVisible} onTitleClick={toggle} {...props}>
            {children}
        </DashboardCardLayout>
    );
};
