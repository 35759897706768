import type {
    AnyRecord,
    CreateAndUpdateProductPrice,
    CreateProductPackage,
    CreateProductPackageDto,
    CreateProductPriceDto,
    UpdateProductPackageDto,
    UpdateProductPriceDto,
} from "src/types";
import { cleanObject, formatDateForBE, isEmpty } from "src/utils";

export const transformProductPackageFormValuesForUpdate = (values: UpdateProductPackageDto): UpdateProductPackageDto => {
    // This should never have happened, it's only for typescript check
    if (!values.productBrandId) {
        throw new Error("ProductBrandId is missing in request");
    }
    return {
        ...values,
        eanCode: isEmpty(values.eanCode) ? undefined : values.eanCode,
    };
};

export const transformProductPackageFormValuesForCreate = (values: CreateProductPackage): CreateProductPackageDto => {
    // This should never have happened, it's only for typescript check
    if (!values.productBrandId) {
        throw new Error("ProductBrandId is missing in request");
    }
    return {
        ...values,
        eanCode: isEmpty(values.eanCode) ? undefined : values.eanCode,
        price:
            values.price?.price && values.price?.validFrom
                ? {
                      price: values.price.price,
                      validFrom: formatDateForBE(values.price.validFrom),
                  }
                : undefined,
    };
};

export const transformProductPriceFormValuesForCreateAndUpdate = (
    values: CreateAndUpdateProductPrice,
): CreateProductPriceDto | UpdateProductPriceDto => ({
    ...values,
    validFrom: formatDateForBE(values.validFrom),
    validTo: values.validTo ? formatDateForBE(values.validTo) : undefined,
});

export const convertProductBrandsWithSupplyLimitsTableFilterNames = (filters: AnyRecord) => {
    const { productPackageName, margin, ...clearedFilters } = filters;
    return cleanObject<string | number>({
        ...clearedFilters,
        name: productPackageName,
        marginSource: margin,
    });
};
