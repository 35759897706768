import type { FC } from "react";

import { Text, Tooltip } from "src/components";
import { Color } from "src/theme";
import { useTranslation } from "src/translations";
import { Separator, TextSeparator } from "./TextSeparator";

type MvaTextProps = { monthlyVolumeAdjustment: number; requestAmount: number };

export const MvaText: FC<MvaTextProps> = ({ monthlyVolumeAdjustment, requestAmount }) => {
    const { t } = useTranslation();

    const formattedRequestedAmount = requestAmount > 0 ? `+${requestAmount}` : `${requestAmount}`;

    return (
        <TextSeparator>
            <Tooltip withArrow label={t("supplyLimits.tiers.requestedAmountTooltip")} color={Color.white} backgroundColor={Color.accent500}>
                <Text weight={700} color={Color.white}>
                    {formattedRequestedAmount}
                </Text>
            </Tooltip>
            <Separator color={Color.white} />
            <Tooltip withArrow label={t("supplyLimits.tiers.currentMvaTooltip")} color={Color.white} backgroundColor={Color.accent500}>
                <Text weight={700} color={Color.white}>
                    {monthlyVolumeAdjustment}
                </Text>
            </Tooltip>
        </TextSeparator>
    );
};
