export enum CustomersTab {
    all = "all",
    new = "new",
    updated = "toUpdate",
    blocked = "toBlock",
    unblocked = "toUnblock",
    deactivated = "toDeactivate",
}

export enum PayerDetailTab {
    notes = "notes",
    dataModificationLog = "dataModificationLog",
    packageDistributionMargins = "packageDistributionMargins",
    specialPrices = "specialPrices",
    shipToPartners = "shipToPartners",
    attachments = "attachments",
}

export enum ShipToDetailTab {
    emergencyOrders = "emergencyOrders",
    supplyLimits = "supplyLimits",
    specialPrices = "specialPrices",
    dataModificationLog = "dataModificationLog",
    notes = "notes",
    attachments = "attachments",
}

export enum ImportExportTab {
    aggregationTool = "aggregationTool",
    importInterfaces = "importInterfaces",
    exportInterfaces = "exportInterfaces",
    suklReport = "suklReport",
}

export enum DashboardTab {
    emergencyOrders = "emergencyOrders",
    supplyLimitSuggestions = "supplyLimitSuggestions",
}
