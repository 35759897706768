import styled from "styled-components";

export const LinkToPublicEmergencyForm = styled.a`
    font-weight: 700;
    color: ${({ theme }) => theme.color.primary600};
    cursor: pointer;
    padding: 0.8rem 1.6rem;
    text-decoration: none;
    font-size: 1.4rem;
    display: flex;
    align-items: center;
    justify-content: center;

    &:hover {
        color: ${({ theme }) => theme.color.primary700};
    }

    &:active {
        color: ${({ theme }) => theme.color.primary500};
        transform: translateY(1px);
    }
`;
