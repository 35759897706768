import type { FC } from "react";

import { Icon, Text } from "src/components";
import { Color, IconSizes } from "src/theme";
import { WidgetWarning as WidgetWarningComponent } from "../components";

type WidgetWarningProps = {
    text: string;
};

export const WidgetWarning: FC<WidgetWarningProps> = ({ text }) => {
    return (
        <WidgetWarningComponent>
            <Icon.Warning size={IconSizes.m} color={Color.warning700} />
            <Text color={Color.warning700} size="1.4rem">
                {text}
            </Text>
        </WidgetWarningComponent>
    );
};
