import type { FC } from "react";
import styled from "styled-components";

import { ActionIcon, Flex, Icon, Text } from "src/components";
import { Color } from "src/theme";
import { useTranslation } from "src/translations";

type ShipToSwitchProps = {
    currentShipToIndex: number;
    maxShipTos: number;
    onPreviousShipTo: () => void;
    onNextShipTo: () => void;
};

export const ShipToSwitch: FC<ShipToSwitchProps> = ({ currentShipToIndex = 0, maxShipTos = 0, onPreviousShipTo, onNextShipTo }) => {
    const { t } = useTranslation();

    const currentShipTo = currentShipToIndex + 1;
    const disablePrevious = currentShipToIndex < 1;
    const disableNext = currentShipToIndex + 1 >= maxShipTos;

    return (
        <Wrapper>
            <Flex alignItems="center" gap="1rem">
                <ActionIcon onClick={onPreviousShipTo} disabled={disablePrevious}>
                    <Icon.ChevronLeft color={disablePrevious ? Color.supportGraphite200 : Color.supportNavy500} />
                </ActionIcon>
                <Text size="1.8rem" weight={400} color={Color.supportNavy500}>
                    {t("copyTiers.shipToSwitchLabel", { currentShipTo: currentShipTo.toString(), maxShipTos: maxShipTos.toString() })}
                </Text>
                <ActionIcon onClick={onNextShipTo} disabled={disableNext}>
                    <Icon.ChevronRight color={disableNext ? Color.supportGraphite200 : Color.supportNavy500} />
                </ActionIcon>
            </Flex>
            <Text size="1.1rem" color={Color.supportNavy300} align="right">
                {t("copyTiers.multipleCustomersFound")}
            </Text>
        </Wrapper>
    );
};

const Wrapper = styled.div`
    display: flex;
    flex-direction: column;
    gap: 0.5rem;
    max-width: 16rem;
`;
