import { endOfMonth, format, startOfMonth, subMonths } from "date-fns";
import { FC, useMemo } from "react";

import { FileMimeType, url, useDownloadFileMutation } from "src/api";
import { Button, Grid, Text } from "src/components";
import { NotificationTypes, useNotifications } from "src/notifications";
import { Color } from "src/theme";
import { useTranslation } from "src/translations";
import { DATE_FORMAT } from "src/utils";
import { SuklExportRow } from "./components";
import { SuklFileType } from "./enums";

export const SuklReport: FC = () => {
    const { t } = useTranslation();
    const notifications = useNotifications();
    const [downloadFile] = useDownloadFileMutation();

    const previousMonth = useMemo(() => subMonths(new Date(), 1), []);

    const downloadSuklFile = async (fileType: SuklFileType) => {
        const notificationId = notifications.showNotification({
            title: t("common.form.loadingTitle"),
            message: t(`importExport.suklReport.export.loading`),
            loading: true,
        });
        const date = format(previousMonth, "yyyy-MM");
        try {
            if (fileType === SuklFileType.Req13) {
                await downloadFile({ url: url.SUKL_REG13, filename: `REG13_${date}`, fileMimeType: FileMimeType.Xls }).unwrap();
            } else if (fileType === SuklFileType.Dis13) {
                await downloadFile({ url: url.SUKL_DIS13, filename: `DIS13_${date}`, fileMimeType: FileMimeType.Xls }).unwrap();
            } else {
                throw new Error(`Invalid sukl file type: ${fileType}`);
            }
            notifications.updateNotification({
                id: notificationId,
                loading: false,
                title: t("common.form.successTitle"),
                message: t(`importExport.suklReport.export.success`),
                type: NotificationTypes.success,
            });
        } catch (e) {
            notifications.updateNotificationToGlobalError({
                id: notificationId,
                error: e,
                fallbackMessage: t("importExport.suklReport.export.error"),
            });
        }
    };

    const dates = useMemo(
        () => ({
            from: format(startOfMonth(previousMonth), DATE_FORMAT),
            to: format(endOfMonth(previousMonth), DATE_FORMAT),
        }),
        [previousMonth],
    );

    return (
        <Grid gridAutoFlow="row" gap="1rem" gridTemplateColumns="minmax(0, 550px)">
            <SuklExportRow>
                <Text size="1.4rem" color={Color.supportGraphite500}>
                    {t("importExport.suklReport.widget.reg13", dates)}
                </Text>
                <Button onClick={() => downloadSuklFile(SuklFileType.Req13)}>{t("common.export")}</Button>
            </SuklExportRow>
            <SuklExportRow>
                <Text size="1.4rem" color={Color.supportGraphite500}>
                    {t("importExport.suklReport.widget.dis13", dates)}
                </Text>
                <Button onClick={() => downloadSuklFile(SuklFileType.Dis13)}>{t("common.export")}</Button>
            </SuklExportRow>
        </Grid>
    );
};
