import type { TFunction } from "i18next";
import type { HeaderGroup, TableHeaderProps } from "react-table";
import styled, { css, CSSProperties } from "styled-components";

import type { TableRecordType } from "src/types";
import { ACTION_COLUMN_ID } from "../../core/table/constants";
import { ResetFilterButton } from "./ResetFilterButton";

type THeadProps<RecordType extends TableRecordType> = Readonly<{
    headerGroups: Array<HeaderGroup<RecordType>>;
    resetFilters: () => void;
    areFiltersDirty: boolean;
    t: TFunction;
}>;

type ColumnHeaderProps = {
    collapse?: boolean;
    maxWidth?: number;
    minWidth?: number;
    $width?: number;
    $headerVerticalAlign?: CSSProperties["verticalAlign"];
};

// @ts-ignore
export const collectHeaderProps = (props, { column }): TableHeaderProps & ColumnHeaderProps => ({
    ...props,
    className: column.collapse ? "collapse" : "",
    width: column.width,
    maxWidth: column.maxWidth,
    minWidth: column.minWidth,
    $headerVerticalAlign: column.headerVerticalAlign,
});

/**
 * Table header component
 * Ready to use with Table component
 */
export const THead = <RecordType extends TableRecordType>({
    headerGroups,
    resetFilters,
    areFiltersDirty,
    t,
}: THeadProps<RecordType>): JSX.Element => {
    return (
        <thead>
            {headerGroups.map((headerGroup) => (
                <tr {...headerGroup.getHeaderGroupProps()}>
                    {headerGroup.headers.map((column) => (
                        <Th {...column.getHeaderProps(collectHeaderProps)}>
                            {/* @ts-ignore */}
                            <ThWrapper $align={column.align}>
                                {column.render("Header")}
                                {column.id === ACTION_COLUMN_ID ? (
                                    <ResetFilterButton hidden={!areFiltersDirty} onClick={resetFilters}>
                                        {t("common.reset")}
                                    </ResetFilterButton>
                                ) : column.canFilter ? (
                                    <div>{column.render("Filter")}</div>
                                ) : null}
                            </ThWrapper>
                        </Th>
                    ))}
                </tr>
            ))}
        </thead>
    );
};

const Th = styled.th<TableHeaderProps & ColumnHeaderProps>`
    background-color: ${({ theme }) => theme.color.primary600};
    padding: 1.2rem;
    box-sizing: content-box;
    text-align: left;
    color: ${({ theme }) => theme.color.neutral50};
    font-size: 1.6rem;
    vertical-align: ${({ $headerVerticalAlign }) => $headerVerticalAlign || "top"};
    font-weight: 700;
    ${({ maxWidth }) =>
        maxWidth &&
        css`
            max-width: ${maxWidth}px;
        `}
    ${({ minWidth }) =>
        minWidth &&
        css`
            min-width: ${minWidth}px;
        `}
`;

const ThWrapper = styled.div<{ $align?: CSSProperties["justifyContent"] }>`
    display: grid;
    font-size: 1.6rem;
    grid-auto-flow: row;
    gap: 1.5rem;
    ${({ $align }) =>
        $align &&
        css`
            justify-items: ${$align};
        `}
`;
