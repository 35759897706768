import styled from "styled-components";

export const ErrorBlock = styled.div`
    display: grid;
    grid-auto-flow: row;
    gap: 2rem;
    padding: 0.8rem 1.6rem;
    justify-items: center;
    background-color: ${({ theme }) => theme.color.error100};
    border: 1px solid ${({ theme }) => theme.color.error500};
    border-radius: ${({ theme }) => theme.radius.default};
`;
