import i18n from "i18next";
import { initReactI18next } from "react-i18next";

import { isUnitTesting } from "src/utils";
import { Languages } from "./constants";
import cs from "./cs.yaml";
import en from "./en.yaml";

const resources = {
    cs: { translation: cs },
    en: { translation: en },
};

export const initTranslations = () =>
    i18n
        .use(initReactI18next) // passes i18n down to react-
        .init({
            resources,
            lng: Languages.en,
            interpolation: {
                escapeValue: false, // react already safes from xss
            },
            parseMissingKeyHandler: (key) => {
                if (isUnitTesting()) {
                    return key;
                }

                // eslint-disable-next-line no-console
                console.warn(`Missing key: ${key}`);
                return `(${key})`;
            },
        });
