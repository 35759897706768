import { useFocusTrap } from "@mantine/hooks";
import { type FormikHelpers, Formik, FormikProps } from "formik";
import type { FormikErrors } from "formik/dist/types";
import type { ReactNode } from "react";
import type { DefaultTheme, StyledComponent } from "styled-components";

import { createGlobalErrorMessage } from "src/api";
import { VerticalFormLayout } from "src/components";
import { NotificationTypes, useNotifications } from "src/notifications";
import { useTranslation } from "src/translations";
import type { RequestErrorType } from "src/types";
import { createObjectFromString, isFunction } from "src/utils";
import { DO_NOT_TRANSLATE_PREFIX } from "./constants";

export type FormProps<Values> = {
    children: (props: FormikProps<Values>) => ReactNode;
    initialValues: Partial<Values>;
    // There is a problem with merging create and update types, therefore we have to use any for now
    // eslint-disable-next-line
    onSubmit: any;
    loadingMessage?: string | ((values: Values) => string);
    successMessage?: string | ((values: Values) => string);
    onSuccess?: () => void;
    onError?: () => void;
    layoutGap?: string;
    enableReinitialize?: boolean;
    unwrapOnSubmit?: boolean;
    validate?: (values: Values) => void | object | Promise<FormikErrors<Values>>;
    layoutComponent?: StyledComponent<"form", DefaultTheme, { $gap?: string | undefined }>;
    trapFocus?: boolean;
};

export const Form = <Values,>({
    children,
    onSubmit,
    initialValues,
    loadingMessage,
    successMessage,
    onSuccess,
    onError,
    layoutGap,
    enableReinitialize,
    unwrapOnSubmit = true,
    validate,
    layoutComponent,
    trapFocus = false,
}: FormProps<Values>): JSX.Element => {
    const notifications = useNotifications();
    const focusTrapRef = useFocusTrap(trapFocus);
    const { t } = useTranslation();

    const onSubmitWrapper = async (values: Values, formikHelpers: FormikHelpers<Values>) => {
        const loadingMessageResolved = isFunction(loadingMessage) ? loadingMessage(values) : loadingMessage;
        const notificationId = notifications.showNotification({
            title: t("common.form.loadingTitle"),
            message: loadingMessageResolved || t("common.form.loadingMessage"),
            loading: true,
        });
        try {
            if (unwrapOnSubmit) {
                await onSubmit(values, formikHelpers).unwrap();
            } else {
                await onSubmit(values, formikHelpers);
            }
            formikHelpers.setSubmitting(false);

            // SUCCESS
            const successMessageResolved = isFunction(successMessage) ? successMessage(values) : successMessage;
            notifications.updateNotification({
                id: notificationId,
                title: t("common.form.successTitle"),
                message: successMessageResolved || t("common.form.successMessage"),
                loading: false,
                type: NotificationTypes.success,
            });
            onSuccess?.();
        } catch (e) {
            formikHelpers.setSubmitting(false);
            const errors = e as RequestErrorType;
            if (errors?.data?.fieldErrors?.length > 0) {
                const fieldErrors = errors?.data?.fieldErrors.reduce(
                    (all, er) => ({
                        ...all,
                        ...createObjectFromString(er.field, `${DO_NOT_TRANSLATE_PREFIX}${er.message ?? er.messageCode}`),
                    }),
                    {},
                );
                formikHelpers.setErrors(fieldErrors);
            }
            const globalMessage = createGlobalErrorMessage(errors, t("common.form.errorGenericMessage"));
            notifications.updateNotification({
                id: notificationId,
                title: t("common.form.errorTitle"),
                message: globalMessage,
                type: NotificationTypes.error,
            });
            onError?.();
        }
    };

    const Layout = layoutComponent || VerticalFormLayout;

    return (
        <Formik
            enableReinitialize={enableReinitialize}
            initialValues={initialValues as Values}
            onSubmit={onSubmitWrapper}
            validateOnBlur={false}
            validateOnChange
            validate={validate}
        >
            {({ handleSubmit, ...formikProps }) => (
                <Layout ref={focusTrapRef} noValidate $gap={layoutGap} onSubmit={handleSubmit}>
                    {children({ handleSubmit, ...formikProps })}
                </Layout>
            )}
        </Formik>
    );
};
