import { useEventListener, useMergedRef, useWindowEvent } from "@mantine/hooks";
import type { ChangeEvent, Dispatch, SetStateAction } from "react";
import { RefObject, useCallback, useLayoutEffect, useRef, useState } from "react";
import type { Cell, IdType, UseExpandedInstanceProps, UseRowSelectInstanceProps } from "react-table";

import { ActionIcon, Icon } from "src/components";
import { Color } from "src/theme";
import type { AnyRecord, ColumnsType, TableRecordType } from "src/types";
import { HeaderTableCheckbox, TableCheckbox } from "../components";
import { ACTION_COLUMN_ID, EXPAND_COLUMN_ID, ICON_TABLE_SIZE, SELECT_COLUMN_ID } from "../constants";

// @ts-ignore
export const collectCellProps = (props, { cell }) => ({
    ...props,
    $align: cell.column.align,
    className: cell.column.collapse ? "collapse" : "",
    $noWrap: cell.column.noWrap,
});

export const addSpecialColumns = <RecordType extends TableRecordType>(
    columns: ColumnsType<RecordType>,
    setExpandState: Dispatch<SetStateAction<Record<number, boolean>>>,
    setIsAllRowsSelected: (selected: boolean) => void,
    expanded?: boolean,
    isSelectable?: boolean,
    isAllRowsSelected?: boolean,
): ColumnsType<RecordType> => {
    // Add select to columns
    const columnsWithSelect = isSelectable
        ? [
              {
                  Header: ({ getToggleAllPageRowsSelectedProps, getToggleAllRowsSelectedProps }: UseRowSelectInstanceProps<RecordType>) => {
                      const checkboxPageProps = getToggleAllPageRowsSelectedProps();
                      const checkboxAllProps = getToggleAllRowsSelectedProps();
                      const onChange = (e: ChangeEvent<HTMLInputElement>) => {
                          if (isAllRowsSelected) {
                              const falseEvent = { ...e, target: { ...e.target, checked: false } };
                              setIsAllRowsSelected(false);
                              checkboxAllProps.onChange?.(falseEvent);
                          } else {
                              checkboxPageProps.onChange?.(e);
                          }
                      };
                      return (
                          <HeaderTableCheckbox
                              {...checkboxPageProps}
                              checked={isAllRowsSelected || checkboxPageProps.checked}
                              indeterminate={isAllRowsSelected ? false : checkboxPageProps.indeterminate}
                              onChange={onChange}
                          />
                      );
                  },
                  // @ts-ignore - TS doesn't know about the type of the function
                  Cell: ({ row, getToggleAllPageRowsSelectedProps, getToggleAllRowsSelectedProps }) => {
                      const checkboxProps = row.getToggleRowSelectedProps();
                      const checkboxPageProps = getToggleAllPageRowsSelectedProps();
                      const checkboxAllProps = getToggleAllRowsSelectedProps();
                      const onChange = (e: ChangeEvent<HTMLInputElement>) => {
                          if (isAllRowsSelected) {
                              // If all rows are selected, we need to unselect the row clicked row,
                              // select other rows and unselect rows on other pages
                              const trueEvent = { ...e, target: { ...e.target, checked: true } };
                              const falseEvent = { ...e, target: { ...e.target, checked: false } };
                              setIsAllRowsSelected(false);
                              checkboxAllProps.onChange(falseEvent);
                              checkboxPageProps.onChange(trueEvent);
                              checkboxProps.onChange?.(falseEvent);
                          } else {
                              checkboxProps.onChange?.(e);
                          }
                      };
                      // TODO :: fix checkbox in base depth when expand is active
                      if (expanded && row.depth === 0) {
                          return null;
                      }
                      return <TableCheckbox {...checkboxProps} checked={isAllRowsSelected || checkboxProps.checked} onChange={onChange} />;
                  },
                  collapse: true,
                  headerVerticalAlign: "bottom",
                  align: "center",
                  accessor: SELECT_COLUMN_ID,
                  id: SELECT_COLUMN_ID,
                  disableFilters: true,
              },
              ...columns,
          ]
        : columns;

    // Add expand to columns
    const columnsWithExpanded = expanded
        ? [
              {
                  accessor: EXPAND_COLUMN_ID,
                  id: EXPAND_COLUMN_ID,
                  disableFilters: true,
                  collapse: true,
                  headerVerticalAlign: "middle",
                  align: "center",
                  Header: ({ rows }: UseExpandedInstanceProps<RecordType>) => {
                      // Compute only rows in depth 0, we don't expect more levels
                      const isAllRowsExpanded = rows.filter((r) => r.depth === 0).every((r) => r.isExpanded);
                      return (
                          <ActionIcon
                              color={Color.primary700}
                              onClick={() => setExpandState(rows.reduce((all, r) => ({ ...all, [r.index]: !isAllRowsExpanded }), {}))}
                          >
                              <Icon.ChevronDoubleDown color={Color.neutral50} size={ICON_TABLE_SIZE} />
                          </ActionIcon>
                      );
                  },
                  Cell: ({ row }: Cell) =>
                      // Use the row.canExpand and row.getToggleRowExpandedProps prop getter
                      // to build the toggle for expanding a row
                      row.canExpand && (
                          <ActionIcon
                              color={Color.supportNavy100}
                              onClick={() => setExpandState((oldState) => ({ ...oldState, [row.index]: !row.isExpanded }))}
                          >
                              {row.isExpanded ? (
                                  <Icon.ChevronUp color={Color.supportNavy500} size={ICON_TABLE_SIZE} />
                              ) : (
                                  <Icon.ChevronDown color={Color.supportNavy500} size={ICON_TABLE_SIZE} />
                              )}
                          </ActionIcon>
                      ),
              },
              ...columnsWithSelect,
          ]
        : columnsWithSelect;

    // Add action to columns
    // Even if there aren't any actions, we still need to add it to the columns for reset button
    return [
        ...columnsWithExpanded,
        {
            Header: "",
            collapse: true,
            headerVerticalAlign: "bottom",
            align: "flex-end",
            accessor: ACTION_COLUMN_ID,
            id: ACTION_COLUMN_ID,
            disableFilters: true,
        },
    ];
};

export const convertFiltersToFilterParams = (filters: Array<AnyRecord>): AnyRecord =>
    filters.reduce((all, { id, value }) => ({ ...all, [id]: value }), {});

export const convertFilterParamsToFilters = <RecordType,>(filterParams?: AnyRecord): Array<{ id: IdType<RecordType>; value: unknown }> =>
    filterParams
        ? Object.entries(filterParams).reduce(
              (all: Array<{ id: IdType<RecordType>; value: unknown }>, [key, value]) => [...all, { id: key, value }],
              [],
          )
        : [];

// eslint-disable-next-line @typescript-eslint/no-explicit-any
export const tableStateReducer = (state: any, action: any) => {
    if (action.type === "toggleAllRowsSelected" && action.value === false) {
        return {
            ...state,
            selectedRowIds: {},
        };
    }
    return state;
};

const TABLE_DECORATION_VISIBILITY_OFFSET = 10;

export const useTableDecoration = (): {
    tableRef: RefObject<HTMLTableElement>;
    wrapperRef: (node: unknown) => void;
    isLeftDecorationVisible: boolean;
    isRightDecorationVisible: boolean;
} => {
    const [isLeftDecorationVisible, setIsLeftDecorationVisible] = useState(false);
    const [isRightDecorationVisible, setIsRightDecorationVisible] = useState(false);
    const tableRef = useRef<HTMLTableElement>(null);
    const tableWrapperRef = useRef<HTMLDivElement>(null);

    const checkDecorationVisibility = useCallback(() => {
        const tableBounding = tableRef.current?.getBoundingClientRect();
        const wrapperBounding = tableWrapperRef.current?.getBoundingClientRect();
        if (tableBounding && wrapperBounding) {
            if (tableBounding.left > wrapperBounding.left - TABLE_DECORATION_VISIBILITY_OFFSET) {
                setIsLeftDecorationVisible(false);
            } else {
                setIsLeftDecorationVisible(true);
            }
            if (tableBounding.right < wrapperBounding.right + TABLE_DECORATION_VISIBILITY_OFFSET) {
                setIsRightDecorationVisible(false);
            } else {
                setIsRightDecorationVisible(true);
            }
        }
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [tableRef.current, tableWrapperRef.current]);

    // layout effect is here because we are working with refs inside checkDecorationVisibility function
    useLayoutEffect(() => {
        checkDecorationVisibility();
    }, [checkDecorationVisibility]);

    const scrollRef = useEventListener("scroll", checkDecorationVisibility);
    useWindowEvent("resize", checkDecorationVisibility);

    const mergedRef = useMergedRef(tableWrapperRef, scrollRef);

    return {
        tableRef,
        wrapperRef: mergedRef,
        isLeftDecorationVisible,
        isRightDecorationVisible,
    };
};
