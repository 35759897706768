import styled from "styled-components";

export const SpecialPriceAttachmentRow = styled.div`
    padding: 0.8rem 1.6rem;
    background-color: ${({ theme }) => theme.color.white};
    border: 1px solid ${({ theme }) => theme.color.neutral100};
    border-radius: ${({ theme }) => theme.radius.default};
    display: grid;
    grid-template-columns: 3fr 1fr 1fr 0.5fr;
    gap: 1rem;
    align-items: center;
    min-width: 700px;
    color: ${({ theme }) => theme.color.supportGraphite500};
`;
