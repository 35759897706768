import { IMAGE_MIME_TYPE, PDF_MIME_TYPE } from "@mantine/dropzone";
import type { TFunction } from "i18next";

import { ActionIcon, Grid, Icon } from "src/components";
import { ConfirmContextConsumer } from "src/modal";
import { ICON_TABLE_SIZE } from "src/table";
import { Color } from "src/theme";
import type { AttachmentDto } from "src/types";

const createDeleteAttachmentButton = (t: TFunction, onConfirm: () => void, setIsTransparentForModal: (isTransparent: boolean) => void) => (
    <ConfirmContextConsumer>
        {({ showConfirm }) => {
            const confirm = async () => {
                const confirmResult = await showConfirm({
                    title: t("customers.attachments.delete.title"),
                    content: t("customers.attachments.delete.content"),
                    setIsTransparentForModal,
                });
                if (confirmResult) {
                    onConfirm();
                }
            };
            return (
                <ActionIcon onClick={confirm} color={Color.error100} tooltip={t("customers.attachments.deleteTooltip")}>
                    <Icon.Delete size={ICON_TABLE_SIZE} color={Color.error500} />
                </ActionIcon>
            );
        }}
    </ConfirmContextConsumer>
);

export const createActions = (
    t: TFunction,
    attachment: AttachmentDto,
    downloadAttachment: (fileId: number, filename: string, fileMimeType: string, open: boolean) => void,
    deleteAttachment: (fileId: number) => void,
    setIsTransparentForModal: (isTransparent: boolean) => void,
) => {
    const canOpen = ([...IMAGE_MIME_TYPE, ...PDF_MIME_TYPE] as string[]).includes(attachment.contentType);
    return (
        <Grid gridAutoFlow="column" gap="0.5rem">
            {canOpen && (
                <ActionIcon
                    onClick={() => downloadAttachment(attachment.id, attachment.fileName, attachment.contentType, true)}
                    color={Color.supportNavy50}
                    tooltip={t("customers.attachments.openTooltip")}
                >
                    <Icon.Open size={ICON_TABLE_SIZE} color={Color.supportNavy500} />
                </ActionIcon>
            )}
            <ActionIcon
                onClick={() => downloadAttachment(attachment.id, attachment.fileName, attachment.contentType, false)}
                color={Color.supportNavy50}
                tooltip={t("customers.attachments.downloadTooltip")}
            >
                <Icon.Download size={ICON_TABLE_SIZE} color={Color.supportNavy500} />
            </ActionIcon>
            {createDeleteAttachmentButton(t, () => deleteAttachment(attachment.id), setIsTransparentForModal)}
        </Grid>
    );
};
