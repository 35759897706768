import { isBefore, startOfDay } from "date-fns";
import type { TFunction } from "i18next";
import type { Row } from "react-table";

import csvIcon from "src/assets/icons/csv.svg";
import xlsIcon from "src/assets/icons/xls.svg";
import { ActionIcon, Grid, Icon, SvgIcon, TableAction, Tooltip } from "src/components";
import { Color, IconSizes } from "src/theme";
import type { AggregationDto } from "src/types";
import { dateTimeFormatter, EMPTY_OBJECT, isDateTodayOrAfter } from "src/utils";
import { AggregationFileType } from "./enums";

const createCsvFileName = (aggregation: AggregationDto): string => {
    const createdDate = dateTimeFormatter(aggregation.created, "yyyyMMddhhmmss");
    return `SD0021_${createdDate}`;
};

const createExcelFileName = (aggregation: AggregationDto): string => {
    const dateFrom = dateTimeFormatter(aggregation.dateFrom, "ddMMyyyy");
    const dateTo = dateTimeFormatter(aggregation.dateTo, "ddMMyyyy");
    return `SAPExport_${dateFrom}_${dateTo}`;
};

export const createActionColumn =
    (t: TFunction, downloadFile: (fileType: AggregationFileType, id: number, filename: string) => void) =>
    ({ original }: Row<AggregationDto>): JSX.Element =>
        (
            <TableAction>
                {original.isCorrective && (
                    <Tooltip
                        withinPortal={false}
                        openDelay={500}
                        backgroundColor={Color.primary50}
                        color={Color.supportGraphite500}
                        label={t(`importExport.aggregatorTool.table.isCorrective`)}
                    >
                        <Grid justifyContent="center" alignItems="center">
                            <Icon.Comment size={IconSizes.m} color={Color.primary600} filled />
                        </Grid>
                    </Tooltip>
                )}
                <ActionIcon
                    onClick={() => downloadFile(AggregationFileType.Csv, original.id, createCsvFileName(original))}
                    color={Color.warning100}
                    tooltip={t(`importExport.aggregatorTool.table.download.${AggregationFileType.Csv}.tooltip`)}
                >
                    <SvgIcon icon={csvIcon} />
                </ActionIcon>
                <ActionIcon
                    onClick={() => downloadFile(AggregationFileType.Xls, original.id, createExcelFileName(original))}
                    color={Color.warning100}
                    tooltip={t(`importExport.aggregatorTool.table.download.${AggregationFileType.Xls}.tooltip`)}
                >
                    <SvgIcon icon={xlsIcon} />
                </ActionIcon>
            </TableAction>
        );

export const excludeAggregationToDate =
    (givenDate: Date) =>
    (date: Date): boolean => {
        const dateToCompare = startOfDay(date);
        const startOfGivenDate = startOfDay(givenDate);

        return isBefore(dateToCompare, startOfGivenDate) || isDateTodayOrAfter(dateToCompare);
    };

export const overrideAggregatorToolTableRowProps = (row: Row<AggregationDto>): Record<string, unknown> => {
    if (row.original.isCorrective) {
        return { $backgroundColor: Color.primary50 };
    }

    return EMPTY_OBJECT;
};
