import type { FC } from "react";

import { createGlobalErrorMessage, useBulkApproveEmergencyOrdersMutation } from "src/api";
import { NotificationTypes, useNotifications } from "src/notifications";
import { useTranslation } from "src/translations";
import type { RequestErrorType } from "src/types";
import { useAppDispatch } from "src/utils";
import { DashboardCard } from "../DashboardCard";
import { tableModule } from "./table";
import { createActionsForSelection } from "./utils";

export const EmergencyOrdersWidget: FC = () => {
    const { t } = useTranslation();

    const dispatch = useAppDispatch();
    const notifications = useNotifications();

    const [bulkApprove] = useBulkApproveEmergencyOrdersMutation();

    const onBulkApprove = (value: { emergencyOrderProductPackageIds: number[] }) => {
        bulkApprove(value)
            .unwrap()
            .then(() => {
                dispatch(tableModule.resetTableSelectionAction);
                notifications.showNotification({
                    title: t("common.form.successTitle"),
                    message: t("dashboard.emergencyOrders.successApproveBulkMessage"),
                    type: NotificationTypes.success,
                });
            })
            .catch((e) => {
                const error = e as RequestErrorType;
                const errorMessage = createGlobalErrorMessage(error, t("dashboard.emergencyOrders.errorApproveBulkMessage"));
                notifications.showNotification({
                    title: t("common.form.errorTitle"),
                    message: errorMessage,
                    type: NotificationTypes.error,
                });
            });
    };

    return (
        <DashboardCard>
            <tableModule.Container actionsForSelection={createActionsForSelection(t, onBulkApprove)} isSelectable canSelectAll={false} />
        </DashboardCard>
    );
};
