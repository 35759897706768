export const EDIT_TERMS_OF_TRADE_MODAL = "EDIT_TERMS_OF_TRADE_MODAL";
export const ACTION_CONFIRM_MODAL = "ACTION_CONFIRM_MODAL";

export const TERMS_OF_TRADE_MODAL_WIDTH = "420px";

// query params
export const ACN_PARAM = "acn";
export const PAYMENT_DUE_PARAM = "paymentDue";
export const INVOICING_FREQUENCY_PARAM = "invoicingFrequency";
export const ACTIVE_PARAM = "active";
export const START_POINT_PARAM = "startPoint";
export const TYPE = "type";
export const PROPAGATE_SPECIAL_PRICES = "propagateSpecialPrices";
