/**
 * Constant for empty object. Is preferred to usage of {}, because it has stable reference.
 * Stable references are important for Redux and React optimizations.
 */
export const EMPTY_OBJECT = {} as const;

/**
 * Constant for empty array. Is preferred to usage of [], because it has stable reference.
 * Stable references are important for Redux and React optimizations.
 */
export const EMPTY_ARRAY = [] as const;

/**
 * Constant for date format provided by date-fns library
 */
export const DATE_FORMAT = "dd/MM/yyyy";
export const DATE_FORMAT_FOR_BE = "yyyy-MM-dd";
export const DATE_TIME_FORMAT = "dd/MM/yyyy HH:mm:ss";

export const CZK_CURRENCY = "Kč";

export const TRUE_STRING = "true";
export const FALSE_STRING = "false";

export const DEFAULT_CURRENCY_DECIMAL_SCALE = 2;
export const DEFAULT_PERCENTAGE_DECIMAL_SCALE = 2;
