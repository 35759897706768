import type { FC, ReactNode } from "react";
import styled from "styled-components";

import { BorderBox, Text } from "src/components";
import { Color } from "src/theme";
import { CopyTiersLayoutAreas } from "./CopyTiersLayout";

type CardWithTitleProps = {
    isNewShipTo: boolean;
    title: string;
    isLoading?: boolean;
    shipToSwitch?: ReactNode;
};

export const CardWithTitle: FC<CardWithTitleProps> = ({ children, isNewShipTo, title, isLoading = false, shipToSwitch = null }) => (
    <Wrapper isNewShipTo={isNewShipTo}>
        <CardHeader>
            <Text color={Color.supportNavy500} size="1.8rem" weight={700}>
                {title}
            </Text>
            {shipToSwitch}
        </CardHeader>
        <BorderBox isLoading={isLoading} padding="1rem 2rem" borderColor={isNewShipTo ? Color.accent500 : Color.neutral200}>
            {children}
        </BorderBox>
    </Wrapper>
);

const Wrapper = styled.div<Pick<CardWithTitleProps, "isNewShipTo">>`
    display: grid;
    row-gap: 1rem;
    grid-area: ${({ isNewShipTo }) => (isNewShipTo ? CopyTiersLayoutAreas.new : CopyTiersLayoutAreas.previous)};
`;

const CardHeader = styled.div`
    display: flex;
    justify-content: space-between;
`;
