// primary colors are 500
export enum Color {
    white = "#FFFFFF",

    neutral50 = "#F8FAFC",
    neutral100 = "#F1F5F9",
    neutral200 = "#E2E8F0",
    neutral300 = "#CBD5E1",
    neutral400 = "#94A3BB",
    neutral500 = "#64748B",
    neutral600 = "#475569",
    neutral700 = "#334155",
    neutral800 = "#1E293B",
    neutral900 = "#0F172A",

    primary50 = "#FFF8FC",
    primary100 = "#FEEFF4",
    primary200 = "#EC99C5",
    primary300 = "#E366A9",
    primary400 = "#D9338C",
    primary500 = "#D0006F",
    primary600 = "#A60059",
    primary700 = "#7D0043",
    primary800 = "#53002C",
    primary900 = "#2A0016",

    accent50 = "#F9FBE6",
    accent100 = "#F3F7CC",
    accent200 = "#E7EF99",
    accent300 = "#FEFFF3",
    accent400 = "#D0DE33",
    accent500 = "#C4D600",
    accent600 = "#9DAB00",
    accent700 = "#768000",
    accent800 = "#4E5600",
    accent900 = "#272B00",

    supportNavy50 = "#E6EBF0",
    supportNavy100 = "#CCD7E0",
    supportNavy200 = "#99AFC1",
    supportNavy300 = "#6688A3",
    supportNavy400 = "#336084",
    supportNavy500 = "#003865",
    supportNavy600 = "#002D51",
    supportNavy700 = "#00223D",
    supportNavy800 = "#001628",
    supportNavy900 = "#000B14",

    supportGraphite50 = "#ECECEC",
    supportGraphite100 = "#D9DADA",
    supportGraphite200 = "#B2B4B4",
    supportGraphite300 = "#8C8F8F",
    supportGraphite400 = "#656969",
    supportGraphite500 = "#3F4444",
    supportGraphite600 = "#323636",
    supportGraphite700 = "#262929",
    supportGraphite800 = "#191B1B",
    supportGraphite900 = "#0D0E0E",

    success50 = "#F6FDF5",
    success100 = "#DBF0D9",
    success200 = "#B7E1B4",
    success300 = "#93D38E",
    success400 = "#6FC469",
    success500 = "#4BB543",
    success600 = "#3C9136",
    success700 = "#2D6D28",
    success800 = "#1E481B",
    success900 = "#0F240D",

    warning50 = "#FDFBE6",
    warning100 = "#FCF6CC",
    warning200 = "#F8ED9A",
    warning300 = "#F5E467",
    warning400 = "#F1DB35",
    warning500 = "#EED202",
    warning600 = "#BEA802",
    warning700 = "#8F7E01",
    warning800 = "#5F5401",
    warning900 = "#302A00",

    error50 = "#FDECEB",
    error100 = "#F9E1E0",
    error200 = "#F8B4AF",
    error300 = "#F48E87",
    error400 = "#F1695F",
    error500 = "#ED4337",
    error600 = "#BE362C",
    error700 = "#8E2821",
    error800 = "#5F1B16",
    error900 = "#180705",
}
