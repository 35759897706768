import type { TFunction } from "i18next";
import type { ReactNode } from "react";
import type { Row } from "react-table";

import { ActionIcon, Icon, TableAction, Text } from "src/components";
import { ExpandRowType, getParentAndChildrenFromTablePage, ICON_TABLE_SIZE } from "src/table";
import { Color } from "src/theme";
import type {
    ExpandTableProductWithSupplyLimitsType,
    ProductBrandWithSupplyLimitsDto,
    SupplyLimitWithPackageAndMarginDto,
} from "src/types";
import { isEmpty } from "src/utils";

const SUPPLY_LIMIT_WITH_PRODUCT_PACKAGE_DEPTH_LEVEL = 1;

export const createActionColumn =
    (t: TFunction, openDrawer: (value: SupplyLimitWithPackageAndMarginDto) => void) =>
    ({ depth, id }: Row<ExpandTableProductWithSupplyLimitsType>, page: Array<Row<ExpandTableProductWithSupplyLimitsType>>): ReactNode => {
        const productWithSupplyLimits = getParentAndChildrenFromTablePage<
            ExpandTableProductWithSupplyLimitsType,
            ProductBrandWithSupplyLimitsDto,
            SupplyLimitWithPackageAndMarginDto
        >(page, id);
        const supplyLimitWithProductPackage = productWithSupplyLimits?.child;
        return depth === SUPPLY_LIMIT_WITH_PRODUCT_PACKAGE_DEPTH_LEVEL && !isEmpty(supplyLimitWithProductPackage) ? (
            <TableAction>
                <ActionIcon
                    tooltip={t("shipToDetail.supplyLimit.editTooltip")}
                    onClick={() => openDrawer(supplyLimitWithProductPackage)}
                    color={Color.accent100}
                >
                    <Icon.Edit size={ICON_TABLE_SIZE} color={Color.primary600} filled />
                </ActionIcon>
            </TableAction>
        ) : null;
    };

export const createExpandRow = (row: Row<ExpandTableProductWithSupplyLimitsType>): ExpandRowType => ({
    accessor: "productPackageName",
    colspan: 5,
    component: (
        <Text color={Color.primary600} weight={700} uppercase whiteSpace="nowrap">
            {row.original.name}
        </Text>
    ),
});
