import { FC, ReactNode, useState } from "react";

import { ZIndex } from "src/theme";
import { Trans, useTranslation } from "src/translations";
import { Button } from "../Button";
import { ModalButtonLayout } from "../layouts";
import { MODAL_OVERLAY_DEFAULT_OPACITY } from "./constants";
import { Modal } from "./Modal";
import { StyledConfirmContent } from "./StyledConfirmContent";

export type OpenConfirmParams = {
    skipConfirm?: boolean;
    transValues?: Record<string, unknown>;
};

export type ConfirmProps = {
    onConfirm: () => void;
    onClose?: () => void;
    children?: (open: (params?: OpenConfirmParams) => void) => JSX.Element;
    title: string;
    maxWidth?: string;
    content?: ReactNode;
    conditionalConfirmation?: () => boolean;
    setIsTransparentForModal?: (value: boolean) => void;
    contentTransKey?: string;
    confirmButtonTransKey?: string;
};

export const Confirm: FC<ConfirmProps> = ({
    onConfirm,
    onClose,
    title,
    content,
    children,
    maxWidth,
    conditionalConfirmation,
    setIsTransparentForModal,
    contentTransKey,
    confirmButtonTransKey,
}) => {
    if (content && contentTransKey) {
        throw new Error("You have to specify content or contentTransKey prop, not both!");
    }
    const { t } = useTranslation();
    const [opened, setOpened] = useState(false);
    const [transValues, setTransValues] = useState({});

    const open = (params?: OpenConfirmParams) => {
        if (params?.skipConfirm === true || (conditionalConfirmation && !conditionalConfirmation())) {
            onConfirm();
        } else {
            setIsTransparentForModal?.(true);
            if (params?.transValues) {
                setTransValues(params.transValues);
            }
            setOpened(true);
        }
    };

    const close = () => {
        onClose?.();
        setIsTransparentForModal?.(false);
        setOpened(false);
    };

    const confirm = () => {
        onConfirm();
        close();
    };

    return (
        <>
            <Modal
                opened={opened}
                onClose={close}
                title={title}
                maxWidth={maxWidth}
                overlayOpacity={setIsTransparentForModal === undefined ? MODAL_OVERLAY_DEFAULT_OPACITY : 0}
                zIndex={ZIndex.confirmation}
            >
                <>
                    {(content || contentTransKey) && (
                        <StyledConfirmContent>{content || <Trans i18nKey={contentTransKey} values={transValues} />}</StyledConfirmContent>
                    )}
                    <ModalButtonLayout>
                        <Button type="button" onClick={confirm}>
                            {t(confirmButtonTransKey || "common.confirm")}
                        </Button>
                        <Button type="button" onClick={close} variant="outline">
                            {t("common.cancel")}
                        </Button>
                    </ModalButtonLayout>
                </>
            </Modal>
            {children?.(open)}
        </>
    );
};
