import type { FC } from "react";
import styled, { css } from "styled-components";

import { Color } from "src/theme";
import { isMac } from "src/utils";
import { Tooltip } from "../Tooltip";

type ResetFilterButtonProps = {
    onClick: () => void;
    hidden?: boolean;
};

export const ResetFilterButton: FC<ResetFilterButtonProps> = ({ hidden, onClick, children }) => (
    <Tooltip openDelay={500} label={`${isMac ? "⌘" : "Ctrl"} + Backspace`} backgroundColor={Color.neutral200} color={Color.supportNavy500}>
        <StyledClearFilterButton onClick={onClick} $hidden={hidden}>
            {children}
        </StyledClearFilterButton>
    </Tooltip>
);

const StyledClearFilterButton = styled.button<{ $hidden?: boolean }>`
    padding: 0;
    margin: 0;
    color: ${({ theme }) => theme.color.neutral50};
    opacity: 1;
    transition: opacity 200ms ease;
    ${({ $hidden }) =>
        $hidden &&
        css`
            pointer-events: none;
            opacity: 0;
        `}
    font-weight: 400;
    font-size: 1.4rem;
    border: none;
    background-color: transparent;
    text-decoration: none;
    line-height: 1.3;

    &:hover {
        text-decoration-color: #a60059;
        text-decoration: underline;
        cursor: pointer;
    }
`;
