import { createModuleActionCreators } from "src/utils";
import { NAME } from "./constants";
import type { SaveFiltersActionPayload, TableId } from "./types";

const { createPayloadAction } = createModuleActionCreators(NAME);

export const resetSelection = createPayloadAction("RESET_SELECTION")<string>();
export const saveFilters = createPayloadAction("SAVE_FILTERS")<SaveFiltersActionPayload>();

export const createResetSelectionAction = (tableId: TableId) => resetSelection(tableId);
