import type { TFunction } from "react-i18next";

import { Button, Confirm, Grid, Icon, TableActionForSelection } from "src/components";
import { routes } from "src/routes";
import { IconSizes } from "src/theme";
import { LinkToPublicEmergencyForm } from "../../components";
import { RefuseForm } from "./RefuseForm";

export const createActionsForSelection =
    (t: TFunction, bulkApprove: (value: { emergencyOrderProductPackageIds: number[] }) => void) =>
    (_: boolean, selectedRowIds: Record<number, boolean>) => {
        const selectedIds = Object.entries(selectedRowIds).map(([key]) => Number(key));
        return (
            <TableActionForSelection>
                <LinkToPublicEmergencyForm target="_blank" href={routes.emergencyOrders.path}>
                    <Grid gridAutoFlow="column" gap="0.5rem">
                        {t("dashboard.emergencyOrders.create")}
                        <Icon.Open size={IconSizes.s} filled />
                    </Grid>
                </LinkToPublicEmergencyForm>
                <Confirm
                    title={t("dashboard.emergencyOrders.approveConfirm.title")}
                    content={t("dashboard.emergencyOrders.approveConfirm.content")}
                    confirmButtonTransKey="dashboard.emergencyOrders.approveSelected"
                    onConfirm={() => bulkApprove({ emergencyOrderProductPackageIds: selectedIds })}
                >
                    {(open) => (
                        <Button disabled={selectedIds.length === 0} type="button" onClick={open}>
                            {t("dashboard.emergencyOrders.approveSelected")}
                        </Button>
                    )}
                </Confirm>
                <RefuseForm selectedIds={selectedIds} />
            </TableActionForSelection>
        );
    };
