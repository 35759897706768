import type { FC } from "react";
import { useState } from "react";

import { createGlobalErrorMessage, useUpdateCustomerConfirmMutation } from "src/api";
import { Modal } from "src/components";
import { useModal } from "src/modal";
import { NotificationTypes, useNotifications } from "src/notifications";
import { useTranslation } from "src/translations";
import type { RequestErrorType } from "src/types";
import { useAppDispatch } from "src/utils";
import { defaultCustomersTableConfig } from "../../constants";
import { createEmptyRowForRequestTables } from "../../utils";
import { Changes } from "./Changes";
import { SEE_CHANGES_MODAL_ID, SEE_CHANGES_MODAL_WIDTH } from "./constants";
import { tableModule } from "./table";
import { createActionColumn, createActionsForSelection } from "./utils";

export const Updated: FC = () => {
    const [selectedCustomerId, setSelectedCustomerId] = useState<number | undefined>(undefined);
    const { t } = useTranslation();
    const [isModalOpened, openModal, closeModal] = useModal(SEE_CHANGES_MODAL_ID);
    const dispatch = useAppDispatch();
    const notifications = useNotifications();
    const [updateCustomersConfirm] = useUpdateCustomerConfirmMutation();

    const onCloseModal = () => {
        closeModal();
        setSelectedCustomerId(undefined);
    };

    const onSubmitUpdate = (customerIds: Array<number>) => {
        updateCustomersConfirm(customerIds)
            .unwrap()
            .then(() => {
                closeModal();
                setSelectedCustomerId(undefined);
                dispatch(tableModule.resetTableSelectionAction);
                notifications.showNotification({
                    title: t("common.form.successTitle"),
                    message: t("customers.updated.updateSuccess"),
                    loading: false,
                    type: NotificationTypes.success,
                });
            })
            .catch((e) => {
                const error = e as RequestErrorType;
                const errorMessage = createGlobalErrorMessage(error, t("customers.updated.updateError"));
                notifications.showNotification({
                    title: t("common.form.errorTitle"),
                    message: errorMessage,
                    loading: false,
                    type: NotificationTypes.error,
                });
            });
    };

    return (
        <>
            <Modal
                title={t("customers.updated.changes.title")}
                opened={isModalOpened}
                onClose={onCloseModal}
                maxWidth={SEE_CHANGES_MODAL_WIDTH}
            >
                <Changes selectedCustomerId={selectedCustomerId} onClose={onCloseModal} onConfirm={onSubmitUpdate} />
            </Modal>
            <tableModule.Container
                isSelectable
                canSelectAll={false}
                actions={createActionColumn(t, setSelectedCustomerId, openModal)}
                defaultTableConfig={defaultCustomersTableConfig}
                actionsForSelection={createActionsForSelection(t, onSubmitUpdate)}
                emptyRow={createEmptyRowForRequestTables(t("customers.updated.emptyRow"), t("customers.updated.emptyRowWithFilters"))}
            />
        </>
    );
};
