import type { TFunction } from "i18next";

import { Text } from "src/components";
import { Color } from "src/theme";
import { isEmpty } from "src/utils";

const czechCurrencyFormatter = new Intl.NumberFormat("cs");

const DECIMAL_SEPARATOR = ",";

export const createCurrencyInputProps = (t: TFunction) => ({
    hideControls: true,
    decimalSeparator: DECIMAL_SEPARATOR,
    precision: 2,
    parser: (value: string | undefined) => (isEmpty(value) ? undefined : value.replace(/\s/g, "")),
    formatter: (value: string | undefined) => {
        if (isEmpty(value)) {
            return "";
        }
        if (!Number.isNaN(parseFloat(value))) {
            const [wholePart, fractionalPart] = value.split(DECIMAL_SEPARATOR);
            return fractionalPart === undefined
                ? czechCurrencyFormatter.format(parseFloat(wholePart))
                : `${czechCurrencyFormatter.format(parseFloat(wholePart))},${fractionalPart || ""}`;
        }
        // If last additional to value will make number invalid, remove last char
        return value.slice(0, -1);
    },
    rightSection: (
        <Text size="1.4rem" color={Color.supportNavy200}>
            {t("common.czechCrown")}
        </Text>
    ),
});

export const percentageInputProps = {
    hideControls: true,
    min: 0,
    max: 99.99,
    decimalSeparator: DECIMAL_SEPARATOR,
    precision: 2,
    rightSection: (
        <Text size="1.4rem" color={Color.supportNavy200}>
            %
        </Text>
    ),
};
