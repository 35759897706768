import { Dispatch, FC, SetStateAction, useCallback } from "react";

import { useTranslation } from "src/translations";
import type { ShipToForCopySupplyLimitsDto } from "src/types";
import { isArray } from "src/utils";
import { EmptyShipToCard, ShipToCard, ShipToSwitch } from "../components";

type PreviousShipToProps = {
    isLoading: boolean;
    isError: boolean;
    previousShipTos?: ShipToForCopySupplyLimitsDto[];
    currentShipToIndex: number;
    setCurrentShipToIndexIndex: Dispatch<SetStateAction<number>>;
};

export const PreviousShipTo: FC<PreviousShipToProps> = ({
    previousShipTos,
    isLoading,
    isError,
    currentShipToIndex,
    setCurrentShipToIndexIndex,
}) => {
    const { t } = useTranslation();
    const cardTitle = t("copyTiers.previousShipTo");

    const numberOfShipTos = previousShipTos?.length || 0;
    const hasMultipleShipTos = Array.isArray(previousShipTos) && numberOfShipTos > 1;

    const onPreviousShipTo = useCallback(() => {
        if (currentShipToIndex > 0) {
            setCurrentShipToIndexIndex(currentShipToIndex - 1);
        }
    }, [currentShipToIndex, setCurrentShipToIndexIndex]);

    const onNextShipTo = useCallback(() => {
        if (currentShipToIndex + 1 < numberOfShipTos) {
            setCurrentShipToIndexIndex(currentShipToIndex + 1);
        }
    }, [currentShipToIndex, numberOfShipTos, setCurrentShipToIndexIndex]);

    if ((isArray(previousShipTos) && numberOfShipTos === 0) || isError) {
        return <EmptyShipToCard isNewShipTo={false} title={cardTitle} />;
    }

    return (
        <ShipToCard
            shipTo={previousShipTos ? previousShipTos[currentShipToIndex] : undefined}
            isLoading={isLoading}
            isNewShipTo={false}
            title={cardTitle}
            shipToSwitch={
                hasMultipleShipTos && (
                    <ShipToSwitch
                        currentShipToIndex={currentShipToIndex}
                        maxShipTos={numberOfShipTos}
                        onPreviousShipTo={onPreviousShipTo}
                        onNextShipTo={onNextShipTo}
                    />
                )
            }
        />
    );
};
