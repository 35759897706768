import { Color } from "src/theme";
import { NotificationTypes } from "./enums";

export const getNotificationColorForType = (type?: NotificationTypes): Color => {
    switch (type) {
        case NotificationTypes.error:
            return Color.error500;
        case NotificationTypes.info:
            return Color.accent500;
        case NotificationTypes.success:
            return Color.success500;
        case NotificationTypes.default:
        default:
            return Color.primary600;
    }
};
