import type { FC } from "react";
import styled from "styled-components";

import { useGetChangesToUpdateQuery } from "src/api";
import arrowIcon from "src/assets/icons/changes_arrow.svg";
import { Button, Grid, LoadingOverlay, ModalButtonLayout, SvgIcon, Text } from "src/components";
import { Color } from "src/theme";
import { useTranslation } from "src/translations";
import { isEmpty } from "src/utils";

type ChangesProps = {
    onClose: () => void;
    onConfirm: (customerIds: Array<number>) => void;
    selectedCustomerId: number | undefined;
};

export const Changes: FC<ChangesProps> = ({ onClose, onConfirm, selectedCustomerId }) => {
    const { data: changes, isLoading } = useGetChangesToUpdateQuery(selectedCustomerId?.toString() || "", { skip: !selectedCustomerId });
    const { t } = useTranslation();
    return (
        <>
            <LoadingOverlay loading={isLoading} />
            <Grid gridAutoFlow="row" gap="2rem">
                <Grid gridTemplateColumns="1fr 2rem 1fr" columnGap="4rem">
                    <Text color={Color.primary600} size="1.6rem" weight={700}>
                        {t("customers.updated.changes.previous")}
                    </Text>
                    <span />
                    <Text color={Color.primary600} size="1.6rem" weight={700}>
                        {t("customers.updated.changes.new")}
                    </Text>
                </Grid>
                {changes &&
                    Object.entries(changes).map(([key, value]) => (
                        <Grid key={key} gridTemplateColumns="1fr 2rem 1fr" columnGap="4rem" rowGap="0.5rem">
                            <IndentedText color={Color.supportNavy500} size="1.4rem">
                                {key}
                            </IndentedText>
                            <span />
                            <IndentedText color={Color.supportNavy500} size="1.4rem">
                                {key}
                            </IndentedText>
                            <IndentedText color={Color.supportNavy300} size="1.4rem">
                                {value.oldValue || null}
                            </IndentedText>
                            <SvgIcon icon={arrowIcon} />
                            <IndentedText color={Color.supportNavy300} size="1.4rem" weight={700}>
                                {value.newValue || null}
                            </IndentedText>
                        </Grid>
                    ))}
            </Grid>
            <ModalButtonLayout>
                {!isEmpty(selectedCustomerId) && (
                    <Button type="button" onClick={() => onConfirm([selectedCustomerId])}>
                        {t("common.confirm")}
                    </Button>
                )}
                <Button type="button" onClick={onClose} variant="outline">
                    {t("common.cancel")}
                </Button>
            </ModalButtonLayout>
        </>
    );
};

const IndentedText = styled(Text)`
    margin-left: 0.8rem;
`;
