import { Tooltip as TooltipMantine, TooltipProps as TooltipMantineProps } from "@mantine/core";
import type { FC } from "react";

import type { Color } from "src/theme";
import { ZIndex } from "src/theme";

type TooltipProps = Readonly<{
    color?: Color;
    backgroundColor?: Color;
}> &
    TooltipMantineProps;

const stylesApiTooltip = {
    tooltip: {
        boxShadow: "0 4px 10px rgba(0, 0, 0, 0.25)",
        padding: "0.8rem 1.6rem",
        borderRadius: "16px",
        opacity: "1 !important",
        wordBreak: "break-word",
    },
};

export const Tooltip: FC<TooltipProps> = ({ color, backgroundColor, children, ...props }) => (
    <TooltipMantine
        // @ts-ignore There is something wrong with wordBreak value
        styles={{
            ...stylesApiTooltip,
            tooltip: { backgroundColor, color, ...stylesApiTooltip.tooltip },
            arrow: { backgroundColor },
        }}
        zIndex={ZIndex.tooltip}
        {...props}
    >
        {children}
    </TooltipMantine>
);
