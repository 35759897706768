import styled from "styled-components";

export const FooterLink = styled.a`
    text-decoration: none;
    color: ${({ theme }) => theme.color.white};
    cursor: pointer;

    &:hover {
        text-decoration: underline;
    }
`;
