import type { FC } from "react";
import { useMemo } from "react";

import { useApproveShipToMutation, useGetSpecialPricesQuery } from "src/api";
import shipToIcon from "src/assets/icons/ship-to.svg";
import {
    Button,
    Checkbox,
    CheckboxProps,
    Grid,
    ModalButtonLayout,
    SectionFormLayout,
    Select,
    SelectProps,
    SvgIcon,
    Text,
} from "src/components";
import { composeValidators, Form, InputField, required } from "src/form";
import { Color } from "src/theme";
import { useTranslation } from "src/translations";
import { ApproveShipToDto, CustomerDto, CustomerStatusEnum, CustomerTypeEnum, InvoicingFrequencyEnum, PaymentDueEnum } from "src/types";
import { enumToLocalizedOptions, zipFormatter } from "src/utils";
import { FormCustomerInfo, PropagateSpecialPriceBlock } from "../components";
import { ApproveStartPointEnum, DefaultSupplyLimitEnum } from "./constants";
import { CopyCustomerWidget } from "./CopyCustomerWidget";

type ApproveShipToFormProps = {
    shipTo: CustomerDto;
    onClose: () => void;
    startPoint: ApproveStartPointEnum;
};

export const ApproveShipToForm: FC<ApproveShipToFormProps> = ({ shipTo, onClose, startPoint }) => {
    const { t } = useTranslation();
    const [approveShipTo] = useApproveShipToMutation();
    const isPayerInactive = shipTo.payer?.status === CustomerStatusEnum.Inactive;
    const { data: payerSpecialPrices } = useGetSpecialPricesQuery(
        { pageIndex: 0, pageSize: 1, filters: { customerId: shipTo.payer?.id } },
        { skip: !shipTo.payer?.id },
    );
    const payerHasSpecialPrice = payerSpecialPrices && payerSpecialPrices.content.length > 0;

    const initialValues = useMemo(
        () => ({
            id: shipTo.id,
            paymentDue: shipTo?.payer?.paymentDue || PaymentDueEnum._30,
            invoicingFrequency: shipTo?.payer?.invoicingFrequency || InvoicingFrequencyEnum.Weekly,
            defaultSupplyLimit: shipTo.branch?.isHospital ? DefaultSupplyLimitEnum.Unlimited : DefaultSupplyLimitEnum.Default,
            active: !isPayerInactive,
            propagateSpecialPrices: false,
            type: shipTo.type ?? CustomerTypeEnum.NotSpecified,
        }),
        [isPayerInactive, shipTo.branch?.isHospital, shipTo.id, shipTo?.payer?.invoicingFrequency, shipTo?.payer?.paymentDue, shipTo.type],
    );

    return (
        <>
            <FormCustomerInfo
                name={shipTo.name}
                street={shipTo.street}
                city={`${zipFormatter(shipTo.zip)} ${shipTo.city}`}
                icon={<SvgIcon icon={shipToIcon} />}
            />
            {/* Please be careful with copying prop enableReinitialize to other forms, it has impact to performance,
             it has to be here, because form will stay mounted if modal is closed */}
            <Form<ApproveShipToDto>
                enableReinitialize
                initialValues={initialValues}
                onSubmit={approveShipTo}
                onSuccess={onClose}
                loadingMessage={t("customers.approveForm.loadingShipTo")}
                successMessage={(values) =>
                    values.active
                        ? t("customers.approveForm.successShipToActive", { name: shipTo.name })
                        : t("customers.approveForm.successShipToInactive", { name: shipTo.name })
                }
            >
                {({ values }) => (
                    <>
                        <CopyCustomerWidget
                            customer={shipTo}
                            paymentDue={values.paymentDue}
                            invoicingFrequency={values.invoicingFrequency}
                            type={values.type}
                            active={values.active}
                            propagateSpecialPrices={values.propagateSpecialPrices}
                            startPoint={startPoint}
                        />
                        <SectionFormLayout
                            title={
                                <Text size="1.6rem" weight={700} color={Color.primary600}>
                                    {t("customers.termsOfTrade.title")}
                                </Text>
                            }
                        >
                            <InputField<SelectProps["value"], SelectProps>
                                name="paymentDue"
                                input={Select}
                                validate={composeValidators(required)}
                                inputProps={{
                                    label: t("customers.termsOfTrade.paymentDue.label"),
                                    placeholder: t("customers.termsOfTrade.paymentDue.placeholder"),
                                    maxWidth: "180px",
                                    options: enumToLocalizedOptions("paymentDue", PaymentDueEnum),
                                    clearable: false,
                                    rightSection: (
                                        <Text size="1.4rem" color={Color.supportNavy200}>
                                            {t("common.days")}
                                        </Text>
                                    ),
                                }}
                            />
                            <InputField<SelectProps["value"], SelectProps>
                                name="invoicingFrequency"
                                input={Select}
                                validate={composeValidators(required)}
                                inputProps={{
                                    label: t("customers.termsOfTrade.invoicingFrequency.label"),
                                    placeholder: t("customers.termsOfTrade.invoicingFrequency.placeholder"),
                                    options: enumToLocalizedOptions("invoicingFrequency", InvoicingFrequencyEnum),
                                    maxWidth: "180px",
                                    clearable: false,
                                }}
                            />
                            <InputField<SelectProps["value"], SelectProps>
                                name="type"
                                input={Select}
                                inputProps={{
                                    label: t("customers.termsOfTrade.type.label"),
                                    placeholder: t("customers.termsOfTrade.type.placeholder"),
                                    options: enumToLocalizedOptions("CustomerTypeEnum", CustomerTypeEnum),
                                    maxWidth: "315px",
                                    description: t("customers.termsOfTrade.type.description"),
                                    clearable: false,
                                }}
                            />
                            <InputField<SelectProps["value"], SelectProps>
                                name="defaultSupplyLimit"
                                input={Select}
                                validate={composeValidators(required)}
                                inputProps={{
                                    label: t("customers.approveForm.defaultSupplyLimit.label"),
                                    description: shipTo.branch?.isHospital
                                        ? t("customers.approveForm.defaultSupplyLimit.hospitalDescription")
                                        : t("customers.approveForm.defaultSupplyLimit.defaultDescription"),
                                    options: enumToLocalizedOptions("defaultSupplyLimit", DefaultSupplyLimitEnum),
                                    maxWidth: "315px",
                                    clearable: false,
                                    disabled: true,
                                }}
                            />
                            <Grid gridAutoFlow="row" gap="2.2rem">
                                {payerHasSpecialPrice && (
                                    <PropagateSpecialPriceBlock>
                                        <InputField<CheckboxProps["value"], CheckboxProps>
                                            name="propagateSpecialPrices"
                                            input={Checkbox}
                                            type="checkbox"
                                            inputProps={{
                                                label: t("customers.termsOfTrade.propagateSpecialPrices.label"),
                                                disabled: !payerHasSpecialPrice,
                                            }}
                                        />
                                    </PropagateSpecialPriceBlock>
                                )}
                                <InputField<CheckboxProps["value"], CheckboxProps>
                                    name="active"
                                    input={Checkbox}
                                    type="checkbox"
                                    inputProps={{
                                        label: t("customers.termsOfTrade.active.label"),
                                        description: t("customers.termsOfTrade.active.description"),
                                        disabled: isPayerInactive,
                                        tooltip: isPayerInactive ? t("customers.termsOfTrade.active.disabledTooltip") : undefined,
                                        tooltipBackgroundColor: Color.neutral400,
                                    }}
                                />
                            </Grid>
                        </SectionFormLayout>
                        <ModalButtonLayout>
                            <Button type="submit">{t("customers.approveForm.submit")}</Button>
                            <Button type="button" onClick={onClose} variant="outline">
                                {t("common.cancel")}
                            </Button>
                        </ModalButtonLayout>
                    </>
                )}
            </Form>
        </>
    );
};
