import type { FC } from "react";
import styled from "styled-components";

import type { Color } from "src/theme";
import { LoadingOverlay } from "../Loading";

type BorderBoxProps = {
    isLoading?: boolean;
    padding?: string;
    fullWidth?: boolean;
    borderColor?: Color | "transparent";
};

const BorderBox: FC<BorderBoxProps> = ({
    children,
    isLoading = false,
    padding = "0px",
    fullWidth = false,
    borderColor = "transparent",
}) => (
    <StyledBox padding={padding} fullWidth={fullWidth} borderColor={borderColor}>
        <LoadingOverlay loading={isLoading} />
        {children}
    </StyledBox>
);

const StyledBox = styled.div<BorderBoxProps>`
    position: relative; // to contain loading overlay
    border: 1px solid ${({ borderColor }) => borderColor || "transparent"};
    border-radius: ${({ theme }) => theme.radius.default};
    box-shadow: ${({ theme }) => theme.boxShadow.table};
    padding: ${({ padding }) => padding};
    width: ${({ fullWidth }) => fullWidth && "100%"};
`;

export default BorderBox;
