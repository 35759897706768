import { Route, Routes } from "react-router-dom";

import { routes } from "src/routes";
import { copyTiers } from "./customers/copyTiers";
import { customerDetail } from "./customers/detail";
import { customers } from "./customers/list";
import { dashboard } from "./dashboard";
import { importExport } from "./importExport";
import { productList } from "./products/list";
import { productPrices } from "./products/prices";
import { bulkEdit as supplyLimitBulkEdit } from "./supplyLimits/bulkEdit";
import { tiers } from "./supplyLimits/tiers";

export const AppRouter = () => (
    <Routes>
        <Route path={routes.dashboard.path} element={<dashboard.Container />} />
        <Route path={routes.products.path} element={<productList.Container />} />
        <Route path={routes.prices.path} element={<productPrices.Container />} />
        <Route path={routes.supplyLimits.path} element={<tiers.Container />} />
        <Route path={routes.supplyLimitsDetail.path} element={<tiers.Container />} />
        <Route path={routes.supplyLimitsBulkEdit.path} element={<supplyLimitBulkEdit.Container />} />
        <Route path={routes.customers.path} element={<customers.Container />} />
        <Route path={routes.customerDetail.path} element={<customerDetail.Container />} />
        <Route path={routes.copyTiers.path} element={<copyTiers.Container />} />
        <Route path={routes.importExport.path} element={<importExport.Container />} />
        <Route path="*" element={<h1>404</h1>} />
    </Routes>
);
