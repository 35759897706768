import type { FC } from "react";
import { useNavigate, useSearchParams } from "react-router-dom";

import { useGetCustomerCountQuery } from "src/api";
import { Icon, TabPanel, Tabs } from "src/components";
import { PageLayout } from "src/layout";
import { createSearchParamsAsString, CustomersTab, routes, TAB_PARAM } from "src/routes";
import { useTranslation } from "src/translations";
import { ActionRequestEnum } from "src/types";
import { createIsEnum, getCustomerCountByActionRequest } from "src/utils";
import { All, Blocked, Deactivated, New, Unblocked, Updated } from "./containers";

const isCustomersTabEnum = createIsEnum(CustomersTab);

export const Customers: FC = () => {
    const { t } = useTranslation();
    const navigate = useNavigate();
    const [searchParams] = useSearchParams();
    const { data: customerCount } = useGetCustomerCountQuery(undefined, { pollingInterval: 20000 });

    const activeTabFromSearchParam = searchParams.get(TAB_PARAM);

    const activeTab =
        activeTabFromSearchParam && isCustomersTabEnum(activeTabFromSearchParam) ? activeTabFromSearchParam : CustomersTab.all;

    return (
        <PageLayout
            breadcrumbs={[
                {
                    name: t("customers.name"),
                    route: routes.customers.path,
                    icon: Icon.People,
                },
            ]}
        >
            <Tabs
                activeTab={activeTab}
                onTabChange={(tabValue: CustomersTab) => {
                    navigate(
                        `${routes.customers.path}?${createSearchParamsAsString({
                            [TAB_PARAM]: tabValue,
                        })}`,
                    );
                }}
                tabs={[
                    {
                        tabValue: CustomersTab.all,
                        label: t("customers.tabs.all"),
                    },
                    {
                        tabValue: CustomersTab.new,
                        label: t("customers.tabs.new", {
                            count: getCustomerCountByActionRequest(ActionRequestEnum.Approve, customerCount),
                        }),
                    },
                    {
                        tabValue: CustomersTab.updated,
                        label: t("customers.tabs.updated", {
                            count: getCustomerCountByActionRequest(ActionRequestEnum.Update, customerCount),
                        }),
                    },
                    {
                        tabValue: CustomersTab.blocked,
                        label: t("customers.tabs.blocked", {
                            count: getCustomerCountByActionRequest(ActionRequestEnum.Block, customerCount),
                        }),
                    },
                    {
                        tabValue: CustomersTab.unblocked,
                        label: t("customers.tabs.unblocked", {
                            count: getCustomerCountByActionRequest(ActionRequestEnum.Unblock, customerCount),
                        }),
                    },
                    {
                        tabValue: CustomersTab.deactivated,
                        label: t("customers.tabs.deactivated", {
                            count: getCustomerCountByActionRequest(ActionRequestEnum.Deactivate, customerCount),
                        }),
                    },
                ]}
            >
                <TabPanel value={CustomersTab.all}>
                    <All />
                </TabPanel>
                <TabPanel value={CustomersTab.new}>
                    <New />
                </TabPanel>
                <TabPanel value={CustomersTab.updated}>
                    <Updated />
                </TabPanel>
                <TabPanel value={CustomersTab.blocked}>
                    <Blocked />
                </TabPanel>
                <TabPanel value={CustomersTab.unblocked}>
                    <Unblocked />
                </TabPanel>
                <TabPanel value={CustomersTab.deactivated}>
                    <Deactivated />
                </TabPanel>
            </Tabs>
        </PageLayout>
    );
};
