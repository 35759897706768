import type { FC } from "react";
import { useCallback } from "react";

import { useTranslation } from "src/translations";
import { useAppDispatch } from "src/utils";
import { useBlockCustomers } from "../../../utils";
import { defaultCustomersTableConfig } from "../../constants";
import { createEmptyRowForRequestTables } from "../../utils";
import { tableModule } from "./table";
import { createActionsForSelection, overrideRowProps } from "./utils";

export const Blocked: FC = () => {
    const { t } = useTranslation();
    const dispatch = useAppDispatch();

    const onSuccess = useCallback(() => dispatch(tableModule.resetTableSelectionAction), [dispatch]);
    const block = useBlockCustomers(onSuccess);

    return (
        <tableModule.Container
            isSelectable
            actionsForSelection={createActionsForSelection(t, block)}
            overrideRowProps={overrideRowProps}
            defaultTableConfig={defaultCustomersTableConfig}
            emptyRow={createEmptyRowForRequestTables(t("customers.block.emptyRow"), t("customers.block.emptyRowWithFilters"))}
        />
    );
};
