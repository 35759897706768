import type { TFunction } from "react-i18next";
import type { Cell } from "react-table";

import { useGetSupplyLimitSuggestionsQuery } from "src/api";
import { HighlightCell, TableLink, Tooltip } from "src/components";
import { routes } from "src/routes";
import { createTableModule, TableNames } from "src/table";
import { Color } from "src/theme";
import type { ColumnsType, SupplyLimitSuggestionDto } from "src/types";

const createColumns = (t: TFunction): ColumnsType<SupplyLimitSuggestionDto> => [
    {
        width: 350,
        minWidth: 150,
        accessor: "shipToName" as const,
        Header: t("dashboard.supplyLimitSuggestions.table.name") as string,
        disableFilters: true,
        Cell: ({ row }: Cell<SupplyLimitSuggestionDto>) => (
            <TableLink to={routes.customerDetail.fillPathParams({ customerId: row.original.shipToId.toString() })}>
                {row.original.shipToName}
            </TableLink>
        ),
    },
    {
        minWidth: 100,
        Header: t("dashboard.supplyLimitSuggestions.table.acn") as string,
        accessor: "shipToAcn" as const,
        disableFilters: true,
    },
    {
        width: 250,
        minWidth: 150,
        accessor: "productPackageName" as const,
        Header: t("dashboard.supplyLimitSuggestions.table.product") as string,
        disableFilters: true,
        Cell: ({ row }: Cell<SupplyLimitSuggestionDto>) => (
            <TableLink to={routes.supplyLimitsDetail.fillPathParams({ productPackageId: row.original.productPackageId.toString() })}>
                {row.original.productPackageName}
            </TableLink>
        ),
    },
    {
        minWidth: 100,
        Header: t("dashboard.supplyLimitSuggestions.table.currentTier") as string,
        accessor: "currentTierNumber" as const,
        disableFilters: true,
    },
    {
        minWidth: 100,
        Header: t("dashboard.supplyLimitSuggestions.table.currentMonthlyLimit") as string,
        accessor: "currentTierMonthlyLimit" as const,
        disableFilters: true,
    },
    {
        minWidth: 150,
        Header: t("dashboard.supplyLimitSuggestions.table.averageRequestedAmount") as string,
        accessor: "averageRequestedAmount" as const,
        disableFilters: true,
    },
    {
        minWidth: 100,
        Header: t("dashboard.supplyLimitSuggestions.table.approvedCount") as string,
        accessor: "approvedEmergencyOrdersCount" as const,
        disableFilters: true,
    },
    {
        minWidth: 100,
        Header: t("dashboard.supplyLimitSuggestions.table.suggestedTierNumber") as string,
        accessor: "suggestedTierNumber" as const,
        disableFilters: true,
        Cell: ({ value }: Cell<SupplyLimitSuggestionDto>) => (value ? <HighlightCell highlight>{value}</HighlightCell> : null),
    },
    {
        minWidth: 100,
        Header: t("dashboard.supplyLimitSuggestions.table.suggestedTierMonthlyLimit") as string,
        accessor: "suggestedTierMonthlyLimit" as const,
        disableFilters: true,
        Cell: ({ value }: Cell<SupplyLimitSuggestionDto>) => (value ? <HighlightCell highlight>{value}</HighlightCell> : null),
    },
    {
        minWidth: 100,
        Header: t("dashboard.supplyLimitSuggestions.table.idealMonthlyLimit") as string,
        // This is custom column and we cannot duplicate accessors
        accessor: "id" as const,
        disableFilters: true,
        Cell: ({ row }: Cell<SupplyLimitSuggestionDto>) => (
            <Tooltip
                withinPortal={false}
                color={Color.supportGraphite500}
                backgroundColor={Color.accent50}
                withArrow
                label={t("dashboard.supplyLimitSuggestions.table.idealMonthlyLimitTooltip")}
            >
                <span>{row.original.currentTierMonthlyLimit + row.original.averageRequestedAmount} </span>
            </Tooltip>
        ),
    },
];

export const tableModule = createTableModule<SupplyLimitSuggestionDto>(
    TableNames.supplyLimitSuggestions,
    useGetSupplyLimitSuggestionsQuery,
    createColumns,
);
