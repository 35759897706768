import type { FC } from "react";

import { Button, Text } from "src/components";
import { Color } from "src/theme";
import { useTranslation } from "src/translations";
import { SuccessLayout } from "./components";

type SuccessProps = { onClick: () => void };

export const Success: FC<SuccessProps> = ({ onClick }) => {
    const { t } = useTranslation();

    return (
        <SuccessLayout>
            <Text weight={700} size="2.1rem" color={Color.primary600}>
                {t("publicEmergencyOrders.success.title")}
            </Text>
            <Text lineHeight="1.6" size="1.4rem" color={Color.supportNavy500}>
                {t("publicEmergencyOrders.success.content")}
            </Text>
            <Button onClick={onClick} type="button">
                {t("publicEmergencyOrders.success.next")}
            </Button>
        </SuccessLayout>
    );
};
