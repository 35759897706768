/* eslint-disable react-hooks/exhaustive-deps */
import {
    type NotificationProps,
    cleanNotifications as cleanNotificationsMantine,
    cleanNotificationsQueue as cleanNotificationsQueueMantine,
    hideNotification as hideNotificationMantine,
    showNotification as showNotificationMantine,
    updateNotification as updateNotificationMantine,
} from "@mantine/notifications";
import { nanoid } from "@reduxjs/toolkit";
import { useCallback } from "react";

import { createGlobalErrorMessage } from "src/api";
import { Color } from "src/theme";
import { useTranslation } from "src/translations";
import type { RequestErrorType } from "src/types";
import { DEFAULT_AUTO_CLOSE_DURATION, DEFAULT_ERROR_AUTO_CLOSE_DURATION } from "./constants";
import { NotificationTypes } from "./enums";
import { getNotificationColorForType } from "./utils";

type UseNotificationsParams = {
    id?: NotificationProps["id"];
    title?: NotificationProps["title"];
    loading?: NotificationProps["loading"];
    message: NotificationProps["message"];
    autoClose?: NotificationProps["autoClose"];
    disallowClose?: NotificationProps["disallowClose"];
    type?: NotificationTypes;
};

type UpdateNotificationToGlobalErrorParams = { id: string; error: unknown; fallbackMessage: string };

type ShowNotificationType = (params: UseNotificationsParams) => string;
type UpdateNotificationType = (params: Pick<UseNotificationsParams, "id" | "message" | "title" | "loading" | "autoClose" | "type">) => void;
type HideNotificationType = (id: string) => void;
type CleanType = () => void;
type CleanQueueType = () => void;
type UpdateNotificationToGlobalErrorType = (params: UpdateNotificationToGlobalErrorParams) => void;

type UseNotificationType = {
    showNotification: ShowNotificationType;
    updateNotification: UpdateNotificationType;
    hideNotification: HideNotificationType;
    clean: CleanType;
    cleanQueue: CleanQueueType;
    updateNotificationToGlobalError: UpdateNotificationToGlobalErrorType;
};

export const useNotifications = (): UseNotificationType => {
    const { t } = useTranslation();

    const showNotification = useCallback((params: UseNotificationsParams) => {
        const id = params.id || nanoid();
        const autoClose = params.loading ? false : params.autoClose;
        const defaultAutoClose = params.type === NotificationTypes.error ? DEFAULT_ERROR_AUTO_CLOSE_DURATION : DEFAULT_AUTO_CLOSE_DURATION;
        showNotificationMantine({
            id,
            message: params.message,
            autoClose: params.autoClose === undefined && !params.loading ? defaultAutoClose : autoClose,
            title: params.title,
            loading: params.loading,
            disallowClose: params.loading ? true : params.disallowClose,
            // We have to use sx prop, because there is no better way to style notification with mantine notification system
            sx: () => ({
                "&:before": {
                    backgroundColor: getNotificationColorForType(params.type),
                },
                "& .mantine-Notification-description": {
                    color: Color.supportNavy400,
                    whiteSpace: "pre-line",
                },
                "& .mantine-Notification-title": {
                    fontWeight: 700,
                    color: getNotificationColorForType(params.type),
                },
            }),
        });

        return id;
    }, []);
    const updateNotification = useCallback(({ id, message, title, loading, type, autoClose }) => {
        const defaultAutoClose = type === NotificationTypes.error ? DEFAULT_ERROR_AUTO_CLOSE_DURATION : DEFAULT_AUTO_CLOSE_DURATION;
        updateNotificationMantine({
            id,
            message,
            title,
            loading,
            autoClose: autoClose === undefined && !loading ? defaultAutoClose : autoClose,
            sx: () => ({
                "&:before": {
                    backgroundColor: getNotificationColorForType(type),
                },
                "& .mantine-Notification-description": {
                    color: Color.supportNavy400,
                    whiteSpace: "pre-line",
                },
                "& .mantine-Notification-title": {
                    fontWeight: 700,
                    color: getNotificationColorForType(type),
                },
            }),
        });
    }, []);
    const hideNotification = useCallback((id: string) => {
        hideNotificationMantine(id);
    }, []);
    const clean = useCallback(() => {
        cleanNotificationsMantine();
    }, []);
    const cleanQueue = useCallback(() => {
        cleanNotificationsQueueMantine();
    }, []);
    const updateNotificationToGlobalError = useCallback(({ id, fallbackMessage, error }) => {
        const typedError = error as RequestErrorType;
        const errorMessage = createGlobalErrorMessage(typedError, fallbackMessage);
        updateNotification({
            id,
            loading: false,
            title: t("common.form.errorTitle"),
            message: errorMessage,
            type: NotificationTypes.error,
        });
    }, []);

    return {
        showNotification,
        hideNotification,
        updateNotification,
        clean,
        cleanQueue,
        updateNotificationToGlobalError,
    };
};
