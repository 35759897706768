import type { TFunction } from "react-i18next";
import type { Cell } from "react-table";

import { useGetDataModificationLogQuery } from "src/api";
import { createSelectColumnFilter, DateColumnFilter, TextColumnFilter } from "src/components";
import { createTableModule, TableNames } from "src/table";
import type { ColumnsType, TableDataModificationLogType } from "src/types";
import { DataModificationLogActionEnum, DataModificationLogTableEnum } from "src/types";
import { DATE_TIME_FORMAT, dateTimeFormatter, enumToLocalizedOptions, getTranslatedEnumValue } from "src/utils";
import { ChangeValuesCell } from "../components";

const createColumns = (t: TFunction, tableName: string): ColumnsType<TableDataModificationLogType> => [
    {
        minWidth: 100,
        accessor: "timestamp" as const,
        Header: t("payerDetail.dataModificationLog.table.timestamp") as string,
        Filter: DateColumnFilter,
        Cell: ({ value }: Cell<TableDataModificationLogType>) => dateTimeFormatter(value, DATE_TIME_FORMAT),
    },
    {
        minWidth: 100,
        Header: t("payerDetail.dataModificationLog.table.user") as string,
        accessor: "userName" as const,
        Filter: TextColumnFilter,
    },
    {
        collapse: true,
        minWidth: 100,
        Header: t("payerDetail.dataModificationLog.table.action") as string,
        accessor: "action" as const,
        Filter: createSelectColumnFilter(enumToLocalizedOptions("DataModificationLogAction", DataModificationLogActionEnum)),
        Cell: ({ value }: Cell<TableDataModificationLogType>) => getTranslatedEnumValue("DataModificationLogAction", value),
    },
    {
        collapse: true,
        minWidth: 150,
        Header: t("payerDetail.dataModificationLog.table.table") as string,
        accessor: "table" as const,
        Filter: createSelectColumnFilter(
            enumToLocalizedOptions(
                "DataModificationLogTable",
                DataModificationLogTableEnum,
                tableName === TableNames.payerDataModificationLog
                    ? [DataModificationLogTableEnum.CustomerSupplyLimitTable, DataModificationLogTableEnum.EmergencyOrderAdjustmentTable]
                    : [],
            ),
        ),
        Cell: ({ value }: Cell<TableDataModificationLogType>) => getTranslatedEnumValue("DataModificationLogTable", value),
    },
    {
        Header: t("payerDetail.dataModificationLog.table.oldValues") as string,
        accessor: "oldValues" as const,
        minWidth: 200,
        disableFilters: true,
        Cell: ({ value, row }: Cell<TableDataModificationLogType>) =>
            value ? <ChangeValuesCell values={value} columns={row.original.column} /> : null,
    },
    {
        Header: t("payerDetail.dataModificationLog.table.newValues") as string,
        accessor: "newValues" as const,
        minWidth: 200,
        disableFilters: true,
        Cell: ({ value, row }: Cell<TableDataModificationLogType>) =>
            value ? (
                <ChangeValuesCell
                    values={value}
                    columns={row.original.column}
                    highlight={row.original.action !== DataModificationLogActionEnum.D}
                />
            ) : null,
    },
];

export const payerDataModificationLogTableModule = createTableModule<TableDataModificationLogType>(
    TableNames.payerDataModificationLog,
    useGetDataModificationLogQuery,
    createColumns,
);

export const shipToDataModificationLogTableModule = createTableModule<TableDataModificationLogType>(
    TableNames.shipToDataModificationLog,
    useGetDataModificationLogQuery,
    createColumns,
);
