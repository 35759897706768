import styled from "styled-components";

export const PropagateSpecialPriceBlock = styled.div`
    display: grid;
    grid-auto-flow: row;
    gap: 1rem;
    padding: 0.6rem 1.2rem;
    background-color: ${({ theme }) => theme.color.warning50};
    border: 1px solid ${({ theme }) => theme.color.warning500};
    border-radius: ${({ theme }) => theme.radius.default};
    max-width: 315px;
`;
