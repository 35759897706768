import { FALSE_STRING, TRUE_STRING } from "./constants";

export const stringTrim = <ValueType = unknown>(value: ValueType) => (typeof value === "string" ? value.trim() : value);

export const removeSpaces = <ValueType = unknown>(value: ValueType) => (typeof value === "string" ? value.replace(/\s+/g, "") : value);

export const stringToBool = (value?: string | null): boolean => {
    if (value === TRUE_STRING) {
        return true;
    }
    if (value === FALSE_STRING) {
        return false;
    }
    throw new Error(`Invalid value: ${value}`);
};
