import type { FileWithPath } from "@mantine/dropzone";
import type { FC } from "react";

import { url, useDeleteAttachmentMutation, useDownloadFileMutation, useUploadAttachmentMutation } from "src/api";
import { Dropzone, Grid, Icon, Text } from "src/components";
import { GlobalFormError } from "src/form";
import { NotificationTypes, useNotifications } from "src/notifications";
import { Color, IconSizes } from "src/theme";
import { useTranslation } from "src/translations";
import { ATTACHMENT_ACCEPT_TYPES, HUNDRED_MB_IN_BYTES } from "../constants";
import { tableModule } from "./table";
import { createActionColumn } from "./utils";

type AttachmentsProps = {
    customerId: string;
};

export const Attachments: FC<AttachmentsProps> = ({ customerId }) => {
    const { t } = useTranslation();
    const notifications = useNotifications();
    const [uploadFile] = useUploadAttachmentMutation();
    const [downloadFile] = useDownloadFileMutation();
    const [deleteFile] = useDeleteAttachmentMutation();

    const uploadAttachment = async (files: Array<File>) => {
        const formData = new FormData();
        files.forEach((file) => {
            if (file.size > HUNDRED_MB_IN_BYTES) {
                throw new GlobalFormError(t("customers.attachments.maxFileSize100MB"));
            }
            formData.append("files", file);
        });
        return uploadFile({ formData, customerId }).unwrap();
    };

    const downloadAttachment = async (fileId: number, filename: string, fileMimeType: string, open: boolean) => {
        const notificationId = notifications.showNotification({
            title: t("common.form.loadingTitle"),
            message: t(`customers.attachments.download.loading`),
            loading: true,
        });
        try {
            await downloadFile({ url: `${url.ATTACHMENT}/${fileId}`, filename, fileMimeType, open }).unwrap();
            notifications.updateNotification({
                id: notificationId,
                loading: false,
                title: t("common.form.successTitle"),
                message: t(`customers.attachments.download.success`),
                type: NotificationTypes.success,
            });
        } catch (e) {
            notifications.updateNotificationToGlobalError({
                id: notificationId,
                error: e,
                fallbackMessage: t(`customers.attachments.download.error`),
            });
        }
    };

    const deleteAttachment = async (fileId: number) => {
        const notificationId = notifications.showNotification({
            title: t("common.form.loadingTitle"),
            message: t(`customers.attachments.delete.loading`),
            loading: true,
        });
        try {
            await deleteFile(fileId).unwrap();
            notifications.updateNotification({
                id: notificationId,
                loading: false,
                title: t("common.form.successTitle"),
                message: t(`customers.attachments.delete.success`),
                type: NotificationTypes.success,
            });
        } catch (e) {
            notifications.updateNotificationToGlobalError({
                id: notificationId,
                error: e,
                fallbackMessage: t(`customers.attachments.delete.success`),
            });
        }
    };

    const onDrop = (files: FileWithPath[]) => {
        const notificationId = notifications.showNotification({
            title: t("common.form.loadingTitle"),
            message: t("customers.attachments.form.loading"),
            loading: true,
        });
        if (files) {
            uploadAttachment(files)
                .then(() => {
                    notifications.updateNotification({
                        id: notificationId,
                        loading: false,
                        title: t("common.form.successTitle"),
                        message: t(`customers.attachments.form.success`),
                        type: NotificationTypes.success,
                    });
                })
                .catch((e) => {
                    notifications.updateNotificationToGlobalError({
                        id: notificationId,
                        error: e,
                        fallbackMessage: t(`customers.attachments.form.error`),
                    });
                });
        }
    };

    return (
        <Grid gridAutoFlow="row" gap="1rem">
            <Dropzone minHeight="10.5rem" accept={ATTACHMENT_ACCEPT_TYPES} onDrop={onDrop} autoFocus={false} multiple>
                <Dropzone.Accept>
                    <Grid gridAutoFlow="column" gap="3rem" justifyContent="center" alignItems="center">
                        <Icon.Checkmark size={IconSizes.xl} color={Color.supportNavy500} filled />
                        <Text color={Color.supportNavy500} size="1.6rem">
                            {t("customers.attachments.dropzone.accept.info")}
                        </Text>
                    </Grid>
                </Dropzone.Accept>
                <Dropzone.Reject>
                    <Grid gridAutoFlow="column" gap="3rem" justifyContent="center" alignItems="center">
                        <Icon.Close size={IconSizes.xl} color={Color.supportNavy500} />
                        <Text color={Color.supportNavy500} size="1.6rem">
                            {t("customers.attachments.dropzone.reject.info")}
                        </Text>
                    </Grid>
                </Dropzone.Reject>
                <Dropzone.Idle>
                    <Grid gridAutoFlow="column" gap="3rem" justifyContent="center" alignItems="center">
                        <Icon.Upload size={IconSizes.xl} color={Color.supportNavy500} filled />
                        <Grid gridAutoFlow="row" gap="0.5rem">
                            <Text color={Color.supportNavy500} size="1.6rem">
                                {t("customers.attachments.dropzone.idle.info")}
                            </Text>
                            <Text color={Color.neutral400} size="1.4rem">
                                {t("customers.attachments.dropzone.idle.info2")}
                            </Text>
                        </Grid>
                    </Grid>
                </Dropzone.Idle>
            </Dropzone>
            <tableModule.Container
                actions={createActionColumn(t, downloadAttachment, deleteAttachment)}
                additionalQueryParams={{ customerId }}
            />
        </Grid>
    );
};
