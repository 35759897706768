import { NAME } from "./constants";
import { reducer } from "./reducer";

export { ConfirmContextConsumer } from "./ConfirmContext";
export { ConfirmProvider } from "./ConfirmProvider";
export { useModal } from "./useModal";
export { useModalControls } from "./useModalControls";
export { useModalState } from "./useModalState";

export default {
    NAME,
    reducer,
};
