import type { FC } from "react";
import { useMemo } from "react";

import { useApprovePayerMutation } from "src/api";
import payerIcon from "src/assets/icons/payer.svg";
import {
    Button,
    Checkbox,
    CheckboxProps,
    ModalButtonLayout,
    NumberInput,
    NumberInputProps,
    SectionFormLayout,
    Select,
    SelectProps,
    SvgIcon,
    Text,
} from "src/components";
import { composeValidators, createCurrencyInputProps, Form, InputField, required } from "src/form";
import { Color } from "src/theme";
import { useTranslation } from "src/translations";
import { ApprovePayerDto, CustomerDto, InvoicingFrequencyEnum, PaymentDueEnum } from "src/types";
import { enumToLocalizedOptions, zipFormatter } from "src/utils";
import { FormCustomerInfo } from "../components";

type ApprovePayerFormProps = {
    payer: CustomerDto;
    onClose: () => void;
};

export const ApprovePayerForm: FC<ApprovePayerFormProps> = ({ payer, onClose }) => {
    const { t } = useTranslation();
    const [approvePayer] = useApprovePayerMutation();

    const initialValues = useMemo(
        () => ({
            id: payer.id,
            creditLimit: 1000000,
            paymentDue: PaymentDueEnum._30,
            invoicingFrequency: InvoicingFrequencyEnum.Weekly,
            active: true,
        }),
        [payer.id],
    );

    return (
        <>
            <FormCustomerInfo
                name={payer.name}
                street={payer.street}
                city={`${zipFormatter(payer.zip)} ${payer.city}`}
                icon={<SvgIcon icon={payerIcon} />}
            />
            <Form<ApprovePayerDto>
                // Please be careful with copying this prop to other forms, it has impact to performance,
                // it has to be here, because form will stay mounted if modal is closed
                enableReinitialize
                initialValues={initialValues}
                onSubmit={approvePayer}
                onSuccess={onClose}
                loadingMessage={t("customers.approveForm.loadingPayer")}
                successMessage={(values) =>
                    values.active
                        ? t("customers.approveForm.successPayerActive", { name: payer.name })
                        : t("customers.approveForm.successPayerInactive", { name: payer.name })
                }
            >
                {() => (
                    <>
                        <SectionFormLayout
                            title={
                                <Text size="1.6rem" weight={700} color={Color.primary600}>
                                    {t("customers.termsOfTrade.title")}
                                </Text>
                            }
                        >
                            <InputField<NumberInputProps["value"], NumberInputProps>
                                name="creditLimit"
                                type="number"
                                input={NumberInput}
                                validate={composeValidators(required)}
                                inputProps={{
                                    label: t("customers.termsOfTrade.creditLimit.label"),
                                    placeholder: t("customers.termsOfTrade.creditLimit.placeholder"),
                                    maxWidth: "180px",
                                    ...createCurrencyInputProps(t),
                                }}
                            />
                            <InputField<SelectProps["value"], SelectProps>
                                name="paymentDue"
                                input={Select}
                                validate={composeValidators(required)}
                                inputProps={{
                                    label: t("customers.termsOfTrade.paymentDue.label"),
                                    placeholder: t("customers.termsOfTrade.paymentDue.placeholder"),
                                    maxWidth: "180px",
                                    options: enumToLocalizedOptions("paymentDue", PaymentDueEnum),
                                    clearable: false,
                                    rightSection: (
                                        <Text size="1.4rem" color={Color.supportNavy200}>
                                            {t("common.days")}
                                        </Text>
                                    ),
                                }}
                            />
                            <InputField<SelectProps["value"], SelectProps>
                                name="invoicingFrequency"
                                input={Select}
                                validate={composeValidators(required)}
                                inputProps={{
                                    label: t("customers.termsOfTrade.invoicingFrequency.label"),
                                    placeholder: t("customers.termsOfTrade.invoicingFrequency.placeholder"),
                                    options: enumToLocalizedOptions("invoicingFrequency", InvoicingFrequencyEnum),
                                    maxWidth: "180px",
                                    clearable: false,
                                }}
                            />
                            <InputField<CheckboxProps["value"], CheckboxProps>
                                name="active"
                                input={Checkbox}
                                type="checkbox"
                                inputProps={{
                                    label: t("customers.termsOfTrade.active.label"),
                                    description: t("customers.termsOfTrade.active.description"),
                                }}
                            />
                        </SectionFormLayout>
                        <ModalButtonLayout>
                            <Button type="submit">{t("customers.approveForm.submit")}</Button>
                            <Button type="button" onClick={onClose} variant="outline">
                                {t("common.cancel")}
                            </Button>
                        </ModalButtonLayout>
                    </>
                )}
            </Form>
        </>
    );
};
