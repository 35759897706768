import type { FC } from "react";
import { useCallback } from "react";

import { useTranslation } from "src/translations";
import { useAppDispatch } from "src/utils";
import { useUnblockCustomers } from "../../../utils";
import { defaultCustomersTableConfig } from "../../constants";
import { createEmptyRowForRequestTables } from "../../utils";
import { tableModule } from "./table";
import { createActionsForSelection } from "./utils";

export const Unblocked: FC = () => {
    const { t } = useTranslation();
    const dispatch = useAppDispatch();

    const onSuccess = useCallback(() => dispatch(tableModule.resetTableSelectionAction), [dispatch]);
    const unblock = useUnblockCustomers(onSuccess);

    return (
        <tableModule.Container
            isSelectable
            actionsForSelection={createActionsForSelection(t, unblock)}
            defaultTableConfig={defaultCustomersTableConfig}
            emptyRow={createEmptyRowForRequestTables(t("customers.unblock.emptyRow"), t("customers.unblock.emptyRowWithFilters"))}
        />
    );
};
