import { useDebouncedValue } from "@mantine/hooks";
import { useFormikContext } from "formik";
import type { FC } from "react";
import { useCallback, useEffect } from "react";

import { useLazyGetShipToByAcnForEmergencyOrderQuery } from "src/api";
import { TextInput, TextInputProps } from "src/components";
import { InputField } from "src/form";
import { useTranslation } from "src/translations";
import type { ShipToPublicFormDto } from "src/types";
import { isNotNullish } from "src/utils";
import { CUSTOMER_ID_FIELD } from "../constants";
import type { PublicEmergencyOrderForm } from "../types";
import { DEBOUNCE_SEARCH_VALUE } from "./constants";

const customerNumberField = "customerNumber";
const customerNameField = "customerName";

export const CustomerDependentFields: FC = () => {
    const { t } = useTranslation();
    const { values, setFieldValue, setFieldTouched, setFieldError } = useFormikContext<PublicEmergencyOrderForm>();
    const [getShipTo] = useLazyGetShipToByAcnForEmergencyOrderQuery();

    const [debounceNumberValue] = useDebouncedValue<string | undefined>(values[customerNumberField], DEBOUNCE_SEARCH_VALUE);

    const searchCustomer: (acn: string) => Promise<ShipToPublicFormDto | null> = useCallback(
        async (acn) =>
            getShipTo({ acn })
                .unwrap()
                .catch(() => {
                    setFieldError(customerNumberField, "common.form.search_error");
                    return null;
                }),
        [getShipTo, setFieldError],
    );

    useEffect(() => {
        if (debounceNumberValue) {
            searchCustomer(debounceNumberValue).then((data) => {
                if (isNotNullish(data)) {
                    setFieldValue(customerNameField, data.name);
                    setFieldValue(CUSTOMER_ID_FIELD, data.id);
                } else {
                    setFieldTouched(customerNumberField, true, false);
                    setFieldError(customerNumberField, "common.form.customer_not_found");
                    setFieldValue(CUSTOMER_ID_FIELD, null, false);
                    setFieldValue(customerNameField, "", false);
                }
            });
        } else {
            setFieldValue(CUSTOMER_ID_FIELD, null, false);
            setFieldValue(customerNameField, "", false);
        }
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [debounceNumberValue, setFieldError]);

    return (
        <>
            <InputField<TextInputProps["value"], TextInputProps>
                name={customerNumberField}
                input={TextInput}
                required
                inputProps={{
                    maxWidth: "450px",
                    label: t("publicEmergencyOrders.form.customerNumber.label"),
                    placeholder: t("publicEmergencyOrders.form.customerNumber.placeholder"),
                    description: t("publicEmergencyOrders.form.customerNumber.description"),
                }}
            />
            <InputField<TextInputProps["value"], TextInputProps>
                name={customerNameField}
                input={TextInput}
                required
                inputProps={{
                    maxWidth: "450px",
                    disabled: true,
                    label: t("publicEmergencyOrders.form.customerName.label"),
                    placeholder: t("publicEmergencyOrders.form.customerName.placeholder"),
                    description: t("publicEmergencyOrders.form.customerName.description"),
                }}
            />
        </>
    );
};
