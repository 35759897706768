import type { FC } from "react";
import type { TableCellProps } from "react-table";
import styled, { css, CSSProperties } from "styled-components";

/**
 * Table cell component
 * Ready to use with Table component
 */

type TCellProps = TableCellProps & { $align?: CSSProperties["justifyContent"]; colSpan?: number; className?: string; $noWrap?: boolean };

export const TCell: FC<TCellProps> = ({ $align, children, className, ...props }) => (
    <Td className={className} {...props}>
        <Align $align={$align}>{children}</Align>
    </Td>
);

const Td = styled.td<TableCellProps & { colSpan?: number; $noWrap?: boolean }>`
    border-bottom: ${({ theme }) => `0.5px solid ${theme.color.supportGraphite50}`};
    ${({ $noWrap }) =>
        $noWrap &&
        css`
            white-space: nowrap;
        `}
    padding: 1.2rem 1.6rem;
    position: relative;
    color: ${({ theme }) => theme.color.supportGraphite500};
    font-size: 1.4rem;
`;

const Align = styled.div<{ $align?: CSSProperties["justifyContent"] }>`
    display: flex;
    min-height: 2.8rem;
    align-items: center;
    ${({ $align }) =>
        $align &&
        css`
            justify-content: ${$align};
        `}
`;
