import type { FC, ReactNode } from "react";
import styled from "styled-components";

type FormSectionLayoutProps = {
    title?: ReactNode;
};

export const SectionFormLayout: FC<FormSectionLayoutProps> = ({ title, children }) => (
    <StyledFormSectionLayout>
        {title}
        <InnerSection>{children}</InnerSection>
    </StyledFormSectionLayout>
);

export const StyledFormSectionLayout = styled.div`
    display: grid;
    grid-auto-flow: row;
    gap: 2rem;
`;

export const InnerSection = styled.div`
    display: grid;
    padding: 0 1rem;
    grid-auto-flow: row;
`;
