import i18n from "i18next";
import type { FC } from "react";
import { useEffect, useState } from "react";

import czechFlag from "src/assets/icons/cz-flag.svg";
import publicLogo from "src/assets/icons/publicLogo.png";
import { Grid, SvgIcon, Text } from "src/components";
import { Color } from "src/theme";
import { Languages, useTranslation } from "src/translations";
import { EmergencyOrdersContentLayout, EmergencyOrdersFooterLayout, EmergencyOrdersLayout, FooterLink } from "./components";
import { CookieBanner } from "./containers";
import { EmergencyOrdersForm } from "./EmergencyOrdersForm";
import { Success } from "./Success";

const currentYear = new Date().getFullYear();

export const PublicEmergencyOrders: FC = () => {
    const [isLanguageLoaded, setIsLanguageLoaded] = useState<boolean>(false);
    const [isSuccess, setIsSuccess] = useState<boolean>(false);
    const { t } = useTranslation();

    useEffect(() => {
        // There is no button for redirect to DTP,
        // therefore you have to reload page when you want to go back to DTP and
        // language will set to default en
        i18n.changeLanguage(Languages.cs).then(() => {
            setIsLanguageLoaded(true);
        });
    }, []);

    return isLanguageLoaded ? (
        <>
            <EmergencyOrdersLayout>
                <div>
                    <a href="https://www.astrazeneca.cz/">
                        <SvgIcon icon={publicLogo} />
                    </a>
                </div>
                <EmergencyOrdersContentLayout>
                    {isSuccess ? (
                        <Success onClick={() => setIsSuccess(false)} />
                    ) : (
                        <>
                            <Text align="center" color={Color.primary600} size="2.1rem" weight={700}>
                                {t("publicEmergencyOrders.title")}
                            </Text>
                            <EmergencyOrdersForm onSuccess={() => setIsSuccess(true)} />
                        </>
                    )}
                </EmergencyOrdersContentLayout>
                <EmergencyOrdersFooterLayout>
                    <Grid alignItems="center" justifyContent="start" gap="1.8rem" gridAutoFlow="column">
                        <SvgIcon icon={czechFlag} />
                        <FooterLink href="https://www.astrazeneca.cz/">{t("publicEmergencyOrders.footer.az")}</FooterLink>
                    </Grid>
                    <Grid gridAutoFlow="row" rowGap="0.5rem">
                        <Grid gap="1.8rem" gridAutoFlow="column" justifyContent="start">
                            <FooterLink target="_blank" href="https://www.astrazeneca.com/legal-notice-and-terms-of-use.html">
                                {t("publicEmergencyOrders.footer.termsOfUse")}
                            </FooterLink>
                            <span>|</span>
                            <FooterLink target="_blank" href="https://www.globalprivacy.astrazeneca.com/cz">
                                {t("publicEmergencyOrders.footer.privacy")}
                            </FooterLink>
                            <span>|</span>
                            <span>{t("publicEmergencyOrders.footer.copyright", { year: currentYear })}</span>
                        </Grid>
                        <Text>{t("publicEmergencyOrders.footer.for")}</Text>
                        <Text>{t("publicEmergencyOrders.footer.number")}</Text>
                    </Grid>
                </EmergencyOrdersFooterLayout>
            </EmergencyOrdersLayout>
            <CookieBanner />
        </>
    ) : null;
};
