import styled, { css } from "styled-components";

export const TableLayout = styled.div<{ maxWidth?: number }>`
    display: grid;
    grid-auto-flow: row;
    gap: 1rem;
    justify-items: center;
    width: 100%;
    ${({ maxWidth }) =>
        maxWidth &&
        css`
            max-width: ${maxWidth}px;
        `};
`;
