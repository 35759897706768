import { useLocalStorage } from "@mantine/hooks";
import { useCallback, useMemo } from "react";

import { useMaxWidthBreakpoint } from "src/theme";
import { isEmptyObject, isNullish } from "src/utils";
import { LOCAL_STORAGE_COLLAPSIBLE_WIDGET_KEY } from "./constants";
import { DashboardCollapsibleWidgetsEnum } from "./enums";

type UseCollapsibleWidgetReturnValue = {
    isVisible: boolean;
    toggle: () => void;
};

// We want to have different state for mobile and for desktop, therefore we need to distinguish enum per resolution
const TABLET_SUFFIX = "TABLET";
const DESKTOP_SUFFIX = "DESKTOP";

export const useCollapsibleWidget = (collapsibleWidgetId?: DashboardCollapsibleWidgetsEnum): UseCollapsibleWidgetReturnValue => {
    const [widgets, setWidgets] = useLocalStorage<Record<string, boolean> | {}>({
        key: LOCAL_STORAGE_COLLAPSIBLE_WIDGET_KEY,
        defaultValue: {},
    });
    const isTablet = useMaxWidthBreakpoint.tablet();
    const widgetKey = `${collapsibleWidgetId}_${isTablet ? TABLET_SUFFIX : DESKTOP_SUFFIX}`;

    // @ts-ignore
    const isVisible = isEmptyObject(widgets) || !collapsibleWidgetId || isNullish(widgets[widgetKey]) ? true : widgets[widgetKey];

    const setAllDesktopWidgets = useCallback(
        (show: boolean) => {
            const desktopWidgets = Object.values(DashboardCollapsibleWidgetsEnum).reduce<
                Record<DashboardCollapsibleWidgetsEnum, boolean> | {}
            >(
                (json, widgetId) => ({
                    ...json,
                    [`${widgetId}_${DESKTOP_SUFFIX}`]: show,
                }),
                {},
            );
            setWidgets({ ...widgets, ...desktopWidgets });
        },
        [setWidgets, widgets],
    );

    const toggle = useCallback(() => {
        if (isTablet) {
            setWidgets({
                ...widgets,
                [widgetKey]: !isVisible,
            });
        } else {
            setAllDesktopWidgets(!isVisible);
        }
    }, [isTablet, isVisible, setWidgets, setAllDesktopWidgets, widgetKey, widgets]);

    return useMemo(
        () => ({
            isVisible,
            toggle,
        }),
        [isVisible, toggle],
    );
};
