import type { AnyRecord, GenerateAggregation, GenerateDto } from "src/types";
import { cleanObject, formatDateForBE } from "src/utils";

export const convertImportInterfacesOperationsTableFilterNames = (filters: AnyRecord) => {
    const { filename, errorMessage, interfaceOperation, ...clearedFilters } = filters;
    return cleanObject<string | number>({
        ...clearedFilters,
        interfaceOperationType: interfaceOperation,
        name: filename,
        rejectReason: errorMessage,
    });
};

export const transformGeneratedValuesForAggregationGeneration = (values: GenerateAggregation): GenerateDto => ({
    ...values,
    from: formatDateForBE(values.from),
    to: formatDateForBE(values.to),
});
