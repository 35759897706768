import type { SpotlightAction } from "@mantine/spotlight";
import type { TFunction } from "i18next";
import type { NavigateFunction } from "react-router-dom";

import { createSearchParamsAsString, routes } from "src/routes";
import { createFiltersSearchParam, TableNames } from "src/table";
import type { SearchDto } from "src/types";
import { SearchResultTypeEnum } from "src/types";
import { ACTION_LIMIT_PER_ENTITY } from "./constants";

const NUMBER_OF_PRODUCT_GROUPS = 2;

const createSupplyLimitActions = (id: string, name: string, navigate: NavigateFunction, t: TFunction) => ({
    title: name,
    group: t("common.spotlight.group.supplyLimits"),
    onTrigger: () => navigate(routes.supplyLimitsDetail.fillPathParams({ productPackageId: id })),
});

const createProductPriceActions = (name: string, navigate: NavigateFunction, t: TFunction) => ({
    title: name,
    group: t("common.spotlight.group.pricesList"),
    onTrigger: () =>
        navigate(`${routes.prices.path}?${createSearchParamsAsString({ [createFiltersSearchParam(TableNames.productPrice)]: { name } })}`),
});

const createCustomerActions = (id: string, name: string, navigate: NavigateFunction, t: TFunction) => ({
    title: name,
    group: t("common.spotlight.group.customers"),
    onTrigger: () => navigate(routes.customerDetail.fillPathParams({ customerId: id })),
});

export const transformAndLimitSearchResultsToActions = (
    t: TFunction,
    results: Array<SearchDto>,
    navigate: NavigateFunction,
): Array<SpotlightAction> => {
    const groupedResults = results.reduce<Record<SearchResultTypeEnum, Array<SearchDto>>>(
        (group, result) => ({
            ...group,
            [result.type]: [...group[result.type], result],
        }),
        {
            [SearchResultTypeEnum.ProductPackage]: [],
            [SearchResultTypeEnum.Customer]: [],
        },
    );

    const customersLimit = Math.min(
        ACTION_LIMIT_PER_ENTITY + Math.max(NUMBER_OF_PRODUCT_GROUPS * (ACTION_LIMIT_PER_ENTITY - groupedResults.PRODUCT_PACKAGE.length), 0),
        groupedResults.CUSTOMER.length,
    );
    const productPackageLimit = Math.min(
        ACTION_LIMIT_PER_ENTITY + Math.max(ACTION_LIMIT_PER_ENTITY - groupedResults.CUSTOMER.length, 0),
        groupedResults.PRODUCT_PACKAGE.length,
    );

    const customerActions = groupedResults.CUSTOMER.slice(0, customersLimit).map((result) =>
        createCustomerActions(result.id.toString(), result.name, navigate, t),
    );

    const productActions = groupedResults.PRODUCT_PACKAGE.slice(0, productPackageLimit).reduce<Array<SpotlightAction>>((acc, result) => {
        const supplyLimitActions = createSupplyLimitActions(result.id.toString(), result.name, navigate, t);
        const productPriceActions = createProductPriceActions(result.name, navigate, t);
        return [...acc, supplyLimitActions, productPriceActions];
    }, []);

    return [...productActions, ...customerActions];
};
