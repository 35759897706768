import type { FC } from "react";
import { forwardRef } from "react";

import { Text } from "src/components";
import { useTranslation } from "src/translations";
import type { SupplyLimitDto } from "src/types";
import { SupplyLimitOption, SupplyLimitOptionsLayout } from "./components";
import { UNLIMITED_TIER_NUMBER } from "./constants";

export type SupplyLimitSelectProps = Readonly<{
    onChange: (id: number) => void;
    value?: number | undefined; // supply limit ID
    error?: string;
    options: SupplyLimitDto[];
}>;

export const SupplyLimitSelect: FC<SupplyLimitSelectProps> = forwardRef(({ onChange, value, error, options }, _) => {
    const { t } = useTranslation();
    return (
        <SupplyLimitOptionsLayout>
            {error && <Text>{error}</Text>}
            {options.map(({ tierNumber, dailyLimit, monthlyLimit, id, isDefault }) => (
                <SupplyLimitOption
                    selected={value === id}
                    key={id}
                    dailyLimit={dailyLimit}
                    monthlyLimit={monthlyLimit}
                    tierLabel={tierNumber === UNLIMITED_TIER_NUMBER ? t("common.unlimited") : tierNumber?.toString()}
                    unlimited={tierNumber === UNLIMITED_TIER_NUMBER}
                    onClick={() => onChange(id)}
                    isDefault={isDefault}
                />
            ))}
        </SupplyLimitOptionsLayout>
    );
});
