import type { FC, ReactNode } from "react";
import styled from "styled-components";

import { noop } from "src/utils";

export type DashboardCardLayoutProps = {
    title?: string;
    onTitleClick?: () => void;
    isVisible?: boolean;
    statusBar?: ReactNode;
};

export const DashboardCardLayout: FC<DashboardCardLayoutProps> = ({ title, isVisible, onTitleClick, statusBar, children }) => (
    <StyledCard>
        {title && (
            <TitleWrapper onClick={onTitleClick || noop}>
                <Title>{title}</Title>
                {!isVisible && statusBar}
            </TitleWrapper>
        )}
        {isVisible && <StyledCardContent>{children}</StyledCardContent>}
    </StyledCard>
);

const StyledCard = styled.div`
    border-radius: ${({ theme }) => theme.radius.default};
    border: 1px solid ${({ theme }) => theme.color.neutral300};
    color: ${({ theme }) => theme.color.supportNavy500};
    background-color: ${({ theme }) => theme.color.white};
    position: relative;
`;

const StyledCardContent = styled.div`
    padding: 1rem;
`;

const Title = styled.h3`
    font-size: 1.8rem;
    margin: 0;
    font-weight: 400;
`;

const TitleWrapper = styled.div`
    display: grid;
    grid-auto-flow: column;
    padding: 1.2rem 1.6rem;
    box-shadow: 0 2px 2px rgba(0, 0, 0, 0.06);
    justify-content: space-between;
    align-items: center;
    grid-template-rows: minmax(25px, auto);
`;
