import type { FC } from "react";
import styled from "styled-components";

import { createCssProperty } from "../createCssProperty";
import type { AlignContent, AlignItems, ColumnGap, FlexDirection, FlexGrow, FlexWrap, Gap, JustifyContent, RowGap } from "../types";

type FlexProps = FlexWrap & FlexGrow & FlexDirection & AlignItems & JustifyContent & AlignContent & Gap & RowGap & ColumnGap;

/**
 * Component for creating simple flex layouts.
 * Should be used only when no other styling of component (for example responsivity) is needed.
 * See https://css-tricks.com/snippets/css/a-guide-to-flexbox/ for details.
 * @see GridElement
 */
export const Flex: FC<FlexProps> = styled.div<FlexProps>`
    display: flex;

    ${({ flexDirection }) => createCssProperty("flex-direction", flexDirection)};
    ${({ flexWrap }) => createCssProperty("flex-wrap", flexWrap)};
    ${({ flexGrow }) => createCssProperty("flex-grow", flexGrow)};
    ${({ justifyContent }) => createCssProperty("justify-content", justifyContent)};
    ${({ alignItems }) => createCssProperty("align-items", alignItems)};
    ${({ alignContent }) => createCssProperty("align-content", alignContent)};
    ${({ gap }) => createCssProperty("gap", gap)};
    ${({ rowGap }) => createCssProperty("row-gap", rowGap)};
    ${({ columnGap }) => createCssProperty("column-gap", columnGap)};
`;
