import type { FC, MouseEventHandler } from "react";
import styled from "styled-components";

import { Color, IconSizes } from "src/theme";
import { Icon } from "../../Icons";

type DateRightSectionProps = {
    onClear: MouseEventHandler<HTMLDivElement>;
};

export const DateRightSection: FC<DateRightSectionProps> = ({ onClear }) => (
    <DateRightSectionWrapper onClick={onClear}>
        <Icon.Close color={Color.supportNavy500} size={IconSizes.s} />
    </DateRightSectionWrapper>
);

const DateRightSectionWrapper = styled.div`
    display: flex;
    align-items: center;
    justify-content: center;

    width: var(--right-icon-size);
    height: 100%;

    cursor: pointer;
`;
