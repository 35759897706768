import { FC, Fragment } from "react";

import { Grid, HighlightCell } from "src/components";

type ChangeValuesCellProps = {
    columns: Array<string>;
    values: Array<string>;
    highlight?: boolean;
};

export const ChangeValuesCell: FC<ChangeValuesCellProps> = ({ columns, values, highlight }) => (
    <HighlightCell highlight={values.length > 0 && highlight ? highlight : false}>
        <Grid gap="0.5rem">
            {values.map((value: string, index) => (
                <Fragment key={columns[index]}>
                    <b>{columns[index]}</b>
                    <div>{value === null ? "null" : value}</div>
                </Fragment>
            ))}
        </Grid>
    </HighlightCell>
);
