import { nanoid } from "@reduxjs/toolkit";

import { RequestError } from "src/api";
import type { ProductPackageDto, RequestErrorType, SupplyLimitDto } from "src/types";
import { isEmpty, isRequestError } from "src/utils";
import { MAX_TIER_LIMIT, MIN_TIER_LIMIT, TIERS_FIELD, UNLIMITED_FIELD } from "../constants";
import type { FormValuesType } from "./types";

const NEW_TIER_IDENTIFICATION = "newTier";

const createNewTier = (id: number | string, tierNumber: number, productPackage: ProductPackageDto) => ({
    id,
    tierNumber,
    dailyLimit: "",
    monthlyLimit: "",
    productPackageId: productPackage.id,
    unlimited: false,
    isDefault: false,
});

export const createTierSelectorId = (id: number | string) => `${id}-tier`;

export const isTierNew = (id: string) => id.includes(NEW_TIER_IDENTIFICATION);
const createNewTierId = () => `${NEW_TIER_IDENTIFICATION}-${nanoid()}`;

export const addTier = (
    setFocusedTierId: (id: number | string | null) => void,
    productPackage: ProductPackageDto,
    nextTierNumber: number,
) => {
    const newTierId = createNewTierId();
    setFocusedTierId(newTierId);
    return createNewTier(newTierId, nextTierNumber, productPackage);
};

export const getErrorMessageFromFields = (e: RequestErrorType) => e.data.fieldErrors.map(({ messageCode }) => messageCode).join(", ");

export const findTierInFormValuesById = (values: FormValuesType, id: number | string | null) =>
    id ? [values.unlimited, ...values.tiers].find((tier) => tier?.id === id) : undefined;

export const findTierIndexInFormStandardTiersById = (values: FormValuesType, id: number | string | null) =>
    id ? values.tiers.findIndex((tier) => tier?.id === id) : undefined;

export const findSameTierInFormValuesById = (values: FormValuesType, focusedTier: SupplyLimitDto): SupplyLimitDto | undefined => {
    const tiersWithoutFocused = [values.unlimited, ...values.tiers].filter((tier) => tier?.id !== focusedTier.id);

    return tiersWithoutFocused.find(
        (tier) => tier?.monthlyLimit === focusedTier.monthlyLimit && tier.dailyLimit === focusedTier.dailyLimit,
    );
};

export const maxDailyLimitValidation = (value: SupplyLimitDto) =>
    !isEmpty(value?.dailyLimit) && value.dailyLimit > MAX_TIER_LIMIT ? "supplyLimits.validation.maxDailyLimit" : undefined;
export const maxMonthlyLimitValidation = (value: SupplyLimitDto) =>
    !isEmpty(value?.monthlyLimit) && value.monthlyLimit > MAX_TIER_LIMIT ? "supplyLimits.validation.maxMonthlyLimit" : undefined;
export const minDailyLimitValidation = (value: SupplyLimitDto) =>
    !isEmpty(value?.dailyLimit) && value.dailyLimit < MIN_TIER_LIMIT ? "supplyLimits.validation.minDailyLimit" : undefined;
export const minMonthlyLimitValidation = (value: SupplyLimitDto) =>
    !isEmpty(value?.monthlyLimit) && value.monthlyLimit < MIN_TIER_LIMIT ? "supplyLimits.validation.minMonthlyLimit" : undefined;
export const dailyIsNotGreaterThenMonthlyValidation = (value: SupplyLimitDto) =>
    !isEmpty(value?.dailyLimit) && !isEmpty(value?.monthlyLimit) && value.dailyLimit > value.monthlyLimit
        ? "supplyLimits.validation.dailyIsNotGreaterThenMonthly"
        : undefined;

export const createError = (error: unknown, values: FormValuesType, focusedTier: SupplyLimitDto): RequestError | unknown => {
    if (isRequestError(error)) {
        const fieldName =
            values.unlimited?.id === focusedTier.id
                ? UNLIMITED_FIELD
                : `${TIERS_FIELD}[${findTierIndexInFormStandardTiersById(values, focusedTier.id)}]`;
        const errorProperty: RequestErrorType = {
            ...error,
            data: {
                ...error.data,
                fieldErrors: error.data.fieldErrors.map(({ messageCode }) => ({ field: fieldName, messageCode })),
            },
        };
        return new RequestError(errorProperty);
    }
    return error;
};
