import type { FC } from "react";
import type { UseFiltersColumnProps } from "react-table";

import type { TableRecordType } from "src/types";
import { Select, SelectOption } from "../Form";
import { TABLE_FILTER_HEIGHT } from "./constants";

type NumberColumnFilterProps<RecordType extends TableRecordType> = Readonly<{
    column: UseFiltersColumnProps<RecordType>;
}>;

export const createBooleanSelectOption = (trueLabel: string, falseLabel: string) => [
    { value: "true", label: trueLabel },
    { value: "false", label: falseLabel },
];

export const createSelectColumnFilter =
    <RecordType extends TableRecordType>(options: SelectOption[]): FC<NumberColumnFilterProps<RecordType>> =>
    ({ column: { filterValue, setFilter } }) => {
        return (
            <Select
                options={options}
                withoutBorder
                withoutErrorPlaceholder
                height={TABLE_FILTER_HEIGHT}
                fontSize="1.2rem"
                value={filterValue || ""}
                withinPortal
                onChange={(value) => {
                    setFilter(value || undefined); // Set undefined to remove the filter entirely
                }}
            />
        );
    };
