import { AppLayout as AppLayoutComponent, ContentLayout } from "components";
import type { FC } from "react";

import { LeftSection } from "./containers";

export const AppLayout: FC = ({ children }) => (
    <AppLayoutComponent>
        <LeftSection />
        <ContentLayout>{children}</ContentLayout>
    </AppLayoutComponent>
);
