import type { FC } from "react";
import styled from "styled-components";

import notFoundIcon from "src/assets/icons/not_found_customers.svg";
import { SvgIcon, Text } from "src/components";
import { Color } from "src/theme";
import { useTranslation } from "src/translations";
import { CardWithTitle } from "./CardWithTitle";

type EmptyShipToCardProps = {
    isNewShipTo: boolean;
    title: string;
};

export const EmptyShipToCard: FC<EmptyShipToCardProps> = ({ isNewShipTo, title }) => {
    const { t } = useTranslation();

    return (
        <CardWithTitle isNewShipTo={isNewShipTo} title={title}>
            <CardLayout>
                <SvgIcon icon={notFoundIcon} height={50} width={50} />
                <Text color={Color.supportNavy500} size="1.4rem">
                    {t("copyTiers.noShipToFound")}
                </Text>
            </CardLayout>
        </CardWithTitle>
    );
};

const CardLayout = styled.div`
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
    min-height: 16rem;
    gap: 1rem;
`;
