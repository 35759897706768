import type { FC } from "react";
import { useMemo } from "react";
import { Link } from "react-router-dom";

import { useGetProductPackageQuery, useGetSupplyLimitsWithShipToCountQuery } from "src/api";
import { Button, Icon, LoadingOverlay } from "src/components";
import { PageLayout } from "src/layout";
import { routes } from "src/routes";
import { useSpotlight } from "src/spotlight";
import { UNLIMITED_TIER_NUMBER } from "src/supplyLimitSelect";
import { Color, IconSizes } from "src/theme";
import { useTranslation } from "src/translations";
import type { SupplyLimitWithShipToCountDto } from "src/types";
import { Search } from "../components";
import { TiersForm } from "./TiersForm";

export const Tiers: FC = () => {
    const spotlight = useSpotlight();
    const detailParams = routes.supplyLimitsDetail.useParams();
    const { data: productPackage, isLoading: isLoadingProductPackage } = useGetProductPackageQuery(detailParams.productPackageId, {
        skip: !detailParams.productPackageId,
    });
    const { data: supplyLimits, isLoading: isLoadingSupplyLimits } = useGetSupplyLimitsWithShipToCountQuery(detailParams.productPackageId, {
        skip: !detailParams.productPackageId,
    });
    const { t } = useTranslation();

    const defaultTiers = { unlimited: undefined, standard: [] };
    const tiers = useMemo(
        () =>
            supplyLimits
                ? supplyLimits.reduce<{ unlimited?: SupplyLimitWithShipToCountDto; standard: Array<SupplyLimitWithShipToCountDto> }>(
                      (all, currentTier) => {
                          if (currentTier.tierNumber === UNLIMITED_TIER_NUMBER) {
                              return { ...all, unlimited: currentTier };
                          }
                          return { ...all, standard: [...all.standard, currentTier] };
                      },
                      defaultTiers,
                  )
                : defaultTiers,
        // eslint-disable-next-line react-hooks/exhaustive-deps
        [productPackage, supplyLimits],
    );

    return (
        <PageLayout
            maxWidth="1000px"
            toolbar={
                productPackage && (
                    <Button
                        component={Link}
                        variant="subtle"
                        fontSize="1.6rem"
                        to={routes.supplyLimitsBulkEdit.fillPathParams({ productPackageId: productPackage.id.toString() })}
                        rightIcon={<Icon.ArrowRight size={IconSizes.s} color={Color.neutral50} />}
                    >
                        {t("supplyLimits.allCustomers")}
                    </Button>
                )
            }
            topSection={<Search onClick={spotlight.openSpotlight} productPackageName={productPackage && productPackage.name} />}
            breadcrumbs={[{ name: t("supplyLimits.name"), route: routes.supplyLimits.path, icon: Icon.VehicleTruck }]}
        >
            <LoadingOverlay loading={isLoadingProductPackage || isLoadingSupplyLimits} />
            {supplyLimits && productPackage && (
                <TiersForm unlimitedTier={tiers.unlimited} standardTiers={tiers.standard} productPackage={productPackage} />
            )}
        </PageLayout>
    );
};
