import { useDebouncedValue } from "@mantine/hooks";
import type { FC } from "react";
import { useEffect, useState } from "react";

import { Confirm } from "src/components";
import { useTranslation } from "src/translations";
import type { SupplyLimitWithShipToCountDto } from "src/types";
import { isEmpty, useOnUpdate } from "src/utils";
import { ConfirmSetDefaultContent, Tier } from "../components";

export type TierInputProps = Readonly<{
    tier: SupplyLimitWithShipToCountDto;
    value?: SupplyLimitWithShipToCountDto;
    onChange: (value: SupplyLimitWithShipToCountDto) => void;
    setFocused: (id: number | null) => void;
    setTouched: () => void;
    focused: boolean;
    isAnyTierFocused: boolean;
    inputId: string;
    error?: string;
    openConfirm: (focusedTier: SupplyLimitWithShipToCountDto) => void;
    onSave: () => void;
    reset: () => void;
}>;

// If you want to use input in InputField component you have to use forwardRef for autofocus,
// but in this component we don't need that functionality so we can ignore forwarded ref
export const TierInput: FC<TierInputProps> = ({
    tier,
    value,
    onChange,
    isAnyTierFocused,
    inputId,
    focused,
    setFocused,
    setTouched,
    error,
    openConfirm,
    onSave,
    reset,
}) => {
    const [localDailyLimit, setLocalDailyLimit] = useState<number | undefined>(value?.dailyLimit || undefined);
    const [localMonthlyLimit, setLocalMonthlyLimit] = useState<number | undefined>(value?.monthlyLimit || undefined);
    const [debouncedDailyLimit] = useDebouncedValue<number | undefined>(localDailyLimit, 300);
    const [debouncedMonthlyLimit] = useDebouncedValue<number | undefined>(localMonthlyLimit, 300);
    const { t } = useTranslation();

    useEffect(() => {
        setLocalDailyLimit(isEmpty(value?.dailyLimit) ? undefined : value?.dailyLimit);
        setLocalMonthlyLimit(isEmpty(value?.monthlyLimit) ? undefined : value?.monthlyLimit);
    }, [value]);

    useOnUpdate(() => {
        if (!isEmpty(debouncedDailyLimit) && !isEmpty(debouncedMonthlyLimit)) {
            setTouched();
            onChange({ ...tier, dailyLimit: debouncedDailyLimit, monthlyLimit: debouncedMonthlyLimit });
        }
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [debouncedDailyLimit, debouncedMonthlyLimit]);

    const onReset = () => {
        if (value) {
            onChange({ ...tier, dailyLimit: value.dailyLimit, monthlyLimit: value.monthlyLimit });
        }
        setFocused(null);
        reset();
    };

    const setDefaultTier = () => {
        if (!isEmpty(value?.dailyLimit) && !isEmpty(value?.monthlyLimit)) {
            const updatedTier = {
                id: tier.id,
                isDefault: true,
                monthlyLimit: value?.monthlyLimit!!,
                dailyLimit: value?.dailyLimit!!,
            };
            setFocused(tier.id);
            onChange({ ...tier, ...updatedTier });
            onSave();
        }
    };

    const onTierSave = () => {
        if (!isEmpty(localMonthlyLimit) && !isEmpty(localDailyLimit)) {
            const updatedTier = { ...tier, dailyLimit: localDailyLimit, monthlyLimit: localMonthlyLimit };
            onChange(updatedTier);
            openConfirm(updatedTier);
        }
    };

    return (
        <Confirm
            title={t("supplyLimits.tiers.setDefaultConfirm.title")}
            content={<ConfirmSetDefaultContent />}
            confirmButtonTransKey="supplyLimits.tiers.setDefaultConfirm.confirmButton"
            onConfirm={setDefaultTier}
        >
            {(openSetDefaultConfirm) => (
                <Tier
                    tierId={tier.id}
                    inputId={inputId}
                    tierNumber={tier.tierNumber}
                    dailyLimit={localDailyLimit}
                    isDefault={tier.isDefault}
                    changeDailyLimit={setLocalDailyLimit}
                    monthlyLimit={localMonthlyLimit}
                    changeMonthlyLimit={setLocalMonthlyLimit}
                    onSave={onTierSave}
                    setFocused={() => setFocused(tier.id)}
                    focused={focused}
                    isAnyTierFocused={isAnyTierFocused}
                    setDefaultTier={openSetDefaultConfirm}
                    error={error}
                    onReset={onReset}
                    productPackageId={tier.productPackageId}
                    pharmaciesCount={tier.shipToCount}
                />
            )}
        </Confirm>
    );
};
