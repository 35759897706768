import type { RequestErrorType } from "src/types";

export const getApiUrl = () => {
    const developmentUrl = APP_ENV.BE_ENV === "local" ? "http://localhost:8080/api" : "https://api.dev.astrazeneca-dtp.morosystems.cz/api";
    return window.location.hostname === "localhost" ? developmentUrl : `${window.location.protocol}//api.${window.location.host}/api`;
};

export const createGlobalErrorMessage = (error: RequestErrorType, fallbackError?: string) => {
    if (error?.data?.globalErrors?.length > 0) {
        return error?.data?.globalErrors.join("\n");
    }
    // This should never happen
    return fallbackError ?? "Unexpected error";
};
