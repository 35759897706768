import styled from "styled-components";

import { Checkbox } from "src/components";

export const HeaderTableCheckbox = styled(Checkbox)`
    margin-bottom: 0.25rem;
    & .mantine-Checkbox-input {
        background-color: transparent;
        border-color: ${({ theme }) => theme.color.primary50};
        &:checked {
            background-color: ${({ theme }) => theme.color.primary50};
            border-color: ${({ theme }) => theme.color.primary50};
            & + svg {
                color: ${({ theme }) => theme.color.primary600};
            }
        }
    }
`;

export const TableCheckbox = styled(Checkbox)`
    & .mantine-Checkbox-input {
        background-color: transparent;
        border-color: ${({ theme }) => theme.color.primary600};
        &:checked {
            background-color: ${({ theme }) => theme.color.primary600};
        }
    }
`;
