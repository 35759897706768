import type { TFunction } from "i18next";
import type { FC } from "react";

import { LabelValuePair, TabularText, Tooltip } from "src/components";
import { Color } from "src/theme";
import type { CustomerBranchDto } from "src/types";

type BranchesCellProps = {
    branches: Array<CustomerBranchDto>;
    t: TFunction;
};

export const IsHospitalCell: FC<BranchesCellProps> = ({ t, branches }) =>
    branches.length > 0 ? (
        <Tooltip
            withinPortal={false}
            openDelay={1000}
            backgroundColor={Color.accent50}
            color={Color.supportGraphite500}
            label={
                <TabularText columnGap="0.5rem">
                    {branches.map((branch) => (
                        <LabelValuePair key={branch.id} label={branch.code} value={branch.name} />
                    ))}
                </TabularText>
            }
        >
            <div>{branches.some((branch) => branch.isHospital) ? t("common.yes") : t("common.no")}</div>
        </Tooltip>
    ) : null;
