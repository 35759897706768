import type { FC } from "react";

import { PhoenixInterfaceOperationFilterDtoInterfaceOperationTypeEnum } from "src/types";
import { importInterfaceTableModule, overrideInterfaceTableRowProps } from "../containers";

export const ImportInterfaces: FC = () => (
    <importInterfaceTableModule.Container
        overrideRowProps={overrideInterfaceTableRowProps}
        additionalQueryParams={{ interfaceOperation: PhoenixInterfaceOperationFilterDtoInterfaceOperationTypeEnum.Incoming }}
    />
);
