import styled, { css } from "styled-components";

import { maxWidthBreakpoint } from "src/theme";

export const EmergencyOrdersFooterLayout = styled.div`
    display: grid;
    grid-template-columns: 1fr minmax(auto, 900px) 1fr;
    grid-template-rows: 6.4rem;
    gap: 3.8rem;
    position: absolute;
    bottom: 0;
    left: 0;
    background-color: ${({ theme }) => theme.color.primary600};
    padding: 0.8rem 1.6rem;
    width: 100%;
    align-items: center;
    color: ${({ theme }) => theme.color.neutral50};
    font-size: 1.4rem;

    ${maxWidthBreakpoint.tablet(css`
        grid-template-columns: 1fr;
        gap: 0;
    `)}
`;
