import type { FC } from "react";
import { useNavigate, useSearchParams } from "react-router-dom";

import { Flex, TabPanel, Tabs } from "src/components";
import { createSearchParamsAsString, routes, ShipToDetailTab, TAB_PARAM } from "src/routes";
import { useTranslation } from "src/translations";
import type { CustomerDto } from "src/types";
import { createIsEnum } from "src/utils";
import { Attachments, DataModificationLog, Notes, SegmentedInfo } from "../../containers";
import { EmergencyOrders } from "./emergencyOrders";
import { ShipToSpecialPrices } from "./shipToSpecialPrices";
import { ShipToSupplyLimit } from "./shipToSupplyLimit";

type ShipToDetailProps = {
    customerDetail: CustomerDto;
};

const isShipToDetailTabEnum = createIsEnum(ShipToDetailTab);

export const ShipToDetail: FC<ShipToDetailProps> = ({ customerDetail }) => {
    const { t } = useTranslation();
    const navigate = useNavigate();
    const { customerId } = routes.customerDetail.useParams();
    const [searchParams] = useSearchParams();

    const activeTabFromSearchParam = searchParams.get(TAB_PARAM);

    const activeTab =
        activeTabFromSearchParam && isShipToDetailTabEnum(activeTabFromSearchParam)
            ? activeTabFromSearchParam
            : ShipToDetailTab.emergencyOrders;

    return (
        <Flex flexDirection="column" gap="3rem">
            <SegmentedInfo isPayer={false} customerDetail={customerDetail} />
            <Tabs<ShipToDetailTab>
                activeTab={activeTab}
                onTabChange={(tabValue: ShipToDetailTab) =>
                    navigate(
                        `${routes.customerDetail.fillPathParams({ customerId })}?${createSearchParamsAsString({
                            [TAB_PARAM]: tabValue,
                        })}`,
                    )
                }
                tabs={[
                    {
                        tabValue: ShipToDetailTab.emergencyOrders,
                        label: t("shipToDetail.tabs.emergencyOrders"),
                    },
                    {
                        tabValue: ShipToDetailTab.supplyLimits,
                        label: t("shipToDetail.tabs.supplyLimits"),
                    },
                    {
                        tabValue: ShipToDetailTab.specialPrices,
                        label: t("shipToDetail.tabs.specialPrices"),
                    },
                    {
                        tabValue: ShipToDetailTab.dataModificationLog,
                        label: t("shipToDetail.tabs.dataModificationLog"),
                    },
                    {
                        tabValue: ShipToDetailTab.notes,
                        label: t("shipToDetail.tabs.notes"),
                    },
                    {
                        tabValue: ShipToDetailTab.attachments,
                        label: t("shipToDetail.tabs.attachments"),
                    },
                ]}
            >
                <TabPanel value={ShipToDetailTab.emergencyOrders}>
                    <EmergencyOrders customerName={customerDetail.name} />
                </TabPanel>
                <TabPanel value={ShipToDetailTab.supplyLimits}>
                    <ShipToSupplyLimit />
                </TabPanel>
                <TabPanel value={ShipToDetailTab.specialPrices}>
                    <ShipToSpecialPrices />
                </TabPanel>
                <TabPanel value={ShipToDetailTab.dataModificationLog}>
                    <DataModificationLog />
                </TabPanel>
                <TabPanel value={ShipToDetailTab.notes}>
                    <Notes />
                </TabPanel>
                <TabPanel value={ShipToDetailTab.attachments}>
                    <Attachments customerId={customerId} />
                </TabPanel>
            </Tabs>
        </Flex>
    );
};
