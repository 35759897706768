import type { TFunction } from "i18next";
import type { NavigateFunction } from "react-router-dom";
import type { Row } from "react-table";

import { ActionIcon, Icon, TableAction } from "src/components";
import { ConfirmContextConsumer } from "src/modal";
import { routes } from "src/routes";
import { ICON_TABLE_SIZE } from "src/table";
import { Color } from "src/theme";
import type { SupplyLimitSuggestionDto } from "src/types";

export const createActionColumn =
    (
        t: TFunction,
        navigate: NavigateFunction,
        approveSuggestions: (suggestionsId: number) => void,
        refuseSuggestions: (suggestionsId: number) => void,
    ) =>
    ({ original }: Row<SupplyLimitSuggestionDto>): JSX.Element =>
        (
            <TableAction>
                {original.suggestedTierId ? (
                    <ConfirmContextConsumer>
                        {({ showConfirm }) => {
                            const confirm = async () => {
                                const confirmResult = await showConfirm({
                                    title: t("dashboard.supplyLimitSuggestions.changeTier.confirm.title"),
                                    content: t("dashboard.supplyLimitSuggestions.changeTier.confirm.content", {
                                        fromMonthlyLimit: original.currentTierMonthlyLimit,
                                        toMonthlyLimit: original.suggestedTierMonthlyLimit,
                                    }),
                                });
                                if (confirmResult) {
                                    approveSuggestions(original.id);
                                }
                            };
                            return (
                                <ActionIcon
                                    onClick={confirm}
                                    color={Color.success100}
                                    tooltip={t("dashboard.supplyLimitSuggestions.changeTier.tooltip")}
                                >
                                    <Icon.Checkmark size={ICON_TABLE_SIZE} color={Color.success500} />
                                </ActionIcon>
                            );
                        }}
                    </ConfirmContextConsumer>
                ) : (
                    <ActionIcon
                        onClick={() =>
                            navigate(routes.supplyLimitsDetail.fillPathParams({ productPackageId: original.productPackageId.toString() }))
                        }
                        color={Color.accent100}
                        tooltip={t("dashboard.supplyLimitSuggestions.createSupplyLimit")}
                    >
                        <Icon.AddSquare size={ICON_TABLE_SIZE} color={Color.supportNavy500} />
                    </ActionIcon>
                )}
                <ConfirmContextConsumer>
                    {({ showConfirm }) => {
                        const confirm = async () => {
                            const confirmResult = await showConfirm({
                                title: t("dashboard.supplyLimitSuggestions.removeSuggestion.confirm.title"),
                                content: t("dashboard.supplyLimitSuggestions.removeSuggestion.confirm.content"),
                            });
                            if (confirmResult) {
                                refuseSuggestions(original.id);
                            }
                        };
                        return (
                            <ActionIcon
                                onClick={confirm}
                                color={Color.error100}
                                tooltip={t(`dashboard.supplyLimitSuggestions.removeSuggestion.tooltip`)}
                            >
                                <Icon.Delete size={ICON_TABLE_SIZE} color={Color.error500} />
                            </ActionIcon>
                        );
                    }}
                </ConfirmContextConsumer>
            </TableAction>
        );
