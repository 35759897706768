import type { TableCommonProps } from "react-table";
import styled from "styled-components";

/**
 * Table body component
 * Ready to use with Table component
 */
export const TBody = styled.tbody<TableCommonProps>`
    font-size: 1.4rem;
`;
