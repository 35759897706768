import { configureStore } from "@reduxjs/toolkit";

import { api, publicApi } from "src/api";
import { GlobalErrorEvents } from "src/errorHandling";
import { EMPTY_OBJECT, isDevelopment } from "src/utils";
import { getRootReducer } from "./reducer";

/**
 * Creates redux store with root reducer.
 * Sets up top level error handling for root reducer.
 */
const createStore = () => {
    const rootReducer = getRootReducer();
    const errorHandlingReducer: typeof rootReducer = (state, action) => {
        try {
            return rootReducer(state, action);
        } catch (error) {
            GlobalErrorEvents.triggerReducerErrorEvent(error);
            /* Error at this place is unrecoverable, app cannot be used anymore and ErrorPage is displayed.
             * For this reason we can return previous state or empty object and type cast it. */
            return (state ?? EMPTY_OBJECT) as StoreState;
        }
    };

    return configureStore({
        reducer: errorHandlingReducer,
        middleware: (getDefaultMiddleware) =>
            getDefaultMiddleware({
                immutableCheck: false,
            }).prepend(api.middleware, publicApi.middleware),
        devTools: isDevelopment(),
    });
};

/**
 * Application store.
 * Use as prop for react-redux Provider component.
 */
export const store = createStore();

export type StoreState = ReturnType<typeof store.getState>;
export type StoreDispatch = typeof store.dispatch;
