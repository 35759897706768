import type { TFunction } from "react-i18next";
import type { Cell, Row } from "react-table";

import { useGetProductBrandsWithSupplyLimitsQuery } from "src/api";
import { HighlightCell, NumberColumnFilter, Text, TextColumnFilter } from "src/components";
import { UNLIMITED_TIER_NUMBER } from "src/supplyLimitSelect";
import { createTableModule, ExpandRowType, TableNames } from "src/table";
import { Color } from "src/theme";
import type { ExpandColumnsType, ExpandTableProductWithSupplyLimitsType, SupplyLimitWithPackageAndMarginDto } from "src/types";

const createColumns = (t: TFunction): ExpandColumnsType<ExpandTableProductWithSupplyLimitsType> => [
    {
        width: 350,
        minWidth: 150,
        accessor: "productPackageName" as const,
        Header: t("copyTiers.supplyLimits.product") as string,
        Filter: TextColumnFilter,
        noWrap: true,
    },
    {
        minWidth: 120,
        Header: t("copyTiers.supplyLimits.tier") as string,
        accessor: "tierNumber" as const,
        Filter: NumberColumnFilter,
        // This will change auto reset behavior of the filter, it will allow zero as value
        filter: "exact",
        Cell: ({ row }: Cell<SupplyLimitWithPackageAndMarginDto>) => (
            <HighlightCell highlight={!row.original.isDefault}>
                {row.original.tierNumber === UNLIMITED_TIER_NUMBER ? t("common.unlimited") : row.original.tierNumber}
            </HighlightCell>
        ),
    },
    {
        minWidth: 120,
        Header: t("copyTiers.supplyLimits.dailyLimit") as string,
        accessor: "dailyLimit" as const,
        Filter: NumberColumnFilter,
        filter: "exact",
        align: "end",
    },
    {
        minWidth: 120,
        Header: t("copyTiers.supplyLimits.monthlyLimit") as string,
        accessor: "monthlyLimit" as const,
        Filter: NumberColumnFilter,
        filter: "exact",
        align: "end",
    },
];

export const createExpandRow = (row: Row<ExpandTableProductWithSupplyLimitsType>): ExpandRowType => ({
    accessor: "productPackageName" as const,
    colspan: 4,
    component: (
        <Text color={Color.primary600} weight={700} uppercase whiteSpace="nowrap">
            {row.original.name}
        </Text>
    ),
});

export const supplyLimitsTable = createTableModule<ExpandTableProductWithSupplyLimitsType>(
    TableNames.supplyLimits,
    useGetProductBrandsWithSupplyLimitsQuery,
    createColumns,
);
