import type { TFunction } from "react-i18next";
import type { Cell } from "react-table";

import { useGetProductPricesQuery } from "src/api";
import { DateColumnFilter, TextColumnFilter } from "src/components";
import { createTableModule, TableNames } from "src/table";
import type { ExpandColumnsType, ExpandTableProductPackageWithPricesType } from "src/types";
import { currencyFormatter, dateTimeFormatter } from "src/utils";

const createColumns = (t: TFunction): ExpandColumnsType<ExpandTableProductPackageWithPricesType> => [
    {
        width: 250,
        minWidth: 100,
        accessor: "name" as const,
        noWrap: true,
        Header: t("prices.table.name") as string,
        Filter: TextColumnFilter,
    },
    {
        minWidth: 100,
        Header: t("prices.table.validFrom") as string,
        accessor: "validFrom" as const,
        Cell: ({ value }: Cell<ExpandTableProductPackageWithPricesType>) => dateTimeFormatter(value),
        Filter: DateColumnFilter,
    },
    {
        minWidth: 100,
        Header: t("prices.table.validTo") as string,
        accessor: "validTo" as const,
        Cell: ({ value }: Cell<ExpandTableProductPackageWithPricesType>) => dateTimeFormatter(value),
        Filter: DateColumnFilter,
    },
    {
        minWidth: 100,
        Header: t("prices.table.price") as string,
        accessor: "price" as const,
        noWrap: true,
        align: "flex-end",
        disableFilters: true,
        Cell: ({ value }: Cell<ExpandTableProductPackageWithPricesType>) => currencyFormatter(value),
    },
];

export const tableModule = createTableModule<ExpandTableProductPackageWithPricesType>(
    TableNames.productPrice,
    useGetProductPricesQuery,
    createColumns,
);
