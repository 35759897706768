import type { TFunction } from "i18next";
import type { ReactNode } from "react";
import type { Filters, Row } from "react-table";

import { convertPayersDistributionMarginTableFilterNames } from "src/api";
import { ActionIcon, Button, Grid, Icon, TableAction, Text } from "src/components";
import { ConfirmContextConsumer } from "src/modal";
import { ExpandRowType, getChildIdFromRowId, getParentAndChildrenFromTablePage, ICON_TABLE_SIZE } from "src/table";
import { Color } from "src/theme";
import type {
    CustomerFilterDto,
    ExpandTableDistributionMarginsType,
    ProductBrandWithMarginsDto,
    ProductPackageWithMarginDto,
} from "src/types";
import { cleanArray, isEmpty } from "src/utils";
import type { ModalAttributes } from "./types";

const PRODUCT_PACKAGE_WITH_MARGIN_DEPTH_LEVEL = 1;

export const createActionColumn =
    (t: TFunction, customerId: string, openModal: (value: ModalAttributes) => void) =>
    ({ depth, id }: Row<ExpandTableDistributionMarginsType>, page: Array<Row<ExpandTableDistributionMarginsType>>): ReactNode => {
        const productWithDistributionMargins = getParentAndChildrenFromTablePage<
            ExpandTableDistributionMarginsType,
            ProductBrandWithMarginsDto,
            ProductPackageWithMarginDto
        >(page, id);
        const productPackageWithMargin = productWithDistributionMargins?.child;
        return depth === PRODUCT_PACKAGE_WITH_MARGIN_DEPTH_LEVEL && !isEmpty(productPackageWithMargin) ? (
            <TableAction>
                <ActionIcon
                    tooltip={t("payerDetail.distributionMargins.editTooltip")}
                    onClick={() =>
                        openModal({
                            productPackagesWithMarginIds: [productPackageWithMargin.id],
                            name: productPackageWithMargin?.productPackageName,
                            marginValue: productPackageWithMargin?.margin,
                            filterFromTable: { customerId: parseInt(customerId, 10) },
                        })
                    }
                    color={Color.accent100}
                >
                    <Icon.Edit size={ICON_TABLE_SIZE} color={Color.primary600} filled />
                </ActionIcon>
            </TableAction>
        ) : null;
    };

export const createActionsForSelection =
    (t: TFunction, customerId: string, openModal: (value: ModalAttributes) => void, onResetMargins: (value: ModalAttributes) => void) =>
    (isAllRowsSelected: boolean, selectedRowIds: Record<number, boolean>, filters: Filters<CustomerFilterDto>) => {
        const filterDto = filters.reduce((all, { id, value }) => ({ [id]: value, ...all }), { customerId: parseInt(customerId, 10) });
        const attributes = {
            productPackagesWithMarginIds: cleanArray(
                Object.entries(selectedRowIds).map(([key]) => {
                    const id = getChildIdFromRowId(key);
                    return id ? Number(id) : undefined;
                }),
            ),
            filterFromTable: convertPayersDistributionMarginTableFilterNames(filterDto),
        };
        const onChangeSelected = () => {
            openModal(attributes);
        };
        return (
            <Grid gridAutoFlow="column" gap="1rem">
                <ConfirmContextConsumer>
                    {({ showConfirm }) => {
                        const confirm = async () => {
                            const confirmResult = await showConfirm({
                                title: t("payerDetail.distributionMargins.resetMargins.title"),
                                content: t("payerDetail.distributionMargins.resetMargins.content"),
                            });
                            if (confirmResult) {
                                onResetMargins(attributes);
                            }
                        };
                        return (
                            <Button
                                disabled={!isAllRowsSelected && Object.keys(selectedRowIds).length === 0}
                                type="button"
                                onClick={confirm}
                                variant="outline"
                            >
                                {t("payerDetail.distributionMargins.resetMargins.button")}
                            </Button>
                        );
                    }}
                </ConfirmContextConsumer>
                <Button disabled={!isAllRowsSelected && Object.keys(selectedRowIds).length === 0} type="button" onClick={onChangeSelected}>
                    {t("payerDetail.distributionMargins.changeSelected")}
                </Button>
            </Grid>
        );
    };

export const createExpandRow = (row: Row<ExpandTableDistributionMarginsType>): ExpandRowType => ({
    accessor: "productPackageName",
    colspan: 2,
    component: (
        <Text color={Color.primary600} weight={700} uppercase whiteSpace="nowrap">
            {row.original.name}
        </Text>
    ),
});
