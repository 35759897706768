import { combineReducers, createReducer, Reducer } from "@reduxjs/toolkit";

import { EMPTY_OBJECT } from "src/utils";
import { closeAllModals, closeModal, openModal } from "./actions";
import type { ModalsState } from "./types";

const initialState: ModalsState = {
    modals: EMPTY_OBJECT,
} as const;

const modals: Reducer<ModalsState["modals"]> = createReducer(initialState.modals, (builder) => {
    builder
        .addCase(openModal, (draft, action) => {
            draft[action.payload] = true;
        })
        .addCase(closeModal, (draft, action) => {
            if (draft[action.payload]) {
                draft[action.payload] = false;
            }
        })
        .addCase(closeAllModals, () => EMPTY_OBJECT);
});

export const reducer: Reducer<ModalsState> = combineReducers({ modals });
