import type { FC } from "react";

import { useUploadSapFileMutation } from "src/api";
import { Button, FileInput, FileInputProps, Flex, Modal } from "src/components";
import { Form, InputField } from "src/form";
import { useTranslation } from "src/translations";
import { AggregationToolFormLayout } from "./components";

type ImportSapFileFormProps = {
    closeModal: () => void;
    isOpen: boolean;
};

export const ImportSapFileForm: FC<ImportSapFileFormProps> = ({ closeModal, isOpen }) => {
    const { t } = useTranslation();
    const [uploadSapFile] = useUploadSapFileMutation();

    const onSubmit = (values: { file: File }) => {
        const formData = new FormData();
        formData.append("file", values.file);
        return uploadSapFile(formData);
    };

    return (
        <Modal title={t("importExport.aggregatorTool.importSapFile.title")} opened={isOpen} onClose={closeModal}>
            <Form<{ file: File }>
                successMessage={t("importExport.aggregatorTool.uploadSuccess")}
                onSuccess={closeModal}
                onSubmit={onSubmit}
                initialValues={{}}
                layoutComponent={AggregationToolFormLayout}
            >
                {({ dirty }) => (
                    <>
                        <InputField<FileInputProps["value"], FileInputProps>
                            name="file"
                            input={FileInput}
                            required
                            inputProps={{
                                label: t("importExport.aggregatorTool.file.label"),
                                placeholder: t("importExport.aggregatorTool.file.placeholder"),
                                accept: "application/vnd.ms-excel",
                            }}
                        />
                        <Flex justifyContent="center">
                            <Button type="submit" disabled={!dirty}>
                                {t("importExport.aggregatorTool.upload")}
                            </Button>
                        </Flex>
                    </>
                )}
            </Form>
        </Modal>
    );
};
