import styled, { css } from "styled-components";

import { maxWidthBreakpoint } from "src/theme";

export const CardLayout = styled.div`
    display: grid;
    grid-auto-columns: 1fr;
    grid-auto-flow: column;
    margin-bottom: 2rem;
    gap: 2rem;

    ${maxWidthBreakpoint.desktopSmall(css`
        grid-template-columns: 1fr 1fr;
        grid-auto-flow: row;
    `)}

    ${maxWidthBreakpoint.tablet(css`
        grid-template-columns: minmax(0, 1fr);
    `)}
`;
