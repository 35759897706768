import { Link } from "react-router-dom";
import styled, { css } from "styled-components";

const WidgetRowStyles = css`
    padding: 1rem 0;
    color: ${({ theme }) => theme.color.supportNavy500};
    text-decoration: none;
    border-bottom: 1px solid ${({ theme }) => theme.color.neutral200};
    transition: transform 0.1s ease;

    &:hover {
        cursor: pointer;
    }
`;

export const WidgetRowLink = styled(Link)<{ $withoutDecoration?: boolean }>`
    ${WidgetRowStyles}
    ${({ $withoutDecoration }) =>
        $withoutDecoration &&
        css`
            border-bottom: none;
        `}
`;

export const WidgetRow = styled.div<{ $withoutDecoration?: boolean }>`
    ${WidgetRowStyles}
    ${({ $withoutDecoration }) =>
        $withoutDecoration &&
        css`
            border-bottom: none;
        `}
`;
