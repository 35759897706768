export class GlobalFormError extends Error {
    data: { globalErrors: Array<string> };

    constructor(msg: string) {
        super(msg);
        this.data = {
            globalErrors: [msg],
        };

        // Set the prototype explicitly.
        Object.setPrototypeOf(this, GlobalFormError.prototype);
    }

    add(newError: string) {
        this.data.globalErrors.push(newError);
    }
}
