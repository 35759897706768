import type { TFunction } from "i18next";
import type { ReactNode } from "react";
import type { NavigateFunction } from "react-router-dom";
import type { Row } from "react-table";

import { ActionIcon, Icon, PackagePlusIcon, TableAction, Text } from "src/components";
import { createSearchParamsAsString, routes } from "src/routes";
import type { ExpandRowType } from "src/table";
import { createFiltersSearchParam, getParentAndChildrenFromTablePage, ICON_TABLE_SIZE, TableNames } from "src/table";
import { Color } from "src/theme";
import type { ExpandTableProductType, ProductBrandDto, ProductPackageDto } from "src/types";
import { EMPTY_OBJECT } from "src/utils";

const PRODUCT_BRAND_DEPTH_LEVEL = 0;

export const createActionColumn =
    (
        t: TFunction,
        navigate: NavigateFunction,
        openProductBrandFormModal: (productBrand: ProductBrandDto) => void,
        openProductPackageFormModal: (productBrand: ProductBrandDto, productPackage?: ProductPackageDto) => void,
    ) =>
    ({ depth, id }: Row<ExpandTableProductType>, page: Array<Row<ExpandTableProductType>>): ReactNode => {
        const productBrandAndProductPackage = getParentAndChildrenFromTablePage<ExpandTableProductType, ProductBrandDto, ProductPackageDto>(
            page,
            id,
        );
        const productBrand: ProductBrandDto = productBrandAndProductPackage.parent;
        const productPackage: ProductPackageDto | undefined = productBrandAndProductPackage.child;
        const isProductBrandArchived = productBrand.archived;
        const productBrandIconColors: Color = isProductBrandArchived ? Color.supportGraphite300 : Color.primary600;
        const productPackageIconColors: Color = isProductBrandArchived ? Color.supportNavy500 : Color.primary600;
        return (
            <TableAction>
                {depth === PRODUCT_BRAND_DEPTH_LEVEL ? (
                    <>
                        <ActionIcon
                            tooltip={t("products.updateBrandTooltip")}
                            disabled={isProductBrandArchived}
                            onClick={() => openProductBrandFormModal(productBrand)}
                            color={Color.supportNavy100}
                        >
                            <Icon.Edit size={ICON_TABLE_SIZE} color={productBrandIconColors} filled />
                        </ActionIcon>
                        <ActionIcon
                            tooltip={t("products.createPackageTooltip")}
                            disabled={isProductBrandArchived}
                            onClick={() => openProductPackageFormModal(productBrand)}
                            color={Color.supportNavy100}
                        >
                            <PackagePlusIcon color={productBrandIconColors} />
                        </ActionIcon>
                    </>
                ) : (
                    <>
                        <ActionIcon
                            disabled={isProductBrandArchived}
                            onClick={() => openProductPackageFormModal(productBrand, productPackage)}
                            color={Color.accent100}
                            tooltip={t("products.updatePackageTooltip")}
                        >
                            <Icon.Edit size={ICON_TABLE_SIZE} color={productPackageIconColors} />
                        </ActionIcon>
                        {productPackage && (
                            <>
                                <ActionIcon
                                    tooltip={t("products.goToPriceListTooltip")}
                                    disabled={isProductBrandArchived || !productPackage.active}
                                    onClick={() =>
                                        navigate(
                                            `${routes.prices.path}?${createSearchParamsAsString({
                                                [createFiltersSearchParam(TableNames.productPrice)]: { name: productPackage.name },
                                            })}`,
                                        )
                                    }
                                    color={Color.accent100}
                                >
                                    <Icon.Money size={ICON_TABLE_SIZE} color={productPackageIconColors} />
                                </ActionIcon>
                                <ActionIcon
                                    tooltip={t("products.goToSupplyLimitTooltip")}
                                    disabled={isProductBrandArchived || !productPackage.active}
                                    onClick={() =>
                                        navigate(
                                            routes.supplyLimitsDetail.fillPathParams({ productPackageId: productPackage.id.toString() }),
                                        )
                                    }
                                    color={Color.accent100}
                                >
                                    <Icon.VehicleTruck size={ICON_TABLE_SIZE} color={productPackageIconColors} />
                                </ActionIcon>
                            </>
                        )}
                    </>
                )}
            </TableAction>
        );
    };

export const createExpandRow = (row: Row<ExpandTableProductType>): ExpandRowType => ({
    accessor: "name",
    colspan: 5,
    component: (
        <Text color={Color.primary600} weight={700} uppercase whiteSpace="nowrap">
            {row.values.name}
        </Text>
    ),
});

export const overrideRowProps = (row: Row<ExpandTableProductType>): Record<string, unknown> => {
    if (row.depth === PRODUCT_BRAND_DEPTH_LEVEL) {
        if (row.values.archived) {
            return { $backgroundColor: Color.supportNavy50 };
        }
        if (!row.values.active) {
            return { $disabled: true };
        }
    } else if (row.values.archived || !row.values.active) {
        return { $disabled: true };
    }

    return EMPTY_OBJECT;
};
