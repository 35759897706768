import styled from "styled-components";

/**
 * html table don't support border radius with collapsed border,
 * therefore we have to wrap our table into div with border
 */
export const TableBorder = styled.div`
    border: ${({ theme }) => `0.5px solid ${theme.color.neutral200}`};
    border-radius: ${({ theme }) => theme.radius.default};
    box-shadow: 0 4px 25px rgba(148, 163, 187, 0.32);
    width: 100%;
    position: relative;
    overflow-x: auto;
`;
