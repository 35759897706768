import type { FC } from "react";

import { Text } from "src/components";
import { useTranslation } from "src/translations";

export const NotesInfo: FC = () => {
    const { t } = useTranslation();

    return (
        <>
            <Text>{t("payerDetail.notes.info.text")}</Text>
            <ul>
                <li>
                    <Text>{t("payerDetail.notes.info.info1")}</Text>
                </li>
                <li>
                    <Text>{t("payerDetail.notes.info.info2")}</Text>
                </li>
                <li>
                    <Text>{t("payerDetail.notes.info.info3")}</Text>
                </li>
                <li>
                    <Text>{t("payerDetail.notes.info.info4")}</Text>
                </li>
            </ul>
        </>
    );
};
