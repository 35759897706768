import type { FC } from "react";

import { useGetCustomerDetailQuery, useUpdateCustomerNoteMutation } from "src/api";
import { Button, Flex, Grid, LoadingOverlay, RichTextEditor, RichTextEditorProps, Text, Tooltip } from "src/components";
import { Form, InputField } from "src/form";
import { routes } from "src/routes";
import { Color } from "src/theme";
import { useTranslation } from "src/translations";
import type { UpdateNoteDto } from "src/types";
import { NotesInfo } from "./NotesInfo";

export const Notes: FC = () => {
    const { customerId } = routes.customerDetail.useParams();
    const { data: customerDetail, isLoading } = useGetCustomerDetailQuery(customerId);
    const [updateCustomerNote] = useUpdateCustomerNoteMutation();

    const { t } = useTranslation();
    const initialValues = { note: customerDetail?.note ?? "", customerId: Number(customerId) };

    return (
        <>
            <LoadingOverlay loading={isLoading} />
            <Form<UpdateNoteDto>
                onSubmit={updateCustomerNote}
                initialValues={initialValues}
                loadingMessage={t("payerDetail.notes.loadingMessage")}
                successMessage={t("payerDetail.notes.successMessage")}
                layoutGap="3rem"
            >
                {({ dirty }) => (
                    <>
                        <Grid gridAutoFlow="row" gap="0.2rem">
                            <Flex>
                                <Tooltip
                                    multiline
                                    withinPortal={false}
                                    backgroundColor={Color.neutral200}
                                    color={Color.supportNavy500}
                                    label={<NotesInfo />}
                                >
                                    <Text size="1.4rem" color={Color.primary600}>
                                        {t("payerDetail.notes.info.help")}
                                    </Text>
                                </Tooltip>
                            </Flex>
                            <InputField<RichTextEditorProps["value"], RichTextEditorProps>
                                name="note"
                                input={RichTextEditor}
                                inputProps={{ minHeight: "100px" }}
                            />
                        </Grid>
                        <Flex>
                            <Button type="submit" disabled={!dirty}>
                                {t("payerDetail.notes.save")}
                            </Button>
                        </Flex>
                    </>
                )}
            </Form>
        </>
    );
};
