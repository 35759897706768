import type { FC, ReactNode } from "react";
import styled, { CSSProperties } from "styled-components";

import type { Color } from "src/theme";

type TabularTextProps = {
    maxWidth?: string;
    columnGap?: string;
    rowGap?: string;
    color?: Color;
};

export const TabularText: FC<TabularTextProps> = ({ children, maxWidth, columnGap, rowGap, color }) => (
    <StyledTable maxWidth={maxWidth} columnGap={columnGap} rowGap={rowGap} color={color}>
        <tbody>{children}</tbody>
    </StyledTable>
);

type LabelValuePairProps = {
    label: ReactNode;
    value: ReactNode;
    valueAlign?: CSSProperties["textAlign"];
};

export const LabelValuePair: FC<LabelValuePairProps> = ({ label, value, valueAlign }) => (
    <tr>
        <td>{label}</td>
        <ValueCell valueAlign={valueAlign}>{value}</ValueCell>
    </tr>
);

const StyledTable = styled.table<TabularTextProps>`
    border-spacing: ${({ columnGap, rowGap }) => `${columnGap || 0} ${rowGap || 0}`};
    max-width: ${({ maxWidth }) => maxWidth};
    td {
        color: ${({ color }) => color || "inherit"};
    }
`;

const ValueCell = styled.td<{ valueAlign?: CSSProperties["textAlign"] }>`
    text-align: ${({ valueAlign }) => valueAlign ?? "left"};
`;
