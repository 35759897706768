import type { FC } from "react";

import { useGetSupplyLimitForShipToAndProductPackageQuery, useUpdateSupplyLimitAndMarginMutation } from "src/api";
import {
    Button,
    Confirm,
    DrawerButtonLayout,
    DrawerFormLayout,
    DrawerScrollableLayoutWithButton,
    LoadingOverlay,
    NumberInput,
    NumberInputProps,
} from "src/components";
import { Form, InputField, percentageInputProps } from "src/form";
import { routes } from "src/routes";
import {
    type SupplyLimitSelectProps,
    SupplyLimitHeaders,
    SupplyLimitOptionsLayout,
    SupplyLimitReadOnly,
    SupplyLimitSectionHeader,
    SupplyLimitSelect,
    UNLIMITED_TIER_NUMBER,
} from "src/supplyLimitSelect";
import { useTranslation } from "src/translations";
import type { UpdateSupplyLimitAndMarginForShipToDto } from "src/types";

export type ShipToSupplyLimitFormProps = Readonly<{
    productPackageId: number | undefined;
    closeDrawer: () => void;
}>;

export const ShipToSupplyLimitForm: FC<ShipToSupplyLimitFormProps> = ({ productPackageId, closeDrawer }) => {
    const { t } = useTranslation();
    const { customerId } = routes.customerDetail.useParams();
    const [updateSupplyLimitAndMargin] = useUpdateSupplyLimitAndMarginMutation();
    const { data, isLoading } = useGetSupplyLimitForShipToAndProductPackageQuery(
        { shipToId: customerId, productPackageId: productPackageId! },
        { skip: !productPackageId },
    );

    const initialValues = {
        shipToId: Number(customerId),
        shipToMargin: data?.shipToMargin,
        newSupplyLimitId: data?.currentSupplyLimit.id || undefined,
    };

    return (
        <>
            <LoadingOverlay loading={isLoading} />
            {data ? (
                <Form<UpdateSupplyLimitAndMarginForShipToDto>
                    initialValues={initialValues}
                    onSubmit={updateSupplyLimitAndMargin}
                    onSuccess={closeDrawer}
                    layoutComponent={DrawerFormLayout}
                    loadingMessage={t("shipToDetail.supplyLimit.loadingMessage")}
                    successMessage={t("shipToDetail.supplyLimit.successMessage")}
                >
                    {({ dirty, submitForm }) => (
                        <Confirm
                            title={t("shipToDetail.supplyLimit.confirm.title")}
                            contentTransKey="shipToDetail.supplyLimit.confirm.content"
                            onConfirm={submitForm}
                        >
                            {(openConfirm) => (
                                <DrawerScrollableLayoutWithButton
                                    staticSection={
                                        <DrawerButtonLayout>
                                            <Button disabled={!dirty} type="button" onClick={openConfirm}>
                                                {t("common.confirm")}
                                            </Button>
                                            <Button type="button" variant="outline" onClick={closeDrawer}>
                                                {t("common.cancel")}
                                            </Button>
                                        </DrawerButtonLayout>
                                    }
                                >
                                    <>
                                        <SupplyLimitSectionHeader header={t("shipToDetail.supplyLimit.marginSection")} />
                                        <InputField<NumberInputProps["value"], NumberInputProps>
                                            name="shipToMargin"
                                            type="number"
                                            input={NumberInput}
                                            inputProps={{
                                                placeholder: t("shipToDetail.supplyLimit.margin.placeholder"),
                                                maxWidth: "180px",
                                                ...percentageInputProps,
                                            }}
                                        />
                                        <SupplyLimitSectionHeader header={t("shipToDetail.supplyLimit.currentTierSection")} />
                                        <SupplyLimitHeaders />
                                        <SupplyLimitOptionsLayout>
                                            {data.currentSupplyLimit && (
                                                <SupplyLimitReadOnly
                                                    key={data.currentSupplyLimit.id}
                                                    tierLabel={
                                                        data.currentSupplyLimit.tierNumber === UNLIMITED_TIER_NUMBER
                                                            ? t("common.unlimited")
                                                            : data.currentSupplyLimit.tierNumber?.toString()
                                                    }
                                                    monthlyLimit={data.currentSupplyLimit.monthlyLimit}
                                                    dailyLimit={data.currentSupplyLimit.dailyLimit}
                                                    unlimited={data.currentSupplyLimit.tierNumber === UNLIMITED_TIER_NUMBER}
                                                    isDefault={data.currentSupplyLimit.isDefault}
                                                />
                                            )}
                                        </SupplyLimitOptionsLayout>
                                        <SupplyLimitSectionHeader header={t("shipToDetail.supplyLimit.selectNewTierSection")} />
                                        <SupplyLimitHeaders />
                                        <InputField<SupplyLimitSelectProps["value"], SupplyLimitSelectProps>
                                            name="newSupplyLimitId"
                                            input={SupplyLimitSelect}
                                            required
                                            inputProps={{
                                                options: data.availableSupplyLimits,
                                            }}
                                        />
                                    </>
                                </DrawerScrollableLayoutWithButton>
                            )}
                        </Confirm>
                    )}
                </Form>
            ) : null}
        </>
    );
};
