import { nanoid } from "@reduxjs/toolkit";
import { FieldArray } from "formik";
import { FC, Fragment } from "react";

import { Button, Icon, NumberInput, NumberInputProps, Text, Textarea, TextareaProps } from "src/components";
import { InputField } from "src/form";
import { Color, IconSizes } from "src/theme";
import { useTranslation } from "src/translations";
import { ButtonLayout, SplitLine } from "../components";
import { PRODUCT_PACKAGES_ARRAY_FIELD } from "../constants";
import type { EmergencyOrderProductPackageForm } from "../types";
import { ProductPackageDependentFields } from "./ProductPackageDependentFields";

type EmergencyOrdersFormProductPackagesProps = {
    productPackages: Array<EmergencyOrderProductPackageForm>;
};

export const EmergencyOrdersFormProductPackages: FC<EmergencyOrdersFormProductPackagesProps> = ({ productPackages }) => {
    const { t } = useTranslation();

    return (
        <FieldArray
            name={PRODUCT_PACKAGES_ARRAY_FIELD}
            render={(arrayHelpers) =>
                productPackages.map((productPackage, index) => (
                    <Fragment key={productPackage.id}>
                        {index === 0 && (
                            <Text color={Color.primary600} size="1.8rem">
                                {t("publicEmergencyOrders.productPackageTitle")}
                            </Text>
                        )}
                        <ProductPackageDependentFields index={index} />
                        <InputField<NumberInputProps["value"], NumberInputProps>
                            input={NumberInput}
                            name={`${PRODUCT_PACKAGES_ARRAY_FIELD}[${index}].requestedAmount`}
                            inputProps={{
                                maxWidth: "180px",
                                min: 1,
                                max: 999,
                                label: t("publicEmergencyOrders.form.requestAmount.label"),
                            }}
                            required
                        />
                        <InputField<TextareaProps["value"], TextareaProps>
                            input={Textarea}
                            name={`${PRODUCT_PACKAGES_ARRAY_FIELD}[${index}].orderReason`}
                            inputProps={{
                                maxWidth: "400px",
                                label: t("publicEmergencyOrders.form.orderReason.label"),
                                description: t("publicEmergencyOrders.form.orderReason.description"),
                            }}
                            required
                        />
                        {index !== productPackages.length - 1 ? (
                            <SplitLine />
                        ) : (
                            <ButtonLayout noPadding>
                                <Button
                                    type="button"
                                    variant="outline"
                                    leftIcon={<Icon.Add color={Color.primary600} size={IconSizes.s} filled />}
                                    onClick={() =>
                                        arrayHelpers.push({
                                            id: nanoid(),
                                            productPackageId: null,
                                            name: "",
                                            sukl: "",
                                            requestedAmount: 1,
                                            orderReason: "",
                                        })
                                    }
                                >
                                    {t("publicEmergencyOrders.form.addProductPackage")}
                                </Button>
                                {index > 0 && (
                                    <Button variant="subtle" type="button" onClick={() => arrayHelpers.pop()}>
                                        {t("publicEmergencyOrders.form.removeProductPackage")}
                                    </Button>
                                )}
                            </ButtonLayout>
                        )}
                    </Fragment>
                ))
            }
        />
    );
};
