import { LoadingOverlay as LoadingOverlayMantine, LoadingOverlayProps as MantineLoadingOverlayProps } from "@mantine/core";
import type { FC } from "react";
import styled from "styled-components";

type LoadingOverlayProps = Readonly<{
    loading: boolean;
}>;

/**
 * Displays loading over component.
 * It must be inside wrapper with relative position
 */
export const LoadingOverlay: FC<LoadingOverlayProps> = ({ loading }) => <StyledLoadingOverlay visible={loading} />;

const StyledLoadingOverlay = styled(LoadingOverlayMantine)<LoadingOverlayProps & MantineLoadingOverlayProps>`
    z-index: ${({ theme }) => theme.zIndex.loading} !important;
    svg {
        stroke: ${({ theme }) => theme.color.primary600};
    }
`;
