import { useEffect } from "react";

import { createGlobalErrorMessage } from "src/api";
import { useTranslation } from "src/translations";
import { isRequestError } from "src/utils";
import { NotificationTypes } from "./enums";
import { useNotifications } from "./useNotifications";

export const useMutationNotification = (
    result: { isError: boolean; isSuccess: boolean; error?: unknown },
    successMessageKey: string,
    fallbackErrorMessageKey?: string,
) => {
    const notifications = useNotifications();
    const { t } = useTranslation();
    const { isError, isSuccess, error } = result;

    useEffect(() => {
        if (isError) {
            let message = "";
            if (isRequestError(error)) {
                message = createGlobalErrorMessage(error, fallbackErrorMessageKey ? t(fallbackErrorMessageKey) : undefined);
            } else {
                message = fallbackErrorMessageKey ? t(fallbackErrorMessageKey) : "Unexpected error";
            }

            notifications.showNotification({
                title: t("common.form.errorTitle"),
                message,
                type: NotificationTypes.error,
            });
        }
        if (isSuccess) {
            notifications.showNotification({
                title: t("common.form.successTitle"),
                message: t(successMessageKey),
                type: NotificationTypes.success,
            });
        }
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [isError, isSuccess]);
};
