import styled, { css } from "styled-components";

import { maxWidthBreakpoint } from "src/theme";

export const TableActionForSelection = styled.div`
    display: grid;
    grid-auto-flow: column;
    align-items: center;
    gap: 1rem;

    ${maxWidthBreakpoint.tablet(css`
        grid-auto-flow: row;
    `)}
`;
