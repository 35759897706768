import type { FC } from "react";
import { ChangeEvent, useState } from "react";
import { Link } from "react-router-dom";
import styled from "styled-components";

import foundIcon from "src/assets/icons/found_customers.svg";
import notFoundIcon from "src/assets/icons/not_found_customers.svg";
import searchingIcon from "src/assets/icons/searching_customers.svg";
import { Button, Grid, SvgIcon, Text, TextInput } from "src/components";
import { Color } from "src/theme";
import { useTranslation } from "src/translations";
import type { ShipToForCopySupplyLimitsDto } from "src/types";
import { isEmpty } from "src/utils";

type CopyCustomerWidgetComponentProps = {
    isSearching: boolean;
    sameCustomers?: ReadonlyArray<ShipToForCopySupplyLimitsDto>;
    onSearchByAcn: (acn: string) => void;
    notFoundText: string;
    foundText: string;
    foundLink: string;
};

const Searching: FC = () => {
    const { t } = useTranslation();
    return (
        <Grid alignItems="center" justifyContent="center" gridTemplateColumns="min-content auto" gap="1rem">
            <SearchIconAnimated icon={searchingIcon} />
            <Text color={Color.supportNavy500} size="1.4rem">
                {t("customers.approveForm.widget.searching")}
            </Text>
        </Grid>
    );
};

const NotFound: FC<{ text: string }> = ({ text }) => (
    <Grid alignItems="center" justifyContent="start" gridTemplateColumns="min-content auto" gap="1.5rem">
        <SvgIcon icon={notFoundIcon} />
        <Text color={Color.supportNavy500} size="1.4rem">
            {text}
        </Text>
    </Grid>
);

const Found: FC<{ link: string; text: string }> = ({ text, link }) => {
    const { t } = useTranslation();
    return (
        <Grid alignItems="center" justifyContent="start" gridTemplateColumns="min-content auto" gap="1.5rem">
            <SvgIcon icon={foundIcon} />
            <Grid gridAutoFlow="row" gap="0.2rem">
                <Text color={Color.supportNavy500} size="1.4rem">
                    {text}
                </Text>
                <FoundLink to={link}>{t("customers.approveForm.widget.foundLink")}</FoundLink>
            </Grid>
        </Grid>
    );
};

export const CopyCustomerWidgetComponent: FC<CopyCustomerWidgetComponentProps> = ({
    isSearching,
    onSearchByAcn,
    sameCustomers,
    foundLink,
    notFoundText,
    foundText,
}) => {
    const { t } = useTranslation();
    const [localAcn, setLocalAcn] = useState("");

    return (
        <Widget>
            {isSearching ? (
                <Searching />
            ) : sameCustomers && sameCustomers.length > 0 ? (
                <Found text={foundText} link={foundLink} />
            ) : (
                <NotFound text={notFoundText} />
            )}
            <Text size="1.4rem" color={Color.supportNavy300} align="center">
                {t("customers.approveForm.widget.or")}
            </Text>
            <Grid gridAutoFlow="row" gap="0.2rem">
                <Text color={Color.supportNavy500} size="1.4rem">
                    {t("customers.approveForm.widget.acnLabel")}
                </Text>
                <Grid gridTemplateColumns="1fr auto" gap="4rem" justifyContent="space-between" alignItems="center">
                    <TextInput
                        withoutErrorPlaceholder
                        value={localAcn}
                        onChange={(e: ChangeEvent<HTMLInputElement>) => setLocalAcn(e.target.value)}
                        placeholder={t("customers.approveForm.widget.acnPlaceholder")}
                        onKeyDown={(e: KeyboardEvent) => {
                            // We have to catch the enter key here, because otherwise the form will be submitted
                            if (e.key === "Enter") {
                                e.preventDefault();
                                onSearchByAcn(localAcn);
                            }
                        }}
                    />
                    <Button onClick={() => onSearchByAcn(localAcn)} variant="subtle" disabled={isEmpty(localAcn)}>
                        {t("customers.approveForm.widget.acnButton")}
                    </Button>
                </Grid>
            </Grid>
            <Text size="1.1rem" color={Color.supportNavy400} align="center">
                {t("customers.approveForm.widget.acnDescription")}
            </Text>
        </Widget>
    );
};

const SearchIconAnimated = styled(SvgIcon)`
    animation: spin 1s ease infinite;
    @keyframes spin {
        100% {
            transform: rotate(360deg);
        }
    }
`;
const FoundLink = styled(Link)`
    font-size: 1.4rem;
    color: ${({ theme }) => theme.color.supportNavy500};
`;

const Widget = styled.div`
    width: 100%;
    display: grid;
    grid-auto-flow: row;
    gap: 1.5rem;
    padding: 1.6rem;
    background-color: ${({ theme }) => theme.color.neutral50};
    border: 0.5px solid ${({ theme }) => theme.color.neutral200};
    border-radius: ${({ theme }) => theme.radius.default};
`;
