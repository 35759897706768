import styled, { css } from "styled-components";

import { Color, maxWidthBreakpoint } from "src/theme";

export const TextSeparator = styled.div`
    display: grid;
    justify-content: center;
    align-items: center;
    grid-auto-flow: column;
    gap: 0.8rem;

    ${maxWidthBreakpoint.tablet(css`
        grid-auto-flow: row;
        justify-items: center;
    `)};
`;

export const Separator = styled.div<{ color?: Color }>`
    height: 10px;
    width: 1px;
    border-radius: ${({ theme }) => theme.radius.default};
    background-color: ${({ theme, color }) => color ?? theme.color.neutral300};
`;
