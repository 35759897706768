import type { FC } from "react";
import styled from "styled-components";

type SupplyLimitSectionHeaderProps = Readonly<{
    header: string;
    description?: string;
}>;

export const SupplyLimitSectionHeader: FC<SupplyLimitSectionHeaderProps> = ({ header, description }) => (
    <Wrapper>
        <SectionHeader>{header}</SectionHeader>
        {description && <Description>{description}</Description>}
    </Wrapper>
);

const Wrapper = styled.div`
    display: grid;
    grid-auto-flow: row;
    margin-top: 4rem;
    margin-bottom: 1.6rem;
    grid-gap: 0.8rem;
`;

const SectionHeader = styled.h3`
    font-weight: 400;
    margin: 0;
    font-size: 2.1rem;
    color: ${({ theme }) => theme.color.neutral400};
`;

const Description = styled.p`
    font-weight: 400;
    margin: 0;
    font-size: 1.4rem;
    color: ${({ theme }) => theme.color.neutral400};
`;
