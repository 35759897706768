import { NumberInput as NumberInputMantine, NumberInputProps as NumberInputPropsMantine } from "@mantine/core";
import styled from "styled-components";

import {
    createInputStyles,
    createInputWrapperStyles,
    errorMessageStyles,
    InputStylesProps,
    invalidInputStyles,
    labelStyles,
} from "../inputStyles";
import { removeUnusedPropsFromStyledInput } from "../utils";

export type NumberInputProps = NumberInputPropsMantine & InputStylesProps;

export const NumberInput = styled(NumberInputMantine).withConfig(removeUnusedPropsFromStyledInput)<NumberInputProps>`
    ${createInputWrapperStyles}
    & input {
        ${createInputStyles}
    }

    & label {
        ${labelStyles}
    }

    & .mantine-NumberInput-invalid {
        ${invalidInputStyles}
    }

    & .mantine-NumberInput-error {
        ${errorMessageStyles}
    }

    & .mantine-NumberInput-rightSection {
        width: 30px;
    }
`;
