import type { FC } from "react";

import { useGetCustomerDetailQuery } from "src/api";
import { Icon, LoadingOverlay } from "src/components";
import { PageLayout } from "src/layout";
import { routes } from "src/routes";
import { useTranslation } from "src/translations";
import { CustomerCategoryEnum } from "src/types";
import { PayerDetail } from "./payerDetail";
import { ShipToDetail } from "./shipToDetail";

export const CustomerDetail: FC = () => {
    const { t } = useTranslation();
    const { customerId } = routes.customerDetail.useParams();
    const { data: customerDetail, isLoading } = useGetCustomerDetailQuery(customerId);

    return (
        <PageLayout
            breadcrumbs={[
                {
                    name: t("customers.name"),
                    route: routes.customers.path,
                    icon: Icon.People,
                },
                {
                    name: customerDetail ? customerDetail.name : "",
                    route: `${routes.customerDetail.fillPathParams({ customerId })}`,
                },
            ]}
        >
            <LoadingOverlay loading={isLoading} />
            {!isLoading &&
                customerDetail &&
                (customerDetail.category === CustomerCategoryEnum.Payer ? (
                    <PayerDetail customerDetail={customerDetail} />
                ) : (
                    <ShipToDetail customerDetail={customerDetail} />
                ))}
        </PageLayout>
    );
};
