export const tags = {
    // Customers
    customers: "Customers",
    customersAll: "CustomersAll",
    customersToApprove: "CustomersToApprove",
    customersToUpdate: "CustomersToUpdate",
    customersToBlock: "CustomersToBlock",
    customersToUnblock: "CustomersToUnblock",
    customersToDeactivate: "CustomersToDeactivate",
    payerShipTos: "PayerShipTos",
    changesToUpdate: "ChangesToUpdate",
    customersWithSameAddressOrAcn: "CustomersWithSameAddressOrACN",
    payersDistributionMargins: "PayersDistributionMargins",
    payersSpecialPrices: "PayersSpecialPrices",
    emergencyOrdersShipTo: "EmergencyOrdersShipTo",
    emergencyOrdersShipToModal: "EmergencyOrdersShipToModal",
    customersCount: "CustomersCount",
    customerAttachments: "CustomerAttachments",
    specialPriceAttachments: "SpecialPriceAttachments",

    // Products
    productPrices: "ProductPrices",
    productPackage: "ProductPackage",
    productBrands: "ProductBrands",

    // SupplyLimits
    supplyLimits: "SupplyLimits",
    supplyLimitsOption: "SupplyLimitsOption",
    supplyLimitSuggestions: "SupplyLimitSuggestions",

    // AsyncFilters
    branchesFilter: "BranchesFilter",

    // Public
    clientConfig: "clientConfig",
    shipToEmergencyOrder: "shipToEmergencyOrder",
    productPackageEmergencyOrder: "productPackageEmergencyOrder",

    // Interfaces
    importInterfaceOperations: "importInterfaceOperations",
    lastInterfaceOperation: "lastInterfaceOperation",
    aggregations: "aggregations",
    aggregatorToolCount: "aggregatorToolCount",
    aggregatorInterfacesWithError: "aggregatorInterfacesWithError",
    aggregationCsvFile: "aggregationCsvFile",
};
