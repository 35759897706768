import type { TFunction } from "react-i18next";
import type { Cell } from "react-table";

import { useGetShipToForSupplyLimitQuery } from "src/api";
import { BranchColumnFilter, TierColumnFilter } from "src/asyncFilters";
import { TextColumnFilter } from "src/components";
import { UNLIMITED_TIER_NUMBER } from "src/supplyLimitSelect";
import { createTableModule, TableNames } from "src/table";
import type { ColumnsType, ShipToForSupplyLimitDto } from "src/types";

const createColumns = (t: TFunction): ColumnsType<ShipToForSupplyLimitDto> => {
    return [
        {
            minWidth: 100,
            collapse: true,
            accessor: "supplyLimitDto" as const,
            Header: t("supplyLimits.bulkEdit.tierNumber") as string,
            Filter: TierColumnFilter,
            Cell: ({ row }: Cell<ShipToForSupplyLimitDto>) =>
                row.original.supplyLimitDto?.tierNumber === UNLIMITED_TIER_NUMBER
                    ? t("supplyLimits.bulkEdit.unlimited")
                    : row.original.supplyLimitDto?.tierNumber,
        },
        {
            width: 350,
            minWidth: 120,
            Header: t("supplyLimits.bulkEdit.name") as string,
            accessor: "name" as const,
            Filter: TextColumnFilter,
        },
        {
            minWidth: 120,
            width: 250,
            Header: t("supplyLimits.bulkEdit.branch") as string,
            accessor: "branch" as const,
            Cell: ({ row }: Cell<ShipToForSupplyLimitDto>) => `${row.original.branch?.code} - ${row.original.branch?.name}`,
            Filter: BranchColumnFilter,
        },
        {
            minWidth: 100,
            Header: t("supplyLimits.bulkEdit.acn") as string,
            accessor: "acn" as const,
            Filter: TextColumnFilter,
        },
        {
            minWidth: 120,
            width: 300,
            Header: t("supplyLimits.bulkEdit.payerName") as string,
            accessor: "payerName" as const,
            Filter: TextColumnFilter,
        },
        {
            minWidth: 100,
            Header: t("supplyLimits.bulkEdit.payerAcn") as string,
            accessor: "payerAcn" as const,
            Filter: TextColumnFilter,
        },
    ];
};

export const tableModule = createTableModule<ShipToForSupplyLimitDto>(
    TableNames.supplyLimitBulkEdit,
    useGetShipToForSupplyLimitQuery,
    createColumns,
);
