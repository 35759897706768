import { FC, useMemo } from "react";

import { useUpdateTradingTermsMutation } from "src/api";
import payerIcon from "src/assets/icons/payer.svg";
import shipToIcon from "src/assets/icons/ship-to.svg";
import {
    Button,
    Checkbox,
    CheckboxProps,
    Flex,
    ModalButtonLayout,
    NumberInput,
    NumberInputProps,
    SectionFormLayout,
    Select,
    SelectProps,
    SvgIcon,
    Text,
} from "src/components";
import { composeValidators, createCurrencyInputProps, Form, InputField, required } from "src/form";
import { Color } from "src/theme";
import { useTranslation } from "src/translations";
import { CustomerDto, CustomerTypeEnum, InvoicingFrequencyEnum, PaymentDueEnum, UpdateTradingTermsDto } from "src/types";
import { enumToLocalizedOptions, zipFormatter } from "src/utils";
import { FormCustomerInfo } from "../components";

type EditTermsOfTradesFormProps = {
    isPayer: boolean;
    onClose: () => void;
    customerDetail: CustomerDto;
};

export const EditTermsOfTradesForm: FC<EditTermsOfTradesFormProps> = ({ isPayer, onClose, customerDetail }) => {
    const { t } = useTranslation();
    const [updateTradingTerms] = useUpdateTradingTermsMutation();

    const initialValues = useMemo(
        () => ({
            customerId: customerDetail.id,
            creditLimit: isPayer ? customerDetail.creditLimit : undefined,
            paymentDue: customerDetail.paymentDue,
            invoicingFrequency: customerDetail.invoicingFrequency,
            setTradingTermsToShipTos: isPayer ? true : undefined,
            type: !isPayer ? customerDetail.type : undefined,
        }),
        [
            customerDetail.id,
            customerDetail.creditLimit,
            customerDetail.paymentDue,
            customerDetail.invoicingFrequency,
            customerDetail.type,
            isPayer,
        ],
    );

    return (
        <>
            <FormCustomerInfo
                name={customerDetail.name}
                street={customerDetail.street}
                city={`${zipFormatter(customerDetail.zip)} ${customerDetail.city}`}
                icon={<SvgIcon icon={isPayer ? payerIcon : shipToIcon} />}
            />
            <Form<UpdateTradingTermsDto>
                initialValues={initialValues}
                onSubmit={updateTradingTerms}
                onSuccess={onClose}
                loadingMessage={t("customers.termsOfTrade.loadingMessage")}
                successMessage={t("customers.termsOfTrade.successMessage")}
            >
                {() => (
                    <>
                        <SectionFormLayout
                            title={
                                <Text size="1.6rem" weight={700} color={Color.primary600}>
                                    {t("customers.termsOfTrade.title")}
                                </Text>
                            }
                        >
                            {isPayer && (
                                <InputField<NumberInputProps["value"], NumberInputProps>
                                    name="creditLimit"
                                    type="number"
                                    input={NumberInput}
                                    validate={composeValidators(required)}
                                    inputProps={{
                                        label: t("customers.termsOfTrade.creditLimit.label"),
                                        placeholder: t("customers.termsOfTrade.creditLimit.placeholder"),
                                        maxWidth: "180px",
                                        ...createCurrencyInputProps(t),
                                    }}
                                />
                            )}
                            <InputField<SelectProps["value"], SelectProps>
                                name="paymentDue"
                                input={Select}
                                validate={composeValidators(required)}
                                inputProps={{
                                    label: t("customers.termsOfTrade.paymentDue.label"),
                                    placeholder: t("customers.termsOfTrade.paymentDue.placeholder"),
                                    maxWidth: "180px",
                                    options: enumToLocalizedOptions("paymentDue", PaymentDueEnum),
                                    clearable: false,
                                    rightSection: (
                                        <Text size="1.4rem" color={Color.supportNavy200}>
                                            {t("common.days")}
                                        </Text>
                                    ),
                                }}
                            />
                            <InputField<SelectProps["value"], SelectProps>
                                name="invoicingFrequency"
                                input={Select}
                                validate={composeValidators(required)}
                                inputProps={{
                                    label: t("customers.termsOfTrade.invoicingFrequency.label"),
                                    placeholder: t("customers.termsOfTrade.invoicingFrequency.placeholder"),
                                    options: enumToLocalizedOptions("invoicingFrequency", InvoicingFrequencyEnum),
                                    maxWidth: "180px",
                                    clearable: false,
                                }}
                            />
                            {!isPayer && (
                                <InputField<SelectProps["value"], SelectProps>
                                    name="type"
                                    input={Select}
                                    inputProps={{
                                        label: t("customers.termsOfTrade.type.label"),
                                        placeholder: t("customers.termsOfTrade.type.placeholder"),
                                        options: enumToLocalizedOptions("CustomerTypeEnum", CustomerTypeEnum),
                                        maxWidth: "290px",
                                        description: t("customers.termsOfTrade.type.description"),
                                        clearable: false,
                                    }}
                                />
                            )}
                            <Flex flexDirection="column" gap="1.5rem">
                                {isPayer && (
                                    <InputField<CheckboxProps["value"], CheckboxProps>
                                        name="setTradingTermsToShipTos"
                                        input={Checkbox}
                                        type="checkbox"
                                        inputProps={{
                                            label: t("customers.termsOfTrade.setForAllPayers.label"),
                                        }}
                                    />
                                )}
                            </Flex>
                        </SectionFormLayout>
                        <ModalButtonLayout>
                            <Button type="submit">{t("common.edit")}</Button>
                            <Button type="button" onClick={onClose} variant="outline">
                                {t("common.cancel")}
                            </Button>
                        </ModalButtonLayout>
                    </>
                )}
            </Form>
        </>
    );
};
