import type { FC, ReactNode } from "react";
import styled from "styled-components";

type DrawerScrollableLayoutWithButtonProps = Readonly<{
    staticSection?: ReactNode;
}>;

export const DrawerScrollableLayoutWithButton: FC<DrawerScrollableLayoutWithButtonProps> = ({ children, staticSection = null }) => (
    <DrawerScrollableLayoutWithButtonContainer>
        <ScrollableContent>{children}</ScrollableContent>
        <StaticSection>{staticSection}</StaticSection>
    </DrawerScrollableLayoutWithButtonContainer>
);

const DrawerScrollableLayoutWithButtonContainer = styled.div`
    height: 100%;
    display: grid;
    grid-template-rows: auto max-content;
`;

const ScrollableContent = styled.div`
    overflow-y: auto;
    overflow-x: hidden;
    padding-bottom: 3rem;
`;

const StaticSection = styled.div`
    display: grid;
    justify-content: center;
    padding: 3rem 0 0 0;
    align-items: center;
    background: rgb(255, 255, 255);
    background: linear-gradient(180deg, rgba(255, 255, 255, 0) 0%, rgba(255, 255, 255, 1) 25%);
    margin-top: -3rem;
    z-index: 1;
`;
