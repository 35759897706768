import { UnstyledButton } from "@mantine/core";
import type { FC } from "react";
import { Link, NavLink } from "react-router-dom";
import styled, { css } from "styled-components";

import powerBiIcon from "src/assets/icons/powerBi.svg";
import userAvatarIcon from "src/assets/icons/user-avatar.svg";
import { Color, maxWidthBreakpoint } from "src/theme";
import { useTranslation } from "src/translations";
import type { NavItems } from "src/types";
import { getReportsUrl, isMac } from "src/utils";
import { Icon, iconFilledClassName, iconRegularClassName, SvgIcon } from "../../Icons";
import { Text } from "../../Text";
import { Grid } from "../Grid";
import { Logo } from "./Logo";

type HeaderProps = Readonly<{
    navItems: NavItems;
    claim: string;
    isStretched: boolean;
    userName: string;
    openSpotlight: () => void;
}>;

export const Header: FC<HeaderProps> = ({ navItems, claim, isStretched, userName, openSpotlight }) => {
    const { t } = useTranslation();

    return (
        <HeaderComponent>
            <LogoWrapper to="/">
                <Logo claim={claim} hovered={isStretched} />
            </LogoWrapper>
            <Search onClick={openSpotlight} $hovered={isStretched}>
                <SearchIconWrapper $hovered={isStretched}>
                    <Icon.Search size="2.4rem" color={isStretched ? Color.white : Color.primary600} />
                </SearchIconWrapper>
                <SearchText $hovered={isStretched}>
                    {t("common.search")}
                    <Text size="1.1rem">{`${isMac ? "⌘" : "Ctrl"} + K`}</Text>
                </SearchText>
            </Search>
            <Grid>
                <NavigationText size="1.1rem" color={Color.neutral100} $hovered={isStretched}>
                    {t("common.navigation")}
                </NavigationText>
                <Navigation>
                    {navItems.map(({ route, name, icon: NavIcon }) => (
                        <NavItem key={name} to={route}>
                            {NavIcon && <NavIcon size="2.4rem" color={Color.neutral50} />}
                            {name}
                        </NavItem>
                    ))}
                </Navigation>
                <MetabaseWrapper $hovered={isStretched}>
                    <ExternalNavLink href={getReportsUrl()} target="_blank">
                        <SvgIcon icon={powerBiIcon} />
                        {t("common.reports")}
                    </ExternalNavLink>
                </MetabaseWrapper>
            </Grid>
            <User $hovered={isStretched}>
                <SvgIcon icon={userAvatarIcon} />
                <Text>{userName}</Text>
            </User>
        </HeaderComponent>
    );
};

const User = styled.div<{ $hovered: boolean }>`
    display: grid;
    grid-auto-flow: row;
    width: 100%;
    gap: 1rem;
    opacity: 0;
    justify-content: center;
    justify-items: center;
    font-size: 1.8rem;
    color: ${({ theme }) => theme.color.neutral100};
    transition: opacity 50ms ease 0ms;
    ${maxWidthBreakpoint.tablet(css`
        grid-auto-flow: column;
        align-items: center;

        img {
            width: 30px;
        }
    `)}
    ${({ $hovered }) =>
        $hovered &&
        css`
            transition: opacity 200ms ease 200ms;
            opacity: 1;
        `}
`;

const Navigation = styled.nav`
    display: grid;
    height: 100%;
    grid-auto-flow: row;
`;

const NavItem = styled(NavLink)`
    display: grid;
    width: 27rem;
    grid-auto-flow: column;
    gap: 2.8rem;
    height: 100%;
    box-sizing: border-box;
    padding: 2rem 2.2rem;
    font-size: 1.8rem;
    text-decoration: none;
    color: ${({ theme }) => theme.color.neutral100};
    position: relative;
    justify-content: start;
    align-items: center;

    ${maxWidthBreakpoint.tablet(css`
        padding-block: 1.4rem;
    `)}
    svg {
        transition: transform 200ms ease;
    }

    &:after {
        content: "";
        width: 8px;
        border-radius: ${({ theme }) => theme.radius.default};
        height: calc(100% - 16px);
        position: absolute;
        left: -8px;
        top: 8px;
        background-color: ${({ theme }) => theme.color.neutral50};
        transition: left 250ms ease;
    }

    &.active {
        &:after {
            left: -4px;
        }

        svg.${iconFilledClassName} {
            display: inline;
        }

        svg.${iconRegularClassName} {
            display: none;
        }
    }

    &.active:hover {
        cursor: auto;
    }

    &:not(&.active):hover {
        cursor: pointer;

        svg {
            transform: translateX(5px);
        }
    }
`;

const HeaderComponent = styled.header`
    display: grid;
    align-items: center;
    grid-template-rows: 90px;
    grid-auto-rows: auto;
    grid-template-columns: 100%;
    height: 100%;
`;

const LogoWrapper = styled(Link)`
    text-decoration: none;
    position: relative;
    width: 100%;
    height: 90px;
`;

const NavigationText = styled(Text)<{ $hovered: boolean }>`
    padding-left: 2.5rem;
    padding-bottom: 0.5rem;
    opacity: 0;
    ${({ $hovered }) =>
        $hovered &&
        css`
            transition: opacity 200ms ease 200ms;
            opacity: 1;
        `}
`;

const MetabaseWrapper = styled.div<{ $hovered: boolean }>`
    border-top: 1px solid ${({ theme }) => theme.color.primary700};
    ${({ $hovered }) =>
        !$hovered &&
        css`
            width: calc(70px - 1rem);
        `}
    margin-inline: 0.5rem;
    display: grid;
    grid-auto-flow: column;
    grid-gap: 1.8rem;
    justify-content: start;
    align-items: center;
    color: ${({ theme }) => theme.color.neutral100};
`;

const ExternalNavLink = styled.a`
    display: grid;
    width: 26rem;
    grid-auto-flow: column;
    gap: 2.8rem;
    height: 100%;
    box-sizing: border-box;
    padding: 2rem 1.7rem;
    font-size: 1.8rem;
    text-decoration: none;
    color: ${({ theme }) => theme.color.neutral100};
    position: relative;
    justify-content: start;
    align-items: center;

    @media (max-height: 850px) {
        padding-block: 1.4rem;
    }

    img {
        transition: transform 200ms ease;
    }

    &:hover {
        cursor: pointer;

        img {
            transform: translateX(5px);
        }
    }
`;

const Search = styled(UnstyledButton)<{ $hovered: boolean }>`
    &.mantine-UnstyledButton-root {
        width: calc(5rem + 4px);
        height: calc(5rem + 4px);
        background-color: ${({ theme }) => theme.color.white};
        ${({ $hovered }) =>
            $hovered &&
            css`
                width: calc(27rem - 18px);
            `}
        border-radius: 5.4rem;
        border: 2px solid ${({ theme }) => theme.color.white};
        margin-left: 8px;
        transition: width 200ms ease, background-color 200ms ease;
        display: grid;
        grid-auto-flow: column;
        align-items: center;
        justify-content: start;
        margin-top: 2rem;
        gap: 2rem;
        align-self: center;
    }
`;

const SearchIconWrapper = styled.div<{ $hovered: boolean }>`
    border-radius: 50%;
    width: 5rem;
    height: 5rem;
    background-color: ${({ theme }) => theme.color.white};
    display: grid;
    justify-content: center;
    align-items: center;

    &:hover {
        background-color: ${({ theme }) => theme.color.neutral50};
    }

    &:active {
        background-color: ${({ theme }) => theme.color.neutral100};
    }

    ${({ $hovered }) =>
        $hovered &&
        css`
            background-color: ${({ theme }) => theme.color.primary600};

            &:hover {
                background-color: ${({ theme }) => theme.color.primary700};
            }

            &:active {
                background-color: ${({ theme }) => theme.color.primary500};
            }
        `}
`;

const SearchText = styled.div<{ $hovered: boolean }>`
    display: grid;
    opacity: 0;
    font-size: 1.8rem;
    color: ${({ theme }) => theme.color.primary700};
    align-items: end;
    line-height: 1;
    gap: 2rem;
    transition: opacity 200ms ease ${({ $hovered }) => ($hovered ? "200ms" : 0)};
    ${({ $hovered }) =>
        $hovered &&
        css`
            grid-auto-flow: column;
            opacity: 1;
        `}
`;
