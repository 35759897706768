import type { FC } from "react";
import styled, { css } from "styled-components";

import { maxWidthBreakpoint } from "src/theme";
import { LoadingOverlay } from "../Loading";

type SegmentedBoxProps = {
    isLoading?: boolean;
};

export const SegmentedBox: FC<SegmentedBoxProps> = ({ children, isLoading = false }) => (
    <StyledBox>
        <LoadingOverlay loading={isLoading} />
        {children}
    </StyledBox>
);

const StyledBox = styled.div`
    display: grid;
    grid-auto-flow: column;
    grid-auto-columns: 1fr;
    position: relative;
    background-color: ${({ theme }) => theme.color.white};
    border: ${({ theme }) => `1px solid ${theme.color.neutral300}`};
    border-radius: ${({ theme }) => theme.radius.default};
    padding: 8px;

    & .segment:not(:last-child) {
        border-right: ${({ theme }) => `1px solid ${theme.color.neutral300}`};
    }

    ${({ theme }) =>
        maxWidthBreakpoint.tablet(css`
            grid-auto-flow: row;

            & .segment:not(:last-child) {
                border-right: none;
                border-bottom: 1px solid ${theme.color.neutral300}
            }
        ;
        }
        `)};
`;

export const Segment = styled.div.attrs(() => ({ className: "segment" }))`
    padding: 2rem;

    ${maxWidthBreakpoint.desktopSmall(css`
        padding: 1.5rem;
    `)};
`;
