import type { TFunction } from "i18next";
import type { Filters, Row } from "react-table";

import { Button, Confirm } from "src/components";
import { Color } from "src/theme";
import type { CustomerActionRequestDto, CustomerDebtListDto, CustomerFilterDto } from "src/types";
import { getParamsForBlockUnblockTables } from "../../utils";

export const createActionsForSelection =
    (t: TFunction, blockPayersMutation: (value: CustomerActionRequestDto) => void) =>
    (isAllRowsSelected: boolean, selectedRowIds: Record<number, boolean>, filters: Filters<CustomerFilterDto>) => {
        const onClick = () => {
            blockPayersMutation(getParamsForBlockUnblockTables(isAllRowsSelected, selectedRowIds, filters));
        };
        return (
            <Confirm title={t("customers.block.confirm.title")} content={t("customers.block.confirm.content")} onConfirm={onClick}>
                {(open) => (
                    <Button disabled={!isAllRowsSelected && Object.keys(selectedRowIds).length === 0} type="button" onClick={open}>
                        {t("customers.block.blockSelected")}
                    </Button>
                )}
            </Confirm>
        );
    };

export const overrideRowProps = (row: Row<CustomerDebtListDto>): Record<string, unknown> => {
    if (row.original.branches.some((branch) => branch.isHospital)) {
        return { $backgroundColor: Color.primary100 };
    }

    return { $backgroundColor: Color.white };
};
