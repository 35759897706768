import { add } from "date-fns";
import type { FC } from "react";

import { useGenerateAggregationMutation } from "src/api";
import { DateInput, DateInputProps, Grid, Modal } from "src/components";
import { Form, InputField } from "src/form";
import { useTranslation } from "src/translations";
import type { AggregationDto, GenerateAggregation } from "src/types";
import { isDateTodayOrAfter } from "src/utils";
import { AggregationToolFormLayout } from "./components";
import { ExportSubmitWithWarning } from "./ExportSubmitWithWarning";
import { InfoBox } from "./InfoBox";
import { excludeAggregationToDate } from "./utils";

type ExportSapFileFormProps = {
    closeModal: () => void;
    lastAggregation?: AggregationDto;
    isOpen: boolean;
};

export const ExportSapFileForm: FC<ExportSapFileFormProps> = ({ closeModal, lastAggregation, isOpen }) => {
    const { t } = useTranslation();
    const [submit] = useGenerateAggregationMutation();

    const lastAggregationDateTo = lastAggregation ? add(new Date(lastAggregation.dateTo), { days: 1 }) : undefined;

    return (
        <Modal maxWidth="530px" title={t("importExport.aggregatorTool.exportSapFile.title")} opened={isOpen} onClose={closeModal}>
            {(setIsTransparent) => (
                <Form<GenerateAggregation>
                    successMessage={t("importExport.aggregatorTool.success")}
                    onSubmit={submit}
                    onSuccess={closeModal}
                    initialValues={{ from: lastAggregationDateTo }}
                    layoutComponent={AggregationToolFormLayout}
                >
                    {({ dirty, values, submitForm }) => (
                        <>
                            <Grid gridAutoFlow="column" justifyContent="start" gap="3rem" gridTemplateColumns="1fr 1fr">
                                <InputField<DateInputProps["value"], DateInputProps>
                                    name="from"
                                    input={DateInput}
                                    required
                                    inputProps={{
                                        label: t("importExport.aggregatorTool.from.label"),
                                        placeholder: t("common.datePlaceholder"),
                                        excludeDate: isDateTodayOrAfter,
                                        disabled: !!lastAggregationDateTo,
                                    }}
                                />
                                <InputField<DateInputProps["value"], DateInputProps>
                                    name="to"
                                    input={DateInput}
                                    required
                                    inputProps={{
                                        label: t("importExport.aggregatorTool.to.label"),
                                        placeholder: t("common.datePlaceholder"),
                                        excludeDate: lastAggregationDateTo
                                            ? excludeAggregationToDate(lastAggregationDateTo)
                                            : isDateTodayOrAfter,
                                    }}
                                />
                            </Grid>
                            <InfoBox from={values.from} to={values.to} />
                            <ExportSubmitWithWarning
                                from={values.from}
                                to={values.to}
                                setIsTransparentForModal={setIsTransparent}
                                submitForm={submitForm}
                                dirty={dirty}
                            />
                        </>
                    )}
                </Form>
            )}
        </Modal>
    );
};
