import type { SearchDto } from "src/types";
import { api } from "../../baseQuery";
import { url } from "../../constants";
import { tags } from "../../tags";
import { SPOTLIGHT_SEARCH_LIMIT_PER_ENTITY } from "./constants";

const spotlightApi = api.injectEndpoints({
    endpoints: (builder) => ({
        getSpotlightSearchResults: builder.query<Array<SearchDto>, string>({
            query: (searchValue) => ({
                url: `${url.SPOTLIGHT_SEARCH}`,
                params: {
                    searchValue,
                    limitPerEntity: SPOTLIGHT_SEARCH_LIMIT_PER_ENTITY,
                },
            }),
            providesTags: [tags.productBrands, tags.customers],
        }),
    }),
});

export const { useGetSpotlightSearchResultsQuery } = spotlightApi;
