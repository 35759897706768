import { Button as ButtonMantine, ButtonProps } from "@mantine/core";
import styled from "styled-components";

import { filledButton, outlineButton, subtleButton } from "./variants";

export const Button = styled(ButtonMantine).withConfig({
    shouldForwardProp: (prop) => !["fontSize"].includes(prop as string),
})<ButtonProps & { fontSize?: string }>`
    &.mantine-Button-root {
        transition: background-color 100ms ease;
        font-size: ${({ fontSize }) => fontSize || "1.4rem"};
        font-family: ${({ theme }) => theme.fontFamily};
        font-weight: bold;
        padding: 8px 16px;
        border-radius: ${({ theme }) => theme.radius.default};

        svg {
            color: inherit;
        }

        ${({ variant }) => {
            switch (variant) {
                case "outline":
                    return outlineButton;
                case "subtle":
                    return subtleButton;
                default:
                    return filledButton;
            }
        }}
    }
`;
