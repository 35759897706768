import { debounce } from "debounce";
import { useFormikContext } from "formik";
import type { ChangeEvent, FC } from "react";
import { useMemo, useState } from "react";

import { useLazyGetProductPackageByNameForEmergencyOrderQuery, useLazyGetProductPackageBySuklForEmergencyOrderQuery } from "src/api";
import { Select, SelectProps, Text, TextInput, TextInputProps } from "src/components";
import { InputField } from "src/form";
import { Color } from "src/theme";
import { useTranslation } from "src/translations";
import type { ProductPackagePublicFormDto } from "src/types";
import { isNotNullish } from "src/utils";
import { ProductPackageDependentFieldLayout } from "../components";
import { PRODUCT_PACKAGES_ARRAY_FIELD } from "../constants";
import type { PublicEmergencyOrderForm } from "../types";

type ProductPackageDependentFieldsProps = {
    index: number;
};

export const ProductPackageDependentFields: FC<ProductPackageDependentFieldsProps> = ({ index }) => {
    const { t } = useTranslation();
    const nameField = `${PRODUCT_PACKAGES_ARRAY_FIELD}[${index}].name`;
    const productPackageIdField = `${PRODUCT_PACKAGES_ARRAY_FIELD}[${index}].productPackageId`;
    const suklField = `${PRODUCT_PACKAGES_ARRAY_FIELD}[${index}].sukl`;

    const [getProductPackagesByName, { isLoading }] = useLazyGetProductPackageByNameForEmergencyOrderQuery();
    const [getProductPackageBySukl] = useLazyGetProductPackageBySuklForEmergencyOrderQuery();

    const { setFieldTouched, setFieldValue, setFieldError } = useFormikContext<PublicEmergencyOrderForm>();
    const [productPackages, setProductPackages] = useState<Array<ProductPackagePublicFormDto>>([]);

    const onProductPackageSearchByNameDebounce = useMemo(
        () =>
            debounce(async (name: string) => {
                if (name && name.trim() !== "") {
                    getProductPackagesByName({ name })
                        .unwrap()
                        .then((data) => {
                            if (data && data.length !== 0) {
                                setFieldError(nameField, undefined);
                                setProductPackages(data);
                            } else {
                                setFieldTouched(nameField, true, false);
                                setFieldError(nameField, "common.form.product_not_found");
                            }
                        })
                        .catch(() => {
                            setFieldError(nameField, "common.form.search_error");
                        });
                }
            }, 500),
        [getProductPackagesByName, nameField, setFieldError, setFieldTouched],
    );

    const onProductPackageSearchBySuklDebounce = useMemo(
        () =>
            debounce(async (e: ChangeEvent<HTMLInputElement>) => {
                if (e?.target?.value && e?.target?.value.trim() !== "") {
                    setFieldValue(productPackageIdField, null, false);
                    setFieldValue(nameField, null, false);
                    getProductPackageBySukl({ sukl: e.target.value })
                        .unwrap()
                        .then((data) => {
                            if (isNotNullish(data)) {
                                setProductPackages([data]);
                                setFieldValue(nameField, data.id.toString(), false);
                                setFieldValue(productPackageIdField, data.id, false);
                            } else {
                                setFieldTouched(suklField, true, false);
                                setFieldError(suklField, "common.form.sukl_not_found");
                            }
                        })
                        .catch(() => {
                            setFieldError(nameField, "common.form.search_error");
                            return null;
                        });
                }
            }, 500),
        [getProductPackageBySukl, nameField, productPackageIdField, setFieldError, setFieldTouched, setFieldValue, suklField],
    );

    const onProductPackageNameSelect = (id?: string | null) => {
        if (isNotNullish(id) && id !== "") {
            const currentValue = productPackages.find((productPackage) => productPackage.id.toString() === id);
            setFieldValue(suklField, currentValue?.suklCode, false);
            setFieldValue(productPackageIdField, currentValue?.id, false);
        } else {
            setFieldValue(suklField, "", false);
            setFieldValue(productPackageIdField, null, false);
        }
    };

    const productPackageOptions = useMemo(
        () =>
            productPackages.map((option) => ({
                label: option.name,
                value: option.id,
            })),
        [productPackages],
    );

    return (
        <ProductPackageDependentFieldLayout>
            <InputField<SelectProps["value"], SelectProps>
                input={Select}
                name={nameField}
                required
                onInputChange={onProductPackageNameSelect}
                inputProps={{
                    label: t("publicEmergencyOrders.form.productPackageName.label"),
                    placeholder: t("publicEmergencyOrders.form.productPackageName.placeholder"),
                    description: t("publicEmergencyOrders.form.productPackageName.description"),
                    options: productPackageOptions,
                    nothingFound: t("publicEmergencyOrders.form.productPackageName.nothingFound"),
                    onSearchChange: (query: string) => onProductPackageSearchByNameDebounce(query),
                    loading: isLoading,
                }}
            />
            <Text color={Color.primary600} size="1.8rem">
                {t("publicEmergencyOrders.or")}
            </Text>
            <InputField<TextInputProps["value"], TextInputProps>
                input={TextInput}
                name={suklField}
                required
                onInputChange={onProductPackageSearchBySuklDebounce}
                inputProps={{
                    label: t("publicEmergencyOrders.form.sukl.label"),
                    placeholder: t("publicEmergencyOrders.form.sukl.placeholder"),
                    description: t("publicEmergencyOrders.form.sukl.description"),
                }}
            />
        </ProductPackageDependentFieldLayout>
    );
};
