import { useAppParamSelector } from "src/utils";
import { isModalOpen } from "./selectors";
import type { ModalId } from "./types";

type UseModalReturnType = boolean;

export const useModalState = (id: ModalId): UseModalReturnType => {
    const isOpen = useAppParamSelector(isModalOpen, id);

    return isOpen || false;
};
