import styled from "styled-components";

type HighlightCellProps = {
    highlight: boolean;
};

export const HighlightCell = styled.div<HighlightCellProps>`
    background-color: ${({ theme, highlight }) => (highlight ? theme.color.accent100 : "transparent")};
    border-radius: 5px;
    padding: 0.6rem 0.8rem;
`;
