import { UnstyledButton } from "@mantine/core";
import type { ReactElement } from "react";
import styled, { css } from "styled-components";

import { maxWidthBreakpoint } from "src/theme";
import { TabContext } from "./tabContext";

type TabsProps<TabValueEnum = string> = {
    activeTab: TabValueEnum;
    tabs: Array<{ tabValue: TabValueEnum; label: string }>;
    children: ReactElement | ReactElement[];
    onTabChange: (activeTab: TabValueEnum) => void;
};

export const Tabs = <TabValueEnum extends string>({ activeTab, tabs, onTabChange, children }: TabsProps<TabValueEnum>): ReactElement => (
    <TabContext.Provider value={activeTab}>
        <div>
            <TabList>
                {tabs.map(({ label, tabValue }) => (
                    <Tab key={tabValue} onClick={() => onTabChange(tabValue)} $active={activeTab === tabValue}>
                        {label}
                    </Tab>
                ))}
            </TabList>
            {children}
        </div>
    </TabContext.Provider>
);

const TabList = styled.div`
    display: grid;
    grid-auto-flow: column;
    margin-bottom: 2rem;
    justify-content: start;
    border-bottom: 1px solid ${({ theme }) => theme.color.neutral200};

    ${maxWidthBreakpoint.desktopSmall(css`
        display: flex;
        flex-wrap: wrap;
        grid-auto-flow: unset;
        border-bottom: none;
    `)};

    ${({ theme }) =>
        maxWidthBreakpoint.mobile(css`
            display: grid;
            flex-wrap: unset;
            grid-template-columns: 1fr 1fr;
            border-bottom: 1px solid ${theme.color.neutral200};
            padding-bottom: 2rem;
            margin-bottom: 2rem;
        `)};
`;

const Tab = styled(UnstyledButton)<{ $active: boolean }>`
    &.mantine-UnstyledButton-root {
        padding: 0.8rem 1.6rem;
        font-size: 1.6rem;
        color: ${({ theme }) => theme.color.supportNavy400};
        background-color: transparent;
        transition: background-color 0.1s ease;
        border-radius: ${({ theme }) => theme.radius.default} ${({ theme }) => theme.radius.default} 0 0;

        ${({ theme }) =>
            maxWidthBreakpoint.desktopSmall(css`
                border-radius: ${theme.radius.default} ${theme.radius.default} ${theme.radius.default} ${theme.radius.default};
            `)};

        ${({ $active }) =>
            $active &&
            css`
                border-color: ${({ theme }) => theme.color.neutral200};
                background-color: ${({ theme }) => theme.color.neutral200};
                color: ${({ theme }) => theme.color.supportNavy500};
            `}
    }
`;
