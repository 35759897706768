import { css } from "styled-components";

export const filledButton = css`
    background-color: ${({ theme }) => theme.color.primary600};
    box-shadow: 0 4px 8px rgba(166, 0, 89, 0.1);

    &:hover {
        background-color: ${({ theme }) => theme.color.primary700};
        box-shadow: 0 4px 8px rgba(125, 0, 67, 0.1);
    }

    &:active {
        background-color: ${({ theme }) => theme.color.primary500};
        box-shadow: 0 4px 8px rgba(208, 0, 111, 0.1);
    }

    &:disabled {
        background-color: ${({ theme }) => theme.color.neutral400};
        color: ${({ theme }) => theme.color.white};
        box-shadow: 0 4px 8px rgba(148, 163, 187, 0.1);

        &:hover {
            background-color: ${({ theme }) => theme.color.neutral400};
        }
    }
`;

export const outlineButton = css`
    background-color: transparent;
    color: ${({ theme }) => theme.color.primary600};
    border-color: ${({ theme }) => theme.color.primary600};

    &:hover {
        background-color: transparent;
        color: ${({ theme }) => theme.color.primary700};
        border-color: ${({ theme }) => theme.color.primary700};
    }

    &:active {
        background-color: transparent;
        color: ${({ theme }) => theme.color.primary500};
        border-color: ${({ theme }) => theme.color.primary500};
    }

    &:disabled {
        border-color: ${({ theme }) => theme.color.neutral400};
        color: ${({ theme }) => theme.color.neutral400};
        background-color: ${({ theme }) => theme.color.neutral100};

        &:hover {
            background-color: ${({ theme }) => theme.color.neutral100};
        }
    }
`;

export const subtleButton = css`
    background-color: transparent;
    padding: 8px 0;
    color: ${({ theme }) => theme.color.primary600};

    &:hover {
        background-color: transparent;
        color: ${({ theme }) => theme.color.primary700};

        svg {
            color: ${({ theme }) => theme.color.primary700};
        }
    }

    &:active {
        color: ${({ theme }) => theme.color.primary500};

        svg {
            color: ${({ theme }) => theme.color.primary500};
        }
    }

    &:disabled {
        background-color: transparent;
        color: ${({ theme }) => theme.color.neutral400};

        svg {
            color: ${({ theme }) => theme.color.neutral400};
        }

        &:hover {
            background-color: transparent;
            color: ${({ theme }) => theme.color.neutral400};

            svg {
                color: ${({ theme }) => theme.color.neutral400};
            }
        }
    }
`;
