import styled from "styled-components";

export const TableScrollDecoration = styled.div<{ isLeftDecorationVisible: boolean; isRightDecorationVisible: boolean }>`
    width: 100%;
    position: relative;
    border-radius: ${({ theme }) => theme.radius.default};
    display: grid;
    grid-template-rows: minmax(0, 1fr);

    &::before,
    &::after {
        content: "";
        width: 25px;
        height: 100%;
        position: absolute;
        top: 0;
        border-radius: ${({ theme }) => `0 ${theme.radius.default} ${theme.radius.default} 0`};
        z-index: 1;
        opacity: 0;
        transition: opacity 0.4s ease-in-out, transform 0.2s ease-in-out;
        pointer-events: none;
    }

    &::before {
        left: 0;
        background: linear-gradient(-90deg, rgba(255, 255, 255, 0) 0%, rgba(0, 0, 0, 0.08) 100%);
        opacity: ${({ isLeftDecorationVisible }) => (isLeftDecorationVisible ? 1 : 0)};
    }

    &::after {
        right: 0;
        background: linear-gradient(90deg, rgba(255, 255, 255, 0) 0%, rgba(0, 0, 0, 0.08) 100%);
        opacity: ${({ isRightDecorationVisible }) => (isRightDecorationVisible ? 1 : 0)};
    }
`;
