import { combineReducers, createReducer, Reducer } from "@reduxjs/toolkit";

import { EMPTY_OBJECT } from "src/utils";
import { resetSelection, saveFilters } from "./actions";
import type { TableState } from "./types";

const initialState: TableState = {
    resetSelectionTrigger: EMPTY_OBJECT,
    filters: EMPTY_OBJECT,
} as const;

const resetSelectionTrigger: Reducer<TableState["resetSelectionTrigger"]> = createReducer(initialState.resetSelectionTrigger, (builder) => {
    builder.addCase(resetSelection, (draft, action) => {
        draft[action.payload] = draft[action.payload] ? !draft[action.payload] : true;
    });
});

const filters: Reducer<TableState["filters"]> = createReducer(initialState.filters, (builder) => {
    builder.addCase(saveFilters, (draft, action) => {
        draft[action.payload.tableName] = action.payload.filters;
    });
});

export const reducer: Reducer<TableState> = combineReducers({ resetSelectionTrigger, filters });
