import type { FC } from "react";

import { routes } from "src/routes";
import { tableModule } from "./table";
import { overrideRowProps } from "./utils";

export const ShipTos: FC = () => {
    const { customerId } = routes.customerDetail.useParams();

    return customerId ? (
        <tableModule.Container additionalQueryParams={{ payerId: customerId }} overrideRowProps={overrideRowProps} />
    ) : null;
};
