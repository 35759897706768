import type { FC } from "react";
import styled from "styled-components";

import { Grid, Text } from "src/components";
import { Color } from "src/theme";
import { useTranslation } from "src/translations";
import { LimitText } from "./LimitText";
import { MvaText } from "./MvaText";

type TierSampleProps = {
    tierNumber: number;
    dailyLimit: number;
    monthlyLimit: number;
    dailyVolumeAdjustment: number;
    monthlyVolumeAdjustment: number;
    approvedAmount?: number;
};

export const TierSample: FC<TierSampleProps> = ({
    tierNumber,
    dailyLimit,
    monthlyLimit,
    dailyVolumeAdjustment,
    monthlyVolumeAdjustment,
    approvedAmount,
}) => {
    const { t } = useTranslation();

    const requestAmount = approvedAmount ?? 0;

    return (
        <StyledTierSampleWrapper>
            <Grid gridAutoFlow="column" justifyItems="center" gridTemplateColumns="1fr 1fr 1fr 1fr">
                <Text color={Color.supportNavy500} weight={700}>
                    {t("supplyLimits.tiers.headers.tier")}
                </Text>
                <Text color={Color.supportNavy500} weight={700}>
                    {t("supplyLimits.tiers.headers.dailyLimit")}
                </Text>
                <Text color={Color.supportNavy500} weight={700}>
                    {t("supplyLimits.tiers.headers.monthlyLimit")}
                </Text>
                <Text color={Color.supportNavy500} weight={700}>
                    {t("supplyLimits.tiers.headers.mva")}
                </Text>
            </Grid>
            <StyledTierSample>
                <TierSampleCell>
                    <Text>{tierNumber}</Text>
                </TierSampleCell>
                <TierSampleCell>
                    <LimitText
                        currentLimit={dailyLimit + dailyVolumeAdjustment}
                        newLimit={dailyLimit + dailyVolumeAdjustment + requestAmount}
                        tierLimit={dailyLimit}
                    />
                </TierSampleCell>
                <TierSampleCell>
                    <LimitText
                        currentLimit={monthlyLimit + monthlyVolumeAdjustment}
                        newLimit={monthlyLimit + monthlyVolumeAdjustment + requestAmount}
                        tierLimit={monthlyLimit}
                    />
                </TierSampleCell>
                <TierSampleCell>
                    <MvaText monthlyVolumeAdjustment={monthlyVolumeAdjustment} requestAmount={requestAmount} />
                </TierSampleCell>
            </StyledTierSample>
        </StyledTierSampleWrapper>
    );
};

const TierSampleCell = styled.div`
    padding-block: 1.6rem;
    display: grid;
    justify-content: center;
    font-size: 1.6rem;
    color: ${({ theme }) => theme.color.supportNavy500};
    grid-auto-flow: column;
    gap: 0.2rem;
`;

const StyledTierSample = styled.div`
    display: grid;
    grid-template-columns: 1fr 1fr 1fr 1fr;
    grid-auto-flow: column;
    background-color: ${({ theme }) => theme.color.white};
    border: 0.5px solid ${({ theme }) => theme.color.neutral300};
    border-radius: ${({ theme }) => theme.radius.default};
    color: ${({ theme }) => theme.color.supportNavy500};

    & > div:first-child {
        border-radius: ${({ theme }) => theme.radius.default} 0 0 ${({ theme }) => theme.radius.default};
        background-color: ${({ theme }) => theme.color.accent100};
    }

    & > div:last-child {
        border-radius: 0 ${({ theme }) => theme.radius.default} ${({ theme }) => theme.radius.default} 0;
        background-color: ${({ theme }) => theme.color.accent500};
    }
`;

const StyledTierSampleWrapper = styled.div`
    display: grid;
    gap: 0.5rem;
    padding-bottom: 2.2rem;
`;
