import styled, { css } from "styled-components";

import type { Color } from "src/theme";

type TextProps = {
    color?: Color;
    size?: string;
    weight?: number;
    lineHeight?: string;
    uppercase?: boolean;
    letterSpacing?: string;
    align?: "left" | "center" | "right";
    underline?: boolean;
    wordWrap?: "normal" | "break-word";
    whiteSpace?: "normal" | "pre-line" | "nowrap";
    overflow?: "visible" | "hidden" | "scroll" | "auto";
    textOverflow?: "clip" | "ellipsis";
};

export const Text = styled.span<TextProps>`
    ${({
        color = "inherit",
        size,
        weight = "inherit",
        lineHeight,
        letterSpacing,
        uppercase = false,
        align = "left",
        underline = false,
        wordWrap = "normal",
        whiteSpace = "normal",
        overflow,
        textOverflow,
    }) => css`
        font-family: ${({ theme }) => theme.fontFamily};
        color: ${color || "inherit"};
        font-size: ${size || "inherit"};
        font-weight: ${weight};
        text-transform: ${uppercase && "uppercase"};
        word-wrap: ${wordWrap};
        text-align: ${align};
        letter-spacing: ${letterSpacing || "0.04em"};
        text-decoration: ${underline && "underline"};
        white-space: ${whiteSpace};
        line-height: ${lineHeight || undefined};
        text-overflow: ${textOverflow || "inherit"};
        overflow: ${overflow || "inherit"};
    `}
`;
