import type { FC, ReactNode } from "react";
import styled, { css } from "styled-components";

import { Button, Icon, Text } from "src/components";
import { Color, IconSizes, maxWidthBreakpoint } from "src/theme";
import { useTranslation } from "src/translations";

type TiersLayoutProps = Readonly<{
    addTier: () => void;
    unlimited?: ReactNode;
    standard?: ReactNode;
    addTierDisabled: boolean;
}>;

export const TiersLayout: FC<TiersLayoutProps> = ({ addTier, unlimited, addTierDisabled, children }) => {
    const { t } = useTranslation();
    return (
        <StyledTiersLayout>
            <Headers>
                <Text align="left">{t("supplyLimits.tiers.headers.category")}</Text>
                <Text align="center">{t("supplyLimits.tiers.headers.tier")}</Text>
                <Text align="center">{t("supplyLimits.tiers.headers.dailyLimit")}</Text>
                <Text align="center">{t("supplyLimits.tiers.headers.monthlyLimit")}</Text>
                <div />
                <Text align="left">{t("supplyLimits.tiers.headers.appliesTo")}</Text>
            </Headers>
            <TierSectionLabel>{t("supplyLimits.tiers.sectionLabels.unlimited")}</TierSectionLabel>
            <TiersSection>{unlimited}</TiersSection>
            <TierSectionLabel>{t("supplyLimits.tiers.sectionLabels.standard")}</TierSectionLabel>
            <TiersSection>{children}</TiersSection>
            <TiersControls>
                <Button
                    variant="subtle"
                    disabled={addTierDisabled}
                    rightIcon={<Icon.Add size={IconSizes.s} color={Color.primary600} filled />}
                    onClick={addTier}
                    fontSize="1.6rem"
                >
                    {t("supplyLimits.tiers.addTier")}
                </Button>
            </TiersControls>
        </StyledTiersLayout>
    );
};

const StyledTiersLayout = styled.div`
    display: grid;
    row-gap: 5.6rem;
    grid-template-columns: repeat(6, 1fr);
    color: ${({ theme }) => theme.color.supportNavy500};
    margin: auto;

    ${maxWidthBreakpoint.mobile(css`
        row-gap: 3rem;
    `)}
`;

const Headers = styled.div`
    display: grid;
    grid-template-columns: repeat(6, 1fr);
    grid-column: 1/-1;
    grid-row: 1;
    font-weight: 700;
    position: relative;

    &:before,
    &:after {
        position: absolute;
        content: "";
        width: 100%;
        left: 0;
        right: 0;
        height: 1px;
        border-radius: ${({ theme }) => theme.radius.default};
        background-color: ${({ theme }) => theme.color.neutral300};
    }

    &:before {
        bottom: -13rem;
    }

    &:after {
        bottom: -1.6rem;
    }

    ${maxWidthBreakpoint.mobile(css`
        display: none;
    `)}
`;

const TierSectionLabel = styled.h4`
    display: grid;
    justify-content: left;
    grid-column: 1;
    margin: 1.7rem 0 0 0;

    ${maxWidthBreakpoint.mobile(css`
        margin: 1.7rem 1rem 0 0;
    `)}
`;

const TiersSection = styled.div<{ $withBorder?: boolean }>`
    grid-column: 2/-1;
    display: grid;
    grid-gap: 1.6rem;
    grid-auto-flow: row;
    position: relative;
`;

const TiersControls = styled.div`
    margin-top: -2rem;
    grid-column: 2/-1;

    ${maxWidthBreakpoint.mobile(css`
        margin-top: 0;
    `)}
`;
