import { UnstyledButton } from "@mantine/core";
import { type FC, Fragment, ReactNode } from "react";
import { Link } from "react-router-dom";
import styled, { css } from "styled-components";

import { Color, IconSizes, maxWidthBreakpoint } from "src/theme";
import type { NavItem, NavItems } from "src/types";
import { Icon } from "../Icons";
import { Tooltip } from "../Tooltip";

type BreadcrumbsProps = Readonly<{
    breadcrumbs: NavItems;
    tooltip?: string | ReactNode;
    className?: string;
    onMobileMenuOpen: () => void;
}>;

const createBreadcrumb = (breadcrumb: NavItem, isLast: boolean) => {
    const BreadcrumbIcon = breadcrumb.icon;
    const breadcrumbItem = (
        <BreadcrumbContent>
            {BreadcrumbIcon ? <BreadcrumbIcon color={isLast ? Color.supportNavy500 : Color.primary600} size={IconSizes.l} /> : null}
            {breadcrumb.name}
        </BreadcrumbContent>
    );
    return isLast ? breadcrumbItem : <BreadcrumbLink to={breadcrumb.route}>{breadcrumbItem}</BreadcrumbLink>;
};

export const Breadcrumbs: FC<BreadcrumbsProps> = ({ breadcrumbs, tooltip, className, onMobileMenuOpen }) => (
    <BreadcrumbComponent className={className}>
        <HamburgerButton onClick={onMobileMenuOpen}>
            <Hamburger />
        </HamburgerButton>
        {breadcrumbs.map((breadcrumb, index) => (
            <Fragment key={breadcrumb.name}>
                {createBreadcrumb(breadcrumb, index === breadcrumbs.length - 1)}
                {index !== breadcrumbs.length - 1 && <Icon.ChevronRight color={Color.supportNavy500} />}
            </Fragment>
        ))}
        {tooltip && (
            <TooltipPlacement>
                <Tooltip withinPortal={false} label={tooltip} backgroundColor={Color.primary600} color={Color.neutral50} withArrow>
                    <Icon.QuestionCircle size={IconSizes.s} color={Color.primary600} hoverColor={Color.primary500} />
                </Tooltip>
            </TooltipPlacement>
        )}
    </BreadcrumbComponent>
);

const BreadcrumbComponent = styled.div`
    display: grid;
    min-height: 4rem;
    grid-auto-flow: column;
    color: ${({ theme }) => theme.color.supportNavy500};
    gap: 1.5rem;
    justify-content: left;
    align-items: center;
    font-size: 1.8rem;
`;

const TooltipPlacement = styled.div`
    margin-top: 0.5rem;
    align-self: start;
    display: inline-flex;
`;

const BreadcrumbLink = styled(Link)`
    display: inline-grid;
    align-items: center;
    color: ${({ theme }) => theme.color.primary500};
    text-decoration: none;

    &:hover {
        color: ${({ theme }) => theme.color.primary600};
    }
`;

const BreadcrumbContent = styled.div`
    display: grid;
    grid-auto-flow: column;
    gap: 1.5rem;
    align-items: center;
`;

const HamburgerButton = styled(UnstyledButton)`
    &.mantine-UnstyledButton-root {
        position: relative;
        width: 25px;
        height: 16px;
        border: none;
        align-items: center;
        display: none;
        ${maxWidthBreakpoint.tablet(css`
            display: grid;
        `)}
    }
`;

const Hamburger = styled.div`
    height: 3px;
    border-radius: 5px;
    background-color: ${({ theme }) => theme.color.primary500};

    &:before,
    &:after {
        content: "";
        position: absolute;
        height: 3px;
        border-radius: 5px;
        background-color: ${({ theme }) => theme.color.primary500};
        width: 100%;
        left: 0;
    }

    &:before {
        top: 0;
    }

    &:after {
        bottom: 0;
    }
`;
