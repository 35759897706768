import { useMsal } from "@azure/msal-react";
import { useDebouncedValue, useHover, useLocalStorage } from "@mantine/hooks";
import { ActionIcon, AppVersion, Flex, Header, Icon, LeftSection as LeftSectionComponent } from "components";
import type { FC } from "react";

import { routes } from "src/routes";
import { useSpotlight } from "src/spotlight";
import { Color, IconSizes } from "src/theme";
import { useTranslation } from "src/translations";
import { useMaxWidthBreakpoint } from "../../theme/util";
import { useMobileMenu } from "../useMobileMenu";

const DEBOUNCED_HOVERED_DELAY = 300;

export const LeftSection: FC = () => {
    const { t } = useTranslation();
    const spotlight = useSpotlight();
    const [isPinned, setIsPinned] = useLocalStorage({ key: "left-section-pinned", defaultValue: false });
    const { hovered, ref } = useHover();
    const [debouncedHovered] = useDebouncedValue(hovered, DEBOUNCED_HOVERED_DELAY);
    const [isMobileMenuOpen, , closeMobileMenu] = useMobileMenu();
    const { accounts } = useMsal();
    const isTablet = useMaxWidthBreakpoint.tablet();

    const isStretched = !isTablet && !isPinned && debouncedHovered;

    return (
        <LeftSectionComponent
            ref={ref}
            isOpen={isMobileMenuOpen}
            closeMobileMenu={closeMobileMenu}
            bottomSection={
                <Flex justifyContent="space-between" alignItems="center" flexDirection={isStretched ? "row" : "row-reverse"}>
                    <AppVersion visible={isStretched} />
                    {!isTablet && (isPinned || debouncedHovered) && (
                        <ActionIcon onClick={() => setIsPinned((currentIsPinned) => !currentIsPinned)} color={Color.primary500}>
                            {isPinned ? (
                                <Icon.PinOff size={IconSizes.l} color={Color.neutral50} />
                            ) : (
                                <Icon.Pin size={IconSizes.l} color={Color.neutral50} />
                            )}
                        </ActionIcon>
                    )}
                </Flex>
            }
            isStretched={isStretched}
        >
            <Header
                isStretched={isStretched}
                userName={accounts[0]?.name || ""}
                navItems={[
                    { route: routes.dashboard.path, name: t("dashboard.moduleName"), icon: Icon.Home },
                    {
                        route: routes.customers.path,
                        name: t("customers.moduleName"),
                        icon: Icon.People,
                    },
                    { route: routes.products.path, name: t("products.moduleName"), icon: Icon.Box },
                    { route: routes.supplyLimits.path, name: t("supplyLimits.moduleName"), icon: Icon.VehicleTruck },
                    { route: routes.importExport.path, name: t("importExport.moduleName"), icon: Icon.Aggregation },
                ]}
                claim={t("claim")}
                openSpotlight={spotlight.openSpotlight}
            />
        </LeftSectionComponent>
    );
};
