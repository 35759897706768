import type { TableQueryParams } from "src/table";
import type {
    CreateProductBrandDto,
    CreateProductPackage,
    CreateProductPrice,
    ExpandTableProductPackageWithPricesType,
    ExpandTableProductType,
    ExpandTableProductWithSupplyLimitsType,
    GenericPaginationRequestDto,
    PaginationResponseDtoProductBrandDto,
    PaginationResponseDtoProductBrandWithSupplyLimitsDto,
    PaginationResponseDtoProductPackageWithPricesDto,
    ProductPackageDto,
    ProductPriceDto,
    UpdateProductBrandDto,
    UpdateProductPackageDto,
    UpdateProductPrice,
} from "src/types";
import { api } from "../../baseQuery";
import { requestMethod, url } from "../../constants";
import { tags } from "../../tags";
import {
    convertProductBrandsWithSupplyLimitsTableFilterNames,
    transformProductPackageFormValuesForCreate,
    transformProductPackageFormValuesForUpdate,
    transformProductPriceFormValuesForCreateAndUpdate,
} from "./utils";

const productsApi = api.injectEndpoints({
    endpoints: (builder) => ({
        getProductBrands: builder.query<GenericPaginationRequestDto<ExpandTableProductType>, TableQueryParams>({
            query: (queryParams) => {
                return {
                    url: url.PRODUCT_BRAND,
                    params: { pageSize: queryParams.pageSize, pageNumber: queryParams.pageIndex + 1, ...queryParams.filters },
                };
            },
            providesTags: [tags.productBrands],
            transformResponse: (response: PaginationResponseDtoProductBrandDto): GenericPaginationRequestDto<ExpandTableProductType> => {
                return {
                    ...response,
                    content: response.content.map((productBrand) => ({
                        ...productBrand,
                        subRows: productBrand.packages || [],
                    })),
                };
            },
        }),
        getProductPackage: builder.query<ProductPackageDto, string>({
            query: (productPackageId) => ({
                url: `${url.PRODUCT_PACKAGE}/${productPackageId}`,
            }),
            providesTags: [tags.productPackage],
        }),
        getProductPackageByName: builder.query<Array<ProductPackageDto>, string>({
            query: (productPackageName) => ({
                url: `${url.PRODUCT_PACKAGE}`,
                params: {
                    nameSubstring: productPackageName,
                    limit: 50,
                },
            }),
            providesTags: [tags.productBrands],
        }),
        createProductBrand: builder.mutation<void, CreateProductBrandDto>({
            query: (values) => ({
                url: url.PRODUCT_BRAND,
                method: requestMethod.POST,
                body: values,
            }),
            invalidatesTags: [tags.productBrands, tags.productPrices],
        }),
        updateProductBrand: builder.mutation<void, UpdateProductBrandDto>({
            query: (values) => ({
                url: url.PRODUCT_BRAND,
                method: requestMethod.PUT,
                body: values,
            }),
            invalidatesTags: [tags.productBrands, tags.productPrices, tags.customers],
        }),
        createProductPackage: builder.mutation<void, CreateProductPackage>({
            query: (values) => ({
                url: url.PRODUCT_PACKAGE,
                method: requestMethod.POST,
                body: transformProductPackageFormValuesForCreate(values),
            }),
            invalidatesTags: [tags.productBrands, tags.productPrices],
        }),
        updateProductPackage: builder.mutation<void, UpdateProductPackageDto>({
            query: (values) => ({
                url: url.PRODUCT_PACKAGE,
                method: requestMethod.PUT,
                body: transformProductPackageFormValuesForUpdate(values),
            }),
            invalidatesTags: [tags.productBrands, tags.productPrices, tags.payersDistributionMargins, tags.customers],
        }),
        getProductPrices: builder.query<GenericPaginationRequestDto<ExpandTableProductPackageWithPricesType>, TableQueryParams>({
            query: (queryParams) => {
                return {
                    url: url.PRODUCT_PRICES,
                    params: { pageSize: queryParams.pageSize, pageNumber: queryParams.pageIndex + 1, ...queryParams.filters },
                };
            },
            providesTags: [tags.productPrices],
            transformResponse: (
                response: PaginationResponseDtoProductPackageWithPricesDto,
            ): GenericPaginationRequestDto<ExpandTableProductPackageWithPricesType> => {
                return {
                    ...response,
                    content: response.content.map((productPackageWithPrices) => ({
                        ...productPackageWithPrices,
                        price: productPackageWithPrices.currentPrice?.price,
                        validFrom: productPackageWithPrices.currentPrice?.validFrom,
                        validTo: productPackageWithPrices.currentPrice?.validTo,
                        subRows:
                            productPackageWithPrices.prices.map((productPrice) => ({
                                name: productPackageWithPrices.name,
                                current: productPrice.id === productPackageWithPrices.currentPrice?.id,
                                ...productPrice,
                            })) || [],
                    })),
                };
            },
        }),
        getCurrentPriceForProductPackage: builder.query<ProductPriceDto, number>({
            query: (productPackageId) => ({ url: `${url.CURRENT_PRODUCT_PRICES}/${productPackageId}` }),
            providesTags: [tags.productPrices],
        }),
        createProductPrice: builder.mutation<void, CreateProductPrice>({
            query: (values) => ({
                url: url.PRODUCT_PRICES,
                method: requestMethod.POST,
                body: transformProductPriceFormValuesForCreateAndUpdate(values),
            }),
            invalidatesTags: [tags.productBrands, tags.productPrices],
        }),
        updateProductPrice: builder.mutation<void, UpdateProductPrice>({
            query: (values) => ({
                url: url.PRODUCT_PRICES,
                method: requestMethod.PUT,
                body: transformProductPriceFormValuesForCreateAndUpdate(values),
            }),
            invalidatesTags: [tags.productBrands, tags.productPrices, tags.customers],
        }),
        deleteProductPrice: builder.mutation<void, number>({
            query: (id) => ({
                url: `${url.PRODUCT_PRICES}/${id}`,
                method: requestMethod.DELETE,
            }),
            invalidatesTags: [tags.productBrands, tags.productPrices],
        }),
        getProductBrandsWithSupplyLimits: builder.query<
            GenericPaginationRequestDto<ExpandTableProductWithSupplyLimitsType>,
            TableQueryParams
        >({
            query: (queryParams) => ({
                url: url.PRODUCT_BRANDS_WITH_SUPPLY_LIMITS,
                params: {
                    pageSize: queryParams.pageSize,
                    pageNumber: queryParams.pageIndex + 1,
                    ...convertProductBrandsWithSupplyLimitsTableFilterNames(queryParams.filters),
                },
            }),
            keepUnusedDataFor: 0,
            providesTags: [tags.productBrands, tags.supplyLimits],
            transformResponse: (
                response: PaginationResponseDtoProductBrandWithSupplyLimitsDto,
            ): GenericPaginationRequestDto<ExpandTableProductWithSupplyLimitsType> => {
                return {
                    ...response,
                    content: response.content.map((productBrandWithSupplyLimits) => ({
                        ...productBrandWithSupplyLimits,
                        subRows: productBrandWithSupplyLimits.supplyLimits || [],
                    })),
                };
            },
        }),
    }),
});

export const {
    useGetProductPackageQuery,
    useDeleteProductPriceMutation,
    useUpdateProductPriceMutation,
    useCreateProductPriceMutation,
    useGetProductPricesQuery,
    useCreateProductPackageMutation,
    useUpdateProductBrandMutation,
    useCreateProductBrandMutation,
    useGetProductBrandsQuery,
    useUpdateProductPackageMutation,
    useGetProductBrandsWithSupplyLimitsQuery,
    useLazyGetProductPackageByNameQuery,
    useLazyGetCurrentPriceForProductPackageQuery,
} = productsApi;
