import styled from "styled-components";

export const InfoSection = styled.div`
    display: grid;
    grid-auto-flow: column;
    gap: 1rem;
    margin-top: 0.8rem;
    padding: 1.6rem 1.9rem;
    background-color: ${({ theme }) => theme.color.accent50};
    border: 1px solid ${({ theme }) => theme.color.neutral100};
    border-radius: ${({ theme }) => theme.radius.default};
`;
