import { format } from "date-fns";
import type { FC } from "react";
import { useState } from "react";
import { useNavigate, useSearchParams } from "react-router-dom";

import { api, tags, useGetSupplyLimitSuggestionsQuery } from "src/api";
import { Icon, TabPanel, Tabs } from "src/components";
import { PageLayout } from "src/layout";
import { createSearchParamsAsString, DashboardTab, routes, TAB_PARAM } from "src/routes";
import { useTranslation } from "src/translations";
import { createIsEnum, DATE_TIME_FORMAT, useAppDispatch } from "src/utils";
import { CardLayout, CurrentDate } from "./components";
import {
    CustomersWidget,
    EmergencyOrdersWidget,
    IncomingInterfacesWidget,
    OutgoingInterfacesWidget,
    SupplyLimitSuggestionsWidget,
} from "./containers";

const isDashboardTabEnum = createIsEnum(DashboardTab);

export const Dashboard: FC = () => {
    const { t } = useTranslation();
    const dispatch = useAppDispatch();
    const [currentDate, setCurrentDate] = useState<Date>(new Date());
    const [searchParams] = useSearchParams();
    const navigate = useNavigate();
    const { data: supplyLimitSuggestions } = useGetSupplyLimitSuggestionsQuery({ pageSize: 1, pageIndex: 0, filters: {} });

    const activeTabFromSearchParam = searchParams.get(TAB_PARAM);

    const activeTab =
        activeTabFromSearchParam && isDashboardTabEnum(activeTabFromSearchParam) ? activeTabFromSearchParam : DashboardTab.emergencyOrders;

    const onRefresh = () => {
        dispatch(
            api.util.invalidateTags([
                tags.customersCount,
                tags.lastInterfaceOperation,
                tags.emergencyOrdersShipTo,
                tags.supplyLimitSuggestions,
            ]),
        );
        setCurrentDate(new Date());
    };

    return (
        <PageLayout
            toolbar={<CurrentDate onRefresh={onRefresh} date={format(currentDate, DATE_TIME_FORMAT)} />}
            breadcrumbs={[{ name: t("dashboard.name"), route: routes.supplyLimits.path, icon: Icon.Home }]}
        >
            <CardLayout>
                <CustomersWidget />
                <IncomingInterfacesWidget />
                <OutgoingInterfacesWidget />
            </CardLayout>
            <Tabs<DashboardTab>
                activeTab={activeTab}
                onTabChange={(tabValue: DashboardTab) =>
                    navigate(
                        `${routes.dashboard.path}?${createSearchParamsAsString({
                            [TAB_PARAM]: tabValue,
                        })}`,
                    )
                }
                tabs={[
                    {
                        tabValue: DashboardTab.emergencyOrders,
                        label: t("dashboard.emergencyOrders.title"),
                    },
                    {
                        tabValue: DashboardTab.supplyLimitSuggestions,
                        label: t("dashboard.supplyLimitSuggestions.title", { count: supplyLimitSuggestions?.totalElements ?? 0 }),
                    },
                ]}
            >
                <TabPanel value={DashboardTab.emergencyOrders}>
                    <EmergencyOrdersWidget />
                </TabPanel>
                <TabPanel value={DashboardTab.supplyLimitSuggestions}>
                    <SupplyLimitSuggestionsWidget />
                </TabPanel>
            </Tabs>
        </PageLayout>
    );
};
