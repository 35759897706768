import styled from "styled-components";

export const TextInputRightSection = styled.div`
    display: flex;
    align-items: center;
    justify-content: center;

    height: 100%;
    width: 3rem;

    cursor: pointer;
`;
