import { useCallback } from "react";

import { useAppDispatch } from "src/utils";
import { closeModal as closeModalAction, openModal as openModalAction } from "./actions";
import type { ModalId } from "./types";

type UseModalReturnType = [() => void, () => void];

export const useModalControls = (id: ModalId): UseModalReturnType => {
    const dispatch = useAppDispatch();

    const open = useCallback(() => dispatch(openModalAction(id)), [dispatch, id]);
    const close = useCallback(() => dispatch(closeModalAction(id)), [dispatch, id]);

    return [open, close];
};
