import { createModuleActionCreators } from "src/utils";
import { NAME } from "./constants";

const { createEmptyAction, createPayloadAction } = createModuleActionCreators(NAME);

/**
 * open modal and register ID to reducer
 */
export const openModal = createPayloadAction("OPEN_MODAL")<string>();

/**
 * close modal by ID
 */
export const closeModal = createPayloadAction("CLOSE_MODAL")<string>();

export const closeAllModals = createEmptyAction("CLOSE_ALL_MODALS");
