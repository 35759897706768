import type { TFunction } from "react-i18next";
import type { Cell } from "react-table";

import { useGetSpecialPricesQuery } from "src/api";
import { TextColumnFilter } from "src/components";
import { createTableModule, LongTextCell, TableNames } from "src/table";
import type { ExpandColumnsType, ExpandTableSpecialPricesType, ShipToListDto } from "src/types";
import { currencyFormatter, dateTimeFormatter, getTranslatedEnumValue, percentageFormatter } from "src/utils";

const createColumns = (t: TFunction): ExpandColumnsType<ExpandTableSpecialPricesType> => [
    {
        accessor: "specialPriceSource" as const,
        Header: t("specialPrices.table.specialPriceSource") as string,
        disableFilters: true,
        collapse: true,
        Cell: ({ value }: Cell<ExpandTableSpecialPricesType>) => getTranslatedEnumValue("specialPriceSource", value),
    },
    {
        width: 350,
        minWidth: 150,
        accessor: "productPackageName" as const,
        Header: t("specialPrices.table.name") as string,
        Filter: TextColumnFilter,
    },
    {
        minWidth: 100,
        Header: t("specialPrices.table.specialPrice") as string,
        accessor: "specialPriceInclMargin" as const,
        Cell: ({ value }: Cell<ShipToListDto>) => currencyFormatter(value),
        disableFilters: true,
        align: "end",
    },
    {
        minWidth: 100,
        Header: t("specialPrices.table.from") as string,
        accessor: "validFrom" as const,
        Cell: ({ value }: Cell<ShipToListDto>) => dateTimeFormatter(value),
        disableFilters: true,
    },
    {
        minWidth: 100,
        Header: t("specialPrices.table.to") as string,
        accessor: "validTo" as const,
        Cell: ({ value }: Cell<ShipToListDto>) => dateTimeFormatter(value),
        disableFilters: true,
    },
    {
        Header: t("specialPrices.table.margin") as string,
        accessor: "specialPriceMargin" as const,
        minWidth: 100,
        Cell: ({ value }: Cell<ShipToListDto>) => percentageFormatter(value),
        disableFilters: true,
        align: "end",
    },
    {
        Header: t("specialPrices.table.contract") as string,
        accessor: "contractId" as const,
        minWidth: 100,
        disableFilters: true,
        align: "end",
    },
    {
        Header: t("specialPrices.table.createdBy") as string,
        accessor: "createdBy" as const,
        collapse: true,
        minWidth: 100,
        disableFilters: true,
    },
    {
        Header: t("specialPrices.table.note") as string,
        accessor: "azNote" as const,
        collapse: true,
        Cell: ({ value }: Cell<ExpandTableSpecialPricesType>) => <LongTextCell text={value} charLimit={8} />,
        disableFilters: true,
    },
    {
        Header: t("specialPrices.table.status") as string,
        accessor: "active" as const,
        collapse: true,
        Cell: ({ value }: Cell<ExpandTableSpecialPricesType>) =>
            value ? t("specialPrices.table.active.true") : t("specialPrices.table.active.false"),
        disableFilters: true,
    },
];

export const tableModule = createTableModule<ExpandTableSpecialPricesType>(
    TableNames.shipToSpecialPrices,
    useGetSpecialPricesQuery,
    createColumns,
);
