import type { TableQueryParams } from "src/table";
import {
    ActionRequestEnum,
    ApproveEmergencyOrderDto,
    ApprovePayerDto,
    ApproveShipToDto,
    AttachmentDto,
    CreateSpecialPriceDto,
    CustomerActionRequestDto,
    CustomerCountPerActionRequestDto,
    CustomerDto,
    DiffObject,
    EmergencyOrderProductPackageDto,
    EmergencyOrderProductPackageStatusEnum,
    ExpandTableCustomerType,
    ExpandTableDistributionMarginsType,
    ExpandTableSpecialPricesType,
    GenericPaginationRequestDto,
    PaginationResponseDtoAttachmentDto,
    PaginationResponseDtoCustomerDebtListDto,
    PaginationResponseDtoCustomerListDto,
    PaginationResponseDtoDataModificationLogDto,
    PaginationResponseDtoEmergencyOrderProductPackageListDto,
    PaginationResponseDtoProductBrandWithMarginsDto,
    PaginationResponseDtoProductBrandWithSpecialPricesDto,
    PaginationResponseDtoShipToListDto,
    RefuseEmergencyOrderDto,
    ShipToForCopySupplyLimitsDto,
    SpecialPriceDto,
    TableDataModificationLogType,
    UpdateMarginsForPayerDtoFixed,
    UpdateNoteDto,
    UpdateSpecialPriceDto,
    UpdateTradingTermsDto,
} from "src/types";
import { api } from "../../baseQuery";
import { requestMethod, url } from "../../constants";
import { tags } from "../../tags";
import {
    convertCustomersToApproveUpdateDeactivateTableFilterNames,
    convertCustomersToBlockUnblockFilterNames,
    convertPayersDataModificationLogTableFilterNames,
    convertPayersDistributionMarginTableFilterNames,
    convertPayersSpecialPricesTableFilterNames,
} from "./utils";

export { convertCustomersToBlockUnblockFilterNames, convertPayersDistributionMarginTableFilterNames } from "./utils";

const customersApi = api.injectEndpoints({
    endpoints: (builder) => ({
        getPayerShipTos: builder.query<PaginationResponseDtoShipToListDto, TableQueryParams>({
            query: ({ pageSize, pageIndex, filters }) => {
                const { payerId, ...tableFilters } = filters;
                return {
                    url: `${url.PAYER_SHIP_TOS}/${payerId}`,
                    params: { pageSize, pageNumber: pageIndex + 1, ...tableFilters },
                };
            },
            keepUnusedDataFor: 0,
            providesTags: [tags.customers, tags.payerShipTos],
        }),
        getPayers: builder.query<GenericPaginationRequestDto<ExpandTableCustomerType>, TableQueryParams>({
            query: (queryParams) => {
                return {
                    url: `${url.CUSTOMERS}/payers`,
                    params: { pageSize: queryParams.pageSize, pageNumber: queryParams.pageIndex + 1, ...queryParams.filters },
                };
            },
            keepUnusedDataFor: 0,
            providesTags: [tags.customers, tags.customersAll],
            transformResponse: (
                response: GenericPaginationRequestDto<CustomerDto>,
            ): GenericPaginationRequestDto<ExpandTableCustomerType> => {
                return {
                    ...response,
                    content: response.content.map((customer) => ({
                        ...customer,
                        subRows: customer.shipToList || [],
                    })),
                };
            },
        }),
        getCustomersToApprove: builder.query<PaginationResponseDtoCustomerListDto, TableQueryParams>({
            query: (queryParams) => {
                return {
                    url: url.CUSTOMERS,
                    params: {
                        actionRequest: ActionRequestEnum.Approve,
                        pageSize: queryParams.pageSize,
                        pageNumber: queryParams.pageIndex + 1,
                        ...convertCustomersToApproveUpdateDeactivateTableFilterNames(queryParams.filters),
                    },
                };
            },
            keepUnusedDataFor: 0,
            providesTags: [tags.customers, tags.customersToApprove],
        }),
        getCustomersToUpdate: builder.query<PaginationResponseDtoCustomerListDto, TableQueryParams>({
            query: (queryParams) => {
                return {
                    url: url.CUSTOMERS,
                    params: {
                        actionRequest: ActionRequestEnum.Update,
                        pageSize: queryParams.pageSize,
                        pageNumber: queryParams.pageIndex + 1,
                        ...convertCustomersToApproveUpdateDeactivateTableFilterNames(queryParams.filters),
                    },
                };
            },
            keepUnusedDataFor: 0,
            providesTags: [tags.customers, tags.customersToUpdate],
        }),
        getChangesToUpdate: builder.query<Record<string, DiffObject>, string>({
            query: (customerId) => {
                return {
                    url: url.CHANGES_TO_UPDATE,
                    params: {
                        customerId,
                    },
                };
            },
            providesTags: [tags.changesToUpdate],
        }),
        updateCustomerConfirm: builder.mutation<void, Array<number>>({
            query: (customerIds) => ({
                url: url.UPDATE_CUSTOMER_CONFIRM,
                method: requestMethod.PUT,
                params: {
                    customerIds,
                },
            }),
            invalidatesTags: [tags.customers, tags.changesToUpdate],
        }),
        getCustomerDetail: builder.query<CustomerDto, string>({
            query: (customerId) => ({ url: `${url.CUSTOMERS}/${customerId}` }),
            keepUnusedDataFor: 0,
            providesTags: [tags.customers],
        }),
        getCustomersWithSameAddressOrAcn: builder.query<Array<ShipToForCopySupplyLimitsDto>, { targetShipToId: number; acn?: string }>({
            query: ({ targetShipToId, acn }) => ({ url: `${url.WITH_SAME_ADDRESS_OR_ACN}/${targetShipToId}`, params: { acn } }),
            providesTags: [tags.customersWithSameAddressOrAcn],
        }),
        getCustomersToBlock: builder.query<PaginationResponseDtoCustomerDebtListDto, TableQueryParams>({
            query: (queryParams) => {
                return {
                    url: url.CUSTOMERS_TO_BLOCK,
                    params: {
                        pageSize: queryParams.pageSize,
                        pageNumber: queryParams.pageIndex + 1,
                        ...convertCustomersToBlockUnblockFilterNames(queryParams.filters),
                    },
                };
            },
            keepUnusedDataFor: 0,
            providesTags: [tags.customers, tags.customersToBlock],
        }),
        getCustomersToUnblock: builder.query<PaginationResponseDtoCustomerDebtListDto, TableQueryParams>({
            query: (queryParams) => {
                return {
                    url: url.CUSTOMERS_TO_UNBLOCK,
                    params: {
                        pageSize: queryParams.pageSize,
                        pageNumber: queryParams.pageIndex + 1,
                        ...convertCustomersToBlockUnblockFilterNames(queryParams.filters),
                    },
                };
            },
            keepUnusedDataFor: 0,
            providesTags: [tags.customers, tags.customersToUnblock],
        }),
        getCustomersToDeactivate: builder.query<PaginationResponseDtoCustomerListDto, TableQueryParams>({
            query: (queryParams) => {
                return {
                    url: url.CUSTOMERS,
                    params: {
                        actionRequest: ActionRequestEnum.Deactivate,
                        pageSize: queryParams.pageSize,
                        pageNumber: queryParams.pageIndex + 1,
                        ...convertCustomersToApproveUpdateDeactivateTableFilterNames(queryParams.filters),
                    },
                };
            },
            keepUnusedDataFor: 0,
            providesTags: [tags.customers, tags.customersToDeactivate],
        }),
        getPayersDistributionMargin: builder.query<GenericPaginationRequestDto<ExpandTableDistributionMarginsType>, TableQueryParams>({
            query: (queryParams) => ({
                url: url.PAYER_DISTRIBUTION_MARGIN,
                params: {
                    pageSize: queryParams.pageSize,
                    pageNumber: queryParams.pageIndex + 1,
                    ...convertPayersDistributionMarginTableFilterNames(queryParams.filters),
                },
            }),
            providesTags: [tags.customers, tags.payersDistributionMargins],
            transformResponse: (
                response: PaginationResponseDtoProductBrandWithMarginsDto,
            ): GenericPaginationRequestDto<ExpandTableDistributionMarginsType> => {
                return {
                    ...response,
                    content: response.content.map((productBrand) => ({
                        ...productBrand,
                        subRows: productBrand.margins || [],
                    })),
                };
            },
        }),
        getSpecialPrices: builder.query<GenericPaginationRequestDto<ExpandTableSpecialPricesType>, TableQueryParams>({
            query: (queryParams) => ({
                url: url.SPECIAL_PRICES_PRODUCT_BRANDS,
                params: {
                    pageSize: queryParams.pageSize,
                    pageNumber: queryParams.pageIndex + 1,
                    ...convertPayersSpecialPricesTableFilterNames(queryParams.filters),
                },
            }),
            providesTags: [tags.customers, tags.payersSpecialPrices],
            transformResponse: (
                response: PaginationResponseDtoProductBrandWithSpecialPricesDto,
            ): GenericPaginationRequestDto<ExpandTableSpecialPricesType> => ({
                ...response,
                content: response.content.map((productBrand) => ({
                    ...productBrand,
                    subRows: productBrand.specialPrices || [],
                })),
            }),
        }),
        getSpecialPrice: builder.query<SpecialPriceDto, number>({
            query: (specialPriceId) => ({
                url: `${url.SPECIAL_PRICES}/${specialPriceId}`,
            }),
            providesTags: [tags.customers],
        }),
        getWaitingEmergencyOrders: builder.query<PaginationResponseDtoEmergencyOrderProductPackageListDto, TableQueryParams>({
            query: (queryParams) => ({
                url: url.EMERGENCY_ORDERS,
                params: {
                    pageSize: queryParams.pageSize,
                    pageNumber: queryParams.pageIndex + 1,
                    ...queryParams.filters,
                    status: EmergencyOrderProductPackageStatusEnum.Waiting,
                },
            }),
            keepUnusedDataFor: 0,
            providesTags: [tags.customers, tags.emergencyOrdersShipTo],
        }),
        getCustomerAttachments: builder.query<PaginationResponseDtoAttachmentDto, TableQueryParams>({
            query: (queryParams) => {
                const { customerId, ...filters } = queryParams.filters;
                return {
                    url: `${url.CUSTOMER_ATTACHMENT}/${customerId}`,
                    params: {
                        pageSize: queryParams.pageSize,
                        pageNumber: queryParams.pageIndex + 1,
                        ...filters,
                    },
                };
            },
            keepUnusedDataFor: 0,
            providesTags: [tags.customerAttachments],
        }),
        getSpecialPriceAttachments: builder.query<Array<AttachmentDto>, { customerId: string; specialPriceId: number }>({
            query: ({ customerId, specialPriceId }) => ({
                url: `${url.SPECIAL_PRICE_ATTACHMENT}/${customerId}/${specialPriceId}`,
            }),
            keepUnusedDataFor: 0,
            providesTags: [tags.specialPriceAttachments],
        }),
        getEmergencyOrdersForShipTo: builder.query<PaginationResponseDtoEmergencyOrderProductPackageListDto, TableQueryParams>({
            query: (queryParams) => {
                const { customerId, ...otherFilters } = queryParams.filters;
                return {
                    url: `${url.EMERGENCY_ORDERS_SHIP_TO}/${customerId}`,
                    params: {
                        pageSize: queryParams.pageSize,
                        pageNumber: queryParams.pageIndex + 1,
                        ...otherFilters,
                    },
                };
            },
            keepUnusedDataFor: 0,
            providesTags: [tags.customers, tags.emergencyOrdersShipTo],
        }),
        getEmergencyOrderProductPackageForShipTo: builder.query<EmergencyOrderProductPackageDto, { eoProductPackageId: number }>({
            query: ({ eoProductPackageId }) => ({
                url: `${url.EMERGENCY_ORDER_PRODUCT_PACKAGE_SHIP_TO}/${eoProductPackageId}`,
            }),
            providesTags: [tags.emergencyOrdersShipToModal],
            keepUnusedDataFor: 0,
        }),
        getCustomerCount: builder.query<Array<CustomerCountPerActionRequestDto>, void>({
            query: () => ({
                url: url.CUSTOMER_COUNT,
            }),
            providesTags: [tags.customers, tags.customersCount],
            keepUnusedDataFor: 0,
        }),
        getDataModificationLog: builder.query<GenericPaginationRequestDto<TableDataModificationLogType>, TableQueryParams>({
            query: (queryParams) => ({
                url: `${url.DATA_MODIFICATION_LOG}`,
                params: {
                    pageSize: queryParams.pageSize,
                    pageNumber: queryParams.pageIndex + 1,
                    ...convertPayersDataModificationLogTableFilterNames(queryParams.filters),
                },
            }),
            providesTags: [tags.customers],
            keepUnusedDataFor: 0,
            transformResponse: (
                response: PaginationResponseDtoDataModificationLogDto,
            ): GenericPaginationRequestDto<TableDataModificationLogType> => {
                return {
                    ...response,
                    content: response.content.map(({ id, timestamp, userName, action, table, changedFields }) => ({
                        id,
                        timestamp,
                        userName,
                        action,
                        table,
                        ...changedFields
                            .sort((a, b) => a.column.localeCompare(b.column))
                            .reduce<{
                                column: Array<string>;
                                oldValues: Array<string | null>;
                                newValues: Array<string | null>;
                            }>(
                                (acc, current) => {
                                    if (current.oldValue || current.newValue) {
                                        return {
                                            column: [...acc.column, current.column],
                                            oldValues: [...acc.oldValues, current.oldValue ?? null],
                                            newValues: [...acc.newValues, current.newValue ?? null],
                                        };
                                    }
                                    return acc;
                                },
                                { column: [], oldValues: [], newValues: [] },
                            ),
                    })),
                };
            },
        }),
        approvePayer: builder.mutation<void, ApprovePayerDto>({
            query: (values) => ({
                url: url.APPROVE_PAYER,
                method: requestMethod.PUT,
                body: values,
            }),
            invalidatesTags: [tags.customers],
        }),
        approveShipTo: builder.mutation<void, ApproveShipToDto>({
            query: (values) => ({
                url: url.APPROVE_SHIP_TO,
                method: requestMethod.PUT,
                body: values,
            }),
            invalidatesTags: [tags.customers],
        }),
        updateTradingTerms: builder.mutation<UpdateTradingTermsDto, string>({
            query: (values) => ({
                url: url.UPDATE_TRADING_TERMS,
                method: requestMethod.PUT,
                body: values,
            }),
            invalidatesTags: [tags.customers],
        }),
        updateCustomerNote: builder.mutation<UpdateNoteDto, string>({
            query: (values) => ({
                url: url.UPDATE_NOTE,
                method: requestMethod.PUT,
                body: values,
            }),
            invalidatesTags: [tags.customers],
        }),
        blockCustomers: builder.mutation<void, CustomerActionRequestDto>({
            query: (values) => ({
                url: url.BLOCK_CUSTOMERS,
                method: requestMethod.PUT,
                body: values,
            }),
            invalidatesTags: [tags.customers],
        }),
        unblockCustomers: builder.mutation<void, CustomerActionRequestDto>({
            query: (values) => ({
                url: url.UNBLOCK_CUSTOMERS,
                method: requestMethod.PUT,
                body: values,
            }),
            invalidatesTags: [tags.customers],
        }),
        deactivateCustomers: builder.mutation<void, Array<number>>({
            query: (customerIds) => ({
                url: url.DEACTIVATE_CUSTOMERS,
                method: requestMethod.PUT,
                params: {
                    customerIds,
                },
            }),
            invalidatesTags: [tags.customers],
        }),
        activateCustomers: builder.mutation<void, Array<number>>({
            query: (customerIds) => ({
                url: url.ACTIVATE_CUSTOMERS,
                method: requestMethod.PUT,
                params: {
                    customerIds,
                },
            }),
            invalidatesTags: [tags.customers],
        }),
        updateDistributionMarginForPayer: builder.mutation<void, UpdateMarginsForPayerDtoFixed>({
            query: (values) => ({
                url: url.UPDATE_DISTRIBUTION_MARGIN,
                method: requestMethod.PUT,
                body: values,
            }),
            invalidatesTags: [tags.customers, tags.supplyLimits],
        }),
        resetDistributionMarginForPayer: builder.mutation<void, UpdateMarginsForPayerDtoFixed>({
            query: (values) => ({
                url: url.RESET_DISTRIBUTION_MARGIN,
                method: requestMethod.PUT,
                body: values,
            }),
            invalidatesTags: [tags.customers, tags.supplyLimits],
        }),
        bulkRefuseEmergencyOrders: builder.mutation<void, RefuseEmergencyOrderDto>({
            query: (values) => ({
                url: url.EMERGENCY_ORDER_BULK_REFUSE,
                method: requestMethod.PUT,
                body: values,
            }),
            invalidatesTags: [tags.emergencyOrdersShipTo],
        }),
        refuseEmergencyOrders: builder.mutation<void, RefuseEmergencyOrderDto>({
            query: (values) => ({
                url: url.EMERGENCY_ORDER_REFUSE,
                method: requestMethod.PUT,
                body: values,
            }),
            invalidatesTags: [tags.emergencyOrdersShipTo],
        }),
        bulkApproveEmergencyOrders: builder.mutation<void, { emergencyOrderProductPackageIds: number[] }>({
            query: ({ emergencyOrderProductPackageIds }) => ({
                url: url.EMERGENCY_ORDER_BULK_APPROVE,
                method: requestMethod.PUT,
                params: { emergencyOrderProductPackageIds },
            }),
            invalidatesTags: [tags.emergencyOrdersShipTo, tags.supplyLimitSuggestions],
        }),
        approveEmergencyOrders: builder.mutation<void, ApproveEmergencyOrderDto>({
            query: (values) => ({
                url: url.EMERGENCY_ORDER_APPROVE,
                method: requestMethod.PUT,
                body: values,
            }),
            invalidatesTags: [tags.emergencyOrdersShipTo, tags.supplyLimitSuggestions],
        }),
        createSpecialPrice: builder.mutation<void, CreateSpecialPriceDto>({
            query: (values) => ({
                url: url.SPECIAL_PRICES,
                method: requestMethod.POST,
                body: values,
            }),
            invalidatesTags: [tags.customers],
        }),
        updateSpecialPrice: builder.mutation<void, UpdateSpecialPriceDto>({
            query: (values) => ({
                url: url.SPECIAL_PRICES,
                method: requestMethod.PUT,
                body: values,
            }),
            invalidatesTags: [tags.customers],
        }),
        deleteSpecialPrice: builder.mutation<void, number>({
            query: (specialPriceId) => ({
                url: `${url.SPECIAL_PRICES}/${specialPriceId}`,
                method: requestMethod.DELETE,
            }),
            invalidatesTags: [tags.customers],
        }),
        uploadAttachment: builder.mutation<void, { formData: unknown; customerId: string }>({
            query: (values) => ({
                url: `${url.CUSTOMER_ATTACHMENT}/${values.customerId}`,
                method: requestMethod.POST,
                body: values.formData,
            }),
            invalidatesTags: [tags.customerAttachments],
        }),
        uploadSpecialPriceAttachment: builder.mutation<void, { formData: unknown; customerId: string; specialPriceId: number }>({
            query: (values) => ({
                url: `${url.SPECIAL_PRICE_ATTACHMENT}/${values.customerId}/${values.specialPriceId}`,
                method: requestMethod.POST,
                body: values.formData,
            }),
            invalidatesTags: [tags.specialPriceAttachments],
        }),
        deleteAttachment: builder.mutation<void, number>({
            query: (attachmentId) => ({
                url: `${url.ATTACHMENT}/${attachmentId}`,
                method: requestMethod.DELETE,
            }),
            invalidatesTags: [tags.customerAttachments, tags.specialPriceAttachments],
        }),
    }),
});

export const {
    useGetChangesToUpdateQuery,
    useGetCustomersToUpdateQuery,
    useGetPayerShipTosQuery,
    useGetCustomersToApproveQuery,
    useLazyGetCustomersWithSameAddressOrAcnQuery,
    useGetCustomersWithSameAddressOrAcnQuery,
    useApproveShipToMutation,
    useApprovePayerMutation,
    useGetPayersQuery,
    useGetCustomerDetailQuery,
    useUpdateCustomerConfirmMutation,
    useUpdateCustomerNoteMutation,
    useUpdateTradingTermsMutation,
    useGetCustomersToBlockQuery,
    useBlockCustomersMutation,
    useGetCustomersToUnblockQuery,
    useUnblockCustomersMutation,
    useGetCustomersToDeactivateQuery,
    useDeactivateCustomersMutation,
    useActivateCustomersMutation,
    useGetPayersDistributionMarginQuery,
    useUpdateDistributionMarginForPayerMutation,
    useResetDistributionMarginForPayerMutation,
    useGetEmergencyOrdersForShipToQuery,
    useGetWaitingEmergencyOrdersQuery,
    useGetEmergencyOrderProductPackageForShipToQuery,
    useApproveEmergencyOrdersMutation,
    useBulkApproveEmergencyOrdersMutation,
    useRefuseEmergencyOrdersMutation,
    useBulkRefuseEmergencyOrdersMutation,
    useGetSpecialPricesQuery,
    useGetCustomerCountQuery,
    useGetSpecialPriceQuery,
    useCreateSpecialPriceMutation,
    useUpdateSpecialPriceMutation,
    useDeleteSpecialPriceMutation,
    useGetDataModificationLogQuery,
    useUploadAttachmentMutation,
    useGetCustomerAttachmentsQuery,
    useDeleteAttachmentMutation,
    useGetSpecialPriceAttachmentsQuery,
    useUploadSpecialPriceAttachmentMutation,
} = customersApi;
