import type { FC, MouseEventHandler, ReactNode } from "react";
import styled from "styled-components";

import { Color, IconSizes } from "src/theme";
import { Icon } from "../../Icons";
import { Loader } from "../../Loading";

type SelectRightSectionProps = {
    showClear: boolean;
    onRemove?: MouseEventHandler<HTMLDivElement>;
    rightSection?: ReactNode;
    loading?: boolean;
    showChevron?: boolean;
};

export const SelectRightSection: FC<SelectRightSectionProps> = ({ showClear, onRemove, loading, rightSection, showChevron = true }) => (
    <>
        {rightSection}
        {loading ? (
            <SelectLoadingWrapper>
                <Loader size={15} />
            </SelectLoadingWrapper>
        ) : (
            <SelectRightSectionWrapper showClear={showClear} onClick={(e) => onRemove?.(e)}>
                {showClear ? (
                    <Icon.Close color={Color.supportNavy500} size={IconSizes.s} />
                ) : showChevron ? (
                    <Icon.ChevronDown color={Color.supportNavy500} size={IconSizes.s} />
                ) : null}
            </SelectRightSectionWrapper>
        )}
    </>
);

const SelectLoadingWrapper = styled.div`
    display: flex;
    align-items: center;
    justify-content: center;

    width: var(--right-icon-size);
    height: 100%;

    pointer-events: none;
`;

const SelectRightSectionWrapper = styled.div<Pick<SelectRightSectionProps, "showClear">>`
    display: flex;
    align-items: center;
    justify-content: center;

    width: var(--right-icon-size);
    height: 100%;

    cursor: pointer;
    pointer-events: ${({ showClear }) => (showClear ? "auto" : "none")};
`;
