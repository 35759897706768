import type { FC } from "react";
import styled from "styled-components";

import { Grid } from "src/components";
import { Color } from "src/theme";
import type { ErrorSource } from "src/types";

export type ErrorPageProps = Readonly<{
    error?: unknown;
    source?: ErrorSource;
}>;

/**
 * ErrorPage is displayed by ErrorBoundary component when unhandled error occurs in application.
 * This component should be as simple as possible to minimize probability of error occurring within this component, because such
 * error cannot be caught and handled.
 *
 * Type of error is unknown, because it is typed this way in catch(). You may narrow it inside ErrorPage if you want to display different errors in different ways.
 */
export const ErrorPage: FC<ErrorPageProps> = ({ source, error }) => (
    <StyledErrorPage>
        <Grid gap="0.5rem">
            <StyledHeader>Error</StyledHeader>
            <div>We are sorry, some error occurred. Try again or contact support if problems persist.</div>
        </Grid>
        <Grid gap="0.5rem">
            <StyledHeader>Technical details</StyledHeader>
            <div>Error source: {source}</div>
            <div>Error message: {error instanceof Error ? error.message : `${error}`}</div>
        </Grid>
    </StyledErrorPage>
);

const StyledErrorPage = styled.div`
    display: grid;
    margin: 3rem;
    gap: 3rem;
    color: ${Color.supportNavy500};
`;

const StyledHeader = styled.h1`
    margin: 0;
`;
