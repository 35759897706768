import type { ModalId } from "./types";
import { useModalControls } from "./useModalControls";
import { useModalState } from "./useModalState";

type UseModalReturnType = [boolean, () => void, () => void];

export const useModal = (id: ModalId): UseModalReturnType => {
    const [open, close] = useModalControls(id);
    const isOpen = useModalState(id);

    return [isOpen, open, close];
};
