import type { FC } from "react";
import { useState } from "react";

import { createGlobalErrorMessage, useBulkApproveEmergencyOrdersMutation } from "src/api";
import { Modal } from "src/components";
import { useModal } from "src/modal";
import { NotificationTypes, useNotifications } from "src/notifications";
import { routes } from "src/routes";
import { useTranslation } from "src/translations";
import type { RequestErrorType } from "src/types";
import { EmergencyOrderProductPackageStatusEnum } from "src/types";
import { useAppDispatch } from "src/utils";
import { SHIP_TO_EMERGENCY_ORDERS_MODAL, SHIP_TO_EMERGENCY_ORDERS_MODAL_WIDTH } from "./constants";
import { ShipToEmergencyOrdersForm } from "./ShipToEmergencyOrdersForm";
import { tableModule } from "./table";
import { createActionColumn, createActionsForSelection } from "./utils";

type EmergencyOrdersProps = {
    customerName: string;
};

export const EmergencyOrders: FC<EmergencyOrdersProps> = ({ customerName }) => {
    const { t } = useTranslation();
    const { customerId } = routes.customerDetail.useParams();
    const dispatch = useAppDispatch();
    const notifications = useNotifications();
    const [selectedEOProductPackage, setSelectedEOProductPackage] = useState<{ id: number; name: string } | undefined>(undefined);
    const [isModalOpened, openModal, closeModal] = useModal(SHIP_TO_EMERGENCY_ORDERS_MODAL);
    const [bulkApprove] = useBulkApproveEmergencyOrdersMutation();

    const onCloseModal = () => {
        closeModal();
    };

    const onOpenModal = (eoProductPackage: { id: number; name: string }) => {
        setSelectedEOProductPackage(eoProductPackage);
        openModal();
    };

    const onBulkApprove = (value: { emergencyOrderProductPackageIds: number[] }) => {
        bulkApprove(value)
            .unwrap()
            .then(() => {
                dispatch(tableModule.resetTableSelectionAction);
                notifications.showNotification({
                    title: t("common.form.successTitle"),
                    message: t("shipToDetail.emergencyOrders.successApproveBulkMessage"),
                    type: NotificationTypes.success,
                });
            })
            .catch((e) => {
                const error = e as RequestErrorType;
                const errorMessage = createGlobalErrorMessage(error, t("shipToDetail.emergencyOrders.errorApproveBulkMessage"));
                notifications.showNotification({
                    title: t("common.form.errorTitle"),
                    message: errorMessage,
                    type: NotificationTypes.error,
                });
            });
    };

    return (
        <>
            <tableModule.Container
                actionsForSelection={createActionsForSelection(t, onBulkApprove)}
                actions={createActionColumn(t, onOpenModal)}
                isSelectable
                canSelectAll={false}
                additionalQueryParams={{ customerId }}
                defaultTableConfig={{ initialFilters: { status: EmergencyOrderProductPackageStatusEnum.Waiting } }}
            />
            <Modal
                title={t("shipToDetail.emergencyOrders.modal.title", {
                    shipToName: customerName,
                    productPackageName: selectedEOProductPackage?.name,
                })}
                opened={isModalOpened}
                onClose={onCloseModal}
                maxWidth={SHIP_TO_EMERGENCY_ORDERS_MODAL_WIDTH}
            >
                <ShipToEmergencyOrdersForm closeModal={onCloseModal} eoProductPackageId={selectedEOProductPackage?.id} />
            </Modal>
        </>
    );
};
