import type { FC } from "react";
import { useEffect } from "react";

import { createGlobalErrorMessage, useLazyGetInterfacesWithErrorQuery } from "src/api";
import { Button, Confirm, Flex, Icon, Text, TextLink, WarningBox } from "src/components";
import { NotificationTypes, useNotifications } from "src/notifications";
import { createSearchParamsAsString, ImportExportTab, routes, TAB_PARAM } from "src/routes";
import { createFiltersSearchParam, TableNames } from "src/table";
import { Color, IconSizes } from "src/theme";
import { Trans, useTranslation } from "src/translations";
import type { RequestErrorType } from "src/types";
import { PhoenixInterfaceOperationDtoStatusEnum } from "src/types";
import { formatDateForBE, isNotNullish } from "src/utils";

type ExportSubmitWithWarningProps = {
    from: Date;
    to: Date;
    setIsTransparentForModal: (isTransparent: boolean) => void;
    submitForm: () => Promise<void>;
    dirty: boolean;
};

export const ExportSubmitWithWarning: FC<ExportSubmitWithWarningProps> = ({ from, to, setIsTransparentForModal, submitForm, dirty }) => {
    const { t } = useTranslation();
    const notifications = useNotifications();
    const [fetchInterfacesWithError, { data: interfacesWithError, isLoading: isInterfacesWithErrorLoading }] =
        useLazyGetInterfacesWithErrorQuery();

    const getInterfacesWithError = async (fromDate: Date, toDate: Date) => {
        try {
            await fetchInterfacesWithError({ from: formatDateForBE(fromDate), to: formatDateForBE(toDate) }).unwrap();
        } catch (e) {
            const error = e as RequestErrorType;
            const errorMessage = createGlobalErrorMessage(error, t("importExport.aggregatorTool.exportSapFile.checkError"));
            notifications.showNotification({
                title: t("common.form.errorTitle"),
                message: errorMessage,
                type: NotificationTypes.error,
            });
        }
    };

    useEffect(() => {
        if (isNotNullish(from) && isNotNullish(to)) {
            getInterfacesWithError(from, to);
        }
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [from, to]);

    return (
        <>
            {interfacesWithError && interfacesWithError.length > 0 && (
                <WarningBox>
                    <Icon.Warning size={IconSizes.m} color={Color.warning700} />
                    <Trans
                        i18nKey="importExport.aggregatorTool.exportSapFile.warning"
                        components={{
                            text: <Text color={Color.warning700} size="1.4rem" />,
                            linkTo: (
                                <TextLink
                                    to={`${routes.importExport.path}?${createSearchParamsAsString({
                                        [TAB_PARAM]: ImportExportTab.importInterfaces,
                                        [createFiltersSearchParam(TableNames.importInterfaces)]: {
                                            status: PhoenixInterfaceOperationDtoStatusEnum.Error,
                                        },
                                    })}`}
                                    size="1.4rem"
                                    target="_blank"
                                />
                            ),
                        }}
                    />
                </WarningBox>
            )}
            <Flex justifyContent="center">
                <Confirm
                    title={t("importExport.aggregatorTool.exportSapFile.confirm.title")}
                    content={t("importExport.aggregatorTool.exportSapFile.confirm.content", { count: interfacesWithError?.length ?? 0 })}
                    setIsTransparentForModal={setIsTransparentForModal}
                    onConfirm={submitForm}
                    conditionalConfirmation={() => (interfacesWithError ? interfacesWithError.length > 0 : false)}
                >
                    {(openConfirm) => (
                        <Button
                            type="button"
                            onClick={() => {
                                openConfirm();
                            }}
                            disabled={!dirty || isInterfacesWithErrorLoading}
                        >
                            {t("importExport.aggregatorTool.generate")}
                        </Button>
                    )}
                </Confirm>
            </Flex>
        </>
    );
};
