import type { FC, ReactNode } from "react";
import styled from "styled-components";

import { Text } from "src/components";

type FormCustomerInfoProps = {
    name: string;
    street: string;
    city: string;
    icon: ReactNode;
};

export const FormCustomerInfo: FC<FormCustomerInfoProps> = ({ name, street, city, icon }) => (
    <Layout>
        <InfoWrapper>
            <InfoText>{name}</InfoText>
            <InfoText>{street}</InfoText>
            <InfoText>{city}</InfoText>
        </InfoWrapper>
        <IconWrapper>{icon}</IconWrapper>
    </Layout>
);

const InfoText = styled(Text)`
    color: ${({ theme }) => theme.color.supportNavy500};
    display: block;
`;

const InfoWrapper = styled(Text)`
    display: grid;
    grid-auto-flow: row;
    gap: 0.3rem;
`;

const IconWrapper = styled.div`
    width: 80px;
    height: 80px;
    border-radius: ${({ theme }) => theme.radius.default};
    box-shadow: 0 4px 25px rgba(0, 0, 0, 0.06);
    display: grid;
    align-items: center;
    justify-content: center;
`;

const Layout = styled.div`
    display: grid;
    grid-template-columns: auto min-content;
    gap: 3rem;
    align-items: center;
`;
