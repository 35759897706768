import { BaseQueryFn, FetchArgs, fetchBaseQuery, FetchBaseQueryError } from "@reduxjs/toolkit/query";
import { createApi } from "@reduxjs/toolkit/query/react";
import { Mutex } from "async-mutex";

import { acquireAccessToken, msalInstance, setAccessToken } from "src/auth";
import type { AppState } from "src/types";
import { tags } from "./tags";
import { getApiUrl } from "./utils";

const mutex = new Mutex();
const baseQuery = fetchBaseQuery({
    baseUrl: getApiUrl(),
    prepareHeaders: (headers, { getState }) => {
        const { accessToken } = (getState() as AppState).authModule;

        // If we have a token set in state, let's assume that we should be passing it.
        if (accessToken) {
            headers.set("authorization", `Bearer ${accessToken}`);
        }

        return headers;
    },
});

const baseQueryWithRefreshToken: BaseQueryFn<string | FetchArgs, unknown, FetchBaseQueryError> = async (args, api, extraOptions) => {
    // wait until the mutex is available without locking it
    await mutex.waitForUnlock();
    let result = await baseQuery(args, api, extraOptions);
    if (result.error && result.error.status === 401) {
        // checking whether the mutex is locked
        if (!mutex.isLocked()) {
            const release = await mutex.acquire();
            try {
                const accessToken = await acquireAccessToken();
                if (accessToken) {
                    api.dispatch(setAccessToken(accessToken));
                    // retry the initial query
                    result = await baseQuery(args, api, extraOptions);
                } else {
                    await msalInstance.logoutRedirect();
                }
            } finally {
                // release must be called once the mutex should be released again.
                release();
            }
        } else {
            // wait until the mutex is available without locking it
            await mutex.waitForUnlock();
            result = await baseQuery(args, api, extraOptions);
        }
    }
    return result;
};

// initialize an empty api service that we'll inject endpoints into later as needed
export const api = createApi({
    reducerPath: "api",
    baseQuery: baseQueryWithRefreshToken,
    endpoints: () => ({}),
    tagTypes: Object.values(tags),
});

export const publicApi = createApi({
    reducerPath: "publicApi",
    baseQuery,
    endpoints: () => ({}),
    tagTypes: Object.values(tags),
});
