import type { FC } from "react";
import styled, { css } from "styled-components";

import { Icon, Text } from "src/components";
import { Color, IconSizes } from "src/theme";
import { useTranslation } from "src/translations";

type SearchProps = Readonly<{
    onClick: () => void;
    productPackageName?: string;
}>;

export const Search: FC<SearchProps> = ({ onClick, productPackageName }) => {
    const { t } = useTranslation();
    return (
        <SearchWrapper>
            <Button type="button" onClick={onClick} $expanded={!productPackageName}>
                <Icon.Search color={Color.neutral500} size={IconSizes.m} />
                {!productPackageName && (
                    <Text size="1.4rem" weight={400} color={Color.supportNavy300}>
                        {t("supplyLimits.searchPlaceholder")}
                    </Text>
                )}
            </Button>
            {productPackageName && (
                <Text size="2.1rem" weight={700} color={Color.supportNavy500}>
                    {productPackageName}
                </Text>
            )}
        </SearchWrapper>
    );
};

const SearchWrapper = styled.div`
    display: grid;
    grid-auto-flow: column;
    align-items: center;
    grid-gap: 2.8rem;
    justify-content: left;
`;

const Button = styled.button<{ $expanded?: boolean }>`
    padding: 0.8rem 1.6rem;
    background-color: ${({ theme }) => theme.color.white};
    border-radius: ${({ theme }) => theme.radius.default};
    box-shadow: 0 2px 20px rgba(0, 0, 0, 0.05);
    outline: none;
    border: none;
    cursor: pointer;
    display: grid;
    grid-auto-flow: column;
    align-items: center;
    grid-gap: 4rem;
    justify-content: left;
    ${({ $expanded }) =>
        $expanded &&
        css`
            cursor: text;
            width: 300px;
        `}
`;
