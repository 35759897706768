import { useFormikContext } from "formik";
import type { FC } from "react";

import { NumberInput, NumberInputProps } from "src/components";
import { createCurrencyInputProps, InputField, percentageInputProps } from "src/form";
import { useTranslation } from "src/translations";
import type { SpecialPriceDto } from "src/types";
import { isNotNullish, isNullish, useOnUpdate } from "src/utils";
import { isSpecialPriceToday } from "../utils";
import type { CreateAndUpdateSpecialPrice } from "./types";

const HUNDRED_PERCENT = 100.0;

type ProductPackageSpecialPriceFieldsProps = {
    isEditMode: boolean;
    specialPrice?: SpecialPriceDto;
};

export const ProductPackageSpecialPriceFields: FC<ProductPackageSpecialPriceFieldsProps> = ({ isEditMode, specialPrice }) => {
    const { t } = useTranslation();
    const { values, setFieldValue } = useFormikContext<CreateAndUpdateSpecialPrice>();

    useOnUpdate(() => {
        if (isNotNullish(values?.productPackageMargin)) {
            const specialMarginValue = (values.specialPriceInclMargin / values.productPrice) * HUNDRED_PERCENT - HUNDRED_PERCENT;
            setFieldValue("specialPriceMargin", specialMarginValue);
        }
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [setFieldValue, values.productPackageMargin, values.specialPriceInclMargin]);

    return (
        <>
            <InputField<NumberInputProps["value"], NumberInputProps>
                name="specialPriceInclMargin"
                input={NumberInput}
                required
                inputProps={{
                    label: t("specialPrices.modal.finalSpecialPrice.label"),
                    description: t("specialPrices.modal.finalSpecialPrice.description"),
                    ...createCurrencyInputProps(t),
                    disabled: isNullish(values.productPackageId) || (isEditMode && specialPrice && isSpecialPriceToday(specialPrice)),
                }}
            />
            <InputField<NumberInputProps["value"], NumberInputProps>
                name="specialPriceMargin"
                input={NumberInput}
                inputProps={{
                    label: t("specialPrices.modal.specialDistributionMargin.label"),
                    description: t("specialPrices.modal.specialDistributionMargin.description"),
                    ...percentageInputProps,
                    disabled: true,
                }}
            />
        </>
    );
};
