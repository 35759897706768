import type { UseFiltersColumnProps } from "react-table";

import type { TableRecordType } from "src/types";
import { formatDateForBE, getDateFromBEFormat } from "src/utils";
import { DateInput } from "../Form";
import { TABLE_FILTER_HEIGHT } from "./constants";

type DateColumnFilterProps<RecordType extends TableRecordType> = Readonly<{
    column: UseFiltersColumnProps<RecordType>;
}>;

// TODO create parser
export const DateColumnFilter = <RecordType extends TableRecordType>({
    column: { filterValue, setFilter },
}: DateColumnFilterProps<RecordType>) => {
    const currentValue = filterValue ? getDateFromBEFormat(filterValue) : null;

    return (
        <DateInput
            value={currentValue}
            height={TABLE_FILTER_HEIGHT}
            fontSize="1.2rem"
            withoutBorder
            onChange={(value: Date | null) => {
                if (value !== null) {
                    setFilter(formatDateForBE(value));
                } else {
                    setFilter(undefined); // Set undefined to remove the filter entirely
                }
            }}
        />
    );
};
