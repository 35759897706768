import { MultiSelect as MultiSelectMantine, MultiSelectProps as MultiSelectPropsMantine } from "@mantine/core";
import { FC, useLayoutEffect, useMemo, useRef, useState } from "react";
import styled, { css } from "styled-components";

import { Color } from "src/theme";
import { useTranslation } from "src/translations";
import { isNotNullish } from "src/utils";
import {
    createInputStyles,
    createInputWrapperStyles,
    errorMessageStyles,
    InputStylesProps,
    invalidInputStyles,
    labelStyles,
} from "../inputStyles";
import { removeUnusedPropsFromStyledInput } from "../utils";
import { MultiSelectItem } from "./MultiSelectItem";
import { MultiSelectRightSection } from "./MultiSelectRightSection";
import type { MultiSelectOption } from "./types";

export type MultiSelectProps = Omit<MultiSelectPropsMantine, "data"> &
    Readonly<{
        options: MultiSelectOption[];
        onChange: (newValues: string[]) => void;
        // Height is mandatory for correct behaviour
        height: string;
        disableOverflow?: boolean;
        searchInputWidth?: string;
        usedInForm?: boolean;
        setTouched?: (value: boolean, shouldValidate?: boolean | undefined) => void;
    }> &
    InputStylesProps;

export const MultiSelect: FC<MultiSelectProps> = ({
    options,
    value,
    onChange,
    required,
    disabled,
    height,
    fontSize,
    disableOverflow,
    withinPortal = false,
    searchInputWidth,
    usedInForm,
    setTouched,
    clearable,
    ...props
}) => {
    const { t } = useTranslation();
    const isClearable = clearable && !disabled;
    const wrapperInputRef = useRef<HTMLDivElement>(null);
    const [isDropdownOpen, setIsDropdownOpen] = useState<boolean>(false);
    const [trimmedValueCount, setTrimmedValueCount] = useState<number | null>(null);

    // This is a workaround for the fact that the MultiSelect component doesn't support displaying values only in one row.
    // Our Multiselect has overflow hidden. We have to calculate the number of values that is displayed outside of input,
    // so we can display this number instead. We can calculate this number after components are rendered therefore we use useLayoutEffect.
    useLayoutEffect(() => {
        if (wrapperInputRef.current) {
            const input = wrapperInputRef.current.getElementsByClassName("mantine-MultiSelect-wrapper")[0];
            const inputBoundary = input.getBoundingClientRect();
            const valueElements = Array.from(input.getElementsByClassName("mantine-MultiSelect-value"));
            const elementsOutsideInputView = valueElements.reduce((acc, element) => {
                const elementBoundary = element.getBoundingClientRect();
                if (elementBoundary.bottom < inputBoundary.top || elementBoundary.top > inputBoundary.bottom) {
                    return acc + 1;
                }
                return acc;
            }, 0);
            setTrimmedValueCount(elementsOutsideInputView);
        }
    }, [value, isDropdownOpen]);

    const stringOnlyOptions = useMemo(
        () =>
            options.map((option) => ({
                ...option,
                value: String(option.value),
                selected: value?.includes(String(option.value)),
                group: value?.includes(String(option.value)) ? t("common.selected") : null,
            })),
        [t, options, value],
    );

    const showClear = (isNotNullish(value) && value.length > 0 && isClearable) || false;

    return (
        <div ref={wrapperInputRef}>
            <StyledMultiSelect
                data={stringOnlyOptions}
                itemComponent={MultiSelectItem}
                height={height}
                fontSize={fontSize}
                rightSection={
                    <MultiSelectRightSection
                        showClear={showClear}
                        onRemove={(event) => {
                            if (clearable && showClear) {
                                event?.preventDefault();
                                onChange?.([]);
                            }
                        }}
                    />
                }
                // @ts-ignore - we don't need the second param
                filter={(selectedValue: string, _, item: MultiSelectOption) =>
                    item.label.toLowerCase().includes(selectedValue.toLowerCase().trim()) ||
                    (item.description && item.description.toLowerCase().includes(selectedValue.toLowerCase().trim()))
                }
                withinPortal={withinPortal}
                required={required}
                disabled={disabled}
                searchable
                value={value || []}
                onChange={(newValue: string[]) => onChange(newValue)}
                $trimmedValueCount={trimmedValueCount}
                $inputHeight={height}
                $disableOverflow={disableOverflow}
                $usedInForm={usedInForm}
                $searchInputWidth={searchInputWidth}
                onDropdownOpen={() => {
                    setIsDropdownOpen(true);
                }}
                onDropdownClose={() => {
                    // Beware of bug in mantine!
                    // When all values are initially selected onDropdownClose is called in first render.
                    if (isDropdownOpen) {
                        setTouched?.(true);
                        setIsDropdownOpen(false);
                    }
                }}
                {...props}
                // We are using styles API from mantine because we cannot use styled components
                // in Multiselect when it is rendered in portal
                styles={{
                    rightSection: { pointerEvents: "none" },
                    item: {
                        "&[data-hovered]": {
                            color: Color.supportGraphite500,
                            backgroundColor: Color.accent100,
                        },
                        "&[data-selected]": {
                            color: Color.supportGraphite500,
                            backgroundColor: Color.accent200,
                            "&:hover": {
                                backgroundColor: Color.accent200,
                            },
                        },
                    },
                }}
            />
        </div>
    );
};

const StyledMultiSelect = styled(MultiSelectMantine).withConfig(removeUnusedPropsFromStyledInput)<
    MultiSelectProps & {
        $trimmedValueCount?: number;
        $inputHeight?: string;
        $disableOverflow?: boolean;
        $searchInputWidth?: string;
        $usedInForm?: boolean;
    }
>`
    --left-icon-size: 5rem;
    --right-icon-size: 5rem;

    ${createInputWrapperStyles}
    & label {
        ${labelStyles}
    }

    & .mantine-MultiSelect-values {
        min-height: unset;
        padding: 0;
        position: relative;
        gap: 0.5rem;
        ${({ $disableOverflow }) => $disableOverflow && "overflow: hidden; align-content: flex-end;"}
        ${({ $disableOverflow, $inputHeight, withoutBorder }) =>
            $disableOverflow && `height: calc(${$inputHeight} - ${withoutBorder ? "0px" : "0.2rem"});`}
        ${({ $trimmedValueCount }) =>
            $trimmedValueCount > 0 &&
            css`
                &:after {
                    content: "+${$trimmedValueCount}";
                    position: absolute;
                    right: -0.5rem;
                    top: 0.1rem;
                    bottom: 0.1rem;
                    font-size: 1.2rem;
                    background: rgb(255, 255, 255);
                    background: linear-gradient(90deg, rgba(255, 255, 255, 0) 0%, rgba(255, 255, 255, 1) 20%);
                    color: ${({ theme }) => theme.color.supportNavy500};
                    display: flex;
                    align-items: center;
                    padding-inline: 0.5rem;
                }
            `}
    }

    & .mantine-MultiSelect-value {
        background-color: ${({ theme }) => theme.color.neutral100};
        box-shadow: 0 2px 8px rgba(0, 0, 0, 0.04);
        border-radius: 2px;
        ${({ $disableOverflow, $inputHeight, withoutBorder }) =>
            $disableOverflow && `height: calc(${$inputHeight} - ${withoutBorder ? "0px" : "0.2rem"})`};
        margin: 0;
        color: ${({ theme }) => theme.color.supportNavy500};
        padding-inline: 0.5rem;
        max-width: calc(100% - 10px);

        button {
            width: unset;
            min-width: unset;
        }
    }

    & .mantine-MultiSelect-input {
        border: none;
        ${({ $usedInForm, ...rest }) =>
            $usedInForm &&
            css`
                ${createInputStyles(rest)}
            `}
        min-height: unset;
        cursor: text;
    }

    & .mantine-MultiSelect-searchInput {
        width: ${({ $searchInputWidth }) => $searchInputWidth ?? "100%"};
        min-width: unset;
        flex: unset;
    }

    & .mantine-MultiSelect-searchInputInputHidden {
        height: 0;
        position: absolute;
    }

    & input {
        margin: 0;
        ${({ $disableOverflow, $inputHeight, withoutBorder }) =>
            $disableOverflow && `height: calc(${$inputHeight} - ${withoutBorder ? "0px" : "0.2rem"})`};
        line-height: ${({ height }) => height};
        color: ${({ theme }) => theme.color.supportNavy500};
        font-family: ${({ theme }) => theme.fontFamily};
        border-radius: ${({ theme }) => theme.radius.default};
        font-size: ${({ fontSize }) => fontSize || "1.4rem"};
        min-height: unset;

        &::placeholder {
            font-size: 1.4rem;
            color: ${({ theme }) => theme.color.supportNavy200};
        }
    }

    & .mantine-MultiSelect-error {
        ${errorMessageStyles}
    }

    & .mantine-MultiSelect-invalid {
        ${invalidInputStyles}
    }
`;
