import { useMediaQuery, useViewportSize } from "@mantine/hooks";
import { css, FlattenSimpleInterpolation } from "styled-components";

import { Breakpoint } from "./breakpoint";

export type BreakpointsName = "mobile" | "tablet" | "desktopSmall" | "desktopLarge";

export const createMaxWidth =
    (breakpoint: Breakpoint) =>
    (style: FlattenSimpleInterpolation): FlattenSimpleInterpolation =>
        css`
            @media (max-width: ${breakpoint}px) {
                ${style};
            }
        `;

export const maxWidthBreakpoint: Record<BreakpointsName, (style: FlattenSimpleInterpolation) => FlattenSimpleInterpolation> = {
    mobile: createMaxWidth(Breakpoint.Mobile),
    tablet: createMaxWidth(Breakpoint.Tablet),
    desktopSmall: createMaxWidth(Breakpoint.DesktopSmall),
    desktopLarge: createMaxWidth(Breakpoint.DesktopLarge),
};

const createUseMaxWidth = (breakpoint: Breakpoint) => (): boolean => useMediaQuery(`(max-width: ${breakpoint}px)`);

export const useMaxWidthBreakpoint: Record<BreakpointsName, () => boolean> = {
    mobile: createUseMaxWidth(Breakpoint.Mobile),
    tablet: createUseMaxWidth(Breakpoint.Tablet),
    desktopSmall: createUseMaxWidth(Breakpoint.DesktopSmall),
    desktopLarge: createUseMaxWidth(Breakpoint.DesktopLarge),
};

export const useResponsiveValue = <T extends unknown>(values: Record<BreakpointsName, T>): T => {
    const { width } = useViewportSize();

    if (width >= Breakpoint.DesktopLarge) {
        return values.desktopLarge;
    }
    if (width >= Breakpoint.DesktopSmall) {
        return values.desktopSmall;
    }
    if (width >= Breakpoint.Tablet) {
        return values.tablet;
    }
    return values.mobile;
};
