import type { FC, ReactNode } from "react";
import styled from "styled-components";

import { Flex, LabelValuePairsLayout, Text, TextLink } from "src/components";
import { routes } from "src/routes";
import { Color } from "src/theme";
import { Trans, useTranslation } from "src/translations";
import type { CustomerDto, ShipToForCopySupplyLimitsDto } from "src/types";
import { formatStringDate, zipFormatter } from "src/utils";
import type { SupplyLimitsSettingsType } from "../types";
import { CardWithTitle } from "./CardWithTitle";

type ShipToCardProps = {
    isNewShipTo: boolean;
    title: string;
    shipTo?: CustomerDto | ShipToForCopySupplyLimitsDto;
    isLoading?: boolean;
    shipToSwitch?: ReactNode;
    supplyLimitsSettings?: SupplyLimitsSettingsType;
};

export const ShipToCard: FC<ShipToCardProps> = ({
    isNewShipTo,
    title,
    shipTo,
    isLoading = false,
    shipToSwitch = null,
    supplyLimitsSettings,
}) => {
    const { t } = useTranslation();

    return (
        <CardWithTitle isNewShipTo={isNewShipTo} title={title} isLoading={isLoading} shipToSwitch={shipToSwitch}>
            <CardLayout>
                <Flex flexDirection="column" gap="0.5rem">
                    <Text color={Color.primary600} size="1.8rem" weight={700}>
                        {shipTo?.name}
                    </Text>
                    <Text color={Color.supportNavy500} size="1.4rem">
                        {shipTo?.street}
                    </Text>
                    <Text color={Color.supportNavy500} size="1.4rem">
                        {shipTo ? `${zipFormatter(shipTo.zip)} ${shipTo.city}` : null}
                    </Text>
                </Flex>
                <LabelValuePairsLayout gap="10rem">
                    <Text color={Color.supportNavy500} size="1.4rem">
                        {t("copyTiers.payer")}
                    </Text>
                    <Text color={Color.supportNavy500} size="1.4rem">
                        {isNewShipTo ? (shipTo as CustomerDto)?.payer?.name : (shipTo as ShipToForCopySupplyLimitsDto)?.payerName}
                    </Text>

                    <Text color={Color.supportNavy500} size="1.4rem">
                        {t("copyTiers.acn")}
                    </Text>
                    <TextLink
                        to={routes.customerDetail.fillPathParams({
                            customerId: shipTo?.id.toString()!,
                        })}
                        size="1.4rem"
                    >
                        {shipTo?.acn.toString()}
                    </TextLink>

                    <Text color={Color.supportNavy500} size="1.4rem">
                        {t("copyTiers.tradingStart")}
                    </Text>
                    <Text color={Color.supportNavy500} size="1.4rem">
                        {formatStringDate(shipTo?.tradingStartDate)}
                    </Text>

                    <Text color={Color.supportNavy500} size="1.4rem">
                        {t("copyTiers.supplyLimits.label")}
                    </Text>
                    {isNewShipTo ? (
                        <NewShipToSupplyLimitsValue supplyLimitsSettings={supplyLimitsSettings} />
                    ) : (
                        <Text color={Color.supportNavy500} size="1.4rem">
                            {t(
                                (shipTo as ShipToForCopySupplyLimitsDto)?.hasCustomizedSupplyLimits
                                    ? "copyTiers.supplyLimits.customized"
                                    : "copyTiers.supplyLimits.default",
                            )}
                        </Text>
                    )}
                </LabelValuePairsLayout>
            </CardLayout>
        </CardWithTitle>
    );
};

const NewShipToSupplyLimitsValue: FC<{ supplyLimitsSettings?: SupplyLimitsSettingsType }> = ({ supplyLimitsSettings }) => {
    const { t } = useTranslation();

    if (supplyLimitsSettings?.hasCustomizedSupplyLimits) {
        return (
            <Text color={Color.supportNavy500} size="1.4rem">
                <Trans
                    i18nKey="copyTiers.supplyLimits.customizedSettings"
                    values={{ acn: supplyLimitsSettings?.acn?.toString() }}
                    components={{
                        shipToLink: (
                            <TextLink
                                to={routes.customerDetail.fillPathParams({
                                    customerId: supplyLimitsSettings?.id.toString()!,
                                })}
                                size="1.4rem"
                            />
                        ),
                    }}
                />
            </Text>
        );
    }

    return (
        <Text color={Color.supportNavy500} size="1.4rem">
            {t("copyTiers.supplyLimits.default")}
        </Text>
    );
};

const CardLayout = styled.div`
    display: flex;
    flex-direction: column;
    justify-content: space-between;
    min-height: 16rem;
    min-width: 40rem;
`;
