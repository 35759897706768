import styled from "styled-components";

export const AggregationToolInfoBox = styled.div`
    display: grid;
    position: relative;
    grid-auto-flow: column;
    justify-content: start;
    align-items: start;
    gap: 1.5rem;
    grid-template-columns: max-content 1fr;
    margin-bottom: 2.2rem;
    padding: 1.6rem 1.8rem;
    background-color: ${({ theme }) => theme.color.white};
    border: 1px solid ${({ theme }) => theme.color.neutral100};
    border-radius: ${({ theme }) => theme.radius.default};
`;
