export enum FileMimeType {
    Csv = "text/csv",
    Xls = "application/vnd.ms-excel",
}

export type DownloadFileArguments = {
    url: string;
    filename: string;
    fileMimeType: string;
    open?: boolean;
};
