import { FieldArray } from "formik";
import type { FC } from "react";
import { useEffect, useMemo, useState } from "react";

import { useCreateSupplyLimitMutation, useUpdateSupplyLimitMutation } from "src/api";
import { Confirm, OpenConfirmParams } from "src/components";
import { composeValidators, Form, InputField } from "src/form";
import { UNLIMITED_TIER_NUMBER } from "src/supplyLimitSelect";
import { useTranslation } from "src/translations";
import type { ProductPackageDto, SupplyLimitWithShipToCountDto } from "src/types";
import { TiersLayout } from "../components";
import { MAX_TIER_LIMIT, TIERS_FIELD, UNLIMITED_FIELD } from "../constants";
import { TierInput, TierInputProps } from "./TierInput";
import type { FormValuesType } from "./types";
import {
    addTier,
    createError,
    createTierSelectorId,
    dailyIsNotGreaterThenMonthlyValidation,
    findSameTierInFormValuesById,
    findTierInFormValuesById,
    isTierNew,
    maxDailyLimitValidation,
    maxMonthlyLimitValidation,
    minDailyLimitValidation,
    minMonthlyLimitValidation,
} from "./utils";

type TiersFormProps = {
    unlimitedTier?: SupplyLimitWithShipToCountDto;
    standardTiers: Array<SupplyLimitWithShipToCountDto>;
    productPackage: ProductPackageDto;
};

export const TiersForm: FC<TiersFormProps> = ({ unlimitedTier, standardTiers, productPackage }) => {
    if (!unlimitedTier) {
        throw new Error("There isn't any unlimited tier");
    }

    const [createSupplyLimit] = useCreateSupplyLimitMutation();
    const [updateSupplyLimit] = useUpdateSupplyLimitMutation();
    const [focusedTierId, setFocusedTierId] = useState<number | string | null>(null);
    const { t } = useTranslation();

    const isAnyTierFocused = focusedTierId !== null;

    useEffect(() => {
        if (focusedTierId) {
            const input = document.getElementById(createTierSelectorId(focusedTierId));
            input?.focus();
        }
    }, [focusedTierId]);

    const onSubmit = (values: FormValuesType) => {
        const focusedTier = findTierInFormValuesById(values, focusedTierId);
        if (!focusedTier) {
            throw new Error("You have to be focused on tier for update.");
        }
        if (isTierNew(focusedTier.id.toString()) && focusedTier.tierNumber) {
            return createSupplyLimit({
                productPackageId: focusedTier.productPackageId,
                tierNumber: focusedTier.tierNumber,
                monthlyLimit: focusedTier.monthlyLimit,
                dailyLimit: focusedTier.dailyLimit,
            })
                .unwrap()
                .then(() => {
                    setFocusedTierId(null);
                })
                .catch((e: unknown) => {
                    throw createError(e, values, focusedTier);
                });
        }
        return updateSupplyLimit({
            id: focusedTier.id,
            isDefault: focusedTier.isDefault,
            monthlyLimit: focusedTier.monthlyLimit,
            dailyLimit: focusedTier.dailyLimit,
        })
            .unwrap()
            .then(() => {
                setFocusedTierId(null);
            })
            .catch((e: unknown) => {
                throw createError(e, values, focusedTier);
            });
    };

    const defaultUnlimitedTier: SupplyLimitWithShipToCountDto = useMemo(
        () => ({
            ...unlimitedTier,
            monthlyLimit: unlimitedTier?.monthlyLimit || MAX_TIER_LIMIT,
            dailyLimit: unlimitedTier?.dailyLimit || MAX_TIER_LIMIT,
        }),
        [unlimitedTier],
    );

    const createOpenConfirmWithCheck =
        (values: FormValuesType, openConfirm: (params: OpenConfirmParams) => void) => (focusedTier: SupplyLimitWithShipToCountDto) => {
            const sameTier = findSameTierInFormValuesById(values, focusedTier);
            openConfirm({
                skipConfirm: sameTier === undefined,
                transValues: { tier: sameTier?.tierNumber === UNLIMITED_TIER_NUMBER ? t("common.unlimited") : sameTier?.tierNumber },
            });
        };

    return (
        <Form<FormValuesType>
            initialValues={{
                unlimited: defaultUnlimitedTier,
                tiers: standardTiers,
            }}
            // Please be careful with copying this prop to other forms, it has impact to performance
            enableReinitialize
            onSubmit={onSubmit}
            unwrapOnSubmit={false}
            loadingMessage={t("supplyLimits.tiers.loading")}
            successMessage={t("supplyLimits.tiers.success")}
        >
            {({ values, submitForm, resetForm }) => (
                <Confirm
                    title={t("supplyLimits.tiers.confirm.title")}
                    contentTransKey="supplyLimits.tiers.confirm.content"
                    onConfirm={submitForm}
                >
                    {(openConfirm) => {
                        const openConfirmWithCheck = createOpenConfirmWithCheck(values, openConfirm);
                        return (
                            <FieldArray
                                name={TIERS_FIELD}
                                render={(arrayHelpers) => (
                                    <TiersLayout
                                        addTier={() =>
                                            arrayHelpers.push(addTier(setFocusedTierId, productPackage, values.tiers.length + 1))
                                        }
                                        addTierDisabled={isAnyTierFocused}
                                        unlimited={
                                            <InputField<TierInputProps["value"], TierInputProps>
                                                input={TierInput}
                                                name={UNLIMITED_FIELD}
                                                validate={composeValidators(
                                                    maxDailyLimitValidation,
                                                    maxMonthlyLimitValidation,
                                                    minDailyLimitValidation,
                                                    minMonthlyLimitValidation,
                                                    dailyIsNotGreaterThenMonthlyValidation,
                                                )}
                                                inputProps={{
                                                    tier: unlimitedTier,
                                                    focused: focusedTierId === unlimitedTier?.id,
                                                    inputId: createTierSelectorId(unlimitedTier?.id),
                                                    setFocused: setFocusedTierId,
                                                    isAnyTierFocused,
                                                    onSave: submitForm,
                                                    openConfirm: openConfirmWithCheck,
                                                    reset: resetForm,
                                                }}
                                            />
                                        }
                                    >
                                        {values.tiers.map((tier, index) => (
                                            <InputField<TierInputProps["value"], TierInputProps>
                                                key={tier.id}
                                                input={TierInput}
                                                name={`${TIERS_FIELD}[${index}]`}
                                                validate={composeValidators(
                                                    maxDailyLimitValidation,
                                                    maxMonthlyLimitValidation,
                                                    minDailyLimitValidation,
                                                    minMonthlyLimitValidation,
                                                    dailyIsNotGreaterThenMonthlyValidation,
                                                )}
                                                inputProps={{
                                                    tier,
                                                    focused: focusedTierId === tier.id,
                                                    inputId: createTierSelectorId(tier.id),
                                                    setFocused: setFocusedTierId,
                                                    isAnyTierFocused,
                                                    onSave: submitForm,
                                                    openConfirm: openConfirmWithCheck,
                                                    reset: resetForm,
                                                }}
                                            />
                                        ))}
                                    </TiersLayout>
                                )}
                            />
                        );
                    }}
                </Confirm>
            )}
        </Form>
    );
};
