import type { FC } from "react";
import { useMemo, useState } from "react";

import blockedIcon from "src/assets/icons/blocked-customer.svg";
import dotsIcon from "src/assets/icons/horizontal_dots.svg";
import {
    ActionIcon,
    Button,
    Flex,
    Icon,
    LabelValuePair,
    Menu,
    MenuDropdown,
    MenuItem,
    MenuTarget,
    Modal,
    ModalButtonLayout,
    SvgIcon,
    TabularText,
    Text,
} from "src/components";
import { useModal } from "src/modal";
import { Color, IconSizes } from "src/theme";
import { useTranslation } from "src/translations";
import type { CustomerDto } from "src/types";
import { CustomerCategoryEnum, CustomerStatusEnum } from "src/types";
import { currencyFormatter, EMPTY_OBJECT, getTranslatedEnumValue } from "src/utils";
import { Card } from "../components";
import { ACTION_CONFIRM_MODAL, EDIT_TERMS_OF_TRADE_MODAL, TERMS_OF_TRADE_MODAL_WIDTH } from "../constants";
import { useActivateCustomers, useBlockCustomers, useDeactivateCustomers, useUnblockCustomers } from "../utils";
import { EditTermsOfTradesForm } from "./EditTermsOfTradesForm";
import { CustomerAction } from "./enums";
import { getCustomerDetailTextColor } from "./utils";

type ApprovedProps = {
    isPayer: boolean;
    customerDetail?: CustomerDto;
};

export const Approved: FC<ApprovedProps> = ({ isPayer, customerDetail }) => {
    const { t } = useTranslation();
    const [isEditModalOpened, openEditModal, closeEditModal] = useModal(EDIT_TERMS_OF_TRADE_MODAL);
    const [isConfirmModalOpened, openConfirmModal, closeConfirmModal] = useModal(ACTION_CONFIRM_MODAL);
    const [confirmType, setConfirmType] = useState<CustomerAction | null>(null);

    const deactivate = useDeactivateCustomers();
    const block = useBlockCustomers();
    const unblock = useUnblockCustomers();
    const activate = useActivateCustomers();

    const isActive = customerDetail?.status === CustomerStatusEnum.Active;
    const isBlocked = customerDetail?.status === CustomerStatusEnum.Blocked;
    const isInactive = customerDetail?.status === CustomerStatusEnum.Inactive;
    const textColor = getCustomerDetailTextColor(isInactive);

    // BE is currently not supporting action for customer by ID, but only by array of IDs.
    const customerIdRequest = useMemo(() => (customerDetail?.id ? [customerDetail.id] : []), [customerDetail?.id]);
    const actionRequest = useMemo(() => ({ filterDto: EMPTY_OBJECT, customerIds: customerIdRequest }), [customerIdRequest]);

    const confirmContent = useMemo(() => {
        switch (confirmType) {
            case CustomerAction.Activate:
                return {
                    title: t("customers.actions.activate.confirmTitle"),
                    content: t("customers.actions.activate.confirmContent"),
                    confirmButton: t("customers.actions.activate.button"),
                    onConfirm: () => activate(customerIdRequest),
                };
            case CustomerAction.Deactivate:
                return {
                    title: t("customers.actions.deactivate.confirmTitle"),
                    content: t("customers.actions.deactivate.confirmContent"),
                    confirmButton: t("customers.actions.deactivate.button"),
                    onConfirm: () => deactivate(customerIdRequest),
                };
            case CustomerAction.Block:
                return {
                    title: t("customers.actions.block.confirmTitle"),
                    content: t("customers.actions.block.confirmContent"),
                    confirmButton: t("customers.actions.block.button"),
                    onConfirm: () => block(actionRequest),
                };
            case CustomerAction.Unblock:
                return {
                    title: t("customers.actions.unblock.confirmTitle"),
                    content: t("customers.actions.unblock.confirmContent"),
                    confirmButton: t("customers.actions.unblock.button"),
                    onConfirm: () => unblock(actionRequest),
                };
            default:
                return null;
        }
    }, [actionRequest, activate, block, confirmType, customerIdRequest, deactivate, t, unblock]);

    const openConfirm = (type: CustomerAction) => {
        setConfirmType(type);
        openConfirmModal();
    };

    const confirm = (onConfirm?: () => void) => {
        onConfirm?.();
        closeConfirmModal();
    };

    return (
        <>
            <Card>
                <Flex justifyContent="space-between">
                    <Flex flexDirection="column" gap="1rem" flexGrow={1}>
                        <Text color={Color.primary600} size="1.8rem">
                            {t("payerDetail.approved.terms")}
                        </Text>
                        <TabularText maxWidth="30rem" rowGap="0.2rem">
                            <LabelValuePair
                                label={
                                    <Text color={textColor} size="1.4rem">
                                        {t("payerDetail.approved.creditLimit")}
                                    </Text>
                                }
                                value={
                                    <Text color={textColor} size="1.4rem">
                                        {currencyFormatter(
                                            customerDetail?.category === CustomerCategoryEnum.Payer
                                                ? customerDetail?.creditLimit
                                                : customerDetail?.payer?.creditLimit,
                                        )}
                                    </Text>
                                }
                            />
                            <LabelValuePair
                                label={
                                    <Text color={textColor} size="1.4rem">
                                        {t("payerDetail.approved.paymentDue")}
                                    </Text>
                                }
                                value={
                                    <Text color={textColor} size="1.4rem">
                                        {getTranslatedEnumValue("paymentDue", customerDetail?.paymentDue)}
                                    </Text>
                                }
                            />
                            <LabelValuePair
                                label={
                                    <Text color={textColor} size="1.4rem">
                                        {t("payerDetail.approved.invoicingFrequency")}
                                    </Text>
                                }
                                value={
                                    <Text color={textColor} size="1.4rem">
                                        {getTranslatedEnumValue("invoicingFrequency", customerDetail?.invoicingFrequency)}
                                    </Text>
                                }
                            />
                        </TabularText>
                    </Flex>
                    <Menu transition="pop-top-right" position="left-start">
                        <MenuTarget>
                            <ActionIcon color={Color.neutral100}>
                                <SvgIcon icon={dotsIcon} />
                            </ActionIcon>
                        </MenuTarget>
                        <MenuDropdown>
                            <MenuItem disabled={isInactive} onClick={() => openEditModal()}>
                                {t("customers.actions.edit")}
                            </MenuItem>
                            {!isActive && (
                                <MenuItem disabled={isBlocked} onClick={() => openConfirm(CustomerAction.Activate)}>
                                    {t("customers.actions.activate.button")}
                                </MenuItem>
                            )}
                            {isActive && (
                                <MenuItem onClick={() => openConfirm(CustomerAction.Deactivate)}>
                                    {t("customers.actions.deactivate.button")}
                                </MenuItem>
                            )}
                            {!isBlocked && (
                                <MenuItem danger disabled={isInactive} onClick={() => openConfirm(CustomerAction.Block)}>
                                    {t("customers.actions.block.button")}
                                </MenuItem>
                            )}
                            {isBlocked && (
                                <MenuItem danger onClick={() => openConfirm(CustomerAction.Unblock)}>
                                    {t("customers.actions.unblock.button")}
                                </MenuItem>
                            )}
                        </MenuDropdown>
                    </Menu>
                </Flex>
                <Flex gap="1rem" alignItems="center" justifyContent="center">
                    {isActive && (
                        <>
                            <Text color={Color.neutral500} size="1.4rem">
                                {t("payerDetail.approved.activeCustomer")}
                            </Text>
                            <Icon.Checkmark size={IconSizes.m} color={Color.accent500} filled />
                        </>
                    )}
                    {isInactive && (
                        <>
                            <Text color={Color.neutral500} size="1.4rem">
                                {t("payerDetail.approved.inactiveCustomer")}
                            </Text>
                            <Icon.Close size={IconSizes.l} color={Color.neutral400} />
                        </>
                    )}
                    {isBlocked && (
                        <>
                            <Text color={Color.neutral500} size="1.4rem">
                                {t("payerDetail.approved.blockedCustomer")}
                            </Text>
                            <SvgIcon icon={blockedIcon} />
                        </>
                    )}
                </Flex>
            </Card>

            <Modal title={confirmContent?.title} opened={isConfirmModalOpened} onClose={closeConfirmModal}>
                <Text>{confirmContent?.content}</Text>
                <ModalButtonLayout>
                    <Button type="button" onClick={() => confirm(confirmContent?.onConfirm)}>
                        {confirmContent?.confirmButton}
                    </Button>
                    <Button type="button" onClick={closeConfirmModal} variant="outline">
                        {t("common.cancel")}
                    </Button>
                </ModalButtonLayout>
            </Modal>

            <Modal
                title={t("customers.termsOfTrade.editTerms")}
                opened={isEditModalOpened}
                onClose={closeEditModal}
                maxWidth={TERMS_OF_TRADE_MODAL_WIDTH}
            >
                {customerDetail && <EditTermsOfTradesForm isPayer={isPayer} onClose={closeEditModal} customerDetail={customerDetail} />}
            </Modal>
        </>
    );
};
