import type { TFunction } from "react-i18next";
import type { Cell } from "react-table";

import { useGetCustomersToUnblockQuery } from "src/api";
import { createBooleanSelectOption, createSelectColumnFilter, TableLink, TextColumnFilter } from "src/components";
import { routes } from "src/routes";
import { createTableModule, TableNames } from "src/table";
import { Color } from "src/theme";
import type { ColumnsType, CustomerDebtListDto } from "src/types";
import { currencyFormatter } from "src/utils";
import { IsHospitalCell } from "../../../components";

const createColumns = (t: TFunction): ColumnsType<CustomerDebtListDto> => {
    return [
        {
            width: 350,
            minWidth: 120,
            Header: t("customers.table.payerName") as string,
            accessor: "name" as const,
            Filter: TextColumnFilter,
            Cell: ({ row, value }: Cell<CustomerDebtListDto>) => (
                <TableLink
                    color={Color.primary600}
                    weight={700}
                    to={routes.customerDetail.fillPathParams({
                        customerId: row.original.id.toString(),
                    })}
                >
                    {value}
                </TableLink>
            ),
        },
        {
            minWidth: 100,
            Header: t("customers.table.acn") as string,
            accessor: "acn" as const,
            Filter: TextColumnFilter,
        },
        {
            minWidth: 100,
            Header: t("customers.table.tin") as string,
            accessor: "tin" as const,
            Filter: TextColumnFilter,
        },
        {
            minWidth: 75,
            Header: t("customers.table.isHospital") as string,
            accessor: "branches" as const,
            Cell: ({ row }: Cell<CustomerDebtListDto>) => <IsHospitalCell t={t} branches={row.original.branches} />,
            Filter: createSelectColumnFilter(createBooleanSelectOption(t("common.yes"), t("common.no"))),
        },
        {
            minWidth: 100,
            Header: t("customers.table.totalGross") as string,
            accessor: "totalGross" as const,
            noWrap: true,
            align: "end",
            disableFilters: true,
            Cell: ({ value }: Cell<CustomerDebtListDto>) => currencyFormatter(value),
        },
    ];
};

export const tableModule = createTableModule<CustomerDebtListDto>(
    TableNames.customersUnblocked,
    useGetCustomersToUnblockQuery,
    createColumns,
);
