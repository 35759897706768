import type { TFunction } from "react-i18next";
import type { Cell } from "react-table";

import { useGetPayerShipTosQuery } from "src/api";
import { createSelectColumnFilter, TableLink, TextColumnFilter } from "src/components";
import { routes } from "src/routes";
import { createTableModule, TableNames } from "src/table";
import type { ColumnsType, ShipToListDto } from "src/types";
import { CustomerListDto, CustomerStatusEnum, CustomerTypeEnum } from "src/types";
import { enumToLocalizedOptions, getTranslatedEnumValue, zipFormatter } from "src/utils";

const createColumns = (t: TFunction): ColumnsType<ShipToListDto> => [
    {
        width: 350,
        minWidth: 150,
        accessor: "name" as const,
        Header: t("payerDetail.shipTos.table.name") as string,
        Filter: TextColumnFilter,
        Cell: ({ row }: Cell<ShipToListDto>) => (
            <TableLink to={routes.customerDetail.fillPathParams({ customerId: row.original.id.toString() })}>{row.original.name}</TableLink>
        ),
    },
    {
        minWidth: 100,
        Header: t("payerDetail.shipTos.table.type") as string,
        accessor: "type" as const,
        Filter: createSelectColumnFilter(enumToLocalizedOptions("CustomerTypeEnum", CustomerTypeEnum)),
        Cell: ({ value }: Cell<CustomerListDto>) => getTranslatedEnumValue("CustomerTypeEnum", value),
    },
    {
        minWidth: 100,
        Header: t("payerDetail.shipTos.table.acn") as string,
        accessor: "acn" as const,
        Filter: TextColumnFilter,
    },
    {
        minWidth: 100,
        Header: t("payerDetail.shipTos.table.street") as string,
        accessor: "street" as const,
        Filter: TextColumnFilter,
    },
    {
        minWidth: 100,
        Header: t("payerDetail.shipTos.table.city") as string,
        accessor: "city" as const,
        Filter: TextColumnFilter,
    },
    {
        Header: t("payerDetail.shipTos.table.zip") as string,
        accessor: "zip" as const,
        minWidth: 100,
        Filter: TextColumnFilter,
        Cell: ({ value }: Cell<ShipToListDto>) => zipFormatter(value),
    },
    {
        Header: t("payerDetail.shipTos.table.tin") as string,
        accessor: "tin" as const,
        minWidth: 100,
        Filter: TextColumnFilter,
    },
    {
        Header: t("payerDetail.shipTos.table.status") as string,
        accessor: "status" as const,
        minWidth: 100,
        Filter: createSelectColumnFilter(enumToLocalizedOptions("customerStatus", CustomerStatusEnum)),
        Cell: ({ value }: Cell<ShipToListDto>) => getTranslatedEnumValue("customerStatus", value),
    },
];

export const tableModule = createTableModule<ShipToListDto>(TableNames.payerShipTo, useGetPayerShipTosQuery, createColumns);
