import { isFuture } from "date-fns";
import type { TFunction } from "i18next";
import type { Row } from "react-table";

import { ActionIcon, Confirm, Icon, TableAction, Text } from "src/components";
import type { ExpandRowType } from "src/table";
import { getParentAndChildrenFromTablePage, ICON_TABLE_SIZE } from "src/table";
import { Color, IconSizes } from "src/theme";
import type { ExpandTableProductPackageWithPricesType, ProductPackageWithPricesDto, ProductPriceDto } from "src/types";
import { EMPTY_OBJECT, noop } from "src/utils";

const PRODUCT_PACKAGE_DEPTH_LEVEL = 0;

const createDeletePriceButton = (t: TFunction, onConfirm: () => void) => (
    <Confirm title={t("prices.table.confirm.title")} content={t("prices.table.confirm.content")} onConfirm={onConfirm}>
        {(open) => (
            <ActionIcon tooltip={t("prices.deleteTooltip")} onClick={open} color={Color.error100}>
                <Icon.Delete size={ICON_TABLE_SIZE} color={Color.error500} />
            </ActionIcon>
        )}
    </Confirm>
);

export const isProductPriceInFuture = (productPrice: ProductPriceDto) => {
    const validFromDate = new Date(productPrice.validFrom);
    return isFuture(validFromDate);
};

const isDeleteButtonVisible = (index: number, productPrice?: ProductPriceDto) =>
    // index === 0 means first item in prices, because you can delete only newest record
    index === 0 && productPrice && isProductPriceInFuture(productPrice);

const isEditButtonVisible = (productPrice?: ProductPriceDto, productPackageWithPrices?: ProductPackageWithPricesDto) => {
    if (!productPrice) {
        return false;
    }
    // Show edit button if updated price is in the future, or it is current price without any other prices after
    return (
        (productPackageWithPrices?.currentPrice?.id === productPrice.id && productPrice.id === productPackageWithPrices.prices[0].id) ||
        isProductPriceInFuture(productPrice)
    );
};

export const createActionColumn =
    (
        t: TFunction,
        openProductPriceFormModal: (productPackage: ProductPackageWithPricesDto, productPrice?: ProductPriceDto) => void,
        deleteProductPrice: (id: number) => void,
    ) =>
    (
        { depth, id, index }: Row<ExpandTableProductPackageWithPricesType>,
        page: Array<Row<ExpandTableProductPackageWithPricesType>>,
    ): JSX.Element => {
        const productBrandAndProductPrice = getParentAndChildrenFromTablePage<
            ExpandTableProductPackageWithPricesType,
            ProductPackageWithPricesDto,
            ProductPriceDto
        >(page, id);
        const productPackage: ProductPackageWithPricesDto = productBrandAndProductPrice.parent;
        const productPrice: ProductPriceDto | undefined = productBrandAndProductPrice.child;
        const isProductPackageActive = productPackage.active;
        const iconColors: Color = isProductPackageActive ? Color.primary600 : Color.supportGraphite300;

        return (
            <TableAction>
                {depth === PRODUCT_PACKAGE_DEPTH_LEVEL ? (
                    <ActionIcon
                        tooltip={t("prices.newTooltip")}
                        onClick={isProductPackageActive ? () => openProductPriceFormModal(productPackage) : noop}
                        color={Color.supportNavy100}
                        disabled={!isProductPackageActive}
                    >
                        <Icon.AddSquare size={IconSizes.m} color={iconColors} filled />
                    </ActionIcon>
                ) : (
                    <>
                        {isEditButtonVisible(productPrice, productPackage) && (
                            <ActionIcon
                                tooltip={t("prices.updateTooltip")}
                                disabled={!isProductPackageActive}
                                onClick={() => openProductPriceFormModal(productPackage, productPrice)}
                                color={Color.accent100}
                            >
                                <Icon.Edit size={ICON_TABLE_SIZE} color={iconColors} filled />
                            </ActionIcon>
                        )}
                        {isDeleteButtonVisible(index, productPrice) &&
                            createDeletePriceButton(t, productPrice ? () => deleteProductPrice(productPrice?.id) : noop)}
                    </>
                )}
            </TableAction>
        );
    };

export const createExpandRow = (row: Row<ExpandTableProductPackageWithPricesType>): ExpandRowType => ({
    accessor: "name",
    colspan: 1,
    component: (
        <Text color={row.original.active ? Color.primary600 : Color.supportGraphite300} weight={700} uppercase whiteSpace="nowrap">
            {row.values.name}
        </Text>
    ),
});

export const overrideRowProps = (row: Row<ExpandTableProductPackageWithPricesType>): Record<string, unknown> => {
    if (row.depth === PRODUCT_PACKAGE_DEPTH_LEVEL) {
        if (!row.original.active) {
            return { $backgroundColor: Color.neutral100, $disabled: true };
        }
    }
    // @ts-ignore
    if (row.original.current) {
        return { $backgroundColor: Color.warning50 };
    }

    return EMPTY_OBJECT;
};
