import type { DefaultTheme } from "styled-components";
import { createGlobalStyle } from "styled-components";

import { BoxShadow } from "./boxShadow";
import { Color } from "./color";
import { IconSizes } from "./iconSizes";
import { Radius } from "./radius";
import { ZIndex } from "./zIndex";

export { BoxShadow } from "./boxShadow";
export { Breakpoint } from "./breakpoint";
export { Color } from "./color";
export { IconSizes } from "./iconSizes";
export { maxWidthBreakpoint, useMaxWidthBreakpoint } from "./util";
export { ZIndex } from "./zIndex";

export const theme: DefaultTheme = {
    boxShadow: BoxShadow,
    fontFamily: "Arial, Helvetica Neue, Helvetica, sans-serif",
    color: Color,
    radius: Radius,
    zIndex: ZIndex,
    iconSizes: IconSizes,
};

export const GlobalStyle = createGlobalStyle`
    * {
        box-sizing: border-box;
    }

    html {
        font-size: 62.5%;
    }

    body {
        font-size: 1.6rem;
        background-color: ${Color.neutral50};
    }
`;
