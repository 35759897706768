import { InteractionType } from "@azure/msal-browser";
import { MsalAuthenticationTemplate, MsalProvider } from "@azure/msal-react";
import type { FC } from "react";
import { useEffect } from "react";

import { useAppDispatch, useAppSelector } from "src/utils";
import { setAccessToken } from "./actions";
import { ErrorAuth, LoadingAuth } from "./components";
import { loginRequest } from "./config";
import msalInstance from "./msalInstance";
import { getAccessToken } from "./selectors";
import { acquireAccessToken } from "./utils";

// @ts-ignore - for some reason I cannot use FC as type for component
const AuthTokenProvider = ({ children }) => {
    const currentAccessToken = useAppSelector(getAccessToken);
    const dispatch = useAppDispatch();

    useEffect(() => {
        if (!currentAccessToken) {
            acquireAccessToken().then((token) => {
                if (token) {
                    dispatch(setAccessToken(token));
                }
            });
        }
    }, [currentAccessToken, dispatch]);

    return currentAccessToken ? children : <LoadingAuth />;
};

export const AuthProvider: FC = ({ children }) => (
    <MsalProvider instance={msalInstance}>
        <MsalAuthenticationTemplate
            interactionType={InteractionType.Redirect}
            authenticationRequest={loginRequest}
            errorComponent={ErrorAuth}
            loadingComponent={LoadingAuth}
        >
            <AuthTokenProvider>{children}</AuthTokenProvider>
        </MsalAuthenticationTemplate>
    </MsalProvider>
);
