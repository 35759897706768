import type { TFunction } from "react-i18next";
import type { Cell } from "react-table";

import { useGetWaitingEmergencyOrdersQuery } from "src/api";
import { EmergencyOrderProcessedCell, HighlightCell, TableLink, TextColumnFilter } from "src/components";
import { routes } from "src/routes";
import { UNLIMITED_TIER_NUMBER } from "src/supplyLimitSelect";
import { createTableModule, LongTextCell, TableNames } from "src/table";
import type { ColumnsType, EmergencyOrderProductPackageListDto } from "src/types";
import { DATE_TIME_FORMAT, dateTimeFormatter } from "src/utils";

const createColumns = (t: TFunction): ColumnsType<EmergencyOrderProductPackageListDto> => [
    {
        collapse: true,
        noWrap: true,
        accessor: "requestedOn" as const,
        Header: t("dashboard.emergencyOrders.table.requestedOn") as string,
        disableFilters: true,
        Cell: ({ value }: Cell<EmergencyOrderProductPackageListDto>) => dateTimeFormatter(value, DATE_TIME_FORMAT),
    },
    {
        width: 250,
        minWidth: 120,
        Header: t("dashboard.emergencyOrders.table.requestedFor") as string,
        accessor: "customerName" as const,
        Filter: TextColumnFilter,
        Cell: ({ value, row }: Cell<EmergencyOrderProductPackageListDto>) => (
            <TableLink to={routes.customerDetail.fillPathParams({ customerId: row.original.customerId.toString() })}>{value}</TableLink>
        ),
    },
    {
        minWidth: 100,
        Header: t("dashboard.emergencyOrders.table.acn") as string,
        accessor: "customerAcn" as const,
        Filter: TextColumnFilter,
    },
    {
        minWidth: 100,
        Header: t("dashboard.emergencyOrders.table.product") as string,
        accessor: "productPackageName" as const,
        Filter: TextColumnFilter,
    },
    {
        collapse: true,
        Header: t("dashboard.emergencyOrders.table.tierNumber") as string,
        accessor: "tierNumber" as const,
        align: "center",
        disableFilters: true,
        Cell: ({ row }: Cell<EmergencyOrderProductPackageListDto>) => (
            <HighlightCell highlight={!row.original.isDefaultTier}>
                {row.original.tierNumber === UNLIMITED_TIER_NUMBER ? t("common.unlimited") : row.original.tierNumber}
            </HighlightCell>
        ),
    },
    {
        collapse: true,
        Header: t("dashboard.emergencyOrders.table.dailyLimit") as string,
        accessor: "dailyLimit" as const,
        disableFilters: true,
        align: "center",
    },
    {
        collapse: true,
        Header: t("dashboard.emergencyOrders.table.monthlyLimit") as string,
        accessor: "monthlyLimit" as const,
        disableFilters: true,
        align: "center",
    },
    {
        collapse: true,
        Header: t("dashboard.emergencyOrders.table.monthlyVolumeAdjustment") as string,
        accessor: "monthlyVolumeAdjustment" as const,
        disableFilters: true,
        align: "center",
    },
    {
        collapse: true,
        Header: t("dashboard.emergencyOrders.table.requestedAmount") as string,
        accessor: "requestedAmount" as const,
        disableFilters: true,
        align: "center",
    },
    {
        collapse: true,
        Header: t("dashboard.emergencyOrders.table.processed.header") as string,
        accessor: "approvedThisMonth" as const,
        disableFilters: true,
        align: "center",
        Cell: ({ row }: Cell<EmergencyOrderProductPackageListDto>) => <EmergencyOrderProcessedCell row={row.original} />,
    },
    {
        minWidth: 120,
        Header: t("dashboard.emergencyOrders.table.description") as string,
        accessor: "orderReason" as const,
        disableFilters: true,
        Cell: ({ value }: Cell<EmergencyOrderProductPackageListDto>) => <LongTextCell text={value} charLimit={10} />,
    },
];

export const tableModule = createTableModule<EmergencyOrderProductPackageListDto>(
    TableNames.emergencyOrders,
    useGetWaitingEmergencyOrdersQuery,
    createColumns,
);
