import type { FC } from "react";
import { useCallback } from "react";

import { useTranslation } from "src/translations";
import { useAppDispatch } from "src/utils";
import { useDeactivateCustomers } from "../../../utils";
import { defaultCustomersTableConfig } from "../../constants";
import { createEmptyRowForRequestTables } from "../../utils";
import { tableModule } from "./table";
import { createActionsForSelection } from "./utils";

export const Deactivated: FC = () => {
    const { t } = useTranslation();
    const dispatch = useAppDispatch();

    const onSuccess = useCallback(() => dispatch(tableModule.resetTableSelectionAction), [dispatch]);
    const deactivate = useDeactivateCustomers(onSuccess);

    return (
        <tableModule.Container
            isSelectable
            canSelectAll={false}
            defaultTableConfig={defaultCustomersTableConfig}
            actionsForSelection={createActionsForSelection(t, deactivate)}
            emptyRow={createEmptyRowForRequestTables(t("customers.deactivate.emptyRow"), t("customers.deactivate.emptyRowWithFilters"))}
        />
    );
};
