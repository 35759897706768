import type { Row } from "react-table";

import { Text } from "src/components";
import type { ExpandRowType } from "src/table";
import { Color } from "src/theme";
import type { ExpandTableSpecialPricesType } from "src/types";

export const createExpandRow = (row: Row<ExpandTableSpecialPricesType>): ExpandRowType => ({
    accessor: "productPackageName",
    colspan: 10,
    component: (
        <Text color={Color.primary600} weight={700} uppercase whiteSpace="nowrap">
            {row.original.name}
        </Text>
    ),
});
