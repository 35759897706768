import { Pagination as PaginationMantine } from "@mantine/core";
import type { FC } from "react";
import styled, { css } from "styled-components";

import { Color, maxWidthBreakpoint } from "src/theme";
import { useTranslation } from "src/translations";
import { Select } from "../Form";
import { Grid } from "../layouts";
import { Text } from "../Text";

type PaginationProps = Readonly<{
    activePage: number;
    setPage: (page: number) => void;
    setPageSize: (pageCount: number) => void;
    pageSize: number;
    total: number;
}>;

const pageSizes = ["10", "25", "50"];
const pageSizeOptions = pageSizes.map((pageSize) => ({ label: pageSize, value: pageSize }));

export const Pagination: FC<PaginationProps> = ({ activePage, setPage, total, setPageSize, pageSize }) => {
    const { t } = useTranslation();
    return (
        <PaginationWrapper>
            <StyledPagination page={activePage} onChange={setPage} total={total} />
            <PageSize>
                <Grid gridAutoFlow="column" gap="1.5rem" alignItems="center">
                    <Text size="1.4rem" color={Color.supportNavy500}>
                        {t("common.pagination.pageSize")}
                    </Text>
                    <Select
                        options={pageSizeOptions}
                        withoutErrorPlaceholder
                        clearable={false}
                        fontSize="1.2rem"
                        value={pageSize.toString()}
                        height="2.4rem"
                        withinPortal
                        searchable={false}
                        onChange={(value) => {
                            if (value) {
                                setPageSize(parseInt(value, 10));
                            }
                        }}
                    />
                </Grid>
            </PageSize>
        </PaginationWrapper>
    );
};

const PaginationWrapper = styled.div`
    display: grid;
    grid-template-columns: 150px 1fr 150px;
    width: 100%;
    align-items: center;

    ${maxWidthBreakpoint.tablet(css`
        grid-template-columns: 1fr;
        justify-items: center;
        gap: 2rem;
    `)}
`;

const PageSize = styled.div`
    grid-column: 3;
    justify-self: end;

    ${maxWidthBreakpoint.tablet(css`
        grid-column: unset;
        justify-self: unset;
    `)}
`;

const StyledPagination = styled(PaginationMantine)`
    &.mantine-Group-root {
        grid-column: 2;
        justify-self: center;

        .mantine-Pagination-item {
            background-color: transparent;
            border: 2px solid transparent;
            color: ${({ theme }) => theme.color.supportNavy500};

            &[data-active] {
                background-color: transparent;
                border: 2px solid ${({ theme }) => theme.color.primary500};
                color: ${({ theme }) => theme.color.primary500};
            }
        }

        ${maxWidthBreakpoint.tablet(css`
            grid-column: unset;
        `)}

        ${maxWidthBreakpoint.mobile(css`
            gap: 0;
        `)}
    }
`;
