import type { UseFiltersColumnProps } from "react-table";

import type { TableRecordType } from "src/types";
import { isEmpty } from "src/utils";
import { NumberInput } from "../Form";
import { TABLE_FILTER_HEIGHT } from "./constants";

type NumberColumnFilterProps<RecordType extends TableRecordType> = Readonly<{
    column: UseFiltersColumnProps<RecordType>;
}>;

export const NumberColumnFilter = <RecordType extends TableRecordType>({
    column: { filterValue, setFilter },
}: NumberColumnFilterProps<RecordType>) => {
    const numberValue = !isEmpty(filterValue) && typeof filterValue === "string" ? parseFloat(filterValue) : filterValue;
    return (
        <NumberInput
            value={isEmpty(numberValue) ? undefined : numberValue}
            height={TABLE_FILTER_HEIGHT}
            noClampOnBlur
            fontSize="1.2rem"
            withoutBorder
            hideControls
            withoutErrorPlaceholder
            onChange={(value: number | undefined) => {
                // If old value is same as new value, we don't need to call setFilter
                // this will fix the issue when user click outside the input and rows are expanded
                // because onChange is called on blur, and you cannot turn it off
                if (numberValue !== value) {
                    setFilter(value);
                }
            }}
        />
    );
};
