import styled from "styled-components";

export const WarningBox = styled.div`
    display: grid;
    grid-auto-flow: column;
    justify-content: start;
    align-items: center;
    gap: 1.6rem;
    background-color: ${({ theme }) => theme.color.warning100};
    border-radius: ${({ theme }) => theme.radius.default};
    padding: 1.6rem;
`;
