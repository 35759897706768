import { isToday } from "date-fns";
import { useMemo } from "react";
import { Link } from "react-router-dom";

import { useGetLastInterfaceByOperationTypeQuery } from "src/api";
import failIcon from "src/assets/icons/interfaces_import_fail.svg";
import failSmIcon from "src/assets/icons/interfaces_import_fail_sm.svg";
import okIcon from "src/assets/icons/interfaces_import_ok.svg";
import okSmIcon from "src/assets/icons/interfaces_ok_sm.svg";
import { Button, Grid, Icon, Loader, LoadingOverlay, SvgIcon, Text } from "src/components";
import { createSearchParamsAsString, ImportExportTab, routes, TAB_PARAM } from "src/routes";
import { Color } from "src/theme";
import { Trans, useTranslation } from "src/translations";
import { PhoenixInterfaceOperationFilterDtoInterfaceOperationTypeEnum } from "src/types";
import { DATE_TIME_FORMAT, dateTimeFormatter, isNotNullish } from "src/utils";
import { InterfacesIcon, InterfaceWidgetIconWithInfo, InterfaceWidgetWrapper, SuccessBlock } from "../components";
import { DashboardCollapsibleWidgetsEnum } from "../enums";
import { DashboardCard } from "./DashboardCard";
import { WidgetWarning } from "./WidgetWarning";

export const IncomingInterfacesWidget = () => {
    const { t } = useTranslation();

    const {
        data: lastIncomingInterface,
        isLoading,
        isFetching,
    } = useGetLastInterfaceByOperationTypeQuery(PhoenixInterfaceOperationFilterDtoInterfaceOperationTypeEnum.Incoming);

    const lastIncomingTimestamp = lastIncomingInterface?.lastInterface?.operationTimestamp;
    const lastFailedIncomingTimestamp = lastIncomingInterface?.lastFailedInterface?.operationTimestamp;

    const errorToday = useMemo(() => {
        if (isNotNullish(lastFailedIncomingTimestamp)) {
            const errorDate = new Date(lastFailedIncomingTimestamp);
            return isToday(errorDate);
        }
        return false;
    }, [lastFailedIncomingTimestamp]);

    const getStatusBarIcon = () => {
        if (!isLoading && !isFetching) {
            if (errorToday) {
                return <SvgIcon icon={failSmIcon} />;
            }
            if (lastFailedIncomingTimestamp) {
                return <Icon.Warning color={Color.warning500} filled />;
            }
            return <SvgIcon icon={okSmIcon} />;
        }
        return <Loader size={20} />;
    };

    return (
        <DashboardCard
            title={t("dashboard.incomingInterfaces.title")}
            collapsibleWidgetId={DashboardCollapsibleWidgetsEnum.IncomingInterfacesWidget}
            statusBar={getStatusBarIcon()}
        >
            <InterfaceWidgetWrapper>
                <LoadingOverlay loading={isLoading || isFetching} />
                <InterfaceWidgetIconWithInfo>
                    <InterfacesIcon $withPadding>{errorToday ? <SvgIcon icon={failIcon} /> : <SvgIcon icon={okIcon} />}</InterfacesIcon>
                    <Grid gridAutoFlow="row" gap="0.5rem">
                        <Grid gridTemplateColumns="auto min-content" gap="1rem">
                            <Trans
                                i18nKey="dashboard.incomingInterfaces.widget.lastImport"
                                values={{
                                    date: lastIncomingTimestamp
                                        ? dateTimeFormatter(lastIncomingTimestamp, DATE_TIME_FORMAT)
                                        : t("common.never"),
                                }}
                                components={{
                                    text: <Text color={Color.supportNavy300} size="1.4rem" />,
                                    breakLine: <br />,
                                }}
                            />
                            <Button
                                component={Link}
                                variant="subtle"
                                to={`${routes.importExport.path}?${createSearchParamsAsString({
                                    [TAB_PARAM]: ImportExportTab.importInterfaces,
                                })}`}
                            >
                                {t("dashboard.incomingInterfaces.widget.importDetail")}
                            </Button>
                        </Grid>
                        {lastFailedIncomingTimestamp && (
                            <Grid gridTemplateColumns="1fr min-content">
                                <Text color={Color.error500} size="1.4rem">
                                    {t("dashboard.incomingInterfaces.widget.lastFail", {
                                        date: dateTimeFormatter(lastFailedIncomingTimestamp, DATE_TIME_FORMAT),
                                    })}
                                </Text>
                            </Grid>
                        )}
                    </Grid>
                </InterfaceWidgetIconWithInfo>
                {!lastFailedIncomingTimestamp && (
                    <SuccessBlock>
                        <Text color={Color.supportNavy300} size="1.4rem">
                            {t("dashboard.incomingInterfaces.widget.allFine")}
                        </Text>
                    </SuccessBlock>
                )}
                {errorToday && <WidgetWarning text={t("dashboard.incomingInterfaces.errorToday")} />}
            </InterfaceWidgetWrapper>
        </DashboardCard>
    );
};
