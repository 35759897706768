import type { FC } from "react";
import styled from "styled-components";

import type { Color } from "src/theme";
import { Tooltip } from "../Tooltip";
import { DELAY_FOR_ICON_TOOLTIP } from "./constants";

type ArchiveIconProps = { color: Color; tooltip?: string };

// Special icon for archive state of product
// We are using google icon for this one, instead of fluidIcon
export const ArchiveIcon: FC<ArchiveIconProps> = ({ color, tooltip }) => {
    const icon = (
        <StyledArchiveIcon $color={color} height="16" viewBox="0 0 48 48" width="16">
            <path
                fill="currentColor"
                d="M41.09 10.45l-2.77-3.36c-.56-.66-1.39-1.09-2.32-1.09h-24c-.93 0-1.76.43-2.31 1.09l-2.77 3.36c-.58.7-.92 1.58-.92 2.55v25c0 2.21 1.79 4 4 4h28c2.21 0 4-1.79 4-4v-25c0-.97-.34-1.85-.91-2.55zm-17.09 24.55l-11-11h7v-4h8v4h7l-11 11zm-13.75-25l1.63-2h24l1.87 2h-27.5z"
            />
        </StyledArchiveIcon>
    );
    return tooltip ? (
        <Tooltip withinPortal={false} label={tooltip} withArrow backgroundColor={color} openDelay={DELAY_FOR_ICON_TOOLTIP}>
            {icon}
        </Tooltip>
    ) : (
        icon
    );
};

const StyledArchiveIcon = styled.svg<{ $color: Color }>`
    color: ${({ $color }) => $color};
`;
