// @ts-nocheck
import deep from "deep-get-set";

/**
 * Creates object from nested path and sets a value to the last level.
 * @param path string of properties separated by dot or by array index ('.', [index])
 * @param value value to be set
 *
 * @example
 * createObjectFromString("a.b.c", "example") => a: {b: {c: "example"} }
 * createObjectFromString("a[0].b", "example") => a: {0: {b: "example"} }
 */
export const createObjectFromString = (path, value) => {
    const obj = {};
    let o = obj;

    const pathAsArray = path.replace(/]$/, "").split(/\[|].|\./);

    pathAsArray.forEach((key) => {
        o[key] = {};
        o = o[key];
    });

    deep(obj, pathAsArray, value);

    return obj;
};
