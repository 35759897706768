import type { FC, MouseEventHandler } from "react";
import styled from "styled-components";

import { Color, IconSizes } from "src/theme";
import { Icon } from "../../Icons";

type SelectRightSectionProps = {
    showClear: boolean;
    onRemove: MouseEventHandler<HTMLDivElement>;
};

export const MultiSelectRightSection: FC<SelectRightSectionProps> = ({ showClear, onRemove }) => (
    <SelectRightSectionWrapper showClear={showClear} onClick={onRemove}>
        {showClear ? (
            <Icon.Close color={Color.supportNavy500} size={IconSizes.s} />
        ) : (
            <Icon.ChevronDown color={Color.supportNavy500} size={IconSizes.s} />
        )}
    </SelectRightSectionWrapper>
);

const SelectRightSectionWrapper = styled.div<Pick<SelectRightSectionProps, "showClear">>`
    display: flex;
    align-items: center;
    justify-content: center;

    width: var(--right-icon-size);
    height: 100%;

    cursor: pointer;
    pointer-events: ${({ showClear }) => (showClear ? "auto" : "none")};
`;
