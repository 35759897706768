import { isArray, isEmpty, isNotNullish, removeSpaces, stringTrim } from "src/utils";

// eslint-disable-next-line @typescript-eslint/no-explicit-any
export type Validator = (value: any) => string | undefined;

const emailRegex = /^[_A-Za-z0-9-+]+(\.[_A-Za-z0-9-+]+)*@[A-Za-z0-9-]+(\.[A-Za-z0-9]+)*(\.[A-Za-z]{2,})$/;
const phoneRegex = /^(\+[0-9]{3})?[0-9]{9}$/;

export const createValidator =
    (validation: (value: string) => boolean, errorMessage: string): Validator =>
    (value: string): string | undefined =>
        value && !validation(value.trim()) ? errorMessage : undefined;

export const createMinLengthValidator = (minLength: number, errorMessage: string) =>
    createValidator((value: string) => value.length >= minLength, errorMessage);

export const createMaxLengthValidator = (maxLength: number, errorMessage: string) =>
    createValidator((value: string) => value.length <= maxLength, errorMessage);

export const createExactLengthValidator = (length: number, errorMessage: string) =>
    createValidator((value: string) => value.length === length, errorMessage);

export const email = createValidator((value: string) => emailRegex.test(stringTrim(value)), "validation.constraints.format.email");

export const phone = createValidator((value: string) => phoneRegex.test(removeSpaces(value)), "validation.constraints.format.phone");

export const required = (value: unknown) =>
    stringTrim(value) !== "" && isNotNullish(value) ? undefined : "validation.constraints.required";

export const isNonEmptyArray = (value: unknown) =>
    isNotNullish(value) && isArray(value) && value.length > 0 ? undefined : "validation.constraints.isNonEmptyArray";

export const onlyNumbers = (value: unknown) =>
    isEmpty(value) || (typeof value === "string" && /^\d+$/.test(value)) ? undefined : "validation.constraints.format.onlyNumber";

export const min5Length = createMinLengthValidator(5, "validation.constraints.min5Length");
export const min9Length = createMinLengthValidator(9, "validation.constraints.min9Length");
export const max7Length = createMaxLengthValidator(7, "validation.constraints.max7Length");
export const max14Length = createMaxLengthValidator(14, "validation.constraints.max14Length");
export const max255Length = createMaxLengthValidator(255, "validation.constraints.max255Length");
export const exact9Length = createExactLengthValidator(9, "validation.constraints.exact9Length");
export const exact13Length = createExactLengthValidator(13, "validation.constraints.exact13Length");

export const composeValidators =
    (...validators: Validator[]) =>
    // eslint-disable-next-line @typescript-eslint/no-explicit-any
    (value: any) =>
        validators.reduce<string | undefined>((error, validator) => error || validator(value), undefined);
