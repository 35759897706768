import type { TFunction } from "react-i18next";
import type { Cell } from "react-table";

import { useGetCustomersToDeactivateQuery } from "src/api";
import { createSelectColumnFilter, TableLink, TextColumnFilter } from "src/components";
import { routes } from "src/routes";
import { createTableModule, TableNames } from "src/table";
import { Color } from "src/theme";
import type { ColumnsType, CustomerListDto } from "src/types";
import { CustomerCategoryEnum, CustomerTypeEnum } from "src/types";
import { enumToLocalizedOptions, getTranslatedEnumValue, zipFormatter } from "src/utils";
import { customerCategoryFormatter } from "../../utils";

const createColumns = (t: TFunction): ColumnsType<CustomerListDto> => [
    {
        collapse: true,
        minWidth: 65,
        accessor: "category" as const,
        Header: t("customers.table.category") as string,
        noWrap: true,
        align: "center",
        Filter: createSelectColumnFilter(enumToLocalizedOptions("customerCategory", CustomerCategoryEnum)),
        Cell: ({ value }: Cell<CustomerListDto>) => customerCategoryFormatter(t, value),
    },
    {
        width: 350,
        minWidth: 120,
        Header: t("customers.table.name") as string,
        accessor: "name" as const,
        Filter: TextColumnFilter,
        Cell: ({ row, value }: Cell<CustomerListDto>) => (
            <TableLink
                color={Color.primary600}
                weight={700}
                to={routes.customerDetail.fillPathParams({
                    customerId: row.original.id.toString(),
                })}
            >
                {value}
            </TableLink>
        ),
    },
    {
        minWidth: 100,
        Header: t("customers.table.type") as string,
        accessor: "type" as const,
        Filter: createSelectColumnFilter(enumToLocalizedOptions("CustomerTypeEnum", CustomerTypeEnum)),
        Cell: ({ value }: Cell<CustomerListDto>) => getTranslatedEnumValue("CustomerTypeEnum", value),
    },
    {
        minWidth: 100,
        Header: t("customers.table.acn") as string,
        accessor: "acn" as const,
        Filter: TextColumnFilter,
    },
    {
        minWidth: 100,
        Header: t("customers.table.address") as string,
        accessor: "street" as const,
        Filter: TextColumnFilter,
    },
    {
        minWidth: 100,
        Header: t("customers.table.city") as string,
        accessor: "city" as const,
        Filter: TextColumnFilter,
    },
    {
        minWidth: 100,
        Header: t("customers.table.zip") as string,
        accessor: "zip" as const,
        Cell: ({ value }: Cell<CustomerListDto>) => zipFormatter(value),
        Filter: TextColumnFilter,
    },
    {
        minWidth: 100,
        Header: t("customers.table.tin") as string,
        accessor: "tin" as const,
        Filter: TextColumnFilter,
    },
    {
        minWidth: 100,
        Header: t("customers.table.payerName") as string,
        accessor: "payerName" as const,
        Filter: TextColumnFilter,
    },
    {
        minWidth: 100,
        Header: t("customers.table.payerAcn") as string,
        accessor: "payerAcn" as const,
        Filter: TextColumnFilter,
    },
];

export const tableModule = createTableModule<CustomerListDto>(
    TableNames.customersDeactivated,
    useGetCustomersToDeactivateQuery,
    createColumns,
);
