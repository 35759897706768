import type { FC } from "react";

import { useCreateProductPackageMutation, useUpdateProductPackageMutation } from "src/api";
import {
    Button,
    Checkbox,
    CheckboxProps,
    DateInput,
    DateInputProps,
    Modal,
    ModalButtonLayout,
    NumberInput,
    NumberInputProps,
    SectionFormLayout,
    Text,
    TextInput,
    TextInputProps,
    TwoColumnsFormLayout,
} from "src/components";
import {
    composeValidators,
    createCurrencyInputProps,
    exact9Length,
    exact13Length,
    Form,
    InputField,
    max7Length,
    max255Length,
    min5Length,
    onlyNumbers,
    percentageInputProps,
    required,
} from "src/form";
import { useModal } from "src/modal";
import { Color } from "src/theme";
import { Trans, useTranslation } from "src/translations";
import type { ProductBrandDto, ProductPackageDto } from "src/types";
import { isDateBeforeToday } from "src/utils";
import { PRODUCT_PACKAGE_MODAL_ID } from "../constants";
import type { CreateAndUpdateProductPackage } from "../types";

type ProductPackageFormProps = Readonly<{
    productPackageForEdit?: ProductPackageDto;
    productBrand?: ProductBrandDto;
}>;

export const ProductPackageForm: FC<ProductPackageFormProps> = ({ productPackageForEdit, productBrand }) => {
    const [isModalOpened, , closeModal] = useModal(PRODUCT_PACKAGE_MODAL_ID);
    const [createProductPackage] = useCreateProductPackageMutation();
    const [updateProductPackage] = useUpdateProductPackageMutation();
    const { t } = useTranslation();

    const updateMode = !!productPackageForEdit;

    if (isModalOpened && !productBrand) {
        throw new Error("Product brand has to be selected for displaying product package form.");
    }

    const initialValues = {
        productBrandId: productBrand?.id,
        id: updateMode ? productPackageForEdit?.id : undefined,
        name: updateMode ? productPackageForEdit?.name : productBrand?.name,
        sapCode: updateMode ? productPackageForEdit?.sapCode : "",
        agentCode: updateMode ? productPackageForEdit?.agentCode : "",
        eanCode: updateMode ? productPackageForEdit?.eanCode || "" : "",
        suklCode: updateMode ? productPackageForEdit?.suklCode : "0",
        margin: updateMode ? productPackageForEdit?.margin : undefined,
        active: updateMode ? productPackageForEdit?.active : true,
        price: {
            price: undefined,
            validFrom: undefined,
        },
    };

    return (
        <Modal
            title={
                <Trans
                    i18nKey={productPackageForEdit ? "products.updatePackageTitle" : "products.createPackageTitle"}
                    values={{ name: productBrand?.name }}
                    components={{
                        productName: <Text uppercase />,
                    }}
                />
            }
            opened={isModalOpened}
            maxWidth="550px"
            onClose={closeModal}
        >
            <Form<CreateAndUpdateProductPackage>
                initialValues={initialValues}
                onSubmit={updateMode ? updateProductPackage : createProductPackage}
                onSuccess={closeModal}
                layoutGap="2rem"
                loadingMessage={updateMode ? t("products.productForm.updatingMessage") : t("products.productForm.savingMessage")}
                successMessage={
                    updateMode ? t("products.productForm.updatingMessageSuccess") : t("products.productForm.savingMessageSuccess")
                }
            >
                {({ dirty, values }) => (
                    <>
                        <SectionFormLayout
                            title={
                                <Trans
                                    i18nKey="products.productForm.generalSection"
                                    components={{
                                        text: <Text color={Color.primary600} size="1.6rem" weight={700} />,
                                    }}
                                />
                            }
                        >
                            <InputField<TextInputProps["value"], TextInputProps>
                                name="name"
                                input={TextInput}
                                validate={composeValidators(max255Length)}
                                autofocus
                                inputProps={{
                                    label: t("products.productForm.name.label"),
                                    placeholder: t("products.productForm.name.placeholder"),
                                    maxWidth: "225px",
                                }}
                                required
                            />
                            <InputField<TextInputProps["value"], TextInputProps>
                                name="sapCode"
                                input={TextInput}
                                inputProps={{
                                    label: t("products.productForm.sapCode.label"),
                                    placeholder: t("products.productForm.sapCode.placeholder"),
                                    maxWidth: "180px",
                                }}
                                required
                            />
                            <InputField<TextInputProps["value"], TextInputProps>
                                name="agentCode"
                                validate={composeValidators(onlyNumbers, exact9Length)}
                                input={TextInput}
                                inputProps={{
                                    label: t("products.productForm.agentCode.label"),
                                    placeholder: t("products.productForm.agentCode.placeholder"),
                                    maxWidth: "180px",
                                }}
                                required
                            />
                            <InputField<TextInputProps["value"], TextInputProps>
                                name="suklCode"
                                validate={composeValidators(onlyNumbers, min5Length, max7Length)}
                                input={TextInput}
                                inputProps={{
                                    label: t("products.productForm.suklCode.label"),
                                    placeholder: t("products.productForm.suklCode.placeholder"),
                                    maxWidth: "180px",
                                }}
                                required
                            />
                            <InputField<NumberInputProps["value"], NumberInputProps>
                                name="margin"
                                input={NumberInput}
                                type="number"
                                inputProps={{
                                    label: t("products.productForm.margin.label"),
                                    placeholder: t("products.productForm.margin.placeholder"),
                                    maxWidth: "180px",
                                    ...percentageInputProps,
                                }}
                                required
                            />
                            <InputField<CheckboxProps["value"], CheckboxProps>
                                name="active"
                                type="checkbox"
                                input={Checkbox}
                                inputProps={{
                                    label: t("products.productForm.active.label"),
                                    description: updateMode ? undefined : t("products.productForm.active.description"),
                                }}
                            />
                        </SectionFormLayout>
                        <SectionFormLayout
                            title={
                                <Trans
                                    i18nKey="products.productForm.additionalSection"
                                    components={{
                                        text: <Text color={Color.primary600} size="1.6rem" weight={700} />,
                                        optional: <Text color={Color.neutral500} size="1.4rem" weight={400} />,
                                    }}
                                />
                            }
                        >
                            <InputField<TextInputProps["value"], TextInputProps>
                                name="eanCode"
                                validate={composeValidators(onlyNumbers, exact13Length)}
                                input={TextInput}
                                inputProps={{
                                    label: t("products.productForm.eanCode.label"),
                                    placeholder: t("products.productForm.eanCode.placeholder"),
                                    maxWidth: "180px",
                                }}
                            />
                            {!updateMode && (
                                <TwoColumnsFormLayout>
                                    <InputField<NumberInputProps["value"], NumberInputProps>
                                        name="price.price"
                                        type="number"
                                        input={NumberInput}
                                        validate={(value: NumberInputProps["value"]) =>
                                            // @ts-ignore
                                            values.price.validFrom && required(value)
                                        }
                                        inputProps={{
                                            label: t("products.productForm.price.label"),
                                            placeholder: t("products.productForm.price.placeholder"),
                                            maxWidth: "180px",
                                            ...createCurrencyInputProps(t),
                                        }}
                                    />
                                    <InputField<DateInputProps["value"], DateInputProps>
                                        name="price.validFrom"
                                        input={DateInput}
                                        validate={(value: DateInputProps["value"]) =>
                                            // @ts-ignore
                                            values.price.price && required(value)
                                        }
                                        inputProps={{
                                            label: t("products.productForm.validFrom.label"),
                                            placeholder: t("common.datePlaceholder"),
                                            maxWidth: "180px",
                                            clearable: true,
                                            excludeDate: isDateBeforeToday,
                                        }}
                                    />
                                </TwoColumnsFormLayout>
                            )}
                        </SectionFormLayout>
                        <ModalButtonLayout>
                            <Button type="submit" disabled={!dirty}>
                                {updateMode ? t("common.update") : t("common.create")}
                            </Button>
                            <Button type="button" onClick={closeModal} variant="outline">
                                {t("common.cancel")}
                            </Button>
                        </ModalButtonLayout>
                    </>
                )}
            </Form>
        </Modal>
    );
};
