import styled from "styled-components";

import { Button } from "../Button";

export const TableActionButton = styled(Button).attrs({ variant: "subtle" })`
    &.mantine-Button-root {
        height: auto;
        padding: 0;

        &:hover {
            text-decoration: none;
        }
    }
`;
