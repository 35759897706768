import type { Row } from "react-table";

import { TableLink } from "src/components";
import { routes } from "src/routes";
import type { ExpandRowType } from "src/table";
import { Color } from "src/theme";
import { CustomerStatusEnum, ExpandTableCustomerType } from "src/types";

const PAYER_DEPTH_LEVEL = 0;

export const overrideRowProps = (row: Row<ExpandTableCustomerType>): Record<string, unknown> => {
    if (row.original.status === CustomerStatusEnum.Inactive) {
        return { $backgroundColor: Color.supportGraphite50 };
    }
    if (row.original.status === CustomerStatusEnum.Blocked) {
        return { $backgroundColor: Color.neutral300 };
    }
    if (row.depth === PAYER_DEPTH_LEVEL) {
        return { $backgroundColor: Color.neutral50 };
    }
    return { $backgroundColor: Color.white };
};

export const createExpandRow = (row: Row<ExpandTableCustomerType>): ExpandRowType => ({
    accessor: "name",
    colspan: 2,
    component: (
        <TableLink
            color={Color.primary600}
            weight={700}
            to={`${routes.customerDetail.fillPathParams({
                customerId: row.original.id.toString(),
            })}`}
        >
            {row.values.name}
        </TableLink>
    ),
});
