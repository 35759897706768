import styled from "styled-components";

export const CookieDetail = styled.p`
    padding-top: 3.2rem;
    margin-top: 3.2rem;
    margin-bottom: 0;
    font-size: 1.4rem;
    line-height: 1.4;
    border-top: 1px solid ${({ theme }) => theme.color.neutral300};
`;
