import { useLocalStorage } from "@mantine/hooks";
import type { FC } from "react";
import { useState } from "react";

import { Button, Grid, Icon, Text } from "src/components";
import { Color, IconSizes } from "src/theme";
import { Trans, useTranslation } from "src/translations";
import { CookieBannerLayout, CookieDetail, CookieLink } from "../components";

// If you want to update cookie consent, you probably want to update version
// so banner is visible again even for users which already accepted old cookies.
const COOKIE_VERSION = "1.0.0";

export const CookieBanner: FC = () => {
    const [isDetailVisible, setIsDetailVisible] = useState(false);
    const [consent, setConsent] = useLocalStorage({ key: `public-eo-form-cookie-consent-${COOKIE_VERSION}`, defaultValue: false });
    const { t } = useTranslation();

    return consent ? null : (
        <CookieBannerLayout>
            <Grid justifyContent="space-between" alignItems="center" gridAutoFlow="column" gap="2rem">
                <Grid gridAutoFlow="column" gap="2rem" alignItems="center">
                    <Icon.Cookies color={Color.white} size={IconSizes.xl} />
                    <Grid gap="1rem">
                        <Text size="2.1rem" weight={700}>
                            {t("cookiesConsent.title")}
                        </Text>
                        <div>
                            <Trans
                                i18nKey="cookiesConsent.text"
                                components={{
                                    linkTo: <CookieLink target="_blank" href="https://cookienotice.astrazeneca.com/cs-cz" />,
                                }}
                            />
                        </div>
                    </Grid>
                </Grid>
                <Grid gridAutoFlow="column" gap="3rem">
                    <Button onClick={() => setConsent(true)}>{t("cookiesConsent.agree")}</Button>
                    <Button onClick={() => setIsDetailVisible((oldValue) => !oldValue)} variant="subtle">
                        {isDetailVisible ? t("cookiesConsent.hideDetail") : t("cookiesConsent.detail")}
                    </Button>
                </Grid>
            </Grid>
            {isDetailVisible && <CookieDetail>{t("cookiesConsent.necessaryCookies")}</CookieDetail>}
        </CookieBannerLayout>
    );
};
