import type { FC } from "react";
import styled, { css } from "styled-components";

import { maxWidthBreakpoint } from "src/theme";

type LogoProps = Readonly<{
    claim: string;
    hovered: boolean;
}>;

const LOGO_TEXT_CLASSNAME = "az-logo-text";
const LOGO_ICON_CLASSNAME = "az-logo-icon";

export const Logo: FC<LogoProps> = ({ claim, hovered }) => (
    <>
        <StyledLogo $hovered={hovered} version="1.1" xmlns="http://www.w3.org/2000/svg" viewBox="0 0 344.5 113.9">
            <path
                className={LOGO_TEXT_CLASSNAME}
                fill="currentColor"
                d="M39.5,70.6L44.2,58l4.4,12.7H39.5z M53.2,83.4h4.3L46.3,52.9h-4.2L30.5,83.4h4.3l3.4-9.4h11.6L53.2,83.4z
		 M73.5,76.9c0,2.5-2,3.8-5.5,3.8c-2.8,0-4.6-0.8-5.7-2.6l-4.1,0.7c1.6,3.3,4.9,4.8,9.6,4.8c6.4,0,9.7-3.5,9.7-7.3
		c0-4.8-4.5-6.1-9.6-7.2c-2.5-0.5-5.4-1.4-5.4-3.6c0-1.6,1.5-3.4,4.6-3.4c3.1,0,5,2.1,5.3,3l3.6-1.5c-1.7-3.2-5-4.4-8.4-4.4
		c-5.4,0-8.9,2.6-8.9,6.8c0,4.8,4.1,5.7,8.1,6.6C70.2,73.5,73.5,74.3,73.5,76.9z M86.1,52.9l-4,1.7v5.6h-3.7v3.2h3.7v14.9
		c-0.1,3-0.1,5.2,4.5,5.2c1.5,0,3.1-0.3,3.8-0.4V80c-0.8,0.2-1.8,0.5-2.5,0.5c-1.7,0-1.7-1.2-1.7-3.9V63.4h4.2v-3.2h-4.2V52.9z
		 M93.8,60.2v23.2h3.9V72.7c0-2-0.1-4.3,1-6c1.3-2.1,3.1-2.8,7-2.9v-4c-1.6,0.1-5.7,0.1-7.9,4.5v-4.1H93.8z M128.6,52.9v3.8H146
		l-17.3,22.8v4H151v-3.7h-17.7l17.3-23v-3.8H128.6z M175.4,60.2v23.2h3.9V70.2c0-1.7,0-3.5,1.7-5.2c1.2-1.2,2.7-1.8,4.4-1.8
		c2.1,0,4.3,1,5,3.2c0.2,0.8,0.2,1.5,0.2,3.8v13.1h3.9V69.7c0-2,0-3.4-0.4-4.7c-1.1-3.8-4.7-5.3-7.6-5.3c-1.4,0-5.1,0.3-7.2,4.1
		v-3.5H175.4z M235.8,77.8c-0.8,1.6-2.3,3-5.4,3c-2.6,0-7-1.6-7-9.3c0-2.1,0.4-9.3,6.8-9.3c3.2,0,4.7,1.8,5.4,3.3l3.6-1.5
		c-2.2-3.7-6.3-4.6-9.2-4.6c-7.4,0-11,5.9-11,12.5c0,5.7,3.1,12,11.3,12c4.7,0,7.4-2.3,8.8-4.6L235.8,77.8z M156.6,69.4h12.3
		c-0.3-6.1-3.8-7.1-6.2-7.1C159.4,62.3,157.1,64.7,156.6,69.4z M172.1,79c-1.3,2.3-3.9,4.8-8.9,4.8c-6.8,0-10.8-4.6-10.8-12.4
		c0-8.7,5.7-12,10.6-12c4.3,0,8.3,2.4,9.7,8.2c0.4,2,0.4,3.7,0.4,4.7h-16.6c-0.1,3.9,1.5,8.8,7,8.7c2.2,0,3.8-1.1,4.8-2.6L172.1,79z
		 M200.9,69.4h12.3c-0.3-6.1-3.8-7.1-6.2-7.1C203.6,62.3,201.3,64.7,200.9,69.4z M216.3,79c-1.3,2.3-3.9,4.8-8.9,4.8
		c-6.8,0-10.8-4.6-10.8-12.4c0-8.7,5.7-12,10.6-12c4.3,0,8.3,2.4,9.7,8.2c0.4,2,0.4,3.7,0.4,4.7h-16.7c-0.1,3.9,1.5,8.8,7,8.7
		c2.2,0,3.8-1.1,4.8-2.6L216.3,79z M260.6,83.4l-4.1,0c0,0-0.4-1.6-0.5-3.4c-1.2,1.2-3.4,3.7-7.8,3.7c-4.5,0-7.8-2.7-7.8-6.6
		c0-1.2,0.3-2.5,1-3.5c1.3-2,3.8-3.5,10.7-4.1l3.7-0.3v-0.6c0-2.4,0-6.4-5.2-6.4c-3.2,0-4.4,1.6-4.7,3l-3.6-1.5
		c0.1-0.1,0.1-0.3,0.2-0.4c1.4-2.5,4.1-4.1,8-4.1c2.1,0,4.9,0.5,6.7,2c2.6,2,2.5,5.8,2.5,5.8l0.2,11.9
		C259.9,79,260.1,82.4,260.6,83.4z M255.2,77c0.6-1.3,0.6-1.8,0.6-5.2c-1.5,0.2-3.6,0.5-6.1,1.1c-4.3,0.9-5,2.6-5,4.2
		c0,1.9,1.5,3.4,4.4,3.4C251.8,80.5,254.1,79.4,255.2,77z M125.9,83.4l-4,0c0,0-0.4-1.6-0.5-3.4c-1.2,1.2-3.4,3.7-7.8,3.7
		c-4.5,0-7.9-2.7-7.9-6.6c0-1.2,0.3-2.5,1-3.5c1.3-2,3.8-3.5,10.7-4.1l3.7-0.3v-0.6c0-2.4,0-6.4-5.2-6.4c-3.3,0-4.4,1.6-4.7,3
		l-3.6-1.5c0.1-0.1,0.1-0.3,0.2-0.4c1.4-2.5,4.1-4.1,8-4.1c2.1,0,4.9,0.5,6.7,2c2.6,2,2.5,5.8,2.5,5.8l0.1,11.9
		C125.2,79,125.4,82.4,125.9,83.4z M120.6,77c0.6-1.3,0.6-1.8,0.6-5.2c-1.5,0.2-3.6,0.5-6.1,1.1c-4.3,0.9-5,2.6-5,4.2
		c0,1.9,1.5,3.4,4.4,3.4C117.1,80.5,119.4,79.4,120.6,77z"
            />
            <path
                className={LOGO_ICON_CLASSNAME}
                fill="#FCAF17"
                d="M285.9,63.9c-0.6,0.4-1.2,0.8-1.8,1.2c-7-3.9-22-11.4-23.3-12c-1.4-0.7-3.8-1.7-3.5-4
		c0.4-3.2,6.1-10.3,8.5-13.3c13-15.8,18.8-20.7,22.6-20.2c4.1,0.5,6.4,4.8,6.7,24.6c-2.3-0.7-4.8-1.4-7.5-2.1
		c-0.2-2.9-0.8-7.2-1-8.7c-0.2-1.7-0.6-2.3-1.2-2.4c-0.8-0.1-2.7,1.7-4.4,3.5c-0.8,0.8-3,3-5.4,5.5c-2-0.1-3.4,0.3-4.1,1.3
		c-0.6,0.8-0.7,1.9-0.4,3.3c-1.3,1.3-2.4,2.4-3.2,3.2c-2.7,2.7-5.6,4.9-5.7,6c-0.1,0.9,1.2,1.8,3.2,3
		C270,55.3,281.7,61.7,285.9,63.9z M304.1,44.1c-4.6-1.6-15.9-5.8-27.8-7.4c-4.1-0.5-5.7,0.9-4.1,4.8c1.7,3.9,6,10.3,10.5,15.2
		c4.5,4.8,9.4,6.1,11-1.3c0.1-0.3,0.1-0.5,0.2-0.8c0.4-1.9,0.7-3.8,0.8-5.4c-2-0.9-4.3-1.9-6.5-2.8c0.1,1.7,0.3,4.7,0.4,6.9
		c0,0.3,0,0.6,0,0.8c0.1,3.7-1.1,3.7-3.2,2.2c-2.4-1.7-9.9-11.6-10.8-16c0-0.3,0.1-0.3,0.2-0.2c0.1,0,0.1,0,0.2,0.1c0,0,0,0,0,0l0,0
		c1.7,0.7,7.3,2.9,13,5.3c2.3,1,4.6,1.9,6.6,2.9c2,0.9,3.8,1.8,5.2,2.5c2.5,1.3,3.4,2.2,2.4,3.3c-5.1,5.2-23.1,12.6-29.3,21.8
		c0,0,0,0-0.1,0.1c-0.1,0.1-0.1,0.2-0.2,0.3c0,0,0,0.1-0.1,0.1c-0.3,0.6-0.7,1.5-0.9,2.7c-0.3,2,0.8,3.8,3.7,4.6
		c0.7,0.2,1.5,0.4,2.4,0.5c0.2,0,0.3,0,0.5,0c5.5,0.3,19.9-2.9,24.2-4.8c4.5-2,3.2-4,1.9-5c-1-0.8-6-3.6-8.4-4.9
		c-0.7,0.4-1.2,0.7-1.8,1.2c4.1,2.5,5.8,3.8,6.8,4.5c0.4,0.3,0.2,0.6-0.4,0.7c-0.1,0-0.3,0-0.4,0c-3.9,0.3-11.4,0.8-14.9,1
		c-0.5,0-1.1,0.1-1.4,0.1c0.9-0.8,1.8-1.5,2.3-1.9c7.5-5.3,20-12.7,23.7-15.9C312.6,56.8,320,49.6,304.1,44.1z"
            />
        </StyledLogo>
        <Claim $hovered={hovered}>{claim}</Claim>
    </>
);

const Claim = styled.p<{ $hovered: boolean }>`
    width: 27rem;
    position: absolute;
    bottom: 0;
    left: 24px;
    color: ${({ theme }) => theme.color.neutral50};
    margin: 0;
    opacity: 0;
    ${({ $hovered }) =>
        $hovered
            ? css`
                  transition: all 1000ms ease 700ms;
              `
            : css`
                  transition: all 50ms ease 0ms;
              `}
    ${({ $hovered }) =>
        $hovered &&
        css`
            opacity: 1;
        `}
`;

const StyledLogo = styled.svg<{ $hovered: boolean }>`
    width: 27rem;
    position: absolute;
    top: 0;
    right: -8px;
    color: ${({ theme }) => theme.color.neutral50};
    transition: right 100ms ease 0ms;

    .${LOGO_TEXT_CLASSNAME} {
        opacity: 0;
        transform: translateY(5px);
        ${({ $hovered }) =>
            $hovered
                ? css`
                      transition: all 300ms ease 300ms;
                  `
                : css`
                      transition: all 50ms ease 0ms;
                  `}
    }

    ${({ $hovered }) =>
        $hovered &&
        css`
            right: 0;

            .${LOGO_TEXT_CLASSNAME} {
                opacity: 100%;
                transform: translateY(0);
            }
        `}

    ${maxWidthBreakpoint.tablet(css`
        display: none;
    `)};
`;
