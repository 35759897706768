import { Drawer as DrawerMantine } from "@mantine/core";
import type { FC } from "react";
import styled from "styled-components";

import { Color, IconSizes } from "src/theme";
import { ActionIcon, Icon } from "../Icons";
import { Text } from "../Text";

type DrawerProps = Readonly<{
    open: boolean;
    onClose: () => void;
    title?: string;
}>;

export const Drawer: FC<DrawerProps> = ({ open, children, onClose, title }) => (
    <StyledDrawer withCloseButton={false} onClose={onClose} opened={open} position="right" overlayOpacity={0.2}>
        <HeightWrapper>
            <Title>
                <Text weight={700} color={Color.primary600} size="2.1rem">
                    {title || ""}
                </Text>
                <ActionIcon onClick={onClose} color={Color.supportNavy50}>
                    <Icon.Close size={IconSizes.m} color={Color.supportNavy500} />
                </ActionIcon>
            </Title>
            {children}
        </HeightWrapper>
    </StyledDrawer>
);

const Title = styled.div`
    display: grid;
    grid-template-columns: auto min-content;
    align-items: center;
`;

const StyledDrawer = styled(DrawerMantine)`
    & .mantine-Drawer-drawer {
        width: 600px;
        border-radius: ${({ theme }) => `${theme.radius.default} 0 0 ${theme.radius.default}`};
        background-color: ${({ theme }) => theme.color.white};
        box-shadow: -4px 0 20px 2px rgba(0, 0, 0, 0.1);
    }
`;

const HeightWrapper = styled.div`
    display: flex;
    flex-direction: column;
    height: 100%;
    padding: 3.6rem;
`;
