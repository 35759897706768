import { Textarea as TextareaMantine, TextareaProps as TextareaPropsMantine } from "@mantine/core";
import type { FC } from "react";
import styled, { css } from "styled-components";

import {
    createInputStyles,
    createInputWrapperStyles,
    errorMessageStyles,
    InputStylesProps,
    invalidInputStyles,
    labelStyles,
} from "../inputStyles";
import { removeUnusedPropsFromStyledInput } from "../utils";

// For unknown reasons, value type of Textarea is not exported.
export type TextareaProps = { value: string | number | undefined } & TextareaPropsMantine & InputStylesProps;

// For some reason we have to create new component, because typescript doesn't like when we only export styled component and use it in form
// This is happening only for textarea
export const Textarea: FC<TextareaProps> = (props) => <StyledTextarea {...props} />;

const StyledTextarea = styled(TextareaMantine).withConfig(removeUnusedPropsFromStyledInput)<TextareaProps>`
    ${createInputWrapperStyles}
    & textarea {
        ${createInputStyles} // Override basic input styles for textarea
        ${css`
            height: unset;
            padding-block: 0;
        `}
    }

    & label {
        ${labelStyles}
    }

    & .mantine-Textarea-invalid {
        ${invalidInputStyles}
    }

    & .mantine-Textarea-error {
        ${errorMessageStyles}
    }
`;
