import type { TableQueryParams } from "src/table";
import type {
    AcknowledgementErrorDto,
    GenerateAggregation,
    GenerateDto,
    LastPhoenixInterfaceDto,
    PaginationResponseDtoAggregationDto,
    PaginationResponseDtoPhoenixInterfaceOperationDto,
    PhoenixInterfaceOperationDto,
    PhoenixInterfaceOperationFilterDtoInterfaceOperationTypeEnum,
} from "src/types";
import { api } from "../../baseQuery";
import { requestMethod, url } from "../../constants";
import { tags } from "../../tags";
import { convertImportInterfacesOperationsTableFilterNames, transformGeneratedValuesForAggregationGeneration } from "./utils";

const interfacesApi = api.injectEndpoints({
    endpoints: (builder) => ({
        getInterfaceOperations: builder.query<PaginationResponseDtoPhoenixInterfaceOperationDto, TableQueryParams>({
            query: (queryParams) => {
                return {
                    url: url.IMPORT_INTERFACE_OPERATION,
                    params: {
                        pageSize: queryParams.pageSize,
                        pageNumber: queryParams.pageIndex + 1,
                        ...convertImportInterfacesOperationsTableFilterNames(queryParams.filters),
                    },
                };
            },
            keepUnusedDataFor: 0,
            providesTags: [tags.importInterfaceOperations],
        }),
        getLastInterfaceByOperationType: builder.query<
            LastPhoenixInterfaceDto,
            PhoenixInterfaceOperationFilterDtoInterfaceOperationTypeEnum
        >({
            query: (interfaceOperationType) => ({
                url: url.LAST_IMPORT_INTERFACE_OPERATION,
                params: { interfaceOperationType },
            }),
            keepUnusedDataFor: 0,
            providesTags: [tags.lastInterfaceOperation],
        }),
        getAggregations: builder.query<PaginationResponseDtoAggregationDto, TableQueryParams>({
            query: (queryParams) => {
                return {
                    url: url.AGGREGATOR_TOOL,
                    params: {
                        pageSize: queryParams.pageSize,
                        pageNumber: queryParams.pageIndex + 1,
                        ...queryParams.filters,
                    },
                };
            },
            keepUnusedDataFor: 0,
            providesTags: [tags.aggregations],
        }),
        getCountForAggregation: builder.query<number, GenerateDto>({
            query: (params) => {
                return {
                    url: url.AGGREGATOR_TOOL_COUNT,
                    params,
                };
            },
            keepUnusedDataFor: 0,
            providesTags: [tags.aggregatorToolCount],
        }),
        getInterfacesWithError: builder.query<Array<PhoenixInterfaceOperationDto>, GenerateDto>({
            query: (params) => {
                return {
                    url: url.AGGREGATOR_TOOL_INTERFACES_WITH_ERROR,
                    params,
                };
            },
            keepUnusedDataFor: 0,
            providesTags: [tags.aggregatorInterfacesWithError],
        }),
        getInterfaceAcknowledgementError: builder.query<Array<AcknowledgementErrorDto>, number>({
            query: (phoenixInterfaceOperationId) => ({
                url: `${url.INTERFACE_ACKNOWLEDGEMENT_ERROR}/${phoenixInterfaceOperationId}`,
            }),
            keepUnusedDataFor: 0,
            providesTags: [tags.lastInterfaceOperation],
        }),
        generateAggregation: builder.mutation<void, GenerateAggregation>({
            query: (values) => ({
                url: url.AGGREGATOR_TOOL_GENERATE,
                method: requestMethod.POST,
                params: transformGeneratedValuesForAggregationGeneration(values),
            }),
            invalidatesTags: [tags.aggregations, tags.aggregatorToolCount],
        }),
        uploadSapFile: builder.mutation<void, unknown>({
            query: (body) => ({
                url: url.AGGREGATOR_TOOL_UPLOAD_SAP_FILE,
                method: requestMethod.POST,
                body,
            }),
            invalidatesTags: [tags.aggregations, tags.aggregatorToolCount],
        }),
    }),
});

export const {
    useGetInterfaceOperationsQuery,
    useGetLastInterfaceByOperationTypeQuery,
    useGetInterfaceAcknowledgementErrorQuery,
    useGetAggregationsQuery,
    useLazyGetCountForAggregationQuery,
    useGenerateAggregationMutation,
    useUploadSapFileMutation,
    useLazyGetInterfacesWithErrorQuery,
} = interfacesApi;
