import type { TFunction } from "react-i18next";
import type { Cell } from "react-table";

import { useGetCustomerAttachmentsQuery } from "src/api";
import { TextColumnFilter } from "src/components";
import { createTableModule, TableNames } from "src/table";
import type { AttachmentDto, ColumnsType } from "src/types";
import { bytesFormatter } from "src/utils";

const createColumns = (t: TFunction): ColumnsType<AttachmentDto> => [
    {
        minWidth: 100,
        accessor: "fileName" as const,
        Header: t("customers.attachments.table.name") as string,
        Filter: TextColumnFilter,
    },
    {
        minWidth: 100,
        accessor: "fileSize" as const,
        Header: t("customers.attachments.table.size") as string,
        disableFilters: true,
        Cell: ({ value }: Cell<AttachmentDto>) => bytesFormatter(value),
    },
    {
        minWidth: 100,
        accessor: "contentType" as const,
        Header: t("customers.attachments.table.contentType") as string,
        disableFilters: true,
    },
];

export const tableModule = createTableModule<AttachmentDto>(TableNames.customerAttachments, useGetCustomerAttachmentsQuery, createColumns);
