import styled from "styled-components";

export const SuklExportRow = styled.div`
    display: grid;
    grid-template-columns: 1fr min-content;
    align-items: center;
    gap: 1rem;
    background-color: ${({ theme }) => theme.color.white};
    border: 1px solid ${({ theme }) => theme.color.neutral200};
    border-radius: ${({ theme }) => theme.radius.default};
    padding: 0.8rem 1.6rem;
`;
