import styled, { css } from "styled-components";

import { maxWidthBreakpoint } from "src/theme";

export const EmergencyOrdersLayout = styled.div`
    display: grid;
    grid-template-columns: 1fr minmax(auto, 900px) 1fr;
    grid-template-rows: min-content;
    grid-auto-rows: auto;
    min-height: 100vh;
    padding: 3.8rem 3.8rem 11.8rem 3.8rem;
    gap: 3.8rem;
    position: relative;
    background-color: ${({ theme }) => theme.color.neutral50};
    @media (max-width: 1250px) {
        grid-template-columns: 1fr;
    }

    ${maxWidthBreakpoint.tablet(css`
        padding: 2.8rem 1rem 17.8rem 1rem;
    `)}
`;
