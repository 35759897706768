import type { FC, ReactNode } from "react";
import styled, { css } from "styled-components";

import { maxWidthBreakpoint } from "src/theme";
import type { NavItems } from "src/types";
import { Breadcrumbs } from "../../Breadcrumbs";

export type BasicPageLayoutProps = Readonly<{
    breadcrumbs: NavItems;
    pageTooltip?: string | ReactNode;
    toolbar?: ReactNode;
    topSection?: ReactNode;
    maxWidth?: string;
    onMobileMenuOpen: () => void;
}>;

export const BasicPageLayout: FC<BasicPageLayoutProps> = ({
    breadcrumbs,
    maxWidth,
    pageTooltip,
    children,
    toolbar,
    topSection,
    onMobileMenuOpen,
}) => (
    <StyledBasicPageLayout maxWidth={maxWidth}>
        <StyledBreadcrumbs breadcrumbs={breadcrumbs} tooltip={pageTooltip} onMobileMenuOpen={onMobileMenuOpen} />
        {toolbar && <StyledToolbar>{toolbar}</StyledToolbar>}
        {topSection && <StyledTopSection>{topSection}</StyledTopSection>}
        <Content>{children}</Content>
    </StyledBasicPageLayout>
);

const StyledBasicPageLayout = styled.div<{ maxWidth?: string }>`
    display: grid;
    grid-template-columns: 1fr minmax(auto, ${({ maxWidth }) => maxWidth || "1800px"}) 1fr;
    grid-template-rows: min-content;
    grid-auto-rows: auto;
    margin: 1rem 2rem 2rem 2rem;
    row-gap: 1.5rem;
    position: relative;

    ${maxWidthBreakpoint.mobile(css`
        grid-template-columns: minmax(0, 1fr);
        row-gap: 1rem;
        margin: 2rem 1rem;
    `)};
`;

const StyledToolbar = styled.div`
    grid-row: 1;
    grid-column: 2;
    justify-self: end;

    ${maxWidthBreakpoint.mobile(css`
        grid-row: unset;
        grid-column: unset;
        justify-self: unset;
    `)};
`;

const StyledTopSection = styled.div`
    grid-column: 1 / -1;

    ${maxWidthBreakpoint.mobile(css`
        grid-column: unset;
    `)};
`;

const StyledBreadcrumbs = styled(Breadcrumbs)`
    grid-row: 1;
    grid-column: 1 / 3;

    ${({ theme }) =>
        maxWidthBreakpoint.mobile(css`
            grid-row: unset;
            grid-column: unset;
            position: sticky;
            top: 0;
            background-color: ${theme.color.neutral50};
            z-index: ${theme.zIndex.mobileBreadcrumbs};
            padding: 1rem 0;
        `)};
`;

const Content = styled.div`
    grid-column: 2 / 3;

    ${maxWidthBreakpoint.mobile(css`
        grid-column: unset;
    `)};
`;
