import { format, isAfter, isBefore, isEqual, parse, startOfDay, sub } from "date-fns";
import { cs } from "date-fns/locale";

import { DATE_FORMAT, DATE_FORMAT_FOR_BE } from "./constants";

// JavaScript counts months from 0 to 11
const unlimitedDate = new Date(9999, 11, 31, 23, 59, 59);

export const formatStringDate = (date?: string) => {
    if (!date) {
        return "";
    }

    return format(new Date(date), DATE_FORMAT, { locale: cs });
};

export const formatDateForBE = (date: Date): string => format(new Date(date), DATE_FORMAT_FOR_BE);

export const getDateFromBEFormat = (date: string): Date => parse(date, DATE_FORMAT_FOR_BE, new Date());

export const isDateBeforeToday = (date: Date): boolean => {
    const dateToCompare = startOfDay(new Date(date));
    const today = startOfDay(new Date());

    return isBefore(dateToCompare, today);
};

export const isDateOlderThenYesterday = (date: Date): boolean => {
    const dateToCompare = startOfDay(new Date(date));
    const yesterday = sub(startOfDay(new Date()), { days: 1 });

    return isBefore(dateToCompare, yesterday);
};

export const isDateTodayOrBefore = (date: Date): boolean => {
    const dateToCompare = startOfDay(new Date(date));
    const today = startOfDay(new Date());

    return isBefore(dateToCompare, today) || isEqual(dateToCompare, today);
};

export const isDateTodayOrAfter = (date: Date): boolean => {
    const dateToCompare = startOfDay(new Date(date));
    const today = startOfDay(new Date());

    return isAfter(dateToCompare, today) || isEqual(dateToCompare, today);
};

export const areDatesEqual = (date1: Date, date2: Date): boolean => isEqual(date1, date2);

export const isDateUnlimited = (date: Date) => {
    date.setHours(23, 59, 59);
    return areDatesEqual(date, unlimitedDate);
};
