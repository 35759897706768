import type { FC } from "react";
import { useCallback, useEffect } from "react";
import { useLocation } from "react-router-dom";

import { type BasicPageLayoutProps, BasicPageLayout } from "src/components";
import { useAppDispatch } from "src/utils";
import { closeMobileMenu, openMobileMenu } from "./actions";

type PageLayoutProps = Omit<BasicPageLayoutProps, "onMobileMenuOpen">;

export const PageLayout: FC<PageLayoutProps> = ({ ...props }) => {
    const dispatch = useAppDispatch();

    const { pathname } = useLocation();

    const onOpenMobileMenu = useCallback(() => dispatch(openMobileMenu()), [dispatch]);
    const onCloseMobileMenu = useCallback(() => dispatch(closeMobileMenu()), [dispatch]);

    useEffect(() => {
        onCloseMobileMenu();
    }, [onCloseMobileMenu, pathname]);

    return <BasicPageLayout onMobileMenuOpen={onOpenMobileMenu} {...props} />;
};
