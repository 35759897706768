import { FC, useCallback, useMemo, useState } from "react";
import { useNavigate, useSearchParams } from "react-router-dom";

import { useApproveShipToMutation, useGetCustomersWithSameAddressOrAcnQuery } from "src/api";
import arrowIcon from "src/assets/icons/previous-to-new-ship-to-arrow.svg";
import { Flex, GridElement, Icon, SvgIcon, Text } from "src/components";
import { PageLayout } from "src/layout";
import { NotificationTypes, useNotifications } from "src/notifications";
import { createSearchParamsAsString, CustomersTab, routes, TAB_PARAM } from "src/routes";
import { Color } from "src/theme";
import { useTranslation } from "src/translations";
import { CustomerTypeEnum } from "src/types";
import { isBoolean, isNotNullish, stringToBool } from "src/utils";
import { ApproveStartPointEnum } from "../approveCustomer";
import {
    ACN_PARAM,
    ACTIVE_PARAM,
    INVOICING_FREQUENCY_PARAM,
    PAYMENT_DUE_PARAM,
    PROPAGATE_SPECIAL_PRICES,
    START_POINT_PARAM,
    TYPE,
} from "../constants";
import { CopyTiersLayout, CopyTiersLayoutAreas } from "./components";
import { ApproveButton, createExpandRow, NewShipTo, PreviousShipTo, supplyLimitsTable } from "./containers";
import type { SupplyLimitsSettingsType } from "./types";
import { isApproveStartPointEnum, isCustomerTypeEnum, isInvoicingFrequencyEnum, isPaymentDueEnum } from "./types";

export const CopyTiers: FC = () => {
    const { t } = useTranslation();
    const [searchParams] = useSearchParams();
    const notifications = useNotifications();
    const navigate = useNavigate();

    const { newShipToId } = routes.copyTiers.useParams();
    const {
        data: previousShipTos,
        isLoading,
        isError,
    } = useGetCustomersWithSameAddressOrAcnQuery({ targetShipToId: Number(newShipToId), acn: searchParams.get(ACN_PARAM) || undefined });

    const [currentShipToIndex, setCurrentShipToIndexIndex] = useState(0);

    const [approveShipTo] = useApproveShipToMutation();
    const onApprove = useCallback(async () => {
        const paymentDue = searchParams.get(PAYMENT_DUE_PARAM);
        const invoicingFrequency = searchParams.get(INVOICING_FREQUENCY_PARAM);
        const startPoint = searchParams.get(START_POINT_PARAM);
        const active = stringToBool(searchParams.get(ACTIVE_PARAM));
        const type = searchParams.get(TYPE);
        const propagateSpecialPrices = searchParams.get(PROPAGATE_SPECIAL_PRICES);

        if (isPaymentDueEnum(paymentDue) && isInvoicingFrequencyEnum(invoicingFrequency) && isBoolean(active) && previousShipTos) {
            const customerType = isNotNullish(type) && isCustomerTypeEnum(type) ? type : undefined;
            try {
                await approveShipTo({
                    id: Number(newShipToId),
                    paymentDue,
                    invoicingFrequency,
                    active,
                    shipToIdToCopyFrom: previousShipTos[currentShipToIndex].id,
                    type: customerType ?? CustomerTypeEnum.NotSpecified,
                    propagateSpecialPrices: !!propagateSpecialPrices,
                }).unwrap();
                notifications.showNotification({
                    title: t("common.form.successTitle"),
                    message: t("copyTiers.success"),
                    type: NotificationTypes.success,
                });
                if (isApproveStartPointEnum(startPoint)) {
                    if (startPoint === ApproveStartPointEnum.Detail && newShipToId) {
                        navigate(`${routes.customerDetail.fillPathParams({ customerId: newShipToId })}`);
                    } else {
                        navigate(
                            `${routes.customers.path}?${createSearchParamsAsString({
                                [TAB_PARAM]: CustomersTab.new,
                            })}`,
                        );
                    }
                }
            } catch (error) {
                notifications.showNotification({
                    title: t("common.form.errorTitle"),
                    message: `${t("copyTiers.error")}`,
                    type: NotificationTypes.error,
                });
            }
        }
    }, [approveShipTo, currentShipToIndex, navigate, newShipToId, notifications, previousShipTos, searchParams, t]);

    const supplyLimitsSettings: SupplyLimitsSettingsType | undefined = useMemo(() => {
        if (previousShipTos) {
            const selectedShipTo = previousShipTos[currentShipToIndex];

            return {
                id: selectedShipTo.id,
                acn: selectedShipTo.acn,
                hasCustomizedSupplyLimits: selectedShipTo.hasCustomizedSupplyLimits,
            };
        }

        return undefined;
    }, [currentShipToIndex, previousShipTos]);

    return (
        <PageLayout
            breadcrumbs={[
                {
                    name: t("customers.name"),
                    route: routes.customers.path,
                    icon: Icon.People,
                },
                {
                    name: "Copy Tiers",
                    route: routes.copyTiers.path,
                },
            ]}
        >
            <Flex flexDirection="column">
                <CopyTiersLayout>
                    <PreviousShipTo
                        previousShipTos={previousShipTos}
                        isLoading={isLoading}
                        isError={isError}
                        currentShipToIndex={currentShipToIndex}
                        setCurrentShipToIndexIndex={setCurrentShipToIndexIndex}
                    />
                    <GridElement gridArea={CopyTiersLayoutAreas.arrow} justifySelf="center" alignSelf="center">
                        <SvgIcon icon={arrowIcon} />
                    </GridElement>
                    <NewShipTo supplyLimitsSettings={supplyLimitsSettings} />
                    <ApproveButton onApprove={onApprove} />
                </CopyTiersLayout>
                <Flex flexDirection="column" gap="1rem">
                    <Text color={Color.supportNavy500} size="1.8rem" weight={700}>
                        {t("copyTiers.supplyLimits.title")}
                    </Text>
                    {previousShipTos && (
                        <supplyLimitsTable.Container
                            expandedRow={createExpandRow}
                            additionalQueryParams={{ customerId: previousShipTos[currentShipToIndex].id }}
                        />
                    )}
                </Flex>
            </Flex>
        </PageLayout>
    );
};
