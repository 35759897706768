import type { ChangeEvent } from "react";
import type { UseFiltersColumnProps } from "react-table";

import { Color, IconSizes } from "src/theme";
import type { TableRecordType } from "src/types";
import { TextInput, TextInputRightSection } from "../Form";
import { Icon } from "../Icons";
import { TABLE_FILTER_HEIGHT } from "./constants";

type DefaultColumnFilterProps<RecordType extends TableRecordType> = Readonly<{
    column: UseFiltersColumnProps<RecordType>;
}>;

export const TextColumnFilter = <RecordType extends TableRecordType>({
    column: { filterValue, setFilter },
}: DefaultColumnFilterProps<RecordType>) => {
    return (
        <TextInput
            icon={<Icon.Search color={Color.neutral500} size={IconSizes.s} />}
            value={filterValue || ""}
            fontSize="1.2rem"
            withoutBorder
            withoutErrorPlaceholder
            height={TABLE_FILTER_HEIGHT}
            onChange={(event: ChangeEvent<HTMLInputElement>) => {
                setFilter(event.currentTarget.value || undefined); // Set undefined to remove the filter entirely
            }}
            rightSection={
                filterValue !== undefined && (
                    <TextInputRightSection onClick={() => setFilter(undefined)}>
                        <Icon.Close color={Color.supportNavy500} size={IconSizes.s} />
                    </TextInputRightSection>
                )
            }
        />
    );
};
