import type { FC } from "react";

import { Segment, SegmentedBox } from "src/components";
import { CustomerDto, CustomerStatusEnum } from "src/types";
import { Approved } from "./Approved";
import { Customer } from "./Customer";
import { NotApproved } from "./NotApproved";
import { PayerInfo } from "./PayerInfo";
import { ShipToInfo } from "./ShipToInfo";

type SegmentedInfoProps = {
    isPayer: boolean;
    customerDetail?: CustomerDto;
    isLoading?: boolean;
};

export const SegmentedInfo: FC<SegmentedInfoProps> = ({ isPayer, customerDetail, isLoading }) => (
    <SegmentedBox isLoading={isLoading}>
        <Segment>
            <Customer isPayer={isPayer} customerDetail={customerDetail} />
        </Segment>
        <Segment>
            {customerDetail?.status === CustomerStatusEnum.New ? (
                <NotApproved customerDetail={customerDetail} />
            ) : (
                <Approved isPayer={isPayer} customerDetail={customerDetail} />
            )}
        </Segment>
        <Segment>{isPayer ? <PayerInfo payerDetail={customerDetail} /> : <ShipToInfo shipToDetail={customerDetail} />}</Segment>
    </SegmentedBox>
);
