import type { AnyRecord } from "src/types";
import { cleanObject } from "src/utils";

export const convertCustomersToApproveUpdateDeactivateTableFilterNames = (filters: AnyRecord) => {
    const { category, ...clearedFilters } = filters;
    return {
        ...clearedFilters,
        categories: category ? [category] : undefined,
    };
};

export const convertCustomersToBlockUnblockFilterNames = (filters: AnyRecord) => {
    const { branches, ...clearedFilters } = filters;
    return cleanObject<string | number>({
        ...clearedFilters,
        isHospital: branches ?? undefined,
    });
};

export const convertPayersDistributionMarginTableFilterNames = (filters: AnyRecord) => {
    const { productPackageName, margin, ...clearedFilters } = filters;
    return cleanObject<string | number>({
        ...clearedFilters,
        name: productPackageName,
        marginSource: margin,
    });
};

export const convertPayersSpecialPricesTableFilterNames = (filters: AnyRecord) => {
    const { productPackageName, ...clearedFilters } = filters;
    return cleanObject<string | number>({
        ...clearedFilters,
        name: productPackageName,
    });
};

export const convertPayersDataModificationLogTableFilterNames = (filters: AnyRecord) => {
    const { timestamp, action, table, ...clearedFilters } = filters;
    return cleanObject<string | number | Array<unknown>>({
        ...clearedFilters,
        date: timestamp,
        actions: action ? [action] : undefined,
        tables: table ? [table] : undefined,
    });
};
