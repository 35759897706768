import type { FC } from "react";
import { useNavigate, useSearchParams } from "react-router-dom";

import { SuklReport } from "src/app/importExport/suklReport";
import { Icon, TabPanel, Tabs } from "src/components";
import { PageLayout } from "src/layout";
import { createSearchParamsAsString, ImportExportTab, routes, TAB_PARAM } from "src/routes";
import { useTranslation } from "src/translations";
import { createIsEnum } from "src/utils";
import { AggregationTool } from "./aggregationTool";
import { ExportInterfaces } from "./exportInterfaces";
import { ImportInterfaces } from "./importInterfaces";

const isImportExportTabEnum = createIsEnum(ImportExportTab);

export const ImportExport: FC = () => {
    const { t } = useTranslation();
    const [searchParams] = useSearchParams();
    const navigate = useNavigate();

    const activeTabFromSearchParam = searchParams.get(TAB_PARAM);

    const activeTab =
        activeTabFromSearchParam && isImportExportTabEnum(activeTabFromSearchParam)
            ? activeTabFromSearchParam
            : ImportExportTab.aggregationTool;

    return (
        <PageLayout
            breadcrumbs={[
                {
                    name: t("importExport.name"),
                    route: routes.importExport.path,
                    icon: Icon.Aggregation,
                },
            ]}
        >
            <Tabs<ImportExportTab>
                activeTab={activeTab}
                onTabChange={(tabValue: ImportExportTab) =>
                    navigate(
                        `${routes.importExport.path}?${createSearchParamsAsString({
                            [TAB_PARAM]: tabValue,
                        })}`,
                    )
                }
                tabs={[
                    {
                        tabValue: ImportExportTab.aggregationTool,
                        label: t("importExport.tabs.aggregationTool"),
                    },
                    {
                        tabValue: ImportExportTab.importInterfaces,
                        label: t("importExport.tabs.importInterfaces"),
                    },
                    {
                        tabValue: ImportExportTab.exportInterfaces,
                        label: t("importExport.tabs.exportInterfaces"),
                    },
                    {
                        tabValue: ImportExportTab.suklReport,
                        label: t("importExport.tabs.suklReport"),
                    },
                ]}
            >
                <TabPanel value={ImportExportTab.aggregationTool}>
                    <AggregationTool />
                </TabPanel>
                <TabPanel value={ImportExportTab.importInterfaces}>
                    <ImportInterfaces />
                </TabPanel>
                <TabPanel value={ImportExportTab.exportInterfaces}>
                    <ExportInterfaces />
                </TabPanel>
                <TabPanel value={ImportExportTab.suklReport}>
                    <SuklReport />
                </TabPanel>
            </Tabs>
        </PageLayout>
    );
};
