import type { TFunction } from "react-i18next";
import type { Cell } from "react-table";

import { useGetInterfaceOperationsQuery } from "src/api";
import { createSelectColumnFilter, TextColumnFilter } from "src/components";
import { createTableModule, TableNames } from "src/table";
import type { ColumnsType, PhoenixInterfaceOperationDto } from "src/types";
import { PhoenixInterfaceOperationDtoInterfaceTypeEnum, PhoenixInterfaceOperationDtoStatusEnum } from "src/types";
import { DATE_TIME_FORMAT, dateTimeFormatter, enumToLocalizedOptions, getTranslatedEnumValue } from "src/utils";

const createColumns = (t: TFunction, tableName: string): ColumnsType<PhoenixInterfaceOperationDto> => [
    {
        minWidth: 150,
        accessor: "operationTimestamp" as const,
        Header: t("importExport.importInterfaces.table.operationTimestamp") as string,
        disableFilters: true,
        Cell: ({ value }: Cell<PhoenixInterfaceOperationDto>) => dateTimeFormatter(value, DATE_TIME_FORMAT),
    },
    {
        minWidth: 100,
        accessor: "sequenceNumber" as const,
        Header: t("importExport.importInterfaces.table.sequenceNumber") as string,
        disableFilters: true,
    },
    {
        minWidth: 120,
        Header: t("importExport.importInterfaces.table.interfaceType") as string,
        accessor: "interfaceType" as const,
        Filter: createSelectColumnFilter(
            enumToLocalizedOptions(
                "PhoenixInterfaceOperationDtoInterfaceType",
                PhoenixInterfaceOperationDtoInterfaceTypeEnum,
                tableName === TableNames.importInterfaces
                    ? [PhoenixInterfaceOperationDtoInterfaceTypeEnum.TradingTerms, PhoenixInterfaceOperationDtoInterfaceTypeEnum.Quotas]
                    : [
                          PhoenixInterfaceOperationDtoInterfaceTypeEnum.AccountReceivables,
                          PhoenixInterfaceOperationDtoInterfaceTypeEnum.DeliveryNotes,
                          PhoenixInterfaceOperationDtoInterfaceTypeEnum.Invoices,
                          PhoenixInterfaceOperationDtoInterfaceTypeEnum.InventoryMovements,
                          PhoenixInterfaceOperationDtoInterfaceTypeEnum.MasterData,
                          PhoenixInterfaceOperationDtoInterfaceTypeEnum.RejectedOrders,
                          PhoenixInterfaceOperationDtoInterfaceTypeEnum.StockBalance,
                      ],
            ),
        ),
        Cell: ({ value }: Cell<PhoenixInterfaceOperationDto>) => getTranslatedEnumValue("PhoenixInterfaceOperationDtoInterfaceType", value),
    },
    {
        minWidth: 100,
        Header: t("importExport.importInterfaces.table.filename") as string,
        accessor: "filename" as const,
        Filter: TextColumnFilter,
    },
    {
        minWidth: 120,
        Header: t("importExport.importInterfaces.table.status") as string,
        accessor: "status" as const,
        Filter: createSelectColumnFilter(
            enumToLocalizedOptions("PhoenixInterfaceOperationDtoStatus", PhoenixInterfaceOperationDtoStatusEnum),
        ),
        Cell: ({ value }: Cell<PhoenixInterfaceOperationDto>) => getTranslatedEnumValue("PhoenixInterfaceOperationDtoStatus", value),
    },
    {
        minWidth: 100,
        Header: t("importExport.importInterfaces.table.errorMessage") as string,
        accessor: "errorMessage" as const,
        Filter: TextColumnFilter,
    },
];

export const importInterfaceTableModule = createTableModule<PhoenixInterfaceOperationDto>(
    TableNames.importInterfaces,
    useGetInterfaceOperationsQuery,
    createColumns,
);

export const exportInterfaceTableModule = createTableModule<PhoenixInterfaceOperationDto>(
    TableNames.exportInterfaces,
    useGetInterfaceOperationsQuery,
    createColumns,
);
