import { NAME } from "./constants";
import { reducer } from "./reducer";

export { setAccessToken } from "./actions";
export { AuthProvider } from "./AuthProvider";
export { default as msalInstance } from "./msalInstance";
export { acquireAccessToken } from "./utils";

export default {
    NAME,
    reducer,
};
